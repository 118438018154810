import React, { useState } from "react";
import EditFinanceProject from "./EditFinanceProject";
import GraphFinanceProject from "./GraphFinanceProject";

function FinanceProject(props) {
    const [viewEdit, setView] = useState(localStorage.getItem("viewEdit") || "graph");

    const __setView = (data) => {
        localStorage.setItem("viewEdit", data);
        setView(data);
    };
    if (viewEdit === "edit") {
      return <EditFinanceProject data={props} setView={__setView} />;
    } else {
      return <GraphFinanceProject data={props} setView={__setView} />;
    }
}

export default FinanceProject;
