import axios from "axios";
import { baseApiURL } from "./reusableFunctions";

const _log = {};

_log.add = async (payload) => {
  return axios
    .post(`${baseApiURL}/log/add`, payload)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      return { error: error };
    });
};

_log.getPf = async (pfid) => {
  return axios
    .get(`${baseApiURL}/log/list/pf/${pfid}`)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      return { error: error };
    });
};

_log.getUser = async (userId) => {
  return axios
    .get(`${baseApiURL}/log/list/user/${userId}`)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      return { error: error };
    });
};

_log.getTarget = async (targetId) => {
  return axios
    .get(`${baseApiURL}/log/list/target/${targetId}`)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      return { error: error };
    });
};

_log.getType = async (type) => {
  return axios
    .get(`${baseApiURL}/log/list/type/${type}`)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      return { error: error };
    });
};

export default _log;
