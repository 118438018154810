/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Form, Row, Col } from "react-bootstrap";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { mW, hasRole, baseApiURL, toSqlDate, baseURL } from "../../../components/reusableFunctions";
import { getTheme } from "@fluentui/react";
import { ToastContainer } from "react-toastify";
import Select from "react-select";
import Swal from "sweetalert2";
import { sortBy } from "lodash";
import axios from "axios";
import { _user } from "../../../components/userApi";
import _log from "../../../components/logApi";

function SuperAdmin() {
  const theme = getTheme();
  const isMounted = useRef();
  const user = useSelector((state) => state.user);
  const portfolioSettings = useSelector((state) => state.portfolioSettings);

  //Admin
  const [pfList, setPfList] = useState([]);
  const [pfMembers, setPfMembers] = useState([]);
  const [selectedPf, setSelectedPf] = useState(null);
  const [userLogShow, setUserLogShow] = useState(false);
  const [userLogs, setUserLogs] = useState([]);
  const [userLogModalTitle, setUserLogModalTitle] = useState("User Logs");
  const [spoofTo, setSpoofTo] = useState(null);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current && portfolioSettings.id) {
      if (hasRole("Super Admin")) {
        __getPfList();
      } else {
        alert("You have no Super Admin Access");
        localStorage.clear();
        sessionStorage.clear();
        window.location.replace(`${baseURL}/logout`);
      }
    }
  }, [portfolioSettings.id]);

  useEffect(() => {
    if (isMounted.current && selectedPf?.id) {
      __getPfMembers();
    }
  }, [selectedPf]);

  //Admin Only
  const __getPfList = async () => {
    const resPfList = await axios.get(`${baseApiURL}/portfolio`);
    if (resPfList.data) setPfList(resPfList.data?.portfolios);
    // console.log(resPfList.data);
  };

  const __selectPf = async (data) => {
    setSelectedPf(data);
  };

  const __getPfMembers = async () => {
    const resPfMembers = await axios.get(`${baseApiURL}/user/${selectedPf.pfid}`);
    if (resPfMembers.data) setPfMembers(sortBy(resPfMembers.data, "displayName"));
    // console.log(resPfMembers.data);
  };

  const __getUserLog = async (data) => {
    const resUserLog = await _log.getTarget(data.id);
    setUserLogs(resUserLog);
    setUserLogModalTitle(`${data.displayName} (${data.mail})`);
    setUserLogShow(true);
  };

  const __confirmSpoof = () => {
    Swal.fire({
      title: "Change Portfolio ID",
      text: "This will spoof your account as a member of the chosen portfolio and will log you out. Login again to see the change.",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Confirm",
      confirmButtonColor: "#DF3D22",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        __doPfSpoof();
      }
    });
  };

  const __errorSpoofAlert = () => {
    Swal.close();
    Swal.fire({
      position: "top-end",
      icon: "error",
      title: "Something went wrong!",
      showConfirmButton: false,
      timer: 1500,
      didClose: () => {
        setSpoofTo(null);
      },
    });
  };

  const __doPfSpoof = async () => {
    if (!spoofTo?.pfid) return;
    const payload = { ms_id: user.msId, updates: { pfid: spoofTo.pfid } };
    const resSpoof = await _user.updateInfo(payload);
    if (resSpoof) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.replace(`${baseURL}/logout`);
    } else {
      __errorSpoofAlert();
    }
  };

  return (
    <div className="main-content">
      <div className="pl-3" style={{ ...mW(50, 65) }}>
        <Row>
          {/* LEFT Section */}
          <Col style={{ ...mW(30, 35) }}>
            {/* Top LEFT Section */}
            <Row className="pb-3 pr-4">
              <Col className="pt-3">
                <Form.Row>
                  <Form.Label className="pt-2 txt-lg" column="sm">
                    SPOOF YOUR PORTFOLIO
                  </Form.Label>
                </Form.Row>
                <Row className="pr-4">
                  <Col className="pt-3">
                    <Form.Row className="mb-3">
                      <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 7) }}>
                        Spoof To
                      </Form.Label>
                      <Col>
                        <Select
                          value={spoofTo}
                          options={pfList}
                          placeholder={pfList.length === 0 ? "Loading portfolios..." : "Select portfolio to spoof"}
                          isClearable
                          getOptionLabel={(e) => `${e.company_name}`}
                          getOptionValue={(e) => e.id}
                          onChange={(e) => {
                            setSpoofTo(e);
                          }}
                        />
                        {spoofTo?.pfid && (
                          <DefaultButton
                            className={`ml-5 mt-1 float-right btn-danger`}
                            style={{ boxShadow: theme.effects.elevation4, marginleft: "1em" }}
                            text="Confirm Spoofing"
                            title="Spoof portfolioId to the selected option"
                            onClick={__confirmSpoof}
                            allowDisabledFocus
                          />
                        )}
                      </Col>
                    </Form.Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* Top LEFT Section END */}

            {/* Bottom LEFT Section */}
            <Row className="pb-3 pr-4 top-border-dashed">
              <Col className="pt-3">
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm">
                    VIEW PORTFOLIO USERS
                  </Form.Label>
                </Form.Row>
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 7) }}>
                    Portfolio
                  </Form.Label>
                  <Col>
                    <Select
                      value={selectedPf}
                      placeholder={pfList.length === 0 ? "Loading Portfolios..." : "Select Portfolio"}
                      options={pfList}
                      isClearable
                      getOptionLabel={(e) => `${e.company_name}`}
                      getOptionValue={(e) => e.id}
                      onChange={__selectPf}
                    />
                  </Col>
                </Form.Row>
                {selectedPf && (
                  <Form.Row className="ml-3">
                    <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
                      <div style={{ fontWeight: "bold" }}>Portfolio Users</div>
                      <div style={{ display: "flex", flexDirection: "column", marginLeft: 10, padding: 3 }}>
                        {pfMembers.map((data, index) => (
                          <div key={index} style={{ borderBottom: "1px dashed black", padding: 5, cursor: "pointer", color: data.isEnabled ? "black" : "#898989" }} onClick={() => __getUserLog(data)}>
                            {`${index + 1}. ${data.displayName} `} <span style={{ fontSize: 11 }}>{`(${data.mail})`}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Form.Row>
                )}
              </Col>
            </Row>

            <Row className="pb-3 pr-4">
              <Col className="pt-3">{/* Left Bottom Section */}</Col>
            </Row>
            {/* AutoTask END */}
            {/* Bottom LEFT Section END */}
          </Col>
          {/* LEFT Section End */}
          {/* Right Section */}
          <Col className="left-border-dashed" style={{ ...mW(0, 30) }}>
            {/* Top Right Section */}
            {hasRole("Super Admin") && (
              <Row className="pl-4 pb-3">
                <Col className="pt-3">
                  {/* Top Title-Right Area */}
                  {/* Top Title-Right Area End */}
                </Col>
              </Row>
            )}

            {/* Top Right Section End */}
            {/* Bottom-Right */}
            {/* Bottom-Right End */}
          </Col>
          {/* Right Section End */}
        </Row>
        {/* Footer */}
        {/* Footer End */}
        {/* Alerts and notification */}
        <div>
          <ToastContainer position="top-center" autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
        {/* Modals */}
        {/* View User Log Modal */}
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" backdrop="static" keyboard={false} centered show={userLogShow} onHide={() => setUserLogShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">{userLogModalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <>
              {userLogs.map((data, index) => (
                <div key={data.id} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", fontSize: "1rem", padding: 5, borderBottom: "1px solid rgb(212, 212, 212)" }}>
                  <div>
                    {index + 1}. {data.log}
                  </div>
                  <div>{toSqlDate(data.createdAt)}</div>
                </div>
              ))}
            </>
            {userLogs.length === 0 && <div>No logs for this user yet...</div>}
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default SuperAdmin;
