import React, { useState } from "react";
import { getTheme } from "@fluentui/react";
import { Image } from "react-bootstrap";
import plannerIcon from "../../../../assets/images/planner-icon.png";
import autotaskIcon from "../../../../assets/images/autotask-icon.png";
import syncIcon from "../../../../assets/images/loader.gif";
import "../../../../assets/css/contentProjects/projectBox.css";
import { toCurrency } from "../../../../components/reusableFunctions";

function ProjectBox({ data }) {
  const theme = getTheme();
  const [pboxHover, setpboxHover] = useState(false);
  const [openBG] = useState({ backgroundColor: data.isOpen ? "#FFFFFFF" : "#EAEAEA" });
  const hoverHandler = {
    style: {
      boxShadow: pboxHover ? (data.isOpen ? theme.effects.elevation16 : "") : data.isOpen ? theme.effects.elevation4 : "",
      ...openBG,
    },
    onMouseEnter: () => setpboxHover(true),
    onMouseLeave: () => setpboxHover(false),
  };
  return (
    <div className="project-box" {...hoverHandler}>
      <div className="project-box-header">
        <span className="project-box-title">
          <div className="project-box-title-text">
            {data.title}{" "}
            {data.isOpen ? (
              ""
            ) : (
              <small>
                (<em>CLOSED</em>)
              </small>
            )}
          </div>
          <Image className="project-manager-photo" src={data.photo} roundedCircle />
        </span>
        <div className="project-box-header-line"></div>
      </div>
      <div className="project-box-body">
        <span className="project-box-content">
          <div className="flex-table">
            <div className="flex-tr">
              <div className="flex-td">
                <div className="flex-item">Status ({data?.projectType?.toUpperCase()}):</div>
                <div className="flex-item">
                  <div className={`status-circle-${data.status}`}></div>
                </div>
              </div>
              {/* toCurrency(parseFloat().toFixed(2)) */}
              <div className="flex-td">
                <div className="flex-item">Revenue:</div>
                <div className="flex-item">${toCurrency(parseFloat(+data.revenue).toFixed(2))}</div>
              </div>
              <div className="flex-td">
                <div className="flex-item">Invoiced:</div>
                <div className="flex-item">${toCurrency(parseFloat(+data.invoiced).toFixed(2))}</div>
              </div>
              <div className="flex-td">
                <div className="flex-item">Remaining:</div>
                <div className={"flex-item" + (+data.remaining < 0 ? " text-danger font-weight-bold" : "")}>
                  {data.remaining < 0 ? `-` : ``}${toCurrency(parseFloat(Math.abs(+data.remaining)).toFixed(2))}
                </div>
              </div>
              <div className="flex-td-left">
                {data.inPlanner === 1 && (
                  <div style={{ height: "1em", width: "1em" }} className="ml-2">
                    <Image title="Project is in Planner" src={plannerIcon} fluid />
                  </div>
                )}
                {data.inAutotask === 1 && (
                  <div style={{ height: "1em", width: "1em" }} className="ml-2">
                    <Image title="Project is in Autotask" src={autotaskIcon} fluid />
                  </div>
                )}
              </div>
            </div>
            <div className="flex-tr">
              <div className="flex-td">
                <div className="flex-item">End Date:</div>
                <div className="flex-item">{data.dueDate}</div>
              </div>
              <div className="flex-td">
                <div className="flex-item">Effort Forecast:</div>
                <div className="flex-item">{toCurrency(parseFloat(+data.effortForecast).toFixed(2))}</div>
              </div>
              <div className="flex-td">
                <div className="flex-item">Effort Spent:</div>
                <div className="flex-item">{toCurrency(parseFloat(+data.effortSpent).toFixed(2))}</div>
              </div>
              <div className="flex-td">
                <div className="flex-item">Effort Remaining:</div>
                <div className={"flex-item" + (+data.effortRemaining < 0 ? " text-danger font-weight-bold" : "")}>{toCurrency(parseFloat(+data.effortRemaining).toFixed(2))}</div>
              </div>
              <div className="flex-td-right">
                {data.syncing && (
                  <div style={{ height: "1em" }} className="ml-2">
                    <Image title="Syncing autotask timesheets" src={syncIcon} fluid />
                  </div>
                )}
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
  );
}

export default ProjectBox;
