import { graphConfig, msalConfig, tokenRequest, loginRequest } from "../authConfig";
// import { baseURL } from "../components/reusableFunctions";
import { PublicClientApplication } from "@azure/msal-browser";
//import { InteractionRequiredAuthError } from "@azure/msal-common";
import axios from "axios";

const instance = new PublicClientApplication(msalConfig);
//const accounts = instance.getAllAccounts();
/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken
 */

const checkMsal = async () => {
  // console.log("Checking MSAL creds...");
  const accounts = instance.getAllAccounts();
  // console.log("Accounts:", accounts);
  // console.log("tokenRequest", tokenRequest);
  let requestMsal = {};
  if (accounts.length > 0) {
    // console.log("MsalAccount exists...");
    requestMsal = { scopes: tokenRequest.scopes, account: accounts[0] };
  } else {
    // console.log("No MsalAccount, using local cache...");
    const account = JSON.parse(localStorage.getItem("account"));
    requestMsal = { scopes: tokenRequest.scopes, account: account };
  }
  if (!requestMsal.account) {
    if (!window.isLoggingOut) {
      console.log("No MSAL anywhere, requesting poopup");
      return await instance.loginPopup(loginRequest);
    } else {
      console.log("Logging out ignoring queries...");
      return null;
    }
  }
  return await instance.acquireTokenSilent(requestMsal);
  // if (accounts.length > 0) {
  //   //console.log("silentToken");
  //   return await instance.acquireTokenSilent(requestMsal);
  // } else {
  //   //console.log("popupToken");
  //   /*
  //   if (!localStorage.getItem("user")) {
  //     localStorage.clear();
  //     //sessionStorage.clear();
  //     window.location.replace(`${baseURL}/login`);
  //   }*/
  //   const user = JSON.parse(localStorage.getItem("user"));
  //   const account = JSON.parse(localStorage.getItem("account"));

  //   if (account && user) {
  //     //let currentAccount = await instance.getAccountByUsername(user.mail);
  //     //let currentAccount = instance.getActiveAccount();
  //     console.log("CurrentAccount:", account);

  //     const accounts = instance.getAllAccounts();
  //     requestMsal.account = accounts[0];
  //     if (accounts.length === 0) {
  //       console.log("No cached MSAL creds, throwing popup...");
  //       const newcreds = await instance.acquireTokenPopup(requestMsal);
  //       console.log("NewCreds:", newcreds);
  //       if (newcreds && user && newcreds.uniqueId === user.msId) {
  //         return newcreds;
  //       } else {
  //         localStorage.clear();
  //         sessionStorage.clear();
  //         alert("You switched to another User without logging out. Please login again.");
  //         window.location.replace(`${baseURL}/login`);
  //         return {};
  //       }
  //     }
  //   } else {
  //     localStorage.clear();
  //     sessionStorage.clear();
  //     window.location.replace(`${baseURL}/login`);
  //     return {};
  //   }
  // }
};

export async function getUserProfile() {
  const mscred = await checkMsal();
  if (mscred) {
    const headers = {
      Authorization: `Bearer ${mscred.accessToken}`,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    const options = {
      headers: headers,
    };

    return axios
      .get(graphConfig.profile, options)
      .then((res) => {
        return res.data;
      })
      .catch(function (error) {
        return { error: error };
      });
  } else {
    return { error: "Something went wrong during API Call" };
  }
}

export async function getUserPhoto() {
  const mscred = await checkMsal();
  if (mscred) {
    const headers = {
      Authorization: `Bearer ${mscred.accessToken}`,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    const options = {
      headers: headers,
      responseType: "blob",
    };

    return axios
      .get(graphConfig.photo, options)
      .then((res) => {
        return res.data;
      })
      .catch(function (error) {
        return { error: error };
      });
  } else {
    return { error: "Something went wrong during API Call" };
  }
}

export async function getMemberPhoto(userId) {
  const mscred = await checkMsal();
  if (mscred) {
    const headers = {
      Authorization: `Bearer ${mscred.accessToken}`,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    const options = {
      headers: headers,
      responseType: "blob",
    };

    return axios
      .get(graphConfig.getMemberPhoto.replace("[user-id]", userId), options)
      .then((res) => {
        return res.data;
      })
      .catch(function (error) {
        return { error: error };
      });
  } else {
    return { error: "Something went wrong during API Call" };
  }
}

export async function getUserAvatar(name) {
  const axcon = { responseType: "blob" };
  return axios
    .get(graphConfig.createAvatar.replace("[user-name]", name), axcon)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      return { error: error };
    });
}

export async function doGetGraphApi(type) {
  const mscred = await checkMsal();
  if (mscred) {
    const headers = {
      Authorization: `Bearer ${mscred.accessToken}`,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };

    const options = {
      headers: headers,
    };

    return axios
      .get(type, options)
      .then((res) => {
        return res.data;
      })
      .catch(function (error) {
        return { error: error };
      });
  } else {
    return { error: "Something went wrong during API Call" };
  }
}

export async function doPostGraphApi(apiurl, payload) {
  const mscred = await checkMsal();
  if (mscred) {
    const headers = {
      Authorization: `Bearer ${mscred.accessToken}`,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    };
    const options = {
      headers: headers,
    };

    return axios
      .post(apiurl, payload, options)
      .then((res) => {
        return res.data;
      })
      .catch(function (error) {
        //console.error(error);
        return { error: error };
      });
  } else {
    return { error: "Something went wrong during API Call" };
  }
}

export async function doPatchGraphApi(apiurl, payload, etag) {
  const mscred = await checkMsal();
  if (mscred) {
    const headers = {
      Authorization: `Bearer ${mscred.accessToken}`,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "If-Match": etag,
    };
    const options = {
      headers: headers,
    };

    return axios
      .patch(apiurl, payload, options)
      .then((res) => {
        return res.data;
      })
      .catch(function (error) {
        //console.error(error);
        return { error: error };
      });
  } else {
    //await instance.loginPopup(loginRequest);
    //window.location.replace(`${baseURL}/login`);
    return { error: "Something went wrong during API Call" };
  }
}

export async function doDeleteGraphApi(apiurl, etag) {
  const mscred = await checkMsal();
  if (mscred) {
    const headers = {
      Authorization: `Bearer ${mscred.accessToken}`,
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      "If-Match": etag,
    };
    const options = {
      headers: headers,
    };

    return axios
      .delete(apiurl, options)
      .then((res) => {
        return res.data;
      })
      .catch(function (error) {
        //console.error(error);
        return { error: error };
      });
  } else {
    //await instance.loginPopup(loginRequest);
    //window.location.replace(`${baseURL}/login`);
    return { error: "Something went wrong during API Call" };
  }
}
