import { doGetGraphApi, doPostGraphApi } from "../../../components/graph";
import { graphConfig } from "../../../authConfig";
import { processProjectData } from "../contentProjects/components/getProjectData";
import { baseApiURL, datePast } from "../../../components/reusableFunctions";
import axios from "axios";
import { nanoid } from "nanoid";

const portfolio = {};

portfolio.getUsers = async (group_id) => {
  const members = await doGetGraphApi(graphConfig.getMembers.replace("[group-id]", group_id));
  return members;
};

portfolio.getMyProfile = async () => {
  await doGetGraphApi(graphConfig.profile);
};

portfolio.getData = async (group_id, pf_id) => {
  if (!pf_id) return { error: "API error. PFID cannot be blank when querying..." };
  const localProjects = await portfolio.getLocalProjects(pf_id);
  if (localProjects?.error) {
    return localProjects;
  } else {
    const tmpProjects = localProjects.filter((data) => !(data.project_status === 0 && datePast(data.closed_date ?? data.updatedAt) > 185));
    // console.log(tmpProjects);
    const portfolioNewData = portfolio.processData(tmpProjects);
    return { details: portfolioNewData, projects: tmpProjects };
  }
  /*
  const data = await doGetGraphApi(graphConfig.getPlans.replace("[group-id]", group_id));
  if (data?.error) {
    return data;
  } else {
    const localProjects = await portfolio.getLocalProjects(pf_id);
    if (localProjects?.error) {
      return localProjects;
    } else {
      const ourProjects = compArray(data.value, localProjects, "id", "ms_pid", true, 2);
      const archivedProjects = ourProjects.forRemoval;
      for (const archivedProject of archivedProjects) {
        if (archivedProject.in_planner === 1) {
          await portfolio.archiveProject(archivedProject.pid);
        }
      }
      const portfolioNewData = portfolio.processData(ourProjects.inBoth);
      return { details: portfolioNewData, projects: ourProjects.inBoth };
    }
  }
  */
};

portfolio.getOpenProjectsData = async (pfid) => {
  if (!pfid) return { error: "API error. PFID cannot be blank when querying..." };
  const localProjects = await portfolio.getLocalProjects(pfid);
  if (localProjects?.error) {
    return localProjects;
  } else {
    const tmpProjects = localProjects.filter((data) => !(data.project_status === 0 && datePast(data.closed_date ?? data.updatedAt) > 30));
    // console.log("tmpProjects:", tmpProjects);
    const portfolioNewData = portfolio.procOpenProjectsData(tmpProjects);
    return { details: portfolioNewData, projects: tmpProjects };
  }
};

portfolio.procOpenProjectsData = (projects) => {
  let portfolio = {
    active_projects: projects.filter((data) => data.project_status === 1).length,
    total_projects: projects.filter((data) => !(data.project_status === 0 && datePast(data.closed_date ?? data.updatedAt) > 30)).length,
    efforts: 0,
    efforts_done: 0,
    efforts_left: 0,
    onhold_projects: 0,
    inprogress_projects: 0,
    new_projects: 0,
    budget: 0,
    budget_left: 0,
    invoiced: 0,
    off_track: 0,
    at_risk: 0,
    on_track: 0,
  };
  // console.log(projects);
  for (const project of projects) {
    if (project.project_status === 1) {
      const processedProject = processProjectData(project);
      //if (processedProject.project_status === 1) {
      // if (isNaN(processedProject.effort_remaining)) {
      //   console.log(processedProject.effort_remaining);
      // }
      portfolio.efforts += +processedProject.forecast_hours;
      // console.log(processedProject.effort_spent);
      portfolio.efforts_done += +processedProject.effort_spent;
      portfolio.efforts_left += +processedProject.effort_remaining;
      if (project.project_status) {
        portfolio.onhold_projects += processedProject.project_progress === 3 ? 1 : 0;
        portfolio.inprogress_projects += processedProject.project_progress === 2 ? 1 : 0;
        portfolio.new_projects += processedProject.project_progress === 1 ? 1 : 0;
        // }
        // if (!(processedProject.project_status === 0 && datePast(processedProject.updatedAt) > 30)) {
        portfolio.budget += +processedProject.total_budget;
        portfolio.budget_left += +processedProject.budget_remaining;
        portfolio.invoiced += +processedProject.invoiced;
        // }
        // if (project.project_status) {
        portfolio.off_track += processedProject.overall_status === "high" ? 1 : 0;
        portfolio.at_risk += processedProject.overall_status === "mid" ? 1 : 0;
        portfolio.on_track += processedProject.overall_status === "low" ? 1 : 0;
      }
    }
  }
  // console.log(portfolio);
  return portfolio;
};

portfolio.getLocalProjects = async (pf_id) => {
  return axios
    .get(`${baseApiURL}/project/${pf_id}/*/Active,Burndown,Task,Timesheet,User`)
    .then((res) => {
      // console.log(res.data);
      return res.data;
    })
    .catch(function (error) {
      console.log(error);
      return { error: error };
    });
};

portfolio.getLocalAtProjects = async (pf_id) => {
  return axios
    .get(`${baseApiURL}/project/${pf_id}/*/InAutotask,Active`)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      console.log(error);
      return { error: error };
    });
};

portfolio.syncProject = async (pid, inPlanner = 1, inAutotask = 1) => {
  const updates = { in_planner: inPlanner, in_autotask: inAutotask };
  console.log(`Removing sync options: ${pid} Planner: ${inPlanner} Autotask: ${inAutotask}`);
  const payload = { pid: pid, updates: updates };
  return axios
    .post(`${baseApiURL}/project/edit`, payload)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      console.log(error);
      return { error: error };
    });
};

portfolio.getTenant = async (tenant_id) => {
  return axios
    .get(`${baseApiURL}/portfolio/tenant/${tenant_id}`)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      console.log(error);
      return { error: error };
    });
};

portfolio.getSettings = async (pfid) => {
  if (!pfid) return { error: "Get: Invalid Portfolio Code" };
  return axios
    .get(`${baseApiURL}/portfolio/${pfid}`)
    .then((res) => {
      // console.log("PFData:", res.data);
      return res.data;
    })
    .catch(function (error) {
      console.log(error);
      return { error: error };
    });
};

portfolio.addPortfolio = async (payload) => {
  if (!payload.pfid) return { error: "Add: Invalid Portfolio Code" };
  const createPayload = {
    description: "Project Portfolio Management One",
    displayName: "PPM.ONE",
    groupTypes: ["Unified"],
    mailEnabled: true,
    mailNickname: `ppm.one-api-${nanoid(6)}`,
    securityEnabled: false,
  };
  const resultCreateGroup = await doPostGraphApi(graphConfig.createGroup, createPayload);
  if (resultCreateGroup && resultCreateGroup.id) {
    payload.ms_365_group = resultCreateGroup.id;
    payload.group_data = JSON.stringify(resultCreateGroup);
  } else {
    return { error: "Cannot create Office 365 Group. Please contact us." };
  }

  return axios
    .post(`${baseApiURL}/portfolio/add`, payload)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      console.log(error);
      return { error: error };
    });
};

portfolio.saveSettings = async (payload) => {
  if (!payload.pfid) return { error: "Update: Invalid Portfolio Code" };
  return axios
    .post(`${baseApiURL}/portfolio/update`, payload)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      console.log(error);
      return { error: error };
    });
};

portfolio.processData = (projects) => {
  let portfolio = {
    active_projects: projects.filter((data) => data.project_status === 1).length,
    total_projects: projects.filter((data) => !(data.project_status === 0 && datePast(data.closed_date ?? data.updatedAt) > 30)).length,
    efforts: 0,
    efforts_done: 0,
    efforts_left: 0,
    onhold_projects: 0,
    inprogress_projects: 0,
    new_projects: 0,
    budget: 0,
    budget_left: 0,
    invoiced: 0,
    off_track: 0,
    at_risk: 0,
    on_track: 0,
  };
  // console.log(projects);
  for (const project of projects) {
    const processedProject = processProjectData(project);
    //if (processedProject.project_status === 1) {
    portfolio.efforts += +processedProject.forecast_hours;
    portfolio.efforts_done += +processedProject.effort_spent;
    portfolio.efforts_left += +processedProject.effort_remaining;
    if (project.project_status) {
      portfolio.onhold_projects += processedProject.project_progress === 3 ? 1 : 0;
      portfolio.inprogress_projects += processedProject.project_progress === 2 ? 1 : 0;
      portfolio.new_projects += processedProject.project_progress === 1 ? 1 : 0;
    }
    if (!(processedProject.project_status === 0 && datePast(processedProject.closed_date ?? processedProject.updatedAt) > 30)) {
      portfolio.budget += +processedProject.total_budget;
      portfolio.budget_left += +processedProject.budget_remaining;
      portfolio.invoiced += +processedProject.invoiced;
    }
    if (project.project_status) {
      portfolio.off_track += processedProject.overall_status === "high" ? 1 : 0;
      portfolio.at_risk += processedProject.overall_status === "mid" ? 1 : 0;
      portfolio.on_track += processedProject.overall_status === "low" ? 1 : 0;
    }
  }
  // console.log(portfolio);
  return portfolio;
};

export default portfolio;
