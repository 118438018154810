/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { mW } from "../../../components/reusableFunctions";
import { _rbac } from "../../../components/rbacApi";
import { getTheme } from "@fluentui/react";
import { ToastContainer } from "react-toastify";
import Select from "react-select";
import Swal from "sweetalert2";
import { FluentIcon } from "../../../components/FluentIcon.js";

function ManageRbac() {
  const theme = getTheme();
  const isMounted = useRef();
  const portfolioSettings = useSelector((state) => state.portfolioSettings);
  const [permissions, setPermissions] = useState([]);
  const [xPermissions, setXPermissions] = useState([]);
  const [permForAdding, setPermForAdding] = useState(null);
  const [pfRoles, setPfRoles] = useState([]);
  const [pfRolePermissions, setPfRolePermissions] = useState([]);
  const [newRoleName, setNewRoleName] = useState("");
  const [selectedRole, setSelectedRole] = useState(null);
  const [isRoleModalOpen, setIsRoleModalOpen] = useState(false);
  const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current && portfolioSettings.id) {
      __getPortfolioRoles(portfolioSettings.id);
      __getPermissions();
    }
  }, [portfolioSettings.id]);

  useEffect(() => {
    if (isMounted.current && selectedRole?.id) {
      __getPortfolioRolePermissions(selectedRole.id);
    }
  }, [selectedRole]);

  const __getPermissions = async () => {
    const res_permissions = await _rbac.getPermissions();
    setPermissions(res_permissions);
  };

  const __getPortfolioRoles = async (pf_id) => {
    const res_pfRoles = await _rbac.getPortfolioRoles(pf_id);
    setPfRoles(res_pfRoles);
  };

  const __getPortfolioRolePermissions = async (role_id) => {
    const res_pfRolePermissions = await _rbac.getPortfolioRolePermissions(role_id);
    setPfRolePermissions(res_pfRolePermissions);
  };

  const __selectPortfolioRole = async (data) => {
    setSelectedRole(data);
  };

  const __addNewPortfolioRole = async () => {
    setInProgress(true);
    const payload = { pf_id: portfolioSettings.id, name: newRoleName };
    const res_pfRole = await _rbac.createPortfolioRole(payload);
    if (res_pfRole.error || !res_pfRole) {
      console.log("Error adding portfolio role");
    } else {
      setPfRoles([...pfRoles, res_pfRole]);
      __successAlert();
    }
    setNewRoleName("");
    setInProgress(false);
    setIsRoleModalOpen(false);
  };

  const __deletePortfolioRole = async () => {
    setInProgress(true);
    const res_pfRole = await _rbac.deletePortfolioRole(selectedRole?.id);
    if (res_pfRole.error || !res_pfRole) {
      console.log("Error removing portfolio role");
    } else {
      setPfRoles(pfRoles.filter((data) => data.id !== selectedRole?.id));
      setSelectedRole(null);
      //__successAlert();
    }
    setInProgress(false);
  };

  const __deletePortfolioRolePermission = async (perm_id) => {
    setInProgress(true);
    const res_pfRolePermissions = await _rbac.deletePortfolioRolePermission(selectedRole?.id, +perm_id);
    if (res_pfRolePermissions.error || !res_pfRolePermissions) {
      console.log("Error removing portfolio role");
    } else {
      setPfRolePermissions(pfRolePermissions.filter((data) => data.id !== +perm_id));
      //__successAlert();
    }
    setInProgress(false);
  };

  const __initAddRolePermission = async () => {
    let pfRolePermission_ids = pfRolePermissions.map((data) => {
      return data.id;
    });
    let temp_perms = permissions.filter((data) => !pfRolePermission_ids.includes(data.id));
    setXPermissions(temp_perms);
    setIsPermissionModalOpen(true);
  };

  const __addRolePermission = async () => {
    setInProgress(true);
    const payload = { role_id: selectedRole?.id, permission_id: permForAdding?.id };
    const res_newPermission = await _rbac.addPortfolioRolePermission(payload);
    if (res_newPermission.error || !res_newPermission) {
      console.log("Error adding portfolio role permission");
    } else {
      setPfRolePermissions([...pfRolePermissions, ...permissions.filter((data) => data.id === permForAdding?.id)]);
    }
    setPermForAdding(null);
    setIsPermissionModalOpen(false);
    setInProgress(false);
  };

  const __confirmDeletePortfolioRole = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "This will remove the role, all the permissions added to it and will unassign the role from all users who has it.",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Confirm",
      confirmButtonColor: "#DF3D22",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        __deletePortfolioRole();
      }
    });
  };

  const __confirmDeletePortfolioRolePermission = async (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This will remove the permission for this role and all users using this role will lose access to this permission.",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Confirm",
      confirmButtonColor: "#DF3D22",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        __deletePortfolioRolePermission(data.target.value);
      }
    });
  };

  const __successAlert = () => {
    Swal.close();
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Success!",
      showConfirmButton: false,
      timer: 1500,
      didClose: () => {
        console.log("Processing done...");
      },
    });
  };

  return (
    <div className="main-content">
      <div className="pl-3" style={{ ...mW(50, 65) }}>
        <Row>
          {/* LEFT Section */}
          <Col style={{ ...mW(30, 35) }}>
            {/* Top LEFT Section */}
            <Row className="pb-3 pr-4">
              <Col className="pt-3">
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm">
                    MANAGE ROLES
                  </Form.Label>
                </Form.Row>
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 7) }}>
                    Select Role
                  </Form.Label>
                  <Col>
                    <Select value={selectedRole} options={pfRoles} isClearable getOptionLabel={(e) => e.name} getOptionValue={(e) => e.id} onChange={__selectPortfolioRole} />
                  </Col>
                  <Col style={{ ...mW(0, 8) }} className="pl-0">
                    <DefaultButton className="float-right" style={{ boxShadow: theme.effects.elevation4, marginTop: "0.3em" }} text="Create Role" title="Create New Role" onClick={() => setIsRoleModalOpen(true)} allowDisabledFocus />
                  </Col>
                </Form.Row>
              </Col>
            </Row>
            {/* Top LEFT Section END */}

            {/* Bottom LEFT Section */}
            {/* This section is for AutoTask */}
            <Row className="pb-3 pr-4 top-border-dashed">
              <Col className="pt-3">
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm">
                    MANAGE ROLE PERMISSIONS
                  </Form.Label>
                  {selectedRole?.id && (
                    <Col style={{ ...mW(0, 8) }} className="pl-0">
                      <DefaultButton
                        className="float-right p-0 text-danger"
                        style={{ boxShadow: theme.effects.elevation4, marginTop: "0.3em", fontSize: "0.8em" }}
                        text="Delete Role"
                        title="Delete Selected Role"
                        onClick={__confirmDeletePortfolioRole}
                        allowDisabledFocus
                      />
                    </Col>
                  )}
                </Form.Row>
                <Form.Row className="mb-3">
                  {selectedRole?.id ? (
                    <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 20) }}>
                      Permissions for '<span className="text-primary">{selectedRole?.name}</span>'
                    </Form.Label>
                  ) : (
                    <span className="ml-3 font-italic text-secondary">No role selected...</span>
                  )}
                </Form.Row>
                {selectedRole?.id && (
                  <>
                    {pfRolePermissions.length > 0 ? (
                      <>
                        {pfRolePermissions.map((data, index) => (
                          <Form.Row key={index} className="ml-3">
                            <Form.Label className="mt-1 task-name" column="sm" style={{ ...mW(0, 2.5) }}>
                              #{index + 1}{" "}
                            </Form.Label>
                            <Col>
                              <Form.Control size="md" type="text" placeholder="Add a name..." className="input-task-name" value={data.name} maxLength="120" readOnly />
                            </Col>
                            <Col style={{ ...mW(0, 6) }}>
                              <Button className="i-btn" variant="danger" value={data.id} title="Delete Role" size="sm" onClick={__confirmDeletePortfolioRolePermission}>
                                <FluentIcon name="Delete" />
                              </Button>
                            </Col>
                          </Form.Row>
                        ))}
                      </>
                    ) : (
                      <span className="ml-3 font-italic text-secondary">No permissions found</span>
                    )}
                  </>
                )}
                {selectedRole?.id && (
                  <>
                    {pfRolePermissions?.length !== permissions?.length ? (
                      <Form.Row>
                        <Col>
                          <DefaultButton className="ml-5 mt-3" style={{ boxShadow: theme.effects.elevation4, marginleft: "1em" }} text="Add Permission" title="Add permission to this role" onClick={__initAddRolePermission} allowDisabledFocus />
                        </Col>
                      </Form.Row>
                    ) : (
                      <Form.Row className="mt-3 ml-3">
                        <Col>
                          <small className="font-italic text-info">All permissions have been added</small>
                        </Col>
                      </Form.Row>
                    )}
                  </>
                )}
              </Col>
            </Row>
            {/* AutoTask END */}
            {/* Bottom LEFT Section END */}
          </Col>
          {/* LEFT Section End */}
          {/* Right Section */}
          <Col className="left-border-dashed" style={{ ...mW(0, 30) }}>
            {/* Top Right Section */}
            <Row className="pl-4 pb-3">
              <Col className="pt-3">
                {/* Top Title-Right Area */}
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm">
                    HOW IT WORKS
                  </Form.Label>
                </Form.Row>
                {/* Top Title-Right Area End */}
                <Form.Row className="mb-3">
                  <span className="ml-3"> This is a text on how Roles and Permissions work</span>
                </Form.Row>
              </Col>
            </Row>
            {/* Top Right Section End */}
            {/* Bottom-Right */}
            {/* Bottom-Right End */}
          </Col>
          {/* Right Section End */}
        </Row>
        {/* Footer */}
        {/* Footer End */}
        {/* Alerts and notification */}
        <div>
          <ToastContainer position="top-center" autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
        {/* Modals */}
        {/* New Role Modal */}
        <Modal show={isRoleModalOpen} backdrop="static" keyboard={false} size="md" centered>
          <Modal.Header>
            <Modal.Title>Add New Portfolio Role</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Row className="mb-3">
              <Form.Label className="pt-2 txt-lg" column="sm">
                ROLE NAME
              </Form.Label>
              <Form.Control className="ml-3" size="md" type="text" placeholder="" value={newRoleName} onChange={(e) => setNewRoleName(e.target.value)} required />
            </Form.Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              disabled={inProgress}
              onClick={() => {
                setIsRoleModalOpen(false);
                setNewRoleName("");
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" disabled={inProgress} onClick={__addNewPortfolioRole}>
              {inProgress ? "Saving..." : "Create"}
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Add Role Permission Modal */}
        <Modal show={isPermissionModalOpen} backdrop="static" keyboard={false} size="md" centered>
          <Modal.Header>
            <Modal.Title>Add Permission</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Row className="mb-3">
              <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(7, 7) }}>
                Permission
              </Form.Label>
              <Col>
                <Select options={xPermissions} isClearable getOptionLabel={(e) => e.name} getOptionValue={(e) => e.id} onChange={(e) => setPermForAdding(e)} />
              </Col>
            </Form.Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              disabled={inProgress}
              onClick={() => {
                setIsPermissionModalOpen(false);
                setXPermissions(null);
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" disabled={inProgress || !permForAdding} onClick={__addRolePermission}>
              {inProgress ? "Saving..." : "Add To Role"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default ManageRbac;
