/* eslint-disable react-hooks/exhaustive-deps */
import "./App.css";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { _doLogin, _hasAuth, _doSaveUser } from "./actions/authenticationActions";
import _portfolio from "./actions/portfolioActions";
import { Switch, Redirect, Route } from "react-router-dom";
import OAuthCallback from "./screens/guest/OAuthCallback";
import LoginForm from "./screens/guest/LoginForm";
import MainContainer from "./screens/private/MainContainer";
import { _rbac } from "./components/rbacApi";
import { _user } from "./components/userApi";
import portfolio from "./screens/private/portfolio/getPortfolioData";

function App() {
  const action = useDispatch();
  const isAuth = useSelector((state) => state.isAuth);

  useEffect(() => {
    localStorage.removeItem("atSyncRunning");
    if (window.location.href.indexOf("/oauth") === -1) checkAuth();
  }, []);

  const checkAuth = async () => {
    if (!isAuth) {
      let user = JSON.parse(localStorage.getItem("user"));
      const savedPortfolioSettings = JSON.parse(localStorage.getItem("portfolioSettings"));
      const members = JSON.parse(localStorage.getItem("members"));
      if (user && user.id) {
        const portfolioSettings = await portfolio.getSettings(savedPortfolioSettings.pfid);
        const userRbac = await _rbac.getUserRolesPermissions(user.id);
        const userRes = await _user.get(user.id);
        user = { ...user, ...userRes, rbac: userRbac };
        // user.rbac = userRbac;
        window.rbac_data = user.rbac;
        action(_portfolio.loadSettings(portfolioSettings));
        action(_portfolio.loadMembers(members ?? []));
        action(_doLogin());
        action(_doSaveUser(user));
        action(_hasAuth(true));
      }
    }
  };
  // console.log(window.location.href);
  const showLoginComponent = () => {
    // console.log(window.location.href);
    if (window.location.href.indexOf("#code=") > -1) {
      console.log("gotoOAuth");
      return <div>Logging in...</div>;
    }
    if (window.location.href.indexOf("/oauth") > -1) {
      console.log("gotoOAuth");
      return (
        <div>
          <OAuthCallback />
        </div>
      );
    } else {
      console.log("gotoLogin");
      return (
        <div>
          <Redirect to="/login" />
          {/* <LoginForm /> */}
        </div>
      );
    }
  };
  const gotoMembersComponent = () => {
    console.log("gotoMembersComponent");
    return <div>{window.location.href.indexOf("/members") > -1 ? <></> : <Redirect to="/members" />}</div>;
  };

  return (
    <div className="App" style={{ fontSize: "1rem" }}>
      {!isAuth ? showLoginComponent() : gotoMembersComponent()}
      <Switch>
        <Route path="/members" component={MainContainer} />
        <Route path="/login" component={LoginForm} />
      </Switch>
    </div>
  );
}

export default App;
