/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

function CrossDomainPopup({ onClose, onCode, url, title = "Cross Domain Popup", children }) {
  const [externalPopup, setExternalPopup] = useState(null);

  const connectClick = () => {
    const width = 500;
    const height = 800;
    const left = window.screenX + (window.outerWidth - width) / 2;
    const top = window.screenY + (window.outerHeight - height) / 2.5;
    const _title = title;
    const _url = url;
    const popup = window.open(_url, _title, `width=${width},height=${height},left=${left},top=${top}`);
    setExternalPopup(popup);
  };

  let gotCode = false;

  useEffect(() => {
    if (!externalPopup) {
      return;
    }

    const timer = setInterval(() => {
      if (!externalPopup) {
        timer && clearInterval(timer);
        return;
      }
      try {
        const currentUrl = externalPopup.location.href;
        if (!currentUrl) {
          return;
        }
        const searchParams = new URL(currentUrl).searchParams;
        const code = searchParams.get("code");
        if (code) {
          onCode(code, searchParams.toString());
          timer && clearInterval(timer);
          gotCode = true;
          externalPopup.close();
        } else {
          gotCode = false;
        }
      } catch (error) {
        // eslint-ignore-line
      } finally {
        if (!externalPopup || externalPopup.closed) {
          timer && clearInterval(timer);
          onClose(gotCode);
        }
      }
    }, 500);
  }, [externalPopup]);

  return <div onClick={connectClick}>{children}</div>;
}

export default CrossDomainPopup;
