import React from "react";
//import { initializeIcons } from "@fluentui/react";
import "../assets/fluentui.icons/css/fabric-icons.css";

export function FluentIcon(props) {
    const icon = props.name;
    return <i className={"ms-Icon ms-Icon--" + icon} style={{ pointerEvents: "none" }}></i>;
}

export function FluentIconClickable(props) {
    const icon = props.name;
    return <i className={"ms-Icon ms-Icon--" + icon}></i>;
}
