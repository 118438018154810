import { orderBy, reverse, sortBy } from "lodash";


export const sortDate = (summaries = [], sortedBy = "ASC") => {
  if (summaries.length) {
    if (sortedBy === "ASC") {
        const  newDates = orderBy(summaries, ({date})=>new Date(date).toISOString() ,['asc'])
        
        return newDates;
    } else if ((sortedBy === "DESC")) {
        const  newDates = orderBy(summaries, ({date})=>new Date(date).toISOString(), ['desc'])
        return newDates
    }
  }

  return [];
};


export const sortTask = (tasks=[] , sortByField , sortedBy = 'ASC') => {
  if (tasks.length) {

    if(sortByField === 'at_id') {
      let  newDates = sortBy(tasks, ({at_tid})=>parseInt(at_tid))
      if (sortedBy === "ASC") {
          return newDates;
      } else if ((sortedBy === "DESC")) {
          const  reverseDate = reverse(newDates, ({at_tid})=>parseInt(at_tid))
          return reverseDate
      }
    }

    if(sortByField === 'index' ) {
      let  newDates = sortBy(tasks, ({index})=>parseInt(index))
      if (sortedBy === "ASC") {
          return newDates;
      } else if ((sortedBy === "DESC")) {
          const  reverseDate = reverse(newDates, ({index})=>parseInt(index))
          return reverseDate
      }
    }

  
  }

  return [];
}
