import { authReducer, userInfoReducer, hasAuthReducer, authMethodReducer } from "./authenticationReducer";
import { newProjectReducer } from "./newProjectReducer";
import { editProjectReducer, epTaskEdit, epTaskComments, taskNewComment, taskModal, timesheetModal, editNameModal, newTaskModal } from "./editProjectReducer";
import portfolioReducer from "./portfolioReducer";
import PrReport from "./projectReportReducer";
import { combineReducers } from "redux";

export const isLoading = (state = false, action) => {
  switch (action.type) {
    case "IS_LOADING":
      return action.payload;
    default:
      return state;
  }
};

const allReducers = combineReducers({
  isAuth: authReducer,
  isLoading: isLoading,
  authMethod: authMethodReducer,
  hasAuth: hasAuthReducer,
  user: userInfoReducer,
  portfolioSettings: portfolioReducer.settings,
  portfolioDetails: portfolioReducer.details,
  portfolioMembers: portfolioReducer.members,
  portfolioProjects: portfolioReducer.projects,
  newProject: newProjectReducer,
  editProject: editProjectReducer,
  epTaskEdit: epTaskEdit,
  taskNewComment: taskNewComment,
  taskComments: epTaskComments,
  idOfTaskInModal: taskModal,
  isTimesheetModalOpen: timesheetModal,
  isEditNameModalOpen: editNameModal,
  isNewTaskModalOpen: newTaskModal,
  reportStatus: PrReport.status,
  reportData: PrReport.data,
  reportEditables: PrReport.editables
});

export default allReducers;
