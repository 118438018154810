/* eslint-disable react-hooks/exhaustive-deps */
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";
import { Button, Image } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { _doLogin, _doSaveUser } from "../../actions/authenticationActions";
import "../../assets/css/loginForm.css";
import Logo from "../../assets/images/MicrosoftTeams-image.png";
import MSButton from "../../assets/images/ms-signin_light.png";
import { graphConfig, loginRequest } from "../../authConfig";
import { doGetGraphApi, getUserAvatar, getUserPhoto, getUserProfile } from "../../components/graph";
import { _rbac } from "../../components/rbacApi";
import { baseURL, imgPlaceHolder, hasRole } from "../../components/reusableFunctions";
import { _user } from "../../components/userApi";
import { blobToBase64 } from "../../Utils/blobToBase64";
import portfolio from "../private/portfolio/getPortfolioData";
// import portfolio from "../private/portfolio/getPortfolioData";

function LoginForm() {
  const action = useDispatch();
  const { instance } = useMsal();
  const isAuth = useSelector((state) => state.isAuth);
  const isMsAuth = useIsAuthenticated();
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    if (isMsAuth && !isAuth) {
      __doMsAuthProcess();
      //console.log("isMsAuth:", isMsAuth);
    }
    console.log("Auth: ", isMsAuth, isAuth);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userProfile) {
      __finishAuthProcess();
    }
  }, [userProfile]);

  /*
  const __handleFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const data = {};
    for (let [key, value] of formData.entries()) {
      data[key] = value;
    }
    __handleLogin(data);
  };

  const __handleLogin = (data) => {
    delete data.password;
    data.id = Math.random().toString(16).substr(2, 8);
    action(_doLogin());
    action(_setAuthMethod("Local"));
    action(_doSaveUser(data));
  };
  */
  const __handleMsLogin = async (loginType) => {
    localStorage.clear();
    window.isLoggingOut = false;
    try {
      const res_login = await instance.loginPopup(loginRequest);
      // console.log(res_login);
      localStorage.setItem("account", JSON.stringify(res_login));
      if (res_login.uniqueId) {
        __doMsAuthProcess(res_login);
      } else {
        Swal.fire({
          icon: "error",
          title: "Timeout ERROR",
          text: "MSAL login request has timed out",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.replace(`${baseURL}/login`);
            //instance.logoutPopup();
          }
        });
      }
    } catch (e) {
      const error = JSON.stringify(e);
      if (error.indexOf("AADSTS65004") > -1) {
        __consentDeclined();
      } else if (error.indexOf("AADSTS65001") > -1) {
        __consentNotGranted();
      } else {
        console.log("Unknown Error");
        localStorage.clear();
        sessionStorage.clear();
      }
    }
  };
  // const __handleMsLogin2 = async (loginType) => {
  //   localStorage.clear();
  //   if (loginType === "popup") {
  //     await instance
  //       .loginPopup(loginRequest)
  //       .then((response) => {
  //         console.log("LoginRequest", response);
  //         localStorage.setItem("account", JSON.stringify(response));
  //         __doMsAuthProcess();
  //       })
  //       .catch((e) => {
  //         const error = JSON.stringify(e);
  //         if (error.indexOf("AADSTS65004") > -1) {
  //           __consentDeclined();
  //         } else if (error.indexOf("AADSTS65001") > -1) {
  //           __consentNotGranted();
  //         } else {
  //         }
  //       });
  //     /*
  //     console.log(login);
  //     if (login?.accessToken) {
  //       localStorage.setItem("account", JSON.stringify(login));
  //       __doMsAuthProcess();
  //     }*/
  //   } else if (loginType === "redirect") {
  //     instance.loginRedirect(loginRequest).catch((e) => {
  //       console.log(e);
  //     });
  //   } else {
  //     __doMsAuthProcess();
  //   }
  // };

  const __consentDeclined = () => {
    Swal.fire({
      icon: "error",
      title: "Consent Declined",
      text: "You need to grant admin consent to PPM.one inorder to use this application. If you think it was a mistake, refresh this page or login again.",
      footer: '<a href="">What is an application consent?</a>',
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.replace(`${baseURL}/logout`);
        //instance.logoutPopup();
      }
    });
  };

  const __consentNotGranted = () => {
    Swal.fire({
      icon: "error",
      title: "Consent Not Yet Granted",
      text: "If you have already requested for consent, please contact your organization admin to grant access to PPM.one. Or if you are an admin, login using your admin account and grant the access. You can also do that by logging in on Azure portal.",
      footer: '<a href="">How to grant admin consent</a>',
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.replace(`${baseURL}/logout`);
        //instance.logoutPopup();
      }
    });
  };

  const __loading = () => {
    Swal.fire({
      title: "Processing",
      html: `Please wait while we do our thing...`,
      timer: 0,
      timerProgressBar: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  };

  const __userDisabled = async () => {
    Swal.fire({
      icon: "error",
      title: "Access Blocked",
      text: `Your account has been disabled. Please contact your administrator! (code: NIGOLMROF)`,
      confirmButtonText: "Ok",
      confirmButtonColor: "#DF3D22",
      timer: 5000,
    }).then((result) => {
      window.location.replace(`${baseURL}/logout`);
    });
  };

  const __doMsAuthProcess = async (loginData) => {
    __loading();
    let userDetails = await getUserProfile();
    // const res_userInfo = await _user.getMSInfo(loginData.uniqueId);
    // console.log(userDetails);
    // console.log(res_userInfo);
    // return;
    if (userDetails?.error) {
      console.log("ERROR", userDetails.error);
      if (userDetails.error.indexOf("AADSTS65004") > -1) {
        __consentDeclined();
      } else if (userDetails.error.indexOf("AADSTS65001") > -1) {
        __consentNotGranted();
      } else {
        if (!isAuth) {
          /*
          Swal.fire({
            icon: "error",
            title: "Something went wrong!",
            text: "Try a hard refresh, 'Ctrl/Cmd + Shift + R' then try logging-in again. If the error persist, please contact us.",
            footer: '<a href="">Contact Us</a>'
          });*/
        }
      }
      Swal.close();
      return;
    } else {
      // console.log(userDetails);
      setUserProfile(userDetails);
    }
    // Swal.close();
  };

  const __finishAuthProcess = async () => {
    console.log("finishing auth process...");
    userProfile.authMethod = "MS";
    userProfile.msId = userProfile.id;
    delete userProfile.id;
    try {
      const res_userInfo = await _user.getMSInfo(userProfile.msId);
      // console.log("USERINFO", res_userInfo);
      if (res_userInfo && res_userInfo.isEnabled === false) {
        __userDisabled();
        return;
      }
      if (res_userInfo?.error) {
        Swal.fire({
          icon: "error",
          title: "Something went wrong!",
          text: "There's an error retrieving your account information",
          footer: '<a href="">Contact Us</a>',
        });
        return;
      }

      action(_doLogin());

      const resultTenant = await doGetGraphApi(graphConfig.tenant);
      if (!resultTenant || resultTenant?.error) {
        Swal.fire({
          icon: "error",
          title: "Something went wrong!",
          text: "We cannot query your Organization's information (Tenant). Please contact us or your administrator.",
          footer: '<a href="">Contact Us</a>',
        });
        return;
      }

      if (res_userInfo) {
        userProfile.member = true;
        userProfile.id = res_userInfo?.id;
        userProfile.isEnabled = res_userInfo.isEnabled;
        const res_rbac = await _rbac.getUserRolesPermissions(res_userInfo?.id);
        window.rbac_data = res_rbac;
        userProfile.rbac = res_rbac;
      } else {
        userProfile.member = false;
      }
      //if user has "Super Admin" role, allow portfolio spoofing
      if (hasRole("Super Admin")) {
        const resPfSettings = await portfolio.getSettings(res_userInfo.pfid);
        userProfile.tenant_id = resPfSettings.tenant_id;
        userProfile.tenant_name = resPfSettings.company_name;
      } else {
        userProfile.tenant_id = resultTenant?.value[0].id;
        userProfile.tenant_name = resultTenant?.value[0].displayName;
      }

      const photoBlob = await getUserPhoto();
      if (photoBlob && !photoBlob?.error) {
        const photo = await blobToBase64(photoBlob);
        userProfile.photo = photo;
      } else {
        console.log("No user photo");
        const name = userProfile.displayName ? userProfile.displayName : userProfile.userPrincipalName;
        const result = await getUserAvatar(name);
        if (result?.error) {
          userProfile.photo = imgPlaceHolder;
        } else {
          const photo = await blobToBase64(result);
          userProfile.photo = photo;
        }
      }
      if (userProfile.member === true) {
        console.log("User is onboard");
        const newUserDetails = JSON.parse(JSON.stringify(userProfile));
        const payload = { ms_id: userProfile.msId };
        delete newUserDetails.id;
        delete newUserDetails.msId;
        if (newUserDetails.businessPhones.length !== 0) {
          newUserDetails.businessPhones = newUserDetails.businessPhones.reduce((a, c) => `${a},${c}`);
        } else {
          newUserDetails.businessPhones = null;
        }
        payload.updates = newUserDetails;
        await _user.updateInfo(payload);
      }
      action(_doSaveUser(userProfile));
    } catch (err) {
      console.log(err);
      return;
    }
  };

  // console.log("MSAL Init:", window.initMSAL);
  // console.log("Auth: ", isMsAuth, isAuth);

  return (
    <div className="loginFormContainer">
      <div id="LoginForm">
        {/* {isAuth && !window.initMSAL ? <Redirect to="/members" /> : <Redirect to="/login" />} */}
        <Image src={Logo} fluid />
        <p className="loginSlogan">Maximize your Project Portfolio Success</p>
        {/*
        <Form onSubmit={__handleFormSubmit}>
          <Form.Group>
            <Form.Label>Sign In</Form.Label>
            <Form.Control type="email" name="email" placeholder="Enter email" required />
            <Form.Text className="text-muted">We'll never share your email with anyone else.</Form.Text>
            <Form.Control type="password" placeholder="Password" required />
          </Form.Group>
          <Form.Group controlId="formBasicCheckbox">
            <Form.Check type="checkbox" name="password" label="Remember me" />
          </Form.Group>
          <Button variant="primary" type="submit" block disabled>
            Sign-in
          </Button>
        </Form>
        <div className="hDivider">
          <div className="hDividerText">or</div>
        </div>
        */}
        <div className="signInWithMicrosoft">
          <Button variant="white" onClick={() => __handleMsLogin("popup")}>
            <Image src={MSButton} />
          </Button>
        </div>
        {/*
        <Button className="mt-5" variant="primary" onClick={_doDebug} block>
          Debug
        </Button>
        */}
        <Button className="mt-3" variant="light" onClick={() => (window.location.href = "https://my.ppm.one")} block>
          Go to Website
        </Button>
      </div>
      {/** Modals */}
      {/* Alerts and notification */}
    </div>
  );
}

export default LoginForm;
