/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import "../../../assets/css/contentProjects/addProject.css";
//import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { mW, mH, toDate, throttle } from "../../../components/reusableFunctions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { nanoid } from "nanoid";
import Swal from "sweetalert2";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { getTheme } from "@fluentui/react";
import { FluentIcon } from "../../../components/FluentIcon.js";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { _addNewBucket, _updateNewBucket, _delNewBucket, _resetNewProject } from "../../../actions/newProjectActions";

function AddBucket(props) {
    const theme = getTheme();
    const { url } = useRouteMatch();
    const action = useDispatch();
    const history = useHistory();
    const newProject = useSelector((state) => state.newProject);

    useEffect(() => {
        if (newProject.buckets.length === 0) {
            action(_addNewBucket({ pid: newProject.details.pid, bid: nanoid(6), name: "To do" }));
            //no pr-code catcher
            if (newProject.details.pid === "") {
                history.push(url.replace("/bucket", ""));
            }
        }
        if (url === "/members/add/project/bucket") {
            props.setModuleTitle("ADD NEW BUCKET");
        }
    }, []);

    const __toast = (msg, link, time = 2500, w = "30") =>
        toast.error(`⛔ ${msg}`, {
            onClose: () => (link ? (window.location.href = link) : false),
            position: "top-center",
            autoClose: time,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            className: `toast-width-${w}`,
            progress: undefined,
        });

    const __handleAddBucket = () => {
        action(_addNewBucket({ pid: newProject.details.pid, bid: nanoid(6), name: "" }));
    };

    const __handleBucketNameChange = (e) => {
        const bid = e.target.getAttribute("data-bid");
        const value = e.target.value;
        throttle(action(_updateNewBucket({ bid: bid, name: value })), 500);
    };

    const __handleDelBucket = (e) => {
        const bid = e.target.getAttribute("data-bid");
        action(_delNewBucket({ bid: bid }));
    };

    const __handlePrevious = () => {
        history.push(url.replace("/bucket", ""));
    };

    const __handleNext = () => {
        const values = newProject.buckets.map((data) => (data.name.length === 0 ? false : true));
        if (values.includes(false)) {
            __toast("Please fill-up all fields", null, 1500, 15);
            return;
        }
        history.push(`${url}/task`);
    };

    const __confirmCancel = async () => {
        Swal.fire({
            title: "Are you sure?",
            text: "All data entered will be lost!",
            showCancelButton: true,
            cancelButtonText: "Cancel",
            confirmButtonText: "Confirm",
            confirmButtonColor: "#DF3D22",
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                __handleCancel();
            }
        });
    };

    const __handleCancel = () => {
        action(_resetNewProject());
        history.push("/members");
    };

    return (
        <div className="main-content">
            <div className="pl-3" style={{ ...mW(50, 65) }}>
                <Row style={{ ...mH(40, "auto") }}>
                    {/* LEFT Section */}
                    <Col style={{ ...mW(30, 40) }}>
                        {/* Top LEFT Section */}
                        <Row className="pb-3 pr-4" style={{ ...mH(35, "auto") }}>
                            <Col className="pt-3">
                                <Form.Row className="mb-3">
                                    <Form.Label className="pt-2">
                                        Buckets can be used to describe "Phases" or work streams with a project, or can be configured or can be
                                        used to reflect a "Kanban" board using an Agile Project Management approach.
                                        <br />
                                        <br />A default "To do" bucket is always added automatically when creating a new Project. This can be
                                        modified later in Planner.
                                    </Form.Label>
                                </Form.Row>
                                {newProject.buckets.map((data, index) => (
                                    <Form.Row key={index} className="mb-3">
                                        <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 10) }}>
                                            #{index + 1} BUCKET NAME
                                        </Form.Label>
                                        <Col>
                                            <Form.Control
                                                size="md"
                                                type="text"
                                                placeholder=""
                                                disabled={index === 0 ? true : false}
                                                value={data.name}
                                                data-bid={data.bid}
                                                onChange={__handleBucketNameChange}
                                                required
                                            />
                                        </Col>
                                        <Col style={{ ...mW(0, 4) }}>
                                            {index !== 0 ? (
                                                <Button
                                                    className="i-btn"
                                                    variant="danger"
                                                    data-bid={data.bid}
                                                    size="sm"
                                                    onClick={__handleDelBucket}>
                                                    <FluentIcon name="Delete" />
                                                </Button>
                                            ) : (
                                                ""
                                            )}
                                        </Col>
                                    </Form.Row>
                                ))}
                                <Row className="text-align-right">
                                    <Col>
                                        <DefaultButton
                                            className="float-right"
                                            style={{ boxShadow: theme.effects.elevation4, marginRight: "4.5em" }}
                                            text="Add Another"
                                            onClick={__handleAddBucket}
                                            allowDisabledFocus
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        {/* LEFT Section END */}

                        {/* Bottom LEFT Section END */}
                    </Col>
                    {/* LEFT Section End */}
                    {/* Right Section */}
                    <Col className="left-border-dashed" style={{ ...mW(0, 22) }}>
                        {/* Top Right Section */}
                        <Row className="bottom-border-dashed pl-4 pb-3">
                            <Col className="pt-3">
                                {/* Top Title-Right Area */}
                                <Form.Row className="mb-3">
                                    <Form.Label className="pt-2 txt-lg" column="sm">
                                        PROJECT SCHEDULE
                                    </Form.Label>
                                </Form.Row>
                                {/* Top Title-Right Area End */}
                                <Form.Row className="mb-3">
                                    <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(5.5, 5.5) }}>
                                        Start Date
                                    </Form.Label>
                                    <Col style={{ ...mW(0, 9) }}>
                                        <Form.Control
                                            size="md"
                                            type="text"
                                            placeholder=""
                                            value={toDate(newProject.project_schedule.start_date)}
                                            readOnly
                                        />
                                        <div className="i-inside">
                                            <FluentIcon name="Calendar" />
                                        </div>
                                    </Col>
                                </Form.Row>
                                <Form.Row className="mb-3">
                                    <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(5.5, 5.5) }}>
                                        End Date
                                    </Form.Label>
                                    <Col style={{ ...mW(0, 9) }}>
                                        <Form.Control
                                            size="md"
                                            type="text"
                                            placeholder=""
                                            value={toDate(newProject.project_schedule.end_date)}
                                            readOnly
                                        />
                                        <div className="i-inside">
                                            <FluentIcon name="Calendar" />
                                        </div>
                                    </Col>
                                </Form.Row>
                            </Col>
                        </Row>
                        {/* Top Right Section End */}
                    </Col>
                    {/* Right Section End */}
                </Row>
                {/* Footer */}
                <Row className="mt-3">
                    <div className="add-project-footer">
                        <DefaultButton
                            className="btn-default float-left"
                            style={{ boxShadow: theme.effects.elevation4 }}
                            text="Cancel"
                            allowDisabledFocus
                            onClick={__confirmCancel}
                        />
                        <DefaultButton
                            className="btn-default float-right"
                            style={{ boxShadow: theme.effects.elevation4 }}
                            text="Next"
                            allowDisabledFocus
                            onClick={__handleNext}
                        />
                        <DefaultButton
                            className="btn-default float-right"
                            style={{ boxShadow: theme.effects.elevation4 }}
                            text="Previous"
                            allowDisabledFocus
                            onClick={__handlePrevious}
                        />
                    </div>
                </Row>
                {/* Footer End */}
                {/* Alerts and notification */}

                <div>
                    <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </div>
        </div>
    );
}

export default AddBucket;
