export const _resetNewProject = () => {
  return {
    type: "RESET_NEW_PROJECT",
  };
};

export const _updateNewDetails = (data) => {
  return {
    type: "UPDATE_NEW_DETAILS",
    payload: data,
  };
};

export const _resetNewDetails = () => {
  return {
    type: "RESET_NEW_DETAILS",
  };
};

export const _updateNewSchedule = (data) => {
  return {
    type: "UPDATE_NEW_SCHEDULE",
    payload: data,
  };
};

export const _setNewMonthRange = (data) => {
  return {
    type: "SET_NEW_MONTH_RANGE",
    payload: data,
  };
};

export const _resetNewBurndown = (data) => {
  return {
    type: "RESET_NEW_BURNDOWN",
    payload: data,
  };
};

export const _addNewBurndown = (data) => {
  return {
    type: "ADD_NEW_BURNDOWN",
    payload: data,
  };
};

export const _updateNewBurndown = (data) => {
  return {
    type: "UPDATE_NEW_BURNDOWN",
    payload: data,
  };
};

export const _delNewBurndown = (data) => {
  return {
    type: "DEL_NEW_BURNDOWN",
    payload: data,
  };
};

export const _addNewBucket = (data) => {
  return {
    type: "ADD_NEW_BUCKET",
    payload: data,
  };
};

export const _updateNewBucket = (data) => {
  return {
    type: "UPDATE_NEW_BUCKET",
    payload: data,
  };
};

export const _delNewBucket = (data) => {
  return {
    type: "DEL_NEW_BUCKET",
    payload: data,
  };
};

export const _resetNewTask = (data) => {
  return {
    type: "RESET_NEW_TASK",
    payload: data,
  };
};

export const _addNewTask = (data) => {
  return {
    type: "ADD_NEW_TASK",
    payload: data,
  };
};

export const _updateNewTask = (data) => {
  return {
    type: "UPDATE_NEW_TASK",
    payload: data,
  };
};

export const _delNewTask = (data) => {
  return {
    type: "DEL_NEW_TASK",
    payload: data,
  };
};

export const _loadAutotask = (data) => {
  return {
    type: "LOAD_AUTOTASK_PROJECTS",
    payload: data,
  };
};

export const _loadTimesheets = (data) => {
  return {
    type: "LOAD_TIMESHEETS",
    payload: data,
  };
};

export const _addNewChecklist = (data) => {
  return {
    type: "ADD_NEW_CHECKLIST",
    payload: data,
  };
};

export const _updateNewChecklist = (data) => {
  return {
    type: "UPDATE_NEW_CHECKLIST",
    payload: data,
  };
};

export const _delNewChecklist = (data) => {
  return {
    type: "DEL_NEW_CHECKLIST",
    payload: data,
  };
};
