import React, { useMemo } from "react";
import { mW, toDate, imgPlaceHolder } from "../../../../components/reusableFunctions";
import { Table, Image } from "react-bootstrap";
import Textarea from "react-expanding-textarea";
import { Image as Image2, Page, Text, View, Document, StyleSheet, PDFViewer, Font } from "@react-pdf/renderer";
import _prReport from "../../../../actions/projectReportActions";
import { useSelector, useDispatch } from "react-redux";
import { sortDate, sortTask } from "../../../../Utils";

function ProjectStatusReport(props) {

  const action = useDispatch();
  const reportEditables = useSelector((state) => state.reportEditables);

  const sortedSummaryReportByDate = useMemo(() => {
      return sortDate(props.reportEditables.report_summary,'ASC');
  }, [props.reportEditables.report_summary]) 

  const tasks = useMemo(() => {
    return sortTask(props.reportData.project_overview,'index', 'ASC');
  }, [props.reportData.project_overview])

  const __editComment = (e) => {
    const id = +e.target.name;
    const newComment = e.target.value;
    action(
      _prReport.updateEditables({
        ...reportEditables,
        report_summary: reportEditables.report_summary.map((data) => {
          if (data.id === id) {
            return {
              ...data,
              comment: newComment,
            };
          }
          return data;
        }),
      })
    );
  };

  const __editAssignees = (e) => {
    //e.target.getAttribute('data-tag')
    const id = +e.target.name;
    const newAssignees = e.target.value;
    action(
      _prReport.updateEditables({
        ...reportEditables,
        upcoming_activity: reportEditables.upcoming_activity.map((data, index) => {
          if (index === id) {
            return {
              ...data,
              assignees: newAssignees,
            };
          }
          return data;
        }),
      })
    );
  };

  const __editDueDate = (e) => {
    //e.target.getAttribute('data-tag')
    const id = +e.target.name;
    const newDueDate = e.target.value;
    action(
      _prReport.updateEditables({
        ...reportEditables,
        upcoming_activity: reportEditables.upcoming_activity.map((data, index) => {
          if (index === id) {
            return {
              ...data,
              due_date: newDueDate,
            };
          }
          return data;
        }),
      })
    );
  };

  Font.register({ family: "Lato-Bold", src: "/fonts/Lato-Bold.ttf" });
  Font.register({ family: "Lato-Regular", src: "/fonts/Lato-Regular.ttf" });
  Font.register({ family: "Lato-Light", src: "/fonts/Lato-Light.ttf" });
  Font.register({ family: "Lato-Italic", src: "/fonts/Lato-Italic.ttf" });

  const styles = StyleSheet.create({
    reportContent: {
      padding: 20,
      fontSize: 14,
      backgroundColor: "white",
      fontFamily: "Lato-Regular",
    },
    header: {
      display: "flex",
      flexDirection: "row",
      padding: 20,
      backgroundColor: props.portfolioSettings?.theme_color,
    },
    headerLeft: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "left",
      color: "white",
    },
    headerRight: {
      display: "flex",
      justifyContent: "right",
      flexGrow: 1,
    },
    logoWrap: {
      maxHeight: 50,
      maxWidth: 100,
      marginLeft: "auto",
    },
    companyName: {
      fontSize: 22,
      fontFamily: "Lato-Bold",
    },
    projectName: {
      marginTop: 5,
      fontSize: 14,
    },
    reportTitle: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      justifyContent: "center",
      paddingTop: 12,
    },
    reportTitleText: {
      fontSize: 20,
      color: props.portfolioSettings?.theme_color,
    },
    reportDetails: {
      paddingLeft: 7,
      paddingRight: 7,
    },
    sectionTitle: {
      marginTop: 20,
      marginBottom: 10,
      borderBottom: "1px solid grey",
      paddingBottom: 7,
    },
    sectionTitleText: {
      fontFamily: "Lato-Bold",
      fontSize: 12,
      color: props.portfolioSettings?.theme_color,
    },
    summarySection: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    summaryBox: {
      flexGrow: 1,
      marginLeft: 1,
      backgroundColor: props.portfolioSettings?.theme_color,
    },
    summaryTitleText: {
      padding: 5,
      fontSize: 10,
      fontFamily: "Lato-Bold",
      color: "white",
    },
    summaryText: {
      padding: 5,
      fontSize: 10,
      color: "black",
      fontFamily: "Lato-Regular",
      backgroundColor: "rgba(255, 255, 255, 0.85)",
    },
    commentBox: {
      display: "flex",
      flexDirection: "row",
      paddingLeft: 10,
    },
    commentDot: {
      fontFamily: "Lato-Light",
      fontSize: 10,
      color: "grey",
    },
    commentText: {
      paddingLeft: 5,
      fontFamily: "Lato-Light",
      fontSize: 10,
    },
    statusSection: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      width: "100%",
    },
    statusBox: {
      display: "flex",
      flexDirection: "row",
    },
    statusBoxLeft: {
      display: "flex",
      flexDirection: "column",
      marginRight: 1,
      backgroundColor: props.portfolioSettings?.theme_color,
    },
    statusBoxRight: {
      display: "flex",
      flexDirection: "column",
      backgroundColor: props.portfolioSettings?.theme_color,
    },
    statusTitleText: {
      padding: 5,
      fontSize: 10,
      fontFamily: "Lato-Bold",
      color: "white",
    },
    statusText: {
      padding: 5,
      fontSize: 10,
      color: "black",
      fontFamily: "Lato-Regular",
      backgroundColor: "rgba(255, 255, 255, 0.85)",
    },
    statusIconBox: {
      display: "flex",
      flexDirection: "row",
      flexGrow: 1,
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "rgba(255, 255, 255, 0.85)",
    },
    statusIconlow: { width: 12, height: 12, backgroundColor: "#5bf50b", borderRadius: "50%" },
    statusIconmid: { width: 12, height: 12, backgroundColor: "#F59F05", borderRadius: "50%" },
    statusIconhigh: { width: 12, height: 12, backgroundColor: "#F5055B", borderRadius: "50%" },
    overviewSection: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    overviewBox: {
      flexGrow: 1,
      marginLeft: 1,
      backgroundColor: props.portfolioSettings?.theme_color,
    },
    overviewTitleText: {
      padding: 5,
      fontSize: 10,
      fontFamily: "Lato-Bold",
      color: "white",
    },
    overviewText: {
      padding: 5,
      fontSize: 10,
      color: "black",
      fontFamily: "Lato-Regular",
      backgroundColor: "rgba(255, 255, 255, 0.85)",
      borderTop: "1px solid white",
    },
  });

  const StatusReportPDF = () => (
    <Document>
      <Page size="A4" style={styles.reportContent}>
        <View style={styles.header}>
          <View style={styles.headerLeft}>
            <Text style={styles.companyName}>{props.reportData.company_name}</Text>
            <Text style={styles.projectName}>{props.reportData.project_name}</Text>
          </View>
          <View style={styles.headerRight}>
            <View style={styles.logoWrap}>{props.portfolioSettings?.photo && <Image2 src={props.portfolioSettings?.photo ?? imgPlaceHolder} />}</View>
          </View>
        </View>
        <View style={styles.reportTitle}>
          <Text style={styles.reportTitleText}>PROJECT STATUS REPORT</Text>
        </View>
        <View style={styles.reportDetails}>
          <View style={styles.sectionTitle}>
            <Text style={styles.sectionTitleText}>PROJECT SUMMARY</Text>
          </View>
          <View style={styles.summarySection}>
            <View style={styles.summaryBox}>
              <Text style={styles.summaryTitleText}>Report Date</Text>
              <Text style={styles.summaryText}>{props.reportData.report_date}</Text>
            </View>
            <View style={styles.summaryBox}>
              <Text style={styles.summaryTitleText}>Project Name</Text>
              <Text style={styles.summaryText}>{props.reportData.project_name}</Text>
            </View>
            <View style={styles.summaryBox}>
              <Text style={styles.summaryTitleText}>Prepared By</Text>
              <Text style={styles.summaryText}>{props.reportData.prepared_by}</Text>
            </View>
          </View>
          <View style={styles.sectionTitle}>
            <Text style={styles.sectionTitleText}>REPORT SUMMARY</Text>
          </View>
          <View style={styles.commentSection}>
            {sortedSummaryReportByDate.length > 0 ? (
              <>
                {sortedSummaryReportByDate
                  .filter((data) => data.comment.length > 0)
                  .map((data, index) => (
                    <View key={index} style={styles.commentBox}>
                      <Text style={styles.commentDot}>•</Text>
                      <Text style={styles.commentText}>{data.comment}</Text>
                    </View>
                  ))}
              </>
            ) : (
              <View style={styles.commentBox}>
                <Text style={styles.commentDot}>•</Text>
                <Text style={{ ...styles.commentText, fontFamily: "Lato-Italic" }}>No report updates yet...</Text>
              </View>
            )}
          </View>
          <View wrap={false}>
            <View style={styles.sectionTitle}>
              <Text style={styles.sectionTitleText}>STATUS SUMMARY</Text>
            </View>
            <View style={styles.statusSection}>
              {props.reportData.status_summary.map((data, index) => (
                <View key={index} style={styles.statusBox}>
                  <View style={styles.statusBoxLeft}>
                    <Text style={styles.statusTitleText}>Category</Text>
                    <Text style={styles.statusText}>{data.category}</Text>
                  </View>
                  <View style={styles.statusBoxRight}>
                    <Text style={styles.statusTitleText}>Status</Text>
                    <View style={styles.statusIconBox}>
                      <View style={styles[`statusIcon${data.status}`]}></View>
                    </View>
                  </View>
                </View>
              ))}
            </View>
          </View>
          <View wrap={false}>
            <View style={styles.sectionTitle}>
              <Text style={styles.sectionTitleText}>PROJECT OVERVIEW</Text>
            </View>
            <View style={styles.overviewSection}>
              <View style={styles.overviewBox}>
                <Text style={styles.overviewTitleText}>Task</Text>
                {tasks.map((data, index) => (
                  <Text key={index} style={styles.overviewText}>
                    {data.name}
                  </Text>
                ))}
              </View>
              <View style={{ ...styles.overviewBox, maxWidth: 50 }}>
                <Text style={{ ...styles.overviewTitleText, textAlign: "center" }}>% Done</Text>
                {tasks.map((data, index) => (
                  <Text key={index} style={{ ...styles.overviewText, textAlign: "right", paddingRight: 15 }}>
                    {data.percent_done}
                  </Text>
                ))}
              </View>
              <View style={{ ...styles.overviewBox, maxWidth: 80 }}>
                <Text style={{ ...styles.overviewTitleText, textAlign: "center" }}>Due Date</Text>
                {tasks.map((data, index) => (
                  <Text key={index} style={{ ...styles.overviewText, textAlign: "center" }}>
                    {toDate(data.due_date)}
                  </Text>
                ))}
              </View>
            </View>
          </View>
          <View wrap={false}>
            <View style={styles.sectionTitle}>
              <Text style={styles.sectionTitleText}>UPCOMING ACTIVITY</Text>
            </View>
            <View style={styles.overviewSection}>
              <View style={styles.overviewBox}>
                <Text style={styles.overviewTitleText}>Activity</Text>
                {props.reportEditables.upcoming_activity.length > 0 ? (
                  <>
                    {props.reportEditables.upcoming_activity
                      .filter((data) => data.due_date.length > 0)
                      .map((data, index) => (
                        <Text key={index} style={styles.overviewText}>
                          {data.name}
                        </Text>
                      ))}
                  </>
                ) : (
                  <Text style={{ ...styles.overviewText, fontFamily: "Lato-Italic" }}>No upcoming activities...</Text>
                )}
              </View>
              <View style={{ ...styles.overviewBox, maxWidth: 150 }}>
                <Text style={{ ...styles.overviewTitleText, textAlign: "center" }}>Assigned To</Text>
                {props.reportEditables.upcoming_activity.length > 0 ? (
                  <>
                    {props.reportEditables.upcoming_activity
                      .filter((data) => data.due_date.length > 0)
                      .map((data, index) => (
                        <Text key={index} style={styles.overviewText}>
                          {data.assignees}
                        </Text>
                      ))}
                  </>
                ) : (
                  <Text style={styles.overviewText}> </Text>
                )}
              </View>
              <View style={{ ...styles.overviewBox, maxWidth: 80 }}>
                <Text style={{ ...styles.overviewTitleText, textAlign: "center" }}>Due Date</Text>
                {props.reportEditables.upcoming_activity.length > 0 ? (
                  <>
                    {props.reportEditables.upcoming_activity
                      .filter((data) => data.due_date.length > 0)
                      .map((data, index) => (
                        <Text key={index} style={{ ...styles.overviewText, textAlign: "center" }}>
                          {toDate(data.due_date)}
                        </Text>
                      ))}
                  </>
                ) : (
                  <Text style={styles.overviewText}> </Text>
                )}
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );

  if (props.viewPDF) {
    return (
      <div>
        <PDFViewer style={{ width: "100%", height: "100vh", border: "none" }}>
          <StatusReportPDF />
        </PDFViewer>
      </div>
    );
  }
  return (
    <div className="report-content">
      <div className="report-header" style={{ backgroundColor: props.portfolioSettings?.theme_color }}>
        <div className="report-header-left">
          <div className="report-header-company-name">{props.reportData.company_name}</div>
          <div className="report-header-project-name">{props.reportData.project_name}</div>
        </div>
        <div className="report-header-right">
          <div className="report-logo-wrap">
            <Image src={props.portfolioSettings?.photo} fluid />
          </div>
        </div>
      </div>
      <div className="report-title" style={{ color: props.portfolioSettings?.theme_color }}>
        PROJECT STATUS REPORT
      </div>
      <div className="report-details-container">
        <div className="report-section">
          <div className="report-section-header" style={{ color: props.portfolioSettings?.theme_color }}>
            PROJECT SUMMARY
          </div>
          <div className="report-section-detail" style={{ backgroundColor: props.portfolioSettings?.theme_color }}>
            <Table bordered size="sm">
              <thead>
                <tr className="report-table-head">
                  <th>Report Date</th>
                  <th>Project Name</th>
                  <th>Prepared By</th>
                </tr>
              </thead>
              <tbody>
                <tr className="report-translucent">
                  <td>{props.reportData.report_date}</td>
                  <td>{props.reportData.project_name}</td>
                  <td>{props.reportData.prepared_by}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        <div className="report-section">
          <div className="report-section-header" style={{ color: props.portfolioSettings?.theme_color }}>
            REPORT SUMMARY
          </div>
          <ul className="expanding-list">
            {sortedSummaryReportByDate.map((data, index) => (
              <li key={index}>
                <Textarea className="expanding-textarea" value={data.comment} name={data.id} onChange={__editComment} placeholder="Excluded..." />
              </li>
            ))}
          </ul>
        </div>
        <div className="report-section">
          <div className="report-section-header" style={{ color: props.portfolioSettings?.theme_color }}>
            STATUS SUMMARY
          </div>
          <div className="report-status-summary-container">
            {props.reportData.status_summary.map((data, index) => (
              <div className="report-status-box" style={{ backgroundColor: props.portfolioSettings?.theme_color }} key={index}>
                <Table bordered size="sm">
                  <thead>
                    <tr className="report-table-head">
                      <th>Category</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="report-translucent">
                      <td>{data.category}</td>
                      <td className="text-center">
                        <div className={`ml-2 float-left status-circle-${data.status}`}></div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            ))}
          </div>
        </div>
        <div className="report-section">
          <div className="report-section-header" style={{ color: props.portfolioSettings?.theme_color }}>
            PROJECT OVERVIEW
          </div>
          <div className="report-section-detail" style={{ backgroundColor: props.portfolioSettings?.theme_color }}>
            <Table bordered size="sm">
              <thead>
                <tr className="report-table-head">
                  <th>Task</th>
                  <th className="text-center">% Done</th>
                  <th className="text-center" style={{ ...mW(8, 8) }}>
                    Due Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {tasks.map((data, index) => (
                  <tr className="report-translucent" key={index}>
                    <td>{data.name}</td>
                    <td className="text-center">{Math.round(data.percent_done)}</td>
                    <td className="text-center" style={{ ...mW(8, 8) }}>
                      {toDate(data.due_date)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
        <div className="report-section">
          <div className="report-section-header" style={{ color: props.portfolioSettings?.theme_color }}>
            UPCOMING ACTIVITY
          </div>
          <div className="report-section-detail" style={{ backgroundColor: props.portfolioSettings?.theme_color }}>
            <Table bordered size="sm">
              <thead>
                <tr className="report-table-head">
                  <th>Activity</th>
                  <th className="text-center">Assigned To</th>
                  <th className="text-center" style={{ ...mW(8, 8) }}>
                    Due Date
                  </th>
                </tr>
              </thead>
              <tbody>
                {props.reportEditables.upcoming_activity.length > 0 ? (
                  <React.Fragment>
                    {props.reportEditables.upcoming_activity.map((data, index) => (
                      <tr className="report-translucent" key={index}>
                        <td>{data.name}</td>
                        <td className="text-center">
                          <Textarea
                            className="expanding-textarea text-center"
                            value={data.assignees}
                            onChange={__editAssignees}
                            name={index}
                            placeholder="Enter assignees..."
                          />
                        </td>
                        <td className="text-center" style={{ ...mW(8, 8) }}>
                          <Textarea
                            className="expanding-textarea text-center"
                            value={data.due_date}
                            onChange={__editDueDate}
                            name={index}
                            placeholder="Excluded..."
                          />
                        </td>
                      </tr>
                    ))}
                  </React.Fragment>
                ) : (
                  <tr className="report-translucent">
                    <td colSpan={3}>
                      <em>No upcoming activities...</em>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectStatusReport;
