import { sDate, eDate } from "../components/reusableFunctions";

const initNewProject = {
  details: {
    pid: "",
    at_pid: 0,
    at_crid: 0,
    ms_pid: "-",
    company: "",
    name: "",
    description: "",
    forecast_hours: "",
    project_services: "",
    contingency: 0,
    hourly_rate: 0,
    project_type: "",
    project_status: 1,
    project_progress: 1,
    schedule_status: 1,
    scope_status: 1,
    budget_status: 1,
    in_planner: 0,
    in_autotask: 0,
    effort_only: 0,
    created_by: 0,
  },
  project_schedule: {
    start_date: new Date(),
    end_date: new Date(),
  },
  month_range: [],
  forecast_burndowns: [],
  buckets: [],
  tasks: [],
  task_checklists: [],
  time_sheets: [],
  assignments: [],
  autotask_projects: [],
  autotask_roles: [],
};

export const newProjectReducer = (state = initNewProject, action) => {
  switch (action.type) {
    case "RESET_NEW_PROJECT":
      return initNewProject;
    case "RESET_NEW_DETAILS":
      return {
        ...state,
        details: {
          pid: "",
          at_pid: 0,
          at_crid: 0,
          ms_pid: "-",
          company: "",
          name: "",
          description: "",
          forecast_hours: "",
          project_services: "",
          contingency: 0,
          hourly_rate: 0,
          project_type: "",
          project_status: 1,
          project_progress: 1,
          schedule_status: 1,
          scope_status: 1,
          budget_status: 1,
          in_planner: 0,
          in_autotask: 0,
          created_by: 0,
        },
        project_schedule: {
          start_date: sDate(new Date()),
          end_date: eDate(new Date()),
        },
        month_range: [],
        forecast_burndowns: [],
        buckets: [],
        tasks: [],
        task_checklists: [],
        time_sheets: [],
        assignments: [],
      };
    case "UPDATE_NEW_DETAILS":
      return {
        ...state,
        details: {
          ...state.details,
          ...action.payload,
        },
      };
    case "UPDATE_NEW_SCHEDULE":
      return {
        ...state,
        project_schedule: {
          ...state.project_schedule,
          ...action.payload,
        },
      };
    case "SET_NEW_MONTH_RANGE":
      return {
        ...state,
        month_range: action.payload,
      };
    case "RESET_NEW_BURNDOWN":
      return {
        ...state,
        forecast_burndowns: [action.payload],
      };
    case "ADD_NEW_BURNDOWN":
      return {
        ...state,
        forecast_burndowns: [...state.forecast_burndowns, action.payload],
      };
    case "UPDATE_NEW_BURNDOWN":
      return {
        ...state,
        forecast_burndowns: state.forecast_burndowns.map((data) => {
          if (data.fbid !== action.payload.fbid) {
            return data;
          }
          return {
            ...data,
            ...action.payload,
          };
        }),
      };
    case "ADD_NEW_BUCKET":
      return {
        ...state,
        buckets: [...state.buckets, action.payload],
      };
    case "UPDATE_NEW_BUCKET":
      return {
        ...state,
        buckets: state.buckets.map((data) => {
          if (data.bid !== action.payload.bid) {
            return data;
          }
          return {
            ...data,
            name: action.payload.name,
          };
        }),
      };
    case "DEL_NEW_BUCKET":
      return {
        ...state,
        buckets: [...state.buckets.filter((data) => data.bid !== action.payload.bid)],
        tasks: [
          ...state.tasks.filter((data) => {
            if (data.bid === action.payload.bid) {
              return "";
            }
            return data;
          }),
        ],
        task_checklists: [
          ...state.task_checklists.filter((data) => {
            if (data.bid === action.payload.bid) {
              return "";
            }
            return data;
          }),
        ],
      };
    case "RESET_NEW_TASK":
      return {
        ...state,
        tasks: [],
      };
    case "ADD_NEW_TASK":
      return {
        ...state,
        tasks: [...state.tasks, action.payload],
      };
    case "UPDATE_NEW_TASK":
      return {
        ...state,
        tasks: state.tasks.map((data) => {
          if (data.tid === action.payload.tid && data.bid === action.payload.bid) {
            if (action.payload.due_date) {
              return {
                ...data,
                due_date: action.payload.due_date,
              };
            }
            if (action.payload.start_date) {
              return {
                ...data,
                start_date: action.payload.start_date,
              };
            }
            if (action.payload.assignments) {
              return {
                ...data,
                assignments: action.payload.assignments,
              };
            }
            return {
              ...data,
              name: action.payload.name,
            };
          }
          return data;
        }),
      };
    case "DEL_NEW_TASK":
      return {
        ...state,
        tasks: [
          ...state.tasks.filter((data) => {
            if (data.bid === action.payload.bid && data.tid === action.payload.tid) {
              return "";
            }
            return data;
          }),
        ],
        task_checklists: [
          ...state.task_checklists.filter((data) => {
            if (data.bid === action.payload.bid && data.tid === action.payload.tid) {
              return "";
            }
            return data;
          }),
        ],
      };
    case "ADD_NEW_CHECKLIST":
      return {
        ...state,
        task_checklists: [...state.task_checklists, action.payload],
      };
    case "UPDATE_NEW_CHECKLIST":
      return {
        ...state,
        task_checklists: state.task_checklists.map((data) => {
          if (data.cid === action.payload.cid && data.tid === action.payload.tid && data.bid === action.payload.bid) {
            return {
              ...data,
              name: action.payload.name,
            };
          }
          return data;
        }),
      };
    case "DEL_NEW_CHECKLIST":
      return {
        ...state,
        task_checklists: [
          ...state.task_checklists.filter((data) => {
            if (data.bid === action.payload.bid && data.tid === action.payload.tid && data.cid === action.payload.cid) {
              return "";
            }
            return data;
          }),
        ],
      };
    case "LOAD_TIMESHEETS":
      return {
        ...state,
        time_sheets: action.payload,
      };
    case "LOAD_AUTOTASK_PROJECTS":
      return {
        ...state,
        autotask_projects: action.payload,
      };
    default:
      return state;
  }
};
