import React, { useState } from "react";
import { getTheme } from "@fluentui/react";
import { Image } from "react-bootstrap";
import "../../../../../assets/css/contentProjects/projectBox.css";
import { toCurrency } from "../../../../../components/reusableFunctions";

function UserBox({ data }) {
  const theme = getTheme();
  const [pboxHover, setpboxHover] = useState(false);
  const [openBG] = useState({ backgroundColor: data.isOpen ? "#FFFFFFF" : "#EAEAEA" });
  const hoverHandler = {
    style: {
      boxShadow: pboxHover ? (data.isOpen ? theme.effects.elevation16 : "") : data.isOpen ? theme.effects.elevation4 : "",
      ...openBG,
    },
    onMouseEnter: () => setpboxHover(true),
    onMouseLeave: () => setpboxHover(false),
  };
  return (
    <div className="user-box" {...hoverHandler}>
      <div className="project-box-header">
        <span className="project-box-title">
          <div className="project-box-title-text">
            {data.displayName}{" "}
            {data.isOpen ? (
              ""
            ) : (
              <small>
                (<em>CLOSED</em>)
              </small>
            )}
          </div>
          <Image className="project-manager-photo" src={data.photo} roundedCircle />
        </span>
        <div className="project-box-header-line"></div>
      </div>
      <div className="project-box-body">
        <span className="project-box-content">
          <div className="flex-table">
            <div className="flex-tr">
              <div className="flex-td">
                <div className="flex-item">Utilisation:</div>
                <div className="flex-item">
                  <div className={`status-circle-${data.status}`}></div>
                </div>
              </div>
              {/* toCurrency(parseFloat().toFixed(2)) */}
              <div className="flex-td">
                <div className="flex-item">Effort Forecast:</div>
                <div className="flex-item">{toCurrency(parseFloat(+data.effortForecast).toFixed(2))}</div>
              </div>
              <div className="flex-td">
                <div className="flex-item">Effort Spent:</div>
                <div className="flex-item">{toCurrency(parseFloat(+data.effortSpent).toFixed(2))}</div>
              </div>
              <div className="flex-td">
                <div className="flex-item">Effort Remaining:</div>
                <div className={"flex-item" + (+data.effortRemaining < 0 ? " text-danger font-weight-bold" : "")}>{toCurrency(parseFloat(+data.effortRemaining).toFixed(2))}</div>
              </div>
            </div>
            <div className="flex-tr">
              <div className="flex-td">
                <div className="flex-item">&nbsp;</div>
                <div className="flex-item">&nbsp;</div>
              </div>
              <div className="flex-td">
                <div className="flex-item">%&nbsp;Complete:</div>
                <div className="flex-item">{parseFloat(data.percentComplete).toFixed(2)}%</div>
              </div>
              <div className="flex-td">
                <div className="flex-item">%&nbsp;Utilisation:</div>
                <div className="flex-item">{parseFloat(data.percentUtil).toFixed(2)}%</div>
              </div>
            </div>
          </div>
        </span>
      </div>
    </div>
  );
}

export default UserBox;
