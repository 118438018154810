/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { baseApiURL, toFullDateTime, toCurrency, toDate, can } from "../../../components/reusableFunctions";
import { Table, Button } from "react-bootstrap";
import ProjectDetails from "./components/ProjectDetails";
import { _updateDetails, _loadDetails } from "../../../actions/editProjectActions";
import { FluentIcon } from "../../../components/FluentIcon.js";
import { getProjectData } from "./components/getProjectData";
import Swal from "sweetalert2";
import axios from "axios";
import { CSVLink } from "react-csv";
import { DefaultButton } from "@fluentui/react";

function TimeSheetProject(props) {
  const isMounted = useRef(false);
  const [inProgress, setInProgress] = useState(false);
  const [timesheets, setTimesheets] = useState([]);
  const [totalHours, setTotalHours] = useState(0);
  const editProject = useSelector((state) => state.editProject);
  const portfolioSettings = useSelector((state) => state.portfolioSettings);
  const [savingChanges, setSavingChanges] = useState(false);
  const hasChecked = useRef(false);
  const action = useDispatch();

  const [CSVData, setCSVData] = useState([]); //added by Dennis create state of date for different report

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      if (props.moduleTitle !== "PROJECT TIMESHEETS") {
        props.setModuleTitle("PROJECT TIMESHEETS");
      }
      if (editProject === null && localStorage.getItem("editProject")) {
        action(_loadDetails(JSON.parse(localStorage.getItem("editProject"))));
      }
    }
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      if (editProject?.pid) {
        __loadTimesheets();
      }
    }
  }, [editProject?.pid]);

  const __loadTimesheets = async () => {
    setInProgress(true);
    const result = await __apiGetTimesheetsWithUser();
    if (result) {
      let total_hours = 0;
      for (const ts of result) {
        total_hours += +ts.hours_worked;
      }
      if (isMounted.current) {
        setTotalHours(total_hours);
        setTimesheets(result);

        hasChecked.current = true;
      }
    }
    setInProgress(false);
  };

  //Added by Dennis. This is for setting up the CSV Format
  useEffect(() => {
    if (isMounted.current) {
      if (timesheets.length > 0) {
        __readyCSVTimesheet();
      }
    }
  }, [timesheets.length]);

  const __readyCSVTimesheet = () => {
    if (timesheets.length > 0) {
      const newData = timesheets.map((data) => {
        return { Hours: data.hours_worked, "Date Worked": toDate(data.date_worked), User: data.user.displayName, Description: data.description };
      });
      setCSVData((prev) => newData);
    }
  };
  //End of CSV formatting

  const __delTimesheet = async (id, teid = false, localOnly = 0) => {
    try {
      setSavingChanges(true);
      __loadingProject();
      if (editProject.in_autotask === 1 && teid) {
        if (!localOnly) {
          const resDeleteTe = await axios.delete(`${baseApiURL}/at/delete/TimeEntries/${portfolioSettings.pfid}/${teid}`);
          console.log(resDeleteTe.data);
          if (!resDeleteTe.data.result) {
            //setSavingChanges(false);
            //__failAlert();
            //return;
            console.log("This time sheet does not exist in Autotask anymore. Skipping...");
          } else {
            console.log("Autotask TimeEntry deleted...");
          }
        }
      }
      const result = await axios.post(`${baseApiURL}/timesheet/delete`, { id: id });
      if (result) {
        console.log("Deleted timesheet from local");
        const resultRefresh = await __refreshData(`*/${editProject?.pid}/Timesheet`);
        if (resultRefresh) {
          __successAlert();
        }
      } else {
        __failAlert();
      }
      setSavingChanges(false);
      Swal.close();
    } catch (error) {
      setSavingChanges(false);
      Swal.fire("Error", "Something went wrong. Please contact us!", "error");
    }
  };

  const __apiGetTimesheetsWithUser = async () => {
    return axios
      .get(`${baseApiURL}/timesheet/${editProject?.pid}/user`)
      .then((res) => {
        if (res.data.error) {
          console.log(res.data.error);
          return false;
        } else {
          return res.data;
        }
      })
      .catch(function (error) {
        console.log(error);
        return false;
      });
  };

  const __refreshData = async (payload) => {
    if (!editProject?.pid) return;
    __loadingProject();
    const project = await getProjectData(payload);
    if (project.error) {
      __failAlert();
    } else {
      if (isMounted.current) {
        action(_updateDetails(project));
        Swal.close();
      }
      return true;
    }
    if (isMounted.current) {
      Swal.close();
    }
    return false;
  };

  const __syncAtProject = async () => {
    try {
      __syncLoadingAlert();
      const resAtSync = await axios.get(`${baseApiURL}/at/forcesync/${portfolioSettings.pfid}/${editProject.pid}`);
      console.log(resAtSync.data);
      if (resAtSync?.data?.result === true) {
        __syncSuccessAlert(resAtSync.data.message);
      } else {
        __syncErrorAlert();
      }
    } catch (e) {
      console.log(e);
      __syncErrorAlert();
    }
  };

  const __syncLoadingAlert = () => {
    Swal.fire({
      title: "Syncing Autotask Data",
      html: `Please wait while we do our thing...`,
      timer: 0,
      timerProgressBar: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  };

  const __syncSuccessAlert = (msg) => {
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Success!",
      text: msg,
      showConfirmButton: false,
      timer: 1500,
      didClose: () => {
        console.log("AT sync successful...");
      },
    });
  };

  const __syncErrorAlert = () => {
    Swal.fire("Error", "Something went wrong. Please contact us!", "error");
  };

  const __loadingProject = () => {
    Swal.fire({
      title: "In-Progress",
      html: `Please wait while we do our thing...`,
      timer: 0,
      timerProgressBar: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  };

  const __confirmDelete = async (e) => {
    Swal.fire({
      text: "Are you sure? This cannot be undone!",
      showDenyButton: editProject.in_autotask,
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Confirm",
      denyButtonText: `Local Only`,
      confirmButtonColor: "#DF3D22",
      denyButtonColor: "#007bff",
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        __delTimesheet(e.target.getAttribute("data-id"), e.target.getAttribute("data-teid"));
      } else if (result.isDenied) {
        __delTimesheet(e.target.getAttribute("data-id"), e.target.getAttribute("data-teid"), 1);
      }
    });
  };

  const __successAlert = (text = "Timesheet removed successfully!") => {
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: text,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  const __failAlert = (text = "Something went wrong!") => {
    Swal.fire({
      position: "top-end",
      icon: "error",
      title: text,
      showConfirmButton: false,
      timer: 1500,
    });
  };

  if (!editProject) {
    return <div></div>;
  }

  return (
    <div>
      <ProjectDetails editProject={editProject} />
      <div className="main-content">
        <div style={{ display: "flex", flex: 1, justifyContent: "space-between" }}>
          <div className="txt-lg mb-1">Time Sheets</div>
          <div>
            {editProject.in_autotask === 1 && (
              <button
                title="Force Autotask sync now..."
                style={{ backgroundColor: "#E6FFF6", padding: "3px", marginLeft: "10px", borderRadius: 10, paddingLeft: "5px", paddingRight: "5px", fontSize: 10, boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px" }}
                onClick={() => __syncAtProject()}
              >
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                  <i className="ms-Icon ms-Icon--Refresh" style={{ pointerEvents: "none", marginRight: 2 }} />
                  <div>{"Sync Now"}</div>
                </div>
              </button>
            )}
          </div>
        </div>
        <div style={{ backgroundColor: "#FFFFFF" }} className="mt-2">
          <Table responsive="sm" className="mb-0">
            <thead>
              <tr>
                <th style={{ maxWidth: "3em" }}>#</th>
                <th className="text-right">Hours</th>
                <th style={{ minWidth: "8em" }}>Date Worked</th>
                <th>User</th>
                <th>Description</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {timesheets.length > 0 ? (
                <>
                  {timesheets.map((data, index) => (
                    <tr key={index} className="item-row" title={"Added On: " + toFullDateTime(data.createdAt)}>
                      <td style={{ maxWidth: "3em" }}>
                        <small>{index + 1}</small>
                      </td>
                      <td className="text-primary text-right">{toCurrency(parseFloat(data.hours_worked).toFixed(2))}</td>
                      <td style={{ minWidth: "8em" }}>{data.date_worked}</td>
                      <td>{data.at_data ? JSON.parse(data.at_data)?.name ?? "Unknown" : data.user.displayName}</td>
                      <td>{data.description}</td>
                      <td>
                        {can("Delete Project Timesheet") && (
                          <Button className="i-btn m-0" disabled={savingChanges} variant="danger" data-id={data.id} data-teid={data.at_teid} title="Delete this entry" size="sm" onClick={__confirmDelete}>
                            <FluentIcon name="Delete" />
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
                </>
              ) : (
                <tr>{inProgress ? <td colSpan="6">Loading timesheets...</td> : <td colSpan="6">No timesheets yet...</td>}</tr>
              )}
            </tbody>

            <tfoot className="bg-light">
              <tr>
                <td style={{ maxWidth: "3em" }}>Total:</td>
                <td className="font-weight-bold text-primary text-right">{toCurrency(parseFloat(totalHours).toFixed(2))}</td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                  <CSVLink data={CSVData} filename={"Timesheets"} target="_blank">
                    <DefaultButton className="btn-default float-left" text="Export" allowDisabledFocus />
                  </CSVLink>
                </td>
              </tr>
            </tfoot>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default TimeSheetProject;
