/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { _doLogout, _hasAuth } from "../../actions/authenticationActions";
import { useSelector, useDispatch } from "react-redux";
import { useMsal } from "@azure/msal-react";

function LogoutForm() {
  const action = useDispatch();
  const isAuth = useSelector((state) => state.isAuth);
  const { instance } = useMsal();

  useEffect(() => {
    if (isAuth) {
      instance.logout();
      localStorage.clear();
      sessionStorage.clear();
      action(_doLogout());
      action(_hasAuth(false));
    }
  }, []);
  return <div></div>;
}

export default LogoutForm;
