export const _doLogin = () => {
  localStorage.setItem("auth", true);
  return {
    type: "LOG_IN"
  };
};

export const _doLogout = () => {
  localStorage.removeItem("auth");
  localStorage.removeItem("user");
  localStorage.removeItem("portfolio");
  return {
    type: "LOG_OUT"
  };
};

export const _setAuthMethod = (data) => {
  return {
    type: "AUTH_METHOD",
    payload: data
  };
};

export const _doSaveUser = (data) => {
  localStorage.setItem("user", JSON.stringify(data));
  return {
    type: "SAVE_USER",
    payload: data
  };
};

export const _hasAuth = (state) => {
  return {
    type: "HAS_AUTH",
    payload: state
  };
};
