/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { _user } from "../../../components/userApi";
import { useSelector } from "react-redux";
import UserBox from "./components/teamView/UserBox";
import { ProjectLoader } from "../contentProjects/components/ProjectLoader";
import DatePicker from "react-datepicker";
import { FluentIcon } from "../../../components/FluentIcon";
import { getWorkingDays, toDate } from "../../../components/reusableFunctions";
import moment from "moment/moment";
import { Modal } from "react-bootstrap";

function TeamView() {
  const isMounted = useRef(false);
  const portfolioSettings = useSelector((state) => state.portfolioSettings);
  const portfolioProjects = useSelector((state) => state.portfolioProjects);
  const [xfromDate, setFromDate] = useState(new Date());
  const [xtoDate, setToDate] = useState(new Date(moment().add(15, "d")));
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState([]);
  const [userProjectsData, setUserProjectsData] = useState([]);
  const [selectedUserProjects, setSelectedUserProjects] = useState([]);
  const [selectedUserProjectsTotals, setSelectedUserProjectsTotals] = useState({ totalHours: 0, totalUtil: 0 });
  const [userUtilShow, setUserUtilShow] = useState(false);
  const [userUtilModalTitle, setUserUtilModalTitle] = useState("User Utilisation Calendar");

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      // console.log(getWorkingDays(new Date("02-Nov-2022"), new Date("01-Nov-2022")));
      __getPortfolioUsers(xfromDate, xtoDate);
    }
  }, []);

  const __getPortfolioUsers = async (sDate, eDate) => {
    setLoading(true);
    const getUserRes = await _user.getPortfolioUsers(portfolioSettings.pfid);
    // console.log(getUserRes);
    // console.log(portfolioProjects);
    let userUtilisation = [];
    let userProjectDetails = [];
    for (const user of getUserRes) {
      let userStat = { userId: user.id, userDisplayName: user.displayName, userPhoto: user.photo, effortForecast: 0, effortSpent: 0, effortRemaining: 0, percentComplete: 100, percentUtil: 0, status: "low" };
      let userUtilDetails = { userId: user.id, projects: [] };
      const userProjects = portfolioProjects?.filter((data) => data.user.id === user.id && data.project_status === 1 && new Date(data.start_date) < eDate && new Date(data.end_date) >= sDate);
      if (userProjects.length > 0) {
        // console.log("=========================");
        // console.log(user.displayName, userProjects);

        //List of Projects for the specific user
        let userProjectList = [];

        for (const project of userProjects) {
          //Project details of each project
          let userProject = {};

          // console.log(project.name);
          if (project.effort_spent > project.forecast_hours) {
            userStat.effortForecast = userStat.effortForecast + +project.effort_spent;
            userStat.effortSpent = userStat.effortSpent + +project.effort_spent;
          } else {
            userStat.effortForecast = userStat.effortForecast + +project.forecast_hours;
            userStat.effortSpent = userStat.effortSpent + +project.effort_spent;
          }

          const totalRemEffort = +project.forecast_hours - +project.effort_spent;
          // console.log("FH:", project.forecast_hours, "ES:", project.effort_spent, "TRE:", totalRemEffort);
          const totalRemWorkingDays = new Date(project.end_date) < new Date() ? 1 : getWorkingDays(new Date(), new Date(project.end_date));
          const hoursPerDay = totalRemEffort < 1 ? 0 : totalRemEffort / totalRemWorkingDays;
          const dailyPercentage = totalRemEffort < 1 ? 0 : (hoursPerDay / 8) * 100;
          // console.log("RemWorkingDays", toDate(project.end_date), totalRemWorkingDays);
          // console.log("Hours/day:", hoursPerDay, dailyPercentage, "%");
          userStat.percentUtil = userStat.percentUtil + +dailyPercentage;

          //Project in depth details regarding user utilisation
          userProject.projectName = project.name;
          userProject.effortForecast = +project.forecast_hours;
          userProject.effortSpent = +project.effort_spent;
          userProject.percentComplete = parseFloat((+project.effort_spent / +project.forecast_hours) * 100).toFixed(2) + "%";
          userProject.isOutOfBudget = (+project.effort_spent / +project.forecast_hours) * 100 > 100 ? true : false;
          userProject.startDate = toDate(project.start_date);
          userProject.endDate = toDate(project.end_date);
          userProject.daysLeft = totalRemWorkingDays;
          userProject.isOverdue = new Date(project.end_date) < new Date();
          userProject.hoursPerDay = +hoursPerDay;
          userProject.dailyPercentage = +dailyPercentage;
          //Assemble array of Projects for this user
          userProjectList = [...userProjectList, userProject];
        }
        //Add the array of project details to the user main Object (that will be added later to userProjectDetails array)
        userUtilDetails.projects = userProjectList;

        userStat.effortRemaining = +userStat.effortForecast - +userStat.effortSpent;
        // console.log("percentComplete", userStat.effortSpent, userStat.effortForecast, `${Math.round((userStat.effortSpent / userStat.effortForecast) * 100)}%`);
        userStat.percentComplete = Math.round((+userStat.effortSpent / +userStat.effortForecast) * 100);
        if (userStat.percentUtil <= 100) userStat.status = "low";
        if (userStat.percentUtil > 100 && userStat.percentUtil <= 130) userStat.status = "mid";
        if (userStat.percentUtil > 130) userStat.status = "high";
      }
      //add final objects to the main arrays before the loop
      userUtilisation = [...userUtilisation, userStat];
      userProjectDetails = [...userProjectDetails, userUtilDetails];
    }
    userUtilisation = userUtilisation.filter((data) => data.effortForecast > 0);
    setUserData(userUtilisation);
    setUserProjectsData(userProjectDetails);
    setLoading(false);
    // console.log(userUtilisation);
  };

  const __filterUtilisation = async () => {
    await __getPortfolioUsers(xfromDate, xtoDate);
  };

  const __setFromDate = (date) => {
    if (date > xtoDate) {
      setFromDate(date);
      setToDate(date);
    } else {
      setFromDate(date);
    }
  };

  const __setToDate = (date) => {
    if (date < xfromDate) {
      setFromDate(date);
      setToDate(date);
    } else {
      setToDate(date);
    }
  };

  const __showUserUtil = (data) => {
    setUserUtilModalTitle(`Utilisation details for ${data.userDisplayName}`);
    const projData = userProjectsData.filter((xdata) => xdata.userId === data.userId)[0].projects;

    let totalHours = 0;
    let totalUtil = 0;
    projData.forEach((data) => {
      totalHours += +data.hoursPerDay;
      totalUtil += +data.dailyPercentage;
    });

    setSelectedUserProjectsTotals({ totalHours: totalHours, totalUtil: totalUtil });
    setSelectedUserProjects(projData);
    setUserUtilShow(true);
  };

  const __clearSearch = () => {
    setFromDate("");
    setToDate("");
  };

  return (
    <div className="main-content">
      <div className="project-col">
        <div className="project-row mb-2">
          <div className="search-box" style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
            <span style={{ fontWeight: "bold", fontSize: "1.2rem" }}>Set Period (Date Range)</span>
            <div style={{ width: 160, display: "flex", flexDirection: "row", alignItems: "center", marginLeft: 10, marginRight: 10 }}>
              From:
              <div style={{ width: "100%", marginLeft: 2 }}>
                <DatePicker selected={xfromDate} onChange={(date) => __setFromDate(date)} dateFormat={"dd-MMM-yyyy"} dateClassName="txt-lg" />
              </div>
              <div style={{ position: "relative", marginLeft: -25, marginTop: 6, pointerEvents: "none" }}>
                <FluentIcon name="Calendar" />
              </div>
            </div>
            <div style={{ width: 160, display: "flex", flexDirection: "row", alignItems: "center", marginLeft: 10, marginRight: 10 }}>
              To:
              <div style={{ width: "100%", marginLeft: 2 }}>
                <DatePicker selected={xtoDate} onChange={(date) => __setToDate(date)} dateFormat={"dd-MMM-yyyy"} dateClassName="txt-lg" />
              </div>
              <div style={{ position: "relative", marginLeft: -25, marginTop: 6, pointerEvents: "none" }}>
                <FluentIcon name="Calendar" />
              </div>
            </div>
            <button className="search-go ml-2" onClick={__filterUtilisation}>
              Go
            </button>
            <button className="search-go ml-2" onClick={__clearSearch}>
              Clear
            </button>
          </div>
        </div>
        <div className="project-row">
          {/* */}
          {userData?.length > 0 ? (
            <React.Fragment>
              {userData?.map((data) => (
                <div key={data.userId} onClick={() => __showUserUtil(data)}>
                  <UserBox
                    data={{
                      isOpen: true,
                      displayName: data.userDisplayName,
                      photo: data.userPhoto,
                      status: data.status,
                      effortForecast: data.effortForecast,
                      effortSpent: data.effortSpent,
                      effortRemaining: data.effortRemaining,
                      percentComplete: data.percentComplete,
                      percentUtil: data.percentUtil,
                    }}
                  />
                </div>
              ))}
            </React.Fragment>
          ) : (
            <>
              {loading ? (
                <>
                  <div className="project-box pb-3">
                    <ProjectLoader />
                  </div>
                  <div className="project-box pb-3">
                    <ProjectLoader />
                  </div>
                </>
              ) : (
                "No users found..."
              )}
            </>
          )}
          {/* */}
        </div>
        {/* View User Utilisation Modal */}
        <Modal size="xl" aria-labelledby="contained-modal-title-vcenter" backdrop="static" keyboard={false} centered show={userUtilShow} onHide={() => setUserUtilShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">{userUtilModalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <table cellPadding={5} style={{ width: "100%" }}>
                <thead>
                  <tr style={{ borderBottom: "1px solid black" }}>
                    <th>Project Name</th>
                    <th style={{ textAlign: "center" }}>Effort</th>
                    <th style={{ textAlign: "right" }}>Completion</th>
                    <th style={{ textAlign: "right" }}>Days Left</th>
                    <th style={{ textAlign: "right" }}>Hours/Day</th>
                    <th style={{ textAlign: "right" }}>Start</th>
                    <th style={{ textAlign: "right" }}>End</th>
                    <th style={{ textAlign: "right" }}>Utilisation</th>
                  </tr>
                </thead>
                <tbody>
                  {selectedUserProjects.map((data, index) => (
                    <tr key={index} style={{ backgroundColor: (index + 1) % 2 !== 0 ? "#EBEBEB" : "white" }}>
                      <td style={{ maxWidth: "15rem" }}>{data.projectName}</td>
                      <td style={{ textAlign: "center" }}>
                        <span style={{ color: data.isOutOfBudget ? "#f5055b" : "#323130" }}>
                          {data.effortSpent} / {data.effortForecast}
                        </span>
                      </td>
                      <td style={{ textAlign: "right" }}>
                        <span style={{ color: data.isOutOfBudget ? "#f5055b" : "#323130" }}>{data.percentComplete}</span>
                      </td>
                      <td style={{ textAlign: "right" }}>{data.daysLeft}</td>
                      <td style={{ textAlign: "right" }}>{parseFloat(data.hoursPerDay).toFixed(2)}</td>
                      <td style={{ textAlign: "right" }}>{data.startDate}</td>
                      <td style={{ textAlign: "right" }}>
                        <span style={{ color: data.isOverdue ? "#f5055b" : "#323130" }}>{data.endDate}</span>
                      </td>
                      <td style={{ textAlign: "right" }}>{parseFloat(data.dailyPercentage).toFixed(2) + "%"}</td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr style={{ borderTop: "1px solid black" }}>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: "right", fontWeight: "bold" }}>{parseFloat(selectedUserProjectsTotals.totalHours).toFixed(2)}</td>
                    <td></td>
                    <td></td>
                    <td style={{ textAlign: "right", fontWeight: "bold" }}>{parseFloat(selectedUserProjectsTotals.totalUtil).toFixed(2) + "%"}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default TeamView;
