const _portfolio = {};

_portfolio.loadMembers = (data) => {
  return {
    type: "LOAD_PORTFOLIO_MEMBERS",
    payload: data,
  };
};

_portfolio.updateMember = (data) => {
  return {
    type: "UPDATE_PORTFOLIO_MEMBER",
    payload: data,
  };
};

_portfolio.loadDetails = (data) => {
  return {
    type: "LOAD_PORTFOLIO_DETAILS",
    payload: data,
  };
};

_portfolio.loadProjects = (data) => {
  return {
    type: "LOAD_PORTFOLIO_PROJECTS",
    payload: data,
  };
};

_portfolio.loadSettings = (data) => {
  localStorage.setItem("portfolioSettings", JSON.stringify(data));
  return {
    type: "LOAD_PORTFOLIO_SETTINGS",
    payload: data,
  };
};

_portfolio.updateSettings = (data) => {
  return {
    type: "UPDATE_PORTFOLIO_SETTINGS",
    payload: data,
  };
};

export default _portfolio;
