import React from "react"
import ContentLoader from "react-content-loader"

const TaskLoader = (props) => (
  <ContentLoader 
    speed={1}
    width={500}
    height={160}
    viewBox="0 0 500 160"
    backgroundColor="#dbdbdb"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="47" y="7" rx="3" ry="3" width="202" height="19" /> 
    <rect x="68" y="53" rx="3" ry="3" width="200" height="6" /> 
    <circle cx="27" cy="17" r="12" /> 
    <circle cx="479" cy="17" r="16" /> 
    <circle cx="50" cy="56" r="8" /> 
    <rect x="68" y="74" rx="3" ry="3" width="200" height="6" /> 
    <circle cx="50" cy="77" r="8" /> 
    <rect x="68" y="96" rx="3" ry="3" width="200" height="6" /> 
    <circle cx="50" cy="99" r="8" /> 
    <rect x="19" y="128" rx="0" ry="0" width="18" height="17" /> 
    <rect x="46" y="132" rx="3" ry="3" width="109" height="9" /> 
    <circle cx="180" cy="136" r="8" /> 
    <rect x="193" y="132" rx="3" ry="3" width="50" height="9" />
  </ContentLoader>
)

export default TaskLoader