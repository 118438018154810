import React, { useState } from "react";
import "chart.js/auto";
import { Doughnut, Bar } from "react-chartjs-2";
import { defaults } from "chart.js";
import { toCurrency, toDate } from "../../../../components/reusableFunctions";
import "../../../../assets/css/contentProjects/projectGraph.css";
import { Modal } from "react-bootstrap";

defaults.font.family = "D-DIN";

export function StackedBarGraph(props) {
  if (props && !props.data) {
    return <></>;
  }
  const budgetInvoiced = () => {
    if (props.data.budget_invoiced) {
      return props.data.budget_invoiced;
    } else {
      return 0;
    }
  };
  return (
    <div className="graph-row">
      <div className="graph-col p-3">
        <div className="graph-stacked-bar">
          <div className="stacked-bar-main">
            <div
              title={"Remaining: " + (props?.data?.budget_remaining < 0 ? "-$" : "$") + toCurrency(parseFloat(Math.abs(props?.data?.budget_remaining)).toFixed(2))}
              className="stacked-bar-remaining"
              style={{ height: `${props?.data?.remaining_bar_height}%` }}
            ></div>
            <div title={"Invoiced: $" + toCurrency(parseFloat(budgetInvoiced()).toFixed(2))} className="stacked-bar-spent" style={{ height: `${props?.data?.spent_bar_height}%` }}></div>
          </div>
        </div>
      </div>
      <div className="graph-col p-3">
        <div className="graph-legend">
          <div className="graph-legend-item">
            <div className="graph-legend-item-row">
              <div className="legend-item-color">
                <div className={props?.data?.budget_remaining < 0 ? "legend-color-main" : "legend-color-remaining"}></div>
              </div>
              <div className="legend-item-name">Outstanding</div>
            </div>
            <div className="graph-legend-item-row">
              <div className={props?.data?.budget_remaining < 0 ? "legend-item-value text-danger" : "legend-item-value"}>
                {(props?.data?.budget_remaining < 0 ? "-$" : "$") + toCurrency(parseFloat(Math.abs(props?.data?.budget_remaining)).toFixed(2))}
              </div>
            </div>
          </div>
          <div className="graph-legend-item">
            <div className="graph-legend-item-row">
              <div className="legend-item-color">
                <div className="legend-color-spent"></div>
              </div>
              <div className="legend-item-name">Invoiced</div>
            </div>
            <div className="graph-legend-item-row">
              <div className="legend-item-value">${toCurrency(parseFloat(budgetInvoiced()).toFixed(2))}</div>
            </div>
          </div>
          <div className="graph-legend-item">
            <div className="graph-legend-item-row">
              <div className="legend-item-color">
                <div className="legend-color-main"></div>
              </div>
              <div className="legend-item-name">Total</div>
            </div>
            <div className="graph-legend-item-row">
              <div className="legend-item-value">${toCurrency(parseFloat(budgetInvoiced() + props?.data?.budget_remaining).toFixed(2))}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function DonutChart(props) {
  if (props && !props.data) {
    return <></>;
  }
  return (
    <div className="graph-row">
      <div className="graph-col" style={{ width: "60%" }}>
        <Doughnut
          data={{
            datasets: [
              {
                data: props?.data?.values,
                backgroundColor: props?.data?.bcolors,
              },
            ],
          }}
          width="100%"
          height="100%"
          options={{ maintainAspectRatio: false, cutout: "80%" }}
        />
      </div>
      <div className="graph-col p-2" style={{ width: "40%" }}>
        <div className="graph-legend">
          {props?.data?.values.map((value, index) => (
            <div className="graph-legend-item" key={index}>
              <div className="graph-legend-item-row">
                <div className="legend-item-color">
                  <div className="legend-color-remaining" style={{ backgroundColor: props?.data?.bcolors[index] }}></div>
                </div>
                <div className="legend-item-name">{props?.data?.labels[index]}</div>
              </div>
              <div className="graph-legend-item-row">
                <div className={value < 0 ? "legend-item-value text-danger" : "legend-item-value"}>{value}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export function ProjectStatusChart(props) {
  if (props && !props.data) {
    return <></>;
  }
  return (
    <div className="graph-row">
      <div className="graph-col" style={{ width: "60%" }}>
        <Doughnut
          data={{
            datasets: [
              {
                data: [props?.data?.values.one, props?.data?.values.two, props?.data?.values.three],
                backgroundColor: props?.data?.bcolors,
              },
            ],
          }}
          width="100%"
          height="100%"
          options={{ maintainAspectRatio: false, cutout: "80%" }}
        />
      </div>
      <div className="graph-col p-2" style={{ width: "40%" }}>
        <div className="graph-legend">
          <div className="graph-legend-item">
            <div className="graph-legend-item-row">
              <div className="legend-item-color">
                <div className="legend-color-remaining" style={{ backgroundColor: props?.data?.bcolors[0] }}></div>
              </div>
              <div className="legend-item-name">{props?.data?.labels.one}</div>
            </div>
            <div className="graph-legend-item-row">
              <div className="legend-item-value">{props?.data?.values.one}</div>
            </div>
          </div>
          <div className="graph-legend-item">
            <div className="graph-legend-item-row">
              <div className="legend-item-color">
                <div className="legend-color-spent" style={{ backgroundColor: props?.data?.bcolors[1] }}></div>
              </div>
              <div className="legend-item-name">{props?.data?.labels.two}</div>
            </div>
            <div className="graph-legend-item-row">
              <div className="legend-item-value">{props?.data?.values.two}</div>
            </div>
          </div>
          <div className="graph-legend-item">
            <div className="graph-legend-item-row">
              <div className="legend-item-color">
                <div className="legend-color-main" style={{ backgroundColor: props?.data?.bcolors[2] }}></div>
              </div>
              <div className="legend-item-name">{props?.data?.labels.three}</div>
            </div>
            <div className="graph-legend-item-row">
              <div className="legend-item-value">{props?.data?.values.three}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function FinancialBurndown(props) {
  if (props && !props.values) {
    return <></>;
  }
  // console.log("GraphInfo:", props?.info);
  const data = {
    labels: props.labels,
    datasets: [
      {
        maxBarThickness: 72,
        data: props.values,
        backgroundColor: props.bcolors,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    aspectRatio: 2,
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return (
    <div className="graph-row">
      <Bar
        data={data}
        options={{
          ...options,
          onClick: function (c, i) {
            const e = i[0];
            if (props?.info) {
              //console.log(e?.index);
              const info = props?.info[e?.index];
              const label = this.data.labels[e?.index];
              const total = this.data.datasets[0].data[e?.index];
              if (info) {
                props.setShow(true);
                props.setMonthData(info);
                props.setMonthLabel(label);
                props.setMonthTotal(total);
              }
              //console.log(x_value);
              //console.log(y_value);
            }
          },
        }}
      />
    </div>
  );
}

export function XeroFinancial({ labels, values, bcolors }) {
  const [show, setShow] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [infoData, setInfoData] = useState([]);

  if (!labels || !values || !bcolors) {
    return <></>;
  }

  const data = {
    labels: labels,
    datasets: [
      {
        maxBarThickness: 72,
        label: "Paid",
        data: values.paid,
        backgroundColor: bcolors.paid,
      },
      {
        maxBarThickness: 72,
        label: "Unpaid",
        data: values.unpaid,
        backgroundColor: bcolors.unpaid,
      },
    ],
  };

  const info = [values.paidInfo, values.unpaidInfo];

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    aspectRatio: 2,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return (
    <div className="graph-row">
      <Bar
        data={data}
        // options2={{
        //   ...options,
        // }}
        options={{
          ...options,
          onClick: function (c, i) {
            const e = i[0];
            if (!e) return;
            // console.log(e.datasetIndex, e.index, info[e.datasetIndex][e.index]);
            setModalTitle(`${labels[e.index]} (${e.datasetIndex ? "Unpaid" : "Paid"})`);
            setInfoData(info[e.datasetIndex][e.index]);
            setShow(true);
          },
        }}
      />
      <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" backdrop="static" keyboard={false} centered show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {infoData.map((data) => (
              <div key={data.id} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", fontSize: "1rem", padding: 5, borderBottom: "1px solid rgb(212, 212, 212)" }}>
                <div>
                  #{data.invoiceNumber} - <span style={{ fontWeight: "bold" }}>{data.contactName}</span> ({data.isForecast ? `Due: ${toDate(data.dueDate)}, Expected: ${toDate(data.expectedDate)}` : `Paid: ${toDate(data.paymentDate)}`})
                </div>
                <div>${toCurrency(parseFloat(data.total).toFixed(2))}</div>
              </div>
            ))}
            <div key={data.id} style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", fontSize: "1rem", padding: 5 }}>
              <div>Total</div>
              <div style={{ color: "blue" }}>
                $
                {toCurrency(
                  parseFloat(
                    infoData.reduce(function (tot, arr) {
                      return tot + arr.total;
                    }, 0)
                  ).toFixed(2)
                )}
              </div>
            </div>
          </>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export function EffortForecast(props) {
  if (props && !props.datasets) {
    return <></>;
  }
  const data = {
    labels: props.labels,
    datasets: props.datasets,
  };

  const options = {
    indexAxis: "y",
    scales: {
      x: {
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0,
        },
      },
    },
    maintainAspectRatio: false,
    responsive: true,
    aspectRatio: 2,
    plugins: {
      legend: {
        display: false,
      },
    },
  };
  return (
    <div className="graph-row" style={{ minHeight: "40em" }}>
      <Bar data={data} options={options} />
    </div>
  );
}
