/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "../../assets/css/contentDashboard.css";
import "../../assets/css/contentFinance.css";
import { toShortDate, toDigitDate, addMonths, toCurrency, can, baseApiURL, toMonYr } from "../../components/reusableFunctions";
import { StackedBarGraph, EffortForecast, FinancialBurndown, XeroFinancial } from "./contentProjects/components/ProjectGraph";
import portfolio from "./portfolio/getPortfolioData";
import _portfolio from "../../actions/portfolioActions";
import { _loadDetails } from "../../actions/editProjectActions";
import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { DefaultButton, getTheme } from "@fluentui/react";
import { CSVLink } from "react-csv";
import axios from "axios";
import moment from "moment";

function ContentFinance() {
  const action = useDispatch();
  const isMounted = useRef(false);
  const portfolioDetails = useSelector((state) => state.portfolioDetails);
  const portfolioSettings = useSelector((state) => state.portfolioSettings);
  const portfolioProjects = useSelector((state) => state.portfolioProjects);
  const [show, setShow] = useState(false);
  const [monthData, setMonthData] = useState([]);
  const [monthLabel, setMonthLabel] = useState("");
  const [monthTotal, setMonthTotal] = useState(0);
  const [accrecData, setAccrecData] = useState({});
  const theme = getTheme();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      __getPortfolio();
      __calcXeroData();
    }
  }, []);

  const __getPortfolio = async () => {
    const data = await portfolio.getOpenProjectsData(portfolioSettings.pfid);
    //const data = await portfolio.getData(portfolioSettings.ms_365_group, "PF-r8DTUa");
    if (data?.error) {
      __toast(data.error, null, 2000, 25);
    } else {
      // if (!isEqual(portfolioDetails, data.details)) {
      //   action(_portfolio.loadDetails(data.details));
      // }
      // if (!isEqual(portfolioProjects, data.projects)) {
      //   action(_portfolio.loadProjects(data.projects));
      // }
      action(_portfolio.loadDetails(data.details));
      action(_portfolio.loadProjects(data.projects));
    }
  };

  const __getXeroInvoices = async (type) => {
    const resInvoices = await axios.get(`${baseApiURL}/xero/invoices/${portfolioSettings.pfid}/${type}`);
    const allInvoices = JSON.parse(JSON.stringify(resInvoices.data));
    // console.log(allInvoices);
    let labels = [];
    let values = [];
    let paidValues = [];
    let unpaidValues = [];
    let paidInfo = [];
    let unpaidInfo = [];
    let overallAvgTurnoutDays = 0;
    for (const invoice of allInvoices) {
      if (invoice.status === "PAID") {
        invoice.isForecast = false;
        invoice.expectedDate = invoice.paymentDate;
      } else {
        let totalTurnoutDays = 0;
        let numberOfPayments = 0;
        let overallTurnoutDays = 0;
        for (const invoice2 of resInvoices.data) {
          if (invoice.contactID === invoice2.contactID && invoice2.turnoutDays !== null) {
            numberOfPayments = numberOfPayments + 1;
            totalTurnoutDays = totalTurnoutDays + invoice2.turnoutDays;
          }
          overallTurnoutDays = overallTurnoutDays + invoice2.turnoutDays;
        }
        overallAvgTurnoutDays = overallTurnoutDays > 0 ? overallTurnoutDays / allInvoices.length : 0;
        let avgTurnoutDays = totalTurnoutDays > 0 ? totalTurnoutDays / numberOfPayments : overallAvgTurnoutDays;
        invoice.isForecast = true;
        invoice.expectedDate = new Date(moment(invoice.date).add(avgTurnoutDays, "days"));
        invoice.avgTurnoutDays = avgTurnoutDays;
      }

      if (!labels.includes(toMonYr(invoice.expectedDate))) {
        labels = [...labels, toMonYr(invoice.expectedDate)];
      }
    }
    labels.sort((date1, date2) => new Date(date1) - new Date(date2));
    // console.log(
    //   "Sorted: ",
    //   labels.sort((date1, date2) => new Date(date1) - new Date(date2))
    // );
    // console.log(allInvoices);
    // console.log(overallAvgTurnoutDays);

    for (const monYear of labels) {
      let totalPaidValue = 0;
      let totalUnpaidValue = 0;
      let listPaidInfo = [];
      let listUnpaidInfo = [];
      for (const invoice of allInvoices) {
        if (monYear === toMonYr(invoice.expectedDate)) {
          if (!invoice.isForecast) {
            totalPaidValue = totalPaidValue + +invoice.total;
            listPaidInfo = [...listPaidInfo, invoice];
          } else {
            totalUnpaidValue = totalUnpaidValue + +invoice.total;
            listUnpaidInfo = [...listUnpaidInfo, invoice];
          }
        }
      }
      paidInfo = [...paidInfo, listPaidInfo];
      unpaidInfo = [...unpaidInfo, listUnpaidInfo];
      paidValues = [...paidValues, +parseFloat(totalPaidValue).toFixed(2)];
      unpaidValues = [...unpaidValues, +parseFloat(totalUnpaidValue).toFixed(2)];
    }
    values = { paid: paidValues, unpaid: unpaidValues, paidInfo: paidInfo, unpaidInfo: unpaidInfo };
    // console.log(labels, values);
    const finalResult = { labels: labels, values: values };
    // console.log(finalResult);
    return finalResult;
  };

  const __calcXeroData = async () => {
    const xeroAccrecData = await __getXeroInvoices("ACCREC");
    setAccrecData(xeroAccrecData);
  };

  const burndownData = () => {
    let labels = [];
    let values = [];
    let bcolors = [];
    let info = [];
    if (portfolioProjects) {
      let data = {};
      for (const project of portfolioProjects) {
        // console.log(project);
        if (project.project_type === "fp") {
          for (const fb of project.forecast_burndowns) {
            if (new Date(fb.month) >= addMonths(new Date(), -7) && new Date(fb.month) <= addMonths(new Date(), +7)) {
              //labels = [...labels, fb.month];
              //values = [...values, fb.financial];
              if (typeof data[toDigitDate(new Date(fb.month))] === "undefined") {
                data = { ...data, [toDigitDate(new Date(fb.month))]: { financial: +fb.financial, color: null, info: [{ pid: project.pid, title: `${project.company} - ${project.name}`, amount: +fb.financial }] } };
              } else {
                data[toDigitDate(new Date(fb.month))]["financial"] += +fb.financial;
                data[toDigitDate(new Date(fb.month))]["info"] = [...data[toDigitDate(new Date(fb.month))]["info"], { pid: project.pid, title: `${project.company} - ${project.name}`, amount: +fb.financial }];
              }
              if (new Date(toShortDate(new Date())) > new Date(fb.month)) {
                data[toDigitDate(new Date(fb.month))]["color"] = "#055bfb";
              } else {
                data[toDigitDate(new Date(fb.month))]["color"] = "#5bf50b";
              }
            }
          }
        } else {
          for (const fb of project.forecast_burndowns) {
            if (new Date(fb.month) >= addMonths(new Date(), -7) && new Date(fb.month) <= addMonths(new Date(), +7)) {
              if (new Date(fb.month) >= new Date(toShortDate(new Date())) && project.project_status === 1) {
                if (typeof data[toDigitDate(new Date(fb.month))] === "undefined") {
                  data = { ...data, [toDigitDate(new Date(fb.month))]: { financial: +fb.financial, color: null, info: [{ pid: project.pid, title: `${project.company} - ${project.name}`, amount: +fb.financial }] } };
                } else {
                  data[toDigitDate(new Date(fb.month))]["financial"] += +fb.financial;
                  data[toDigitDate(new Date(fb.month))]["info"] = [...data[toDigitDate(new Date(fb.month))]["info"], { pid: project.pid, title: `${project.company} - ${project.name}`, amount: +fb.financial }];
                }
              } else {
                const financial = project.timesheets.filter((data, index) => toShortDate(data.date_worked) === fb.month).reduce((total, key) => +parseFloat((total = total + key.hours_worked * project.hourly_rate)).toFixed(2), 0);
                if (typeof data[toDigitDate(new Date(fb.month))] === "undefined") {
                  data = { ...data, [toDigitDate(new Date(fb.month))]: { financial: +financial, color: null, info: [{ pid: project.pid, title: `${project.company} - ${project.name}`, amount: +financial }] } };
                } else {
                  data[toDigitDate(new Date(fb.month))]["financial"] += +financial;
                  data[toDigitDate(new Date(fb.month))]["info"] = [...data[toDigitDate(new Date(fb.month))]["info"], { pid: project.pid, title: `${project.company} - ${project.name}`, amount: +financial }];
                }
              }
              if (new Date(toShortDate(new Date())) > new Date(fb.month)) {
                data[toDigitDate(new Date(fb.month))]["color"] = "#055bfb";
              } else {
                data[toDigitDate(new Date(fb.month))]["color"] = "#5bf50b";
              }
            }
          }
        }
      }
      // console.log(data);
      for (const key of Object.keys(data).sort()) {
        labels = [...labels, toShortDate(key)];
        values = [...values, data[key].financial];
        bcolors = [...bcolors, data[key].color];
        info = [...info, data[key].info];
      }
      //console.log(info);
    }

    return { labels: labels, values: values, bcolors: bcolors, info: info };
  };

  const effortForecast = () => {
    let labels = [];
    let project_legend = [];
    let actuals = [];
    let forecasts = [];
    if (portfolioProjects) {
      const openProjects = portfolioProjects.filter((data) => data.project_status === 1);
      let data = {};
      let x = 1;
      for (const project of openProjects) {
        data[x] = { info: `${project.company} - ${project.name}`, actual: 0, forecast: 0 };
        for (const fb of project.forecast_burndowns) {
          data[x]["forecast"] += +fb.effort;
        }
        const efforts = project.timesheets.reduce((total, key) => +parseFloat((total = total + key.hours_worked)).toFixed(2), 0);
        data[x]["actual"] += +efforts;
        x++;
      }
      for (const key of Object.keys(data)) {
        labels = [...labels, data[key].info];
        project_legend = [...project_legend, data[key].info];
        actuals = [...actuals, data[key].actual];
        forecasts = [...forecasts, data[key].forecast];
      }
    }
    return {
      labels: labels,
      project_legend: project_legend,
      datasets: [
        { data: actuals, backgroundColor: "#055bfb", label: "Actual" },
        { data: forecasts, backgroundColor: "#5bf50b", label: "Forecast" },
      ],
    };
  };

  const __toast = (msg, link, time = 2500, w = 30) =>
    toast.error(`⛔ ${msg}`, {
      onClose: () => (link ? (window.location.href = link) : false),
      position: "top-center",
      autoClose: time,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: `toast-width-${w}`,
      progress: undefined,
    });

  if (!portfolioDetails) {
    return (
      <div className="p-5">
        Loading Charts...
        {/* Alerts and notification */}
        <div>
          <ToastContainer position="top-center" autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
      </div>
    );
  }
  effortForecast();
  return (
    <div className="main-content">
      <div className="stat-col">
        <div className="stat-row">
          <div className="chart-box">
            <div className="counter-box-header">
              <span className="counter-box-title">FINANCIAL SUMMARY</span>
              <div className="counter-box-header-line"></div>
            </div>
            <div className="counter-box-body">
              <StackedBarGraph
                data={{
                  spent_bar_height: (portfolioDetails.invoiced / portfolioDetails.budget) * 100,
                  remaining_bar_height: (portfolioDetails.budget_left / portfolioDetails.budget) * 100,
                  budget_remaining: portfolioDetails.budget_left,
                  total_budget: portfolioDetails.budget,
                  budget_invoiced: portfolioDetails.invoiced,
                }}
              />
            </div>
          </div>
          <div className="chart-box-double">
            <div className="counter-box-header">
              <span className="counter-box-title">FINANCIAL BURNDOWN</span>
              <div className="counter-box-header-line"></div>
            </div>

            <div className="counter-box-body">
              <div className="bar-graph-legend row">
                <div className="mr-3">
                  <div className="bar-graph-legend-blue"></div>
                  Actual
                </div>
                <div className="bar-graph-legend-green"></div>
                <div>Forecast</div>
              </div>
              <FinancialBurndown
                labels={burndownData().labels}
                values={burndownData().values}
                bcolors={burndownData().bcolors}
                info={burndownData().info}
                setShow={setShow}
                setMonthLabel={setMonthLabel}
                setMonthData={setMonthData}
                setMonthTotal={setMonthTotal}
              />
            </div>
          </div>
        </div>
        <div className="stat-row">
          <div className="chart-box-full">
            <div className="counter-box-header">
              <span className="counter-box-title">XERO FINANCE (ACCREC)</span>
              <div className="counter-box-header-line"></div>
            </div>
            <div className="counter-box-body">
              <div className="bar-graph-legend row">
                <div className="mr-3">
                  <div className="bar-graph-legend-blue"></div>
                  Paid
                </div>
                <div className="bar-graph-legend-green"></div>
                <div>Unpaid</div>
              </div>
              <XeroFinancial labels={accrecData.labels} values={accrecData.values} bcolors={{ paid: "rgb(5,91,251)", unpaid: "rgb(91,245,11)" }} />
            </div>
          </div>
        </div>
        <div className="stat-row">
          <div className="chart-box-full">
            <div className="counter-box-header">
              <span className="counter-box-title">EFFORT FORECAST ({effortForecast().labels.length})</span>
              <div className="counter-box-header-line"></div>
            </div>
            <div className="counter-box-body">
              <div className="bar-graph-legend row">
                <div className="mr-3">
                  <div className="bar-graph-legend-blue"></div>
                  Actual
                </div>
                <div className="bar-graph-legend-green"></div>
                <div>Forecast</div>
              </div>
              <EffortForecast labels={effortForecast().labels} datasets={effortForecast().datasets} />
            </div>
          </div>
        </div>
      </div>
      {/* Alerts and notification */}
      <div>
        <ToastContainer position="top-center" autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      </div>
      <div>
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" backdrop="static" keyboard={false} centered show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">{monthLabel}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="finance-list">
              {monthData?.length > 0 ? (
                <>
                  {monthData?.map((data) => (
                    <NavLink
                      key={data.pid}
                      onClick={(e) => {
                        if (can("View Project Management Dashboard")) {
                          localStorage.setItem("viewEdit", "edit");
                          action(_loadDetails(portfolioProjects?.filter((project) => project.pid === data.pid)[0]));
                        } else {
                          e.preventDefault();
                        }
                      }}
                      exact
                      to={`/members/projects/finance`}
                    >
                      <div key={data.pid} className={`p-1 m-1 finance-list-item`}>
                        {data.title}
                        <span className="float-right">${toCurrency(parseFloat(data.amount).toFixed(2))}</span>
                      </div>
                    </NavLink>
                  ))}
                </>
              ) : (
                <small className="font-italic">No Data...</small>
              )}
              <div className="p-1 m-1 finance-list-total">
                <span className="font-weight-bold">Total</span>
                <span className="float-right">${toCurrency(parseFloat(monthTotal).toFixed(2))}</span>
              </div>
              <CSVLink
                data={monthData}
                headers={[
                  { label: "Project Name", key: "title" },
                  { label: "Amount", key: "amount" },
                ]}
                filename={`${monthLabel}_Financial_Burndown`}
                target="_blank"
              >
                <DefaultButton className="btn-default float-right" style={{ boxShadow: theme.effects.elevation4 }} text="Export CSV Report" allowDisabledFocus />
              </CSVLink>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default ContentFinance;
