import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { mW, toDate, toCurrency } from "../../../../components/reusableFunctions";
import plannerIcon from "../../../../assets/images/planner-icon.png";
import autotaskIcon from "../../../../assets/images/autotask-icon.png";
import { Modal } from "react-bootstrap";

function ProjectDetails(props) {
  const [showProjectDesc, setShowProjectDesc] = useState(false);

  if (props && !props.editProject) {
    return <></>;
  }
  const hoursLeft = () => {
    const effort_spent = props.editProject.effort_spent ?? 0;
    if (props.editProject.project_status === 0 && props.editProject.project_type === "tm") {
      return 0;
    } else {
      if (props.editProject.forecast_hours) {
        return props.editProject.forecast_hours - effort_spent;
      } else {
        return 0;
      }
    }
  };

  const totalHours = () => {
    let total_hours = props?.editProject?.forecast_hours ?? 0;

    if (props.editProject.project_status === 0 && props.editProject.project_type === "tm") {
      total_hours = props.editProject.timesheets.reduce((total, key) => +parseFloat((total = total + key.hours_worked)).toFixed(2), 0);
    }
    return total_hours;
  };

  const tasksLeft = () => {
    if (props.editProject.tasks) {
      return props.editProject.tasks.length - props.editProject.tasks.filter((data) => data.status === 1).length;
    } else {
      return 0;
    }
  };

  return (
    <>
      {props.editProject.project_status === 0 && (
        <div className="details-row">
          <span style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>PROJECT IS CLOSED</span>
        </div>
      )}
      <div className="details-row">
        <div className="details-col" style={{ ...mW(8, 40) }}>
          <div className="details-title">
            {props.editProject.in_planner === 1 && (
              <div style={{ height: "1em", width: "1em", float: "left", marginRight: "2px" }} className="ml-2">
                <Image title="Project is in Planner" src={plannerIcon} fluid />
              </div>
            )}
            {props.editProject.in_autotask === 1 && (
              <div style={{ height: "1em", width: "1em", float: "left", marginRight: "2px" }} className="ml-2">
                <Image title="Project is in Autotask" src={autotaskIcon} fluid />
              </div>
            )}
            {props.canEditProject && (
              <>
                <button style={{ fontSize: "12px", backgroundColor: "transparent", float: "right" }} onClick={props?.doEditProject}>
                  <i className="ms-Icon ms-Icon--Edit" style={{ pointerEvents: "none" }} />
                </button>
              </>
            )}
            PROJECT NAME
          </div>
          <div className="details-text" onClick={() => setShowProjectDesc(true)} style={{ cursor: "pointer" }}>
            {props?.editProject?.company} - {props?.editProject?.name} ({props?.editProject?.project_type?.toUpperCase()})
          </div>
        </div>
        <div className="details-col" style={{ ...mW(8, 20) }}>
          <div className="details-title text-center">PROJECT LEAD</div>
          <div className="details-text details-text-center details-left-border-dashed">
            <Image className="details-pm-photo" src={props?.editProject?.user?.photo} roundedCircle />
            {props?.editProject?.user?.displayName ? props?.editProject?.user?.displayName : props?.editProject?.user?.userPrincipalName}
          </div>
        </div>
        <div className="details-col" style={{ ...mW(7, 8) }}>
          <div className="details-title text-center">START</div>
          <div className="details-text details-text-center details-left-border-dashed">{toDate(props?.editProject?.start_date)}</div>
        </div>
        <div className="details-col" style={{ ...mW(7, 8) }}>
          <div className="details-title text-center">FINISH</div>
          <div className="details-text details-text-center details-left-border-dashed">{toDate(props?.editProject?.end_date)}</div>
        </div>
        <div className="details-col" style={{ ...mW(7.5, 7.5) }}>
          <div className="details-title text-center">TOTAL HOURS</div>
          <div className="details-text details-text-center details-left-border-dashed">{toCurrency(parseFloat(totalHours()).toFixed(2))}</div>
        </div>
        <div className="details-col" style={{ ...mW(7, 7) }}>
          <div className="details-title text-center">HOURS LEFT</div>
          <div className={"details-text details-text-center details-left-border-dashed" + (toCurrency(parseFloat(hoursLeft()).toFixed(2)) < 0 ? " text-danger" : "")}>{toCurrency(parseFloat(hoursLeft()).toFixed(2))}</div>
        </div>
        <div className="details-col" style={{ ...mW(7, 7) }}>
          <div className="details-title text-center">TOTAL TASKS</div>
          <div className="details-text details-text-center details-left-border-dashed">{props?.editProject?.tasks?.length}</div>
        </div>
        <div className="details-col" style={{ ...mW(7, 7) }}>
          <div className="details-title text-center">TASKS LEFT</div>
          <div className="details-text details-text-center details-left-border-dashed">{tasksLeft()}</div>
        </div>
      </div>
      {/* Modal for Project Description */}
      <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" backdrop="static" keyboard={false} centered show={showProjectDesc} onHide={() => setShowProjectDesc(false)}>
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Project Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <div>{props.editProject.description}</div> */}
          <div dangerouslySetInnerHTML={{ __html: props.editProject.description }} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ProjectDetails;
