/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { getTheme } from "@fluentui/react";
import { Image, Modal, Button, Form, Col } from "react-bootstrap";
import "../../../../assets/css/contentProjects/projectTask.css";
import { FluentIcon } from "../../../../components/FluentIcon.js";
import "../../../../assets/fluentui.icons/css/fabric-icons.css";
import { toDate, toSqlDate, baseApiURL, mW, debounce, sDate, eDate, toDigitDate, toFullDateTime, compArray, isEqual, can } from "../../../../components/reusableFunctions";
import { useSelector, useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import {
  _updateChecklist,
  _delChecklist,
  _setIdOfTaskInModal,
  _updateTask,
  _loadTaskNewAssignee,
  _loadTaskOldAssignee,
  _loadTaskNewComments,
  _updateTaskNewComments,
  _loadTaskOldComments,
  _updateTaskNewComment,
  _updateDetails,
} from "../../../../actions/editProjectActions";
import { graphConfig } from "../../../../authConfig";
import { doPatchGraphApi, doGetGraphApi, doDeleteGraphApi } from "../../../../components/graph";
import { getProjectData } from "./getProjectData";
import Select, { components } from "react-select";
import update_loader from "../../../../assets/images/update_loader.svg";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import axios from "axios";
import { nanoid } from "nanoid";
import { DefaultButton } from "@fluentui/react/lib/Button";

function ProjectTask(props) {
  const theme = getTheme();
  const isMounted = useRef(false);
  const [memberPicker, setMemberPicker] = useState();
  const newAssignments = useSelector((state) => state.epTaskEdit.newAssignees);
  const oldAssignments = useSelector((state) => state.epTaskEdit.oldAssignees);
  const newComments = useSelector((state) => state.taskComments.newComments);
  const oldComments = useSelector((state) => state.taskComments.oldComments);
  const taskNewComment = useSelector((state) => state.taskNewComment);
  const idOfTaskInModal = useSelector((state) => state.idOfTaskInModal);
  const [pboxHover, setpboxHover] = useState(false);
  const [tskHover, setTskHover] = useState(false);
  const [tskUpdating, setTskUpdating] = useState(false);
  const [chkUpdating, setChkUpdating] = useState(false);
  const [postingComment, setPostingComment] = useState(false);
  const [commentDate, setCommentDate] = useState(new Date());
  const [currentAssignments, setCurrentAssignments] = useState([]);
  const [newChecklistModal, setNewChecklistModal] = useState(false);
  const [newChecklistName, setNewChecklistName] = useState("");
  const [savingNewChecklist, setSavingNewChecklist] = useState(false);
  const [editTaskNameModal, setEditTaskNameModal] = useState(false);
  const [newTaskName, setNewTaskName] = useState("");
  const [savingNewTaskName, setSavingNewTaskName] = useState(false);
  const [editChecklistId, setEditChecklistId] = useState(null);
  const [editChecklistNameModal, setEditChecklistNameModal] = useState(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      __rebuildAssignments(props?.task?.assignments);
      loadMemberPicker();
    }
  }, []);

  //build memberPicker with icons
  const { Option } = components;
  const CustomSelectOption = (props) => (
    <Option {...props}>
      <Image src={props?.data?.icon} style={{ marginTop: "-3px", width: "22px", height: "22px" }} fluid roundedCircle />
      {props?.data?.label}
    </Option>
  );

  const CustomSelectValue = (props) => (
    <div>
      <Image src={props?.data?.icon} style={{ marginTop: "-3px", width: "22px", height: "22px" }} fluid roundedCircle />
      {props?.data?.label}
    </div>
  );

  const loadMemberPicker = () => {
    if (typeof memberPicker === "undefined" || memberPicker?.length === 0) {
      let pickerOptions = [];
      for (const member of props?.members) {
        const option = {};
        option.value = member.id;
        option.label = member.displayName;
        option.icon = member.photo;
        pickerOptions = [...pickerOptions, option];
      }
      setMemberPicker(pickerOptions);
    }
  };

  const __toast = (msg, link, time = 2500, w = 30) =>
    toast.error(msg, {
      onClose: () => (link ? (window.location.href = link) : false),
      position: "top-center",
      autoClose: time,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: `toast-width-${w}`,
      progress: undefined,
    });

  const hoverHandler = {
    style: { boxShadow: pboxHover ? theme.effects.elevation16 : theme.effects.elevation4 },
    onMouseEnter: () => setpboxHover(true),
    onMouseLeave: () => setpboxHover(false),
  };

  const __tskHover = (e) => {
    e.stopPropagation();
    setTskHover(true);
  };

  const __tskLeave = (e) => {
    e.stopPropagation();
    setTskHover(false);
  };

  const action = useDispatch();

  const __tskClick = async (status) => {
    setTskUpdating(true);
    const value = status === 1 ? 0 : 100;
    const msPayload = {
      percentComplete: value,
    };
    if (props.project?.in_planner === 1) {
      const result = await __updateMsTask(msPayload);
      if (result?.error) {
        __toast(result.error, null, null, 30);
      }
    }
    const payload = {
      pid: props?.task?.pid,
      bid: props?.task?.bid,
      tid: props?.task?.tid,
      status: _isCompleted() ? 0 : 1,
      completed_by: _isCompleted() ? null : props?.user?.msId,
      completed_on: _isCompleted() ? null : toSqlDate(new Date()),
      updates: {
        status: _isCompleted() ? 0 : 1,
        completed_by: _isCompleted() ? null : props?.user?.msId,
        completed_on: _isCompleted() ? null : toSqlDate(new Date()),
      },
    };
    const result = await __updateTask(payload);
    if (result?.error) {
      __toast(result.error, null, null, 30);
    } else {
      delete payload.updates;
      action(_updateTask(payload));
    }

    setTskUpdating(false);
    props?.changeProgress({ target: { value: 2 } });
  };

  const __chkClick = async (e) => {
    e.stopPropagation();
    if (+e.target.getAttribute("tstat") === 1) return;
    setChkUpdating(e.target.id);
    const checklist = props?.project?.checklists?.filter((cl) => cl?.cid === e.target.id)[0];
    const payload = {
      pid: checklist.pid,
      tid: checklist.tid,
      cid: e.target.id,
      name: checklist.name,
      status: +e.target.getAttribute("update"),
      updates: {
        status: +e.target.getAttribute("update"),
      },
    };
    const result = await __apiUpdateChecklist(payload);
    if (result?.error) {
      __toast(result.error, null, null, 30);
      payload.status = payload.status === 1 ? 0 : 1;
      action(_updateChecklist(payload));
    }
    setChkUpdating(false);
    action(_updateChecklist(payload));
    props?.changeProgress({ target: { value: 2 } });
  };

  const __apiUpdateChecklist = async (payload, type = 1) => {
    const result = await __updateChecklist(payload, type);
    if (result.error) {
      return result.error;
    } else {
      if (props.project?.in_planner === 1) {
        delete payload.updates;
        console.log("Updating planner checklist...");
        const graphResult = await __updateMsChecklist(payload, type);
        if (graphResult.error) {
          if (type === 1) {
            //revert checklist update if Planner fails
            payload.status = payload.status === 1 ? 0 : 1;
            payload.updates = { status: payload.status === 1 ? 0 : 1 };
            await __updateChecklist(payload);
          }
          return graphResult.error;
        }
      }
    }
    return true;
  };

  const __updateChecklist = async (payload, type = 1) => {
    const todo = type === 0 ? "delete" : "update";
    console.log(`${todo} checklist...`);
    return axios
      .post(`${baseApiURL}/checklist/${todo}`, payload)
      .then((res) => {
        return res.data;
      })
      .catch(function (error) {
        return { error: error };
      });
  };

  const __updateMsChecklist = async (payload, type = 1) => {
    let msPayload;
    if (type === 0) {
      msPayload = {
        checklist: {
          [payload.cid]: null,
        },
      };
    } else {
      msPayload = {
        checklist: {
          [payload.cid]: {
            "@odata.type": "microsoft.graph.plannerChecklistItem",
            isChecked: payload.status === 1 ? true : false,
            title: payload.name,
          },
        },
      };
    }
    console.log(msPayload);
    const resultGet = await doGetGraphApi(graphConfig.postTaskDetails.replace("[task-id]", props?.task?.ms_tid));
    if (resultGet?.error) {
      return { error: `Something went wrong getting details for task: ${props?.task?.ms_tid}` };
    } else {
      const etag = resultGet["@odata.etag"];
      console.log("etag found updating checklist...");
      const resultPatch = await doPatchGraphApi(graphConfig.postTaskDetails.replace("[task-id]", props?.task?.ms_tid), msPayload, etag);
      if (resultPatch?.error) {
        return { error: `Something went updating checklist for task: ${props?.task?.ms_tid}` };
      }
    }
    return true;
  };

  const __updateTask = async (payload) => {
    console.log("updating task...");
    return axios
      .post(`${baseApiURL}/task/update`, payload)
      .then((res) => {
        return res.data;
      })
      .catch(function (error) {
        return { error: error };
      });
  };

  const __updateMsTask = async (payload) => {
    console.log(payload);
    const resultGet = await doGetGraphApi(graphConfig.updateTask.replace("[task-id]", props?.task?.ms_tid));
    if (resultGet?.error) {
      return { error: `Something went wrong querying task: ${props?.task?.ms_tid}` };
    } else {
      const etag = resultGet["@odata.etag"];
      console.log("etag found updating task...", payload);
      const resultPatch = await doPatchGraphApi(graphConfig.updateTask.replace("[task-id]", props?.task?.ms_tid), payload, etag);
      if (resultPatch?.error) {
        return { error: `Something went wrong updating task: ${props?.task?.ms_tid}` };
      }
    }
    return true;
  };

  const __confirmTaskCompletion = async (e) => {
    e.stopPropagation();
    Swal.fire({
      title: "Are you sure?",
      text: props?.task?.status === 0 ? "This will complete the task and its checklists" : "This will mark the task as incomplete and checklists will be restored.",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Confirm",
      confirmButtonColor: "#DF3D22",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        __tskClick(props?.task?.status);
      }
    });
  };

  const _isCompleted = () => {
    return props?.task?.status === 1;
  };

  const _isOverdue = () => {
    if (props?.task?.completed_on === null) {
      return new Date(toDate(props?.task?.due_date)) < new Date(toDate(new Date()));
    } else {
      return new Date(toDate(props?.task?.completed_on)) > new Date(toDate(props?.task?.due_date));
    }
  };

  const _doneAllChecklist = () => {
    return _doneChecklist() === _totalChecklist();
  };

  const _doneChecklist = () => {
    return props?.project?.checklists?.filter((cl) => cl.tid === props?.task?.tid && cl.status === 1).length;
  };

  const _totalChecklist = () => {
    return props?.project?.checklists?.filter((cl) => cl.tid === props?.task?.tid).length;
  };

  const _isDone = (checklist) => {
    return checklist?.status === 1 || _isCompleted();
  };

  const _getMemberInfo = (id) => {
    const userInfo = props?.members?.filter((data) => data.id === id)[0];
    return (
      <div style={{ display: "inline" }}>
        <Image src={userInfo?.photo} style={{ marginTop: "-3px", width: "18px", height: "18px" }} fluid roundedCircle />
        {userInfo?.displayName}
      </div>
    );
  };

  const _isTargetTask = () => {
    return idOfTaskInModal === props?.task?.id;
  };

  const __handleTaskStart = async (date) => {
    if (toDigitDate(date) > toDigitDate(props?.task?.due_date)) {
      __toast("Due date is lesser than start date", null, null, 30);
      return;
    }
    if (toDigitDate(date) < toDigitDate(props?.project.start_date) || toDigitDate(date) > toDigitDate(props?.project.end_date)) {
      __toast("Due date is beyond project timeline", null, null, 30);
      return;
    }
    const result = await __changeTaskDate(date, "start");
    if (result?.error) {
      setTskUpdating(false);
      __toast(result.error, null, null, 30);
    } else {
      setTskUpdating(false);
      console.log("Task date change success!");
    }
  };

  const __handleTaskDue = async (date) => {
    if (new Date(toDate(date)) < new Date(toDate(props?.task?.start_date))) {
      __toast("Due date is lesser than start date", null, null, 30);
      return;
    }
    if (new Date(toDate(date)) < new Date(toDate(props?.project.start_date)) || new Date(toDate(date)) > new Date(toDate(props?.project.end_date))) {
      __toast("Due date is beyond project timeline", null, null, 30);
      return;
    }
    const result = await __changeTaskDate(date, "due");
    if (result?.error) {
      __toast(result.error, null, null, 30);
    } else {
      console.log("Task date change success!");
    }
  };

  const __changeTaskDate = async (date, type) => {
    setTskUpdating(true);

    if (props.project?.in_planner === 1) {
      const msPayload = {
        [`${type}DateTime`]: type === "start" ? sDate(date) : eDate(date),
      };
      const result = await __updateMsTask(msPayload);
      if (result?.error) {
        return result;
      }
    }
    if (props?.project?.in_autotask === 1) {
      const result = await __updateAutotaskTask(false, type === "start" ? date : false, type === "due" ? date : false);
      if (result?.error) {
        console.log(result);
      }
    }
    const payload = {
      pid: props?.task?.pid,
      bid: props?.task?.bid,
      tid: props?.task?.tid,
      [`${type}_date`]: type === "start" ? toSqlDate(sDate(date)) : toSqlDate(eDate(date)),
      updates: {
        [`${type}_date`]: type === "start" ? toSqlDate(sDate(date)) : toSqlDate(eDate(date)),
      },
    };
    const result = await __updateTask(payload);
    if (result?.error) {
      return result;
    } else {
      delete payload.updates;
      action(_updateTask(payload));
      return true;
    }
  };

  const __changeTaskAssignments = (value) => {
    action(_loadTaskNewAssignee(value));
  };

  const __changeNewComment = (e) => {
    debounce(action(_updateTaskNewComment(e.target.value)), 500);
  };

  const __sendNewComment = async () => {
    setPostingComment(true);
    const payload = {
      pid: props?.task?.pid,
      tid: props?.task?.tid,
      user_id: props?.user?.id,
      user_data: JSON.stringify({
        msId: props?.user?.msId,
        displayName: props?.user?.displayName,
        photo: props?.user?.photo,
      }),
      comment: taskNewComment,
      status: 1,
      createdAt: commentDate,
    };
    const result = await __postNewComment(payload);
    if (result?.error) {
      __toast(result.error, null, null, 30);
    }
    setPostingComment(false);
    action(_updateTaskNewComment(""));
    action(_updateTaskNewComments(payload));
  };

  const __postNewComment = async (payload) => {
    console.log("posting comment...");
    return axios
      .post(`${baseApiURL}/comment/add`, payload)
      .then((res) => {
        return res.data;
      })
      .catch(function (error) {
        return { error: error };
      });
  };

  const __openTaskModal = async (id) => {
    //id === 0 meaning, closing task modal
    if (id === 0) {
      setpboxHover(false);
      let dataForRefresh = [];
      const comments = compArray(newComments, oldComments, "id", "id", false);
      if (comments.forAdding.length > 0) {
        dataForRefresh = ["Comment"];
      }
      const people = compArray(newAssignments, oldAssignments, "value", "value", false);
      if ((people?.forRemoval.length > 0 || people?.forAdding.length > 0) && can("Edit Project Task Details")) {
        setTskUpdating(true);
        const payload = { assignments: {} };
        if (people.forRemoval.length > 0) {
          for (const id of people.forRemoval) {
            payload.assignments[id] = null;
          }
        }
        if (people.forAdding.length > 0) {
          for (const id of people.forAdding) {
            payload.assignments[id] = {
              "@odata.type": "microsoft.graph.plannerAssignment",
              orderHint: " !",
            };
          }
        }
        if (props.project?.in_planner === 1) {
          const resultMS = await __updateTaskAssignments(payload);
          if (resultMS?.error) {
            __toast(resultMS.error, null, null, 30);
          }
        }
        const final_payload = {
          pid: props?.task?.pid,
          bid: props?.task?.bid,
          tid: props?.task?.tid,
          assignments: JSON.stringify(newAssignments),
          updates: {
            assignments: JSON.stringify(newAssignments),
          },
        };
        const resultLocal = await __updateTask(final_payload);
        if (resultLocal?.error) {
          __toast(resultLocal.error, null, null, 30);
        } else {
          delete final_payload.updates;
          action(_updateTask(final_payload));
          dataForRefresh = [...dataForRefresh, "Task"];
          console.log("task update successfull");
        }

        setTskUpdating(false);
      }
      if (dataForRefresh.length > 0) {
        console.log(dataForRefresh.toString());
        __refreshData(`*/${props?.task?.pid}/${dataForRefresh.toString()}`);
      }
    } else {
      //opening task modal - Load initial data
      if (id === props?.task?.id) {
        /*
        const tmpAssignments = JSON.parse(props?.task?.assignments);
        for (const tmpAssignee of tmpAssignments) {
          const tmpMember = props?.members.filter((data) => data.id === tmpAssignee.value)[0];
          if (tmpMember) {
            tmpAssignee.icon = tmpMember.photo;
            tmpAssignee.label = tmpMember.displayName ?? tmpMember.userPrincipalName;
          }
        }
        */
        //const tmpAssignments = __rebuildAssignments(props?.task?.assignments);
        action(_loadTaskNewAssignee(JSON.parse(JSON.stringify(currentAssignments))));
        action(_loadTaskOldAssignee(JSON.parse(JSON.stringify(currentAssignments))));
        const tmpComments = JSON.parse(JSON.stringify(props?.project?.comments?.filter((comment) => comment.tid === props?.task?.tid)));
        for (const tmpComment of tmpComments) {
          const userData = JSON.parse(tmpComment.user_data);
          const tmpUserData = props?.members.filter((data) => data.id === userData.msId)[0];
          if (tmpUserData) {
            userData.displayName = tmpUserData.displayName ?? tmpUserData.userPrincipalName;
            userData.photo = tmpUserData.photo;
            tmpComment.user_data = JSON.stringify(userData);
          }
        }
        action(_loadTaskNewComments(JSON.parse(JSON.stringify(tmpComments))));
        action(_loadTaskOldComments(JSON.parse(JSON.stringify(tmpComments))));
        if (typeof memberPicker === "undefined" || memberPicker?.length === 0) {
          let pickerOptions = [];
          for (const member of props?.members) {
            const option = {};
            option.value = member.id;
            option.label = member.displayName;
            option.icon = member.photo;
            pickerOptions = [...pickerOptions, option];
          }
          setMemberPicker(pickerOptions);
        }
      }
    }
    action(_setIdOfTaskInModal(id));
  };

  const __delTask = async () => {
    const task = props.task;
    if (props.project.in_planner === 1) {
      setTskUpdating(true);
      console.log("getting task details...");
      const resultGet = await doGetGraphApi(graphConfig.updateTask.replace("[task-id]", task.ms_tid));
      if (resultGet?.error) {
        return { error: `Something went wrong getting details for task: ${task.tid}` };
      } else {
        const etag = resultGet["@odata.etag"];
        console.log("etag found deleting task...", etag);
        const resultDelete = await doDeleteGraphApi(graphConfig.updateTask.replace("[task-id]", task.ms_tid), etag);
        if (resultDelete?.error) {
          __toast("Something went wrong removing task in planner.", null, null, 30);
          setTskUpdating(false);
          return false;
        }
        console.log("task deleted in planner");
      }
    }
    await __delTaskLocal(task);
    __refreshData(`*/${props?.task?.pid}/Task`);
  };

  const __delTaskLocal = async (payload) => {
    console.log("saving checklist...");
    return axios
      .post(`${baseApiURL}/task/delete`, payload)
      .then((res) => {
        return res.data;
      })
      .catch(function (error) {
        console.error(error);
        __toast("ERROR: Something went wrong while saving task.", null, null, 30);
        return { error: error };
      });
  };

  const __confirmTaskDelete = async () => {
    Swal.fire({
      text: "Are you sure you wish to delete this Task?",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Confirm",
      confirmButtonColor: "#DF3D22",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        __delTask();
      }
    });
  };

  const __confirmChecklistDelete = async () => {
    Swal.fire({
      text: "Are you sure you wish to delete this Checklist?",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Confirm",
      confirmButtonColor: "#DF3D22",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        __delChecklist();
      }
    });
  };

  const __rebuildAssignments = async (payload) => {
    const tmpAssignments = JSON.parse(payload);
    for (const tmpAssignee of tmpAssignments) {
      const tmpMember = props?.members.filter((data) => data.id === tmpAssignee.value)[0];
      if (tmpMember) {
        tmpAssignee.icon = tmpMember.photo;
        tmpAssignee.label = tmpMember.displayName ?? tmpMember.userPrincipalName;
      }
    }
    if (!isEqual(JSON.parse(payload), tmpAssignments)) {
      await __updateLocalTaskAssignments(tmpAssignments);
    }
    setCurrentAssignments(tmpAssignments);
  };

  const __updateLocalTaskAssignments = async (assignments) => {
    const payload = {
      pid: props?.task?.pid,
      bid: props?.task?.bid,
      tid: props?.task?.tid,
      updates: {
        assignments: JSON.stringify(assignments),
      },
    };
    const resultLocal = await __updateTask(payload);
    if (resultLocal?.error) {
      console.log("Failed updating task assignment");
    } else {
      console.log("Assignment for this task has been updated");
    }
  };

  const __refreshData = async (payload) => {
    if (!props?.task?.pid) return;
    const project = await getProjectData(payload);
    if (project.error) {
      __toast(project.error, null, null, 30);
    } else {
      action(_updateDetails(project));
      return true;
    }
    return false;
  };

  const __updateTaskAssignments = async (payload) => {
    const taskinfo = await doGetGraphApi(graphConfig.updateTask.replace("[task-id]", props?.task?.ms_tid));
    if (taskinfo?.error) {
      return { error: `Something went wrong getting details for task: ${props?.task?.tid}` };
    } else {
      const etag = taskinfo["@odata.etag"];
      console.log("etag found updating task...", props?.task?.ms_tid, etag);
      const resultPatch = await doPatchGraphApi(graphConfig.updateTask.replace("[task-id]", props?.task?.ms_tid), payload, etag);
      if (resultPatch?.error) {
        return { error: `Something went wrong saving checklist for task: ${props?.task?.tid}` };
      } else {
        return true;
      }
    }
  };

  const __doAddNewChecklist = async () => {
    setSavingNewChecklist(true);
    console.log("Adding new checklist...");
    const cid = nanoid(6);
    const payload = {
      checklist: {
        [cid]: {
          "@odata.type": "microsoft.graph.plannerChecklistItem",
          orderHint: `${cid.padStart(5, "0")} !`,
          title: newChecklistName,
          isChecked: false,
        },
      },
      previewType: "checklist",
    };
    const result = await __postChecklist(payload, props.task, cid);
    if (result?.error) {
      __toast(result.error, null, null, 30);
      return;
    }
    setSavingNewChecklist(false);
    setNewChecklistModal(false);
    await props.refreshData(`*/${props.project?.pid}/Checklist`);
  };

  const __postChecklist = async (payload, task, cid) => {
    if (props.project.in_planner === 1) {
      console.log("getting task details...");
      const resultGet = await doGetGraphApi(graphConfig.postTaskDetails.replace("[task-id]", task.ms_tid));
      if (resultGet?.error) {
        return { error: `Something went wrong getting details for task: ${task.tid}` };
      } else {
        const etag = resultGet["@odata.etag"];
        console.log("etag found posting checklists...");
        const resultPatch = await doPatchGraphApi(graphConfig.postTaskDetails.replace("[task-id]", task.ms_tid), payload, etag);
        if (resultPatch?.error) {
          return { error: `Something went wrong saving checklist for task: ${task.tid}` };
        }
      }
    }
    const checklist = { pid: task.pid, bid: task.bid, tid: task.tid, cid: cid, name: newChecklistName, status: 0 };
    const saveResult = await __saveChecklist(checklist);
    if (saveResult?.error) {
      return { error: saveResult.error };
    }
    return true;
  };

  const __saveChecklist = async (payload) => {
    console.log("saving checklist...");
    return axios
      .post(`${baseApiURL}/checklist/add`, payload)
      .then((res) => {
        return res.data;
      })
      .catch(function (error) {
        console.error(error);
        __toast("ERROR: Something went wrong while saving task.", null, null, 30);
        return { error: error };
      });
  };

  const __editTaskName = async () => {
    if (!can("Edit Project Task Details")) {
      return;
    }
    setNewTaskName(props?.task?.name);
    setEditTaskNameModal(true);
  };

  const __changeNewTaskName = (e) => {
    setNewTaskName(e.target.value);
  };

  const __saveNewTaskName = async () => {
    setSavingNewTaskName(true);
    if (props?.project?.in_planner === 1) {
      const msPayload = {
        title: newTaskName,
      };
      const result = await __updateMsTask(msPayload);
      if (result?.error) {
        return result;
      }
    }
    if (props?.project?.in_autotask === 1) {
      const result = await __updateAutotaskTask(newTaskName);
      if (result?.error) {
        console.log(result);
      }
    }
    const payload = {
      pid: props?.task?.pid,
      bid: props?.task?.bid,
      tid: props?.task?.tid,
      name: newTaskName,
      updates: {
        name: newTaskName,
      },
    };
    const result = await __updateTask(payload);
    if (result?.error) {
      return result;
    } else {
      delete payload.updates;
      action(_updateTask(payload));
    }

    setSavingNewTaskName(false);
    setEditTaskNameModal(false);
  };

  const __updateAutotaskTask = async (taskName = false, startdate = false, enddate = false) => {
    console.log("Updating task in autotask...");
    const payload = {
      id: props.task.at_tid,
      projectID: props.project?.at_pid,
      title: taskName ? taskName : props.task?.name,
      status: 1,
      taskType: 1,
      startDateTime: startdate ? sDate(startdate) : props.task.start_date,
      endDateTime: enddate ? eDate(enddate) : props.task.due_date,
    };

    if (taskName) payload.title = taskName;

    const resPostTask = await axios.post(`${baseApiURL}/at/upsert/${props?.portfolioSettings?.pfid}`, { url: `/Projects/${payload.projectID}/Tasks`, payload: payload });
    if (!resPostTask.data.result) {
      return { error: `Something went wrong updating task: ${payload.title}` };
    } else {
      console.log("Autotask project task updated...");
    }
  };

  const __editChecklist = async (e) => {
    const checklist = props?.project?.checklists?.filter((cl) => cl?.cid === e.target.getAttribute("data-cid"))[0];
    setNewChecklistName(checklist.name);
    setEditChecklistId(checklist.id);
    setEditChecklistNameModal(true);
  };

  const __changeNewChecklistName = (e) => {
    setNewChecklistName(e.target.value);
  };

  const __saveNewChecklistName = async () => {
    setChkUpdating(editChecklistId);
    const checklist = props?.project?.checklists?.filter((cl) => cl?.id === editChecklistId)[0];
    const payload = {
      pid: checklist.pid,
      tid: checklist.tid,
      cid: checklist.cid,
      name: newChecklistName,
      status: checklist.status,
      updates: {
        name: newChecklistName,
        status: checklist.status,
      },
    };
    console.log(payload);
    const result = await __apiUpdateChecklist(payload);
    if (result?.error) {
      __toast(result.error, null, null, 30);
    } else {
      action(_updateChecklist(payload));
    }
    setChkUpdating(false);
  };

  const __delChecklist = async () => {
    setChkUpdating(editChecklistId);
    const checklist = props?.project?.checklists?.filter((cl) => cl?.id === editChecklistId)[0];
    const payload = {
      pid: checklist.pid,
      tid: checklist.tid,
      cid: checklist.cid,
    };
    console.log(payload);
    const result = await __apiUpdateChecklist(payload, 0);
    if (result?.error) {
      __toast(result.error, null, null, 30);
    } else {
      action(_delChecklist(payload));
    }
    setChkUpdating(false);
  };

  //Components section that changes behavior if its on a Card or Modal
  const TaskOptions = (props) => {
    if (!_isTargetTask() && !props) {
      return <></>;
    }
    const start_date = new Date(props?.task?.start_date);
    const due_date = new Date(props?.task?.due_date);
    //const currentAssignments = JSON.parse(props?.task?.assignments);
    return (
      <React.Fragment>
        <Form.Row>
          <Form.Label column="sm" style={{ ...mW(4, 0) }}>
            <div className="float-right txt-sm-l">Start</div>
          </Form.Label>
          <Col style={{ ...mW(0, 7.5) }}>
            <DatePicker disabled={!can("Edit Project Task Details")} selected={start_date} onChange={(date) => __handleTaskStart(date)} dateFormat={"dd-MMM-yyyy"} className="due-date mt-1 ml-3" />
          </Col>
          <Form.Label className="pt-1" column="sm" style={{ ...mW(0, 3) }}>
            <div className="float-right txt-sm-l">Due</div>
          </Form.Label>
          <Col style={{ ...mW(0, 6.3) }} className="p-0">
            <DatePicker disabled={!can("Edit Project Task Details")} selected={due_date} onChange={(date) => __handleTaskDue(date)} dateFormat={"dd-MMM-yyyy"} className="due-date mt-1 ml-3" />
          </Col>
          <div style={{ marginTop: "0.4em", marginLeft: "1.4em" }}>
            <FluentIcon name="CalendarAgenda" />
          </div>
          <Col>
            <DefaultButton
              className="float-right p-0"
              style={{ boxShadow: theme.effects.elevation4 }}
              text="New Checklist"
              title="Add a new checklist"
              disabled={props.project?.project_status === 0 ? true : false || !can("Add Project Task Checklists")}
              onClick={() => {
                setNewChecklistName("");
                setNewChecklistModal(true);
              }}
              allowDisabledFocus
            />
          </Col>
        </Form.Row>
        <Form.Row className="bottom-border-dashed pb-1 mb-3">
          <Form.Label className="pt-2" column="sm" style={{ ...mW(7.2, 0) }}>
            <div className="float-right txt-sm-l">Assigned To</div>
          </Form.Label>
          <Col style={{ ...mW(0, 35) }}>
            <Select
              className="ml-3 member-picker"
              value={newAssignments}
              options={memberPicker}
              isClearable={false}
              isDisabled={!can("Edit Project Task Details")}
              isMulti
              styles={{
                control: (styles) => ({
                  ...styles,
                  backgroundColor: "none",
                  border: "none",
                }),
              }}
              components={{
                Option: CustomSelectOption,
                MultiValueLabel: CustomSelectValue,
                DropdownIndicator: () => null,
                CrossIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              onChange={(value) => __changeTaskAssignments(value)}
            />
          </Col>
        </Form.Row>
      </React.Fragment>
    );
  };

  const TaskDetails = (props) => {
    return (
      <React.Fragment>
        <div className="task-box-header">
          <span className="task-box-title">
            <div className="task-box-title-text">
              {tskUpdating ? (
                <span style={{ position: "absolute", marginTop: "0.2em" }}>
                  <Image src={update_loader} fluid />
                </span>
              ) : (
                <span style={{ position: "absolute", marginTop: "0.3em", marginLeft: "1px" }}>
                  <i
                    className={`ms-Icon ms-Icon--${_isCompleted() ? "CompletedSolid text-primary" : tskHover ? "Completed" : "CircleRing"}`}
                    title={props?.task?.name}
                    onClick={__confirmTaskCompletion}
                    onMouseEnter={__tskHover}
                    onMouseLeave={__tskLeave}
                  ></i>
                </span>
              )}{" "}
              <span style={{ lineHeight: "2em" }} className="ml-4">
                {props?.task?.name}
              </span>
              <span className="float-right">
                {currentAssignments?.map((data) => (
                  <Image key={data.value} className="ml-1" src={data.icon} title={data.label} style={{ marginTop: "0px", width: "24px", height: "24px" }} roundedCircle fluid />
                ))}
              </span>
            </div>
          </span>
          <div className="task-box-header-line"></div>
        </div>
        <div className={_isCompleted() || _doneAllChecklist() ? "task-box-body hidden" : "task-box-body"}>
          <div className="task-box-content">
            {props?.checklists
              ?.filter((item) => item?.tid === props?.task?.tid && item?.status === 0)
              .map((checklist, index) => (
                <div className="task-box-checklist" key={index}>
                  <div className="task-box-checklist-icon-wrap">
                    {chkUpdating && chkUpdating === checklist?.cid ? (
                      <span style={{ marginTop: "-1px", width: "1em", height: "1em" }}>
                        <Image src={update_loader} fluid />
                      </span>
                    ) : (
                      <i
                        key={checklist?.cid}
                        className={`task-box-checklist-icon${_isDone(checklist) ? "-done" : ""} ms-Icon ms-Icon--${_isDone(checklist) ? "CompletedSolid text-primary" : "CircleRing"}`}
                        id={checklist?.cid}
                        pid={props?.task?.pid}
                        tid={checklist?.tid}
                        tstat={props?.task?.status}
                        update={checklist?.status === 0 ? 1 : 0}
                        title={_isDone(checklist) ? "Uncheck: " + checklist?.name : "Check: " + checklist?.name}
                        onClick={__chkClick}
                      ></i>
                    )}
                  </div>{" "}
                  <div className={`task-box-checklist-name ${_isDone(checklist) ? "task-strike" : ""}`}>{checklist?.name}</div>
                </div>
              ))}
          </div>
        </div>
        <div className="task-box-footer mt-3">
          <span style={{ marginTop: "0.1em" }} className={_isOverdue() ? "text-danger" : ""}>
            <FluentIcon name="Calendar" />
          </span>
          <span className={`ml-1 mr-3 ${_isOverdue() ? "text-danger" : ""}`}>{toDate(props?.task?.due_date)}</span>
          <span>
            <i className={`task-box-checklist-icon ms-Icon ms-Icon--${_isCompleted() ? "CompletedSolid text-success" : "Completed"}`}></i>
          </span>
          <span className="ml-1">
            {_isCompleted() ? (
              <div>
                Completed by {_getMemberInfo(props?.task?.completed_by)} on {toDate(props?.task?.completed_on)}
              </div>
            ) : (
              <React.Fragment>
                {_doneChecklist()}/{_totalChecklist()}
              </React.Fragment>
            )}
          </span>
        </div>
      </React.Fragment>
    );
  };

  return (
    <div>
      {!_isTargetTask() ? (
        <div className="task-box" {...hoverHandler} onClick={() => __openTaskModal(props?.task?.id)}>
          <TaskDetails task={props?.task} checklists={props?.project?.checklists} />
        </div>
      ) : (
        <div>
          <div className="task-box">Editing task...</div>
          <Modal show={_isTargetTask()} backdrop="static" keyboard={false} size="xl" animation={false} scrollable={true} centered>
            <Modal.Body>
              <div className="task-box-modal pb-3 mb-0 bottom-border-dashed">
                {/* Edit task section */}
                <div className="task-box-header">
                  <span className="task-box-title">
                    <div className="task-box-title-text">
                      {tskUpdating ? (
                        <span style={{ position: "absolute", marginTop: "0.2em" }}>
                          <Image src={update_loader} fluid />
                        </span>
                      ) : (
                        <span style={{ position: "absolute", marginTop: "0.3em", marginLeft: "1px" }}>
                          <i
                            className={`ms-Icon ms-Icon--${_isCompleted() ? "CompletedSolid text-primary" : tskHover ? "Completed" : "CircleRing"}`}
                            title={props?.task?.name}
                            onClick={__confirmTaskCompletion}
                            onMouseEnter={__tskHover}
                            onMouseLeave={__tskLeave}
                          ></i>
                        </span>
                      )}{" "}
                      <span style={{ lineHeight: "1.9em", cursor: "pointer" }} className="ml-4" onClick={__editTaskName} title="Click to edit task name">
                        {props?.task?.name}
                      </span>
                    </div>
                  </span>
                  <div className="task-box-header-line"></div>
                </div>
                <div className="task-box-body-modal">
                  <div className="task-box-content-modal">
                    <TaskOptions task={props?.task} project={props?.project} />
                    {props?.project?.checklists
                      ?.filter((item) => item?.tid === props?.task?.tid)
                      .map((checklist, index) => (
                        <div className="task-box-checklist" key={index}>
                          <div className="task-box-checklist-icon-wrap">
                            {chkUpdating && chkUpdating === checklist?.cid ? (
                              <span style={{ marginTop: "-1px", width: "1em", height: "1em" }}>
                                <Image src={update_loader} fluid />
                              </span>
                            ) : (
                              <i
                                key={checklist?.cid}
                                className={`task-box-checklist-icon${_isDone(checklist) ? "-done" : ""} ms-Icon ms-Icon--${_isDone(checklist) ? "CompletedSolid text-primary" : "CircleRing"}`}
                                id={checklist?.cid}
                                tstat={props?.task?.status}
                                update={checklist?.status === 0 ? 1 : 0}
                                title={_isDone(checklist) ? "Uncheck: " + checklist?.name : "Check: " + checklist?.name}
                                onClick={__chkClick}
                              ></i>
                            )}
                          </div>{" "}
                          <div className={`task-box-checklist-name ${_isDone(checklist) ? "task-strike" : ""}`} style={{ cursor: "pointer" }} title="Click to edit checklist" data-cid={checklist?.cid} onClick={(e) => __editChecklist(e)}>
                            {checklist?.name}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="task-box-footer mt-3">
                  <span style={{ marginTop: "0.1em" }} className={_isOverdue() ? "text-danger" : ""}>
                    <FluentIcon name="Calendar" />
                  </span>
                  <span className={`ml-1 mr-3 ${_isOverdue() ? "text-danger" : ""}`}>{toDate(props?.task?.due_date)}</span>
                  <span>
                    <i className={`task-box-checklist-icon ms-Icon ms-Icon--${_isCompleted() ? "CompletedSolid text-success" : "Completed"}`}></i>
                  </span>
                  <span className="ml-1">
                    {_isCompleted() ? (
                      <div>
                        Completed by {_getMemberInfo(props?.task?.completed_by)} on {toDate(props?.task?.completed_on)}
                      </div>
                    ) : (
                      <React.Fragment>
                        {_doneChecklist()}/{_totalChecklist()}
                      </React.Fragment>
                    )}
                  </span>
                </div>
                <div className="task-box-comments mb-3">
                  <Form.Row key="taskNewComment">
                    <Form.Label className="pt-2" column="sm">
                      Comments
                    </Form.Label>
                    <Form.Control style={{ width: "100%" }} size="sm" as="textarea" rows={3} name="description" value={taskNewComment} onChange={__changeNewComment} required />
                  </Form.Row>
                  <Button variant="secondary" size="sm" className="float-right mt-1" disabled={postingComment || taskNewComment.length < 6 ? true : false} onClick={__sendNewComment}>
                    {postingComment ? "Sending..." : "Send"}
                  </Button>
                  {props.project?.in_autotask === 1 && (
                    <div className="float-right mt-1 mr-2">
                      <span style={{ fontSize: 12 }}>Back-date comment:</span> <DatePicker popperPlacement="top-end" selected={commentDate} onChange={(date) => setCommentDate(date)} dateFormat={"dd-MMM-yyyy"} className="due-date mt-1" />
                    </div>
                  )}
                </div>
                <div className="task-box-comments">
                  {newComments
                    ?.sort(function (a, b) {
                      return new Date(b.createdAt) - new Date(a.createdAt);
                    })
                    .map((comment, index) => (
                      <div className="task-comment-main mb-3" key={index}>
                        <div className="task-comment-photo">
                          <Image src={JSON.parse(comment.user_data).photo} roundedCircle fluid />
                        </div>
                        <div className="task-comment-body">
                          <div className="task-comment-user">
                            <div>{JSON.parse(comment.user_data).displayName}</div>
                            <div>{toFullDateTime(comment.createdAt)}</div>
                          </div>
                          <div className="task-comment-text">{comment.comment}</div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </Modal.Body>
            <div style={{ padding: 5 }}>
              <Button
                variant="outline-secondary"
                size="sm"
                className="float-left"
                title={props?.project?.in_autotask === 1 ? "Autotask tasks cannot be deleted from PPMone" : "Delete this task"}
                disabled={tskUpdating || chkUpdating || !can("Delete Project Tasks") || props?.project?.in_autotask === 1 ? true : false}
                onClick={() => {
                  __confirmTaskDelete();
                }}
              >
                <FluentIcon name="Delete" />
              </Button>
              <Button
                variant="primary"
                size="sm"
                className="float-right"
                disabled={tskUpdating || chkUpdating ? true : false}
                onClick={() => {
                  __openTaskModal(0);
                  action(_updateTaskNewComment(""));
                }}
              >
                {tskUpdating || chkUpdating ? "Processing..." : "Close"}
              </Button>
            </div>
          </Modal>
        </div>
      )}
      {/* Edit Task Name Modal */}
      <Modal show={editTaskNameModal} backdrop="static" keyboard={false} size="md" centered style={{ backgroundColor: "rgba(0, 0, 0, 0.26)" }}>
        <Modal.Body>
          <Form.Row className="mb-3">
            <Form.Label className="pt-2 txt-lg" column="sm">
              Edit Task Name
            </Form.Label>
            <Form.Control className="ml-3" size="md" type="text" placeholder="" value={newTaskName} onChange={__changeNewTaskName} maxLength="120" required />
            <small className="ml-3 mt-2">Remaining: {120 - newTaskName.length}</small>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            disabled={savingNewTaskName ? true : false}
            onClick={() => {
              setEditTaskNameModal(false);
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={__saveNewTaskName} disabled={newTaskName.length > 3 ? (savingNewTaskName ? true : false) : true}>
            {savingNewTaskName ? "Processing..." : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Add New Checklist Modal */}
      <Modal show={newChecklistModal} backdrop="static" keyboard={false} size="md" centered style={{ backgroundColor: "rgba(0, 0, 0, 0.26)" }}>
        <Modal.Header>
          <Modal.Title>Add A New Checklist</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Row className="mb-3">
            <Form.Label className="pt-2 txt-lg" column="sm">
              Checklist Name
            </Form.Label>
            <Form.Control className="ml-3" size="md" type="text" placeholder="" value={newChecklistName} onChange={(e) => setNewChecklistName(e.target.value)} maxLength="120" required />
            <small className="ml-3 mt-2">Remaining: {120 - newChecklistName.length}</small>
          </Form.Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            disabled={savingNewChecklist ? true : false}
            onClick={() => {
              setNewChecklistModal(false);
              setNewChecklistName("");
            }}
          >
            Cancel
          </Button>
          <Button variant="primary" onClick={__doAddNewChecklist} disabled={newChecklistName.length > 3 ? (savingNewChecklist ? true : false) : true}>
            {savingNewChecklist ? "Processing..." : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Edit Checklist Modal */}
      <Modal show={editChecklistNameModal} backdrop="static" keyboard={false} size="md" centered style={{ backgroundColor: "rgba(0, 0, 0, 0.26)" }}>
        <Modal.Body>
          <Form.Row className="mb-3 bottom-border-dashed">
            <Form.Label className="pt-2 txt-lg" column="sm">
              Edit Checklist
            </Form.Label>
            <Form.Control className="ml-3" size="md" type="text" placeholder="" value={newChecklistName} onChange={__changeNewChecklistName} maxLength="120" required />
            <small className="ml-3 mt-2 mb-2">Remaining: {120 - newChecklistName.length}</small>
          </Form.Row>
          <Button
            variant="outline-secondary"
            size="sm"
            className="float-left"
            title="Delete this checklist"
            disabled={chkUpdating ? true : false}
            onClick={() => {
              __confirmChecklistDelete();
            }}
          >
            <FluentIcon name="Delete" />
          </Button>
          <Button variant="primary" size="sm" className="float-right" onClick={__saveNewChecklistName} disabled={newChecklistName.length > 3 ? (chkUpdating ? true : false) : true}>
            {chkUpdating ? "Processing..." : "Save"}
          </Button>
          <Button
            variant="secondary"
            className="float-right mr-3"
            size="sm"
            disabled={chkUpdating ? true : false}
            onClick={() => {
              setEditChecklistNameModal(false);
            }}
          >
            Cancel
          </Button>
        </Modal.Body>
      </Modal>
      {/* Alerts and notification */}
      <div>
        <ToastContainer position="top-center" autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      </div>
    </div>
  );
}

export default ProjectTask;
