/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useCallback, useRef, useEffect } from "react";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";
import ReactCrop from "react-image-crop";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Form, Row, Col, Image } from "react-bootstrap";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { mW, mH, isEqual, isColorLight, baseApiURL, baseURL, toSqlDate } from "../../components/reusableFunctions";
import { getTheme } from "@fluentui/react";
import { ToastContainer, toast } from "react-toastify";
import portfolio from "./portfolio/getPortfolioData";
import _portfolio from "../../actions/portfolioActions";
import { _at } from "../../components/autotask";
import Swal from "sweetalert2";
import { nanoid } from "nanoid";
import axios from "axios";
import "react-image-crop/dist/ReactCrop.css";
import CrossDomainPopup from "../../components/CrossDomainPopup";
import { _rbac } from "../../components/rbacApi";

function ContentSetting() {
  const theme = getTheme();
  const history = useHistory();
  const { url } = useRouteMatch();
  const isMounted = useRef();
  const action = useDispatch();
  const { option } = useParams();
  const [testingAT, setTestingAT] = useState(false);
  const portfolioSettings = useSelector((state) => state.portfolioSettings);
  const user = useSelector((state) => state.user);
  const [newAtCredName, setNewAtCredName] = useState("");
  const [newAtCredValue, setNewAtCredValue] = useState("");
  const [newXeroCredName, setNewXeroCredName] = useState("");
  const [newXeroCredValue, setNewXeroCredValue] = useState("");
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ width: 250, height: 150 });
  const [completedCrop, setCompletedCrop] = useState(null);
  //Xero Stuff
  const [xeroState, setXeroState] = useState(nanoid(8));
  const [scope] = useState(
    "email profile openid accounting.settings accounting.transactions accounting.journals.read accounting.contacts offline_access accounting.settings"
  );
  const [tokens, setTokens] = useState({});
  const [redirectUri] = useState(`${baseURL}/oauth/callback`);
  const [consUrl] = useState("https://api.xero.com/connections");
  const [cons, setCons] = useState([]);
  const [xeroButtonTxt, setXeroButtonTxt] = useState("Authorize");
  const [xeroCode, setXeroCode] = useState(null);

  useEffect(() => {
    isMounted.current = true;
    if (option === "new") {
      const payload = { company_name: user.tenant_name, pfid: `PF-${nanoid(6)}`, tenant_id: user.tenant_id };
      action(_portfolio.updateSettings(payload));
    } else {
      __getPortfolioSettings();
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current && Object.keys(tokens).length !== 0) {
      if (tokens.access_token && tokens.refresh_token) {
        setXeroButtonTxt("Authenticating...");
        setTimeout(() => __xeroGetConnections(), 1000);
      } else {
        setXeroButtonTxt("Authorize");
        console.log("No tokens got from Xero");
      }
    }
  }, [tokens]);

  useEffect(() => {
    if (isMounted.current) {
      if (cons.length !== 0) {
        action(_portfolio.updateSettings({ xero_tenantId: cons[0].tenantId }));
        setXeroButtonTxt("Initializing...");
        __xeroInitialize();
      } else {
      }
    }
  }, [cons]);

  const onCode = (code, params) => {
    setXeroCode(code);
    setXeroButtonTxt("Authenticating...");
    __exchangeCodeToTokens(code);
  };

  const onClose = (gotCode) => {
    if (!gotCode) {
      setXeroButtonTxt("Authorize");
    }
  };

  const __exchangeCodeToTokens = async (xcode) => {
    const payload = {
      url: `https://identity.xero.com/connect/token`,
      headers: {
        Authorization: "Basic " + Buffer.from(`${portfolioSettings.xero_clientId}:${portfolioSettings.xero_clientSecret}`).toString("base64"),
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: {
        grant_type: "authorization_code",
        code: xcode,
        redirect_uri: redirectUri,
      },
    };

    try {
      const result = await axios.post(`${baseApiURL}/xero/post`, { data: payload });
      const tokens = await result.data;
      // action(_portfolio.updateSettings({ xero_tokenSet: JSON.stringify(tokens) }));
      setTokens(tokens);
    } catch (err) {
      console.log(err);
    }
  };

  const __xeroGetConnections = async () => {
    const payload = {
      url: consUrl,
      headers: {
        Authorization: "Bearer " + tokens.access_token,
        "Content-Type": "application/json",
      },
    };

    try {
      const result = await axios.post(`${baseApiURL}/xero/get`, { data: payload });
      const cons = await result.data;
      setCons(cons);
    } catch (err) {
      console.log(err);
    }
  };

  const __xeroInitialize = async () => {
    const payload = {};
    const updates = {};
    payload.pfid = portfolioSettings.pfid;
    // updates.xero_accessToken = tokens.access_token;
    // updates.xero_refreshToken = tokens.refresh_token;
    updates.xero_tokenSet = JSON.stringify(tokens);
    updates.xero_tokenExpiry = toSqlDate(new Date());
    updates.xero_lastUpdate = toSqlDate(new Date());
    updates.xero_clientId = portfolioSettings.xero_clientId;
    updates.xero_clientSecret = portfolioSettings.xero_clientSecret;
    updates.xero_tenantId = cons[0].tenantId;
    updates.xero_initialized = true;
    payload.updates = updates;
    console.log(payload);
    const data = await portfolio.saveSettings(payload);
    if (!data || data?.error) {
      __toast(data.error ?? "Something went wrong!", null, 2000, 25);
    } else {
      const resInit = await __xeroApiInitData();
      if (!resInit) {
        const payload = {};
        const updates = {};
        payload.pfid = portfolioSettings.pfid;
        updates.xero_initialized = false;
        payload.updates = updates;
        console.log(payload);
        const data = await portfolio.saveSettings(payload);
        if (!data || data?.error) {
          __toast(data.error ?? "Something went wrong!", null, 2000, 25);
        } else {
          axios.get(`${baseApiURL}/xero/stop-cron/${portfolioSettings.pfid}`).then((data) => console.log(data));
          setXeroButtonTxt("Authorize");
          action(_portfolio.updateSettings({ xero_initialized: false }));
          Swal.fire({
            position: "top-end",
            icon: "error",
            title: "Something went wrong!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        return;
      }
      action(_portfolio.updateSettings({ xero_initialized: true }));
      setXeroButtonTxt("Authorize");
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "Connected to Xero",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const __xeroDisconnect = async () => {
    const payload = {};
    const updates = {};
    payload.pfid = portfolioSettings.pfid;
    updates.xero_initialized = false;
    payload.updates = updates;
    console.log(payload);
    const data = await portfolio.saveSettings(payload);
    if (!data || data?.error) {
      __toast(data.error ?? "Something went wrong!", null, 2000, 25);
    } else {
      axios.get(`${baseApiURL}/xero/stop-cron/${portfolioSettings.pfid}`).then((data) => console.log(data));
      setXeroButtonTxt("Authorize");
      action(_portfolio.updateSettings({ xero_initialized: false }));
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: "DISCONNECTED from Xero",
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  const __xeroApiUpdateData = async () => {
    try {
      const result = await axios.get(`${baseApiURL}/xero/update-data/${portfolioSettings.pfid}`);
      const data = await result.data;
      console.log("Xero Updated Data...");
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  const __xeroApiInitData = async () => {
    try {
      const result = await axios.get(`${baseApiURL}/xero/init-data/${portfolioSettings.pfid}`);
      const data = await result.data;
      console.log("Xero Initial Data...");
      console.log(data);
      return true;
    } catch (err) {
      console.log(err);
      return false;
    }
  };

  const __xeroApiQuery = async () => {
    if (!cons.length > 0) {
      console.log("No tenants found");
      return;
    }

    const payload = {
      url: `https://api.xero.com/api.xro/2.0/Invoices`,
      headers: {
        Authorization: "Bearer " + tokens.access_token,
        Accept: "application/json",
        "Xero-tenant-id": cons[0].tenantId,
      },
    };

    try {
      const result = await axios.post(`${baseApiURL}/xero/get`, { data: payload });
      const data = await result.data;
      console.log("Xero Initial Data...");
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  const __toast = (msg, link, time = 2500, w = "30") =>
    toast.error(msg, {
      onClose: () => (link ? (window.location.href = link) : false),
      position: "top-center",
      autoClose: time,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: `toast-width-${w}`,
      progress: undefined,
    });

  const __getPortfolioSettings = async () => {
    const data = await portfolio.getSettings(portfolioSettings.pfid);
    if (data?.error) {
      __toast(data.error, null, 2000, 25);
    } else {
      if (!isEqual(portfolioSettings, data) && isMounted.current) {
        action(_portfolio.loadSettings(data));
      }
    }
  };

  const __inputChange = (e) => {
    let payload = { [e.target.name]: e.target.value };
    action(_portfolio.updateSettings(payload));
  };

  const __atCredChange = () => {
    let payload = { [newAtCredName]: newAtCredValue };
    console.log(payload);
    action(_portfolio.updateSettings(payload));
    setNewAtCredName("");
    setNewAtCredValue("");
  };

  const __xeroCredChange = () => {
    let payload = { [newXeroCredName]: newXeroCredValue };
    console.log(payload);
    action(_portfolio.updateSettings(payload));
    setNewXeroCredName("");
    setNewXeroCredValue("");
  };

  const __saveSettings = async (portfolioData) => {
    console.log("Saving portfolio...");
    let payload = {};
    let data = {};
    portfolioData.at_Version = "1.0";
    if (portfolioData.at_customStatusCodes?.length < 1) {
      portfolioData.at_customStatusCodes = null;
    } else {
      portfolioData.at_customStatusCodes = portfolioData.at_customStatusCodes?.replace(/\s/g, "");
    }
    if (
      portfolioData.at_ApiIntegrationCode?.length >= 1 &&
      portfolioData.at_Secret?.length >= 1 &&
      portfolioData.at_UserName?.length >= 1 &&
      portfolioData.at_Zone?.length >= 1
    ) {
      setTestingAT(true);
      const config = {
        headers: {
          ApiIntegrationCode: portfolioData.at_ApiIntegrationCode,
          UserName: portfolioData.at_UserName,
          Secret: portfolioData.at_Secret,
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      };
      const atData = {};
      const payloadResources = {
        filter: [{ field: "id", op: "gte", value: 0 }],
        includeFields: ["id", "email", "userName", "firstName", "lastName"],
      };

      const resQueryResources = await axios.post(`${baseApiURL}/at/query/Resources/${portfolioSettings.pfid}`, payloadResources);
      const dataResources = resQueryResources.data;
      console.log("Resources: ", dataResources.items);
      if (dataResources.result) {
        atData.resources = dataResources.items;
      } else {
        setTestingAT(false);
        __toast("Autotask connect failed. Please check your credentials or empty the fields to disable", null, 5000, 30);
        return;
      }

      const payloadRoles = {
        filter: [{ field: "id", op: "gte", value: 0 }],
        includeFields: ["id", "description", "hourlyRate", "name"],
      };

      const resQueryRoles = await axios.post(`${baseApiURL}/at/query/Roles/${portfolioSettings.pfid}`, payloadRoles);
      const dataRoles = resQueryRoles.data;
      console.log("Roles: ", dataRoles.items);
      if (dataRoles.result) {
        atData.roles = dataRoles.items;
      } else {
        setTestingAT(false);
        __toast("Autotask connect failed. Please check your credentials or empty the fields to disable", null, 5000, 30);
        return;
      }
      portfolioData.at_Data = JSON.stringify(atData);
      console.log(portfolioData);
      setTestingAT(false);
    }

    if (option === "new") {
      data = await portfolio.addPortfolio(portfolioData);
      await __createRbacPreset(data);
    } else {
      payload.pfid = portfolioData.pfid;
      payload.updates = portfolioData;
      data = await portfolio.saveSettings(payload);
    }
    if (!data || data?.error) {
      __toast(data.error ?? "Something went wrong!", null, 2000, 25);
    } else {
      Swal.fire({
        position: "top-end",
        icon: "success",
        title: !option ? "Saved successfully!" : "Portfolio added successfully!",
        showConfirmButton: false,
        timer: 1500,
      });
      imgRef.current = null;
      // previewCanvasRef.current = null;
      setUpImg(null);
      setCompletedCrop(null);
      if (option === "new") {
        const payload = portfolioSettings;
        payload.new = true;
        action(_portfolio.loadSettings(payload));
        history.push(url.replace("/settings/new", ""));
      }
    }
  };

  const __onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      console.log(e.target.files);
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      // reader.onload = function (event) {
      //   setUpImg(reader.result);
      // };
      // reader.addEventListener("load", () => setUpImg(reader.result?.toString() || ""));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const __onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    // console.log(completedCrop, previewCanvasRef.current, imgRef.current);
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      // console.log("cancelling completedCrop Effect");
      // console.log("completedCrop", completedCrop, "previewCanvas:", previewCanvasRef.current, "imgRef:", imgRef.current);
      return;
    }

    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, crop.width, crop.height);

    const base64Logo = canvas.toDataURL("image/png");
    action(_portfolio.updateSettings({ photo: base64Logo }));
    console.log("Completed crop...");
  }, [completedCrop]);

  const __createRbacPreset = async (data) => {
    console.log("RBAC Preset:", data);
    // Portfolio Manager
    const resPortManager = await _rbac.createPortfolioRole({ pf_id: data.id, name: "Portfolio Manager" });
    const portManagerPermissions = [];
    for (let i = 1; i <= 23; i++) {
      portManagerPermissions.push({ role_id: resPortManager.id, permission_id: i });
    }
    await _rbac.addPortfolioRolePermission(portManagerPermissions);

    // Project manager
    const resProjManager = await _rbac.createPortfolioRole({ pf_id: data.id, name: "Project Manager" });
    const projManagerPermissions = [];
    for (let i = 1; i <= 23; i++) {
      if (i !== 8) projManagerPermissions.push({ role_id: resProjManager.id, permission_id: i });
    }
    await _rbac.addPortfolioRolePermission(projManagerPermissions);

    // Project Engineer
    const resProjEngineer = await _rbac.createPortfolioRole({ pf_id: data.id, name: "Project Engineer" });
    const projEngineerPermissions = [];
    const projEngDummy = [1, 2, 3, 6, 9, 11, 13, 14, 16, 17, 21, 23];
    for (const projEngPerm of projEngDummy) {
      projEngineerPermissions.push({ role_id: resProjEngineer.id, permission_id: projEngPerm });
    }
    await _rbac.addPortfolioRolePermission(projEngineerPermissions);

    // Billing Coordinator
    const resBillCoord = await _rbac.createPortfolioRole({ pf_id: data.id, name: "Billing Coordinator" });
    const billCoordPermissions = [];
    const billCoordDummy = [10, 15, 16, 17, 21, 22];
    for (const billCoordPerm of billCoordDummy) {
      billCoordPermissions.push({ role_id: resBillCoord.id, permission_id: billCoordPerm });
    }
    await _rbac.addPortfolioRolePermission(billCoordPermissions);
  };

  return (
    <div className="main-content">
      <div className="pl-3" style={{ ...mW(50, 65) }}>
        <Row>
          {/* LEFT Section */}
          <Col style={{ ...mW(30, 35) }}>
            {/* Top LEFT Section */}
            <Row className="pb-3 pr-4">
              <Col className="pt-3">
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm">
                    OFFICE 365 SECTION
                  </Form.Label>
                </Form.Row>
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 10) }}>
                    Portfolio Code
                  </Form.Label>
                  <Col>
                    <Form.Control
                      size="md"
                      type="text"
                      placeholder=""
                      readOnly
                      name="pfid"
                      value={portfolioSettings.pfid ?? ""}
                      onChange={__inputChange}
                      required
                    />
                  </Col>
                </Form.Row>
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 10) }}>
                    Tenant ID
                  </Form.Label>
                  <Col>
                    <Form.Control
                      size="md"
                      type="text"
                      placeholder=""
                      readOnly
                      name="tenant_id"
                      value={portfolioSettings.tenant_id ?? ""}
                      onChange={__inputChange}
                      required
                    />
                  </Col>
                </Form.Row>
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 10) }}>
                    Company Name
                  </Form.Label>
                  <Col>
                    <Form.Control
                      size="md"
                      type="text"
                      placeholder=""
                      name="company_name"
                      value={portfolioSettings.company_name ?? ""}
                      onChange={__inputChange}
                      required
                    />
                  </Col>
                </Form.Row>
                {/*
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 10) }}>
                    TimeZone
                  </Form.Label>
                  <Col>
                    <Form.Control
                      size="md"
                      type="text"
                      placeholder=""
                      name="timezone"
                      value={portfolioSettings.timezone ?? ""}
                      onChange={__inputChange}
                    />
                  </Col>
                </Form.Row>
                */}
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 10) }}>
                    Language
                  </Form.Label>
                  <Col>
                    <Form.Control
                      size="md"
                      type="text"
                      placeholder=""
                      name="language"
                      value={portfolioSettings.language ?? ""}
                      onChange={__inputChange}
                    />
                  </Col>
                </Form.Row>
                {/*
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 10) }}>
                    Teams Channel
                  </Form.Label>
                  <Col>
                    <Form.Control
                      size="md"
                      type="text"
                      placeholder=""
                      name="teams_channel"
                      value={portfolioSettings.teams_channel ?? ""}
                      onChange={__inputChange}
                    />
                  </Col>
                </Form.Row>
                */}
                {option !== "new" && (
                  <>
                    <Form.Row className="mb-3">
                      <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 10) }}>
                        O365 Group ID
                      </Form.Label>
                      <Col>
                        <Form.Control
                          size="md"
                          type="text"
                          placeholder=""
                          name="ms_365_group"
                          readOnly
                          value={portfolioSettings.ms_365_group ?? ""}
                          onChange={__inputChange}
                        />
                      </Col>
                    </Form.Row>
                    {portfolioSettings.group_data && (
                      <Form.Row className="mb-3">
                        <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 10) }}>
                          O365 Group Name
                        </Form.Label>
                        <Col>
                          <Form.Control size="md" type="text" readOnly value={JSON.parse(portfolioSettings.group_data).displayName ?? ""} />
                        </Col>
                      </Form.Row>
                    )}
                  </>
                )}
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 10) }}>
                    Theme Color
                  </Form.Label>
                  <Col>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          display: "inline-block",
                          marginTop: "0.3em",
                          height: "1.5em",
                          width: "1.5em",
                          backgroundColor: portfolioSettings.theme_color ?? "#FFFFFF",
                        }}
                      ></div>
                      <Form.Control
                        className="ml-3"
                        style={{ ...mW(8, 10), display: "inline-block" }}
                        size="md"
                        type="text"
                        placeholder=""
                        name="theme_color"
                        value={portfolioSettings.theme_color ?? ""}
                        onChange={__inputChange}
                      />
                    </div>
                    <small className="ml-5 font-italic">
                      {portfolioSettings?.theme_color?.indexOf("#") > -1
                        ? isColorLight(portfolioSettings?.theme_color)
                          ? "Color has a light shade"
                          : "Color has a dark shade"
                        : ""}
                    </small>
                  </Col>
                </Form.Row>
              </Col>
            </Row>
            {/* Top LEFT Section END */}

            {/* Bottom LEFT Section */}
            {/* This section is for AutoTask */}
            <Row className="pb-3 pr-4 top-border-dashed">
              <Col className="pt-3">
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm">
                    AUTOTASK SECTION
                  </Form.Label>
                </Form.Row>
                {option === "new" ? (
                  <>
                    <Form.Row className="mb-3">
                      <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 10) }}>
                        ApiIntegration Code
                      </Form.Label>
                      <Col>
                        <Form.Control
                          size="md"
                          type="text"
                          placeholder=""
                          name="at_ApiIntegrationCode"
                          value={portfolioSettings.at_ApiIntegrationCode ?? ""}
                          onChange={__inputChange}
                          required
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row className="mb-3">
                      <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 10) }}>
                        Username
                      </Form.Label>
                      <Col>
                        <Form.Control
                          size="md"
                          type="text"
                          placeholder=""
                          name="at_UserName"
                          value={portfolioSettings.at_UserName ?? ""}
                          onChange={__inputChange}
                          required
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row className="mb-3">
                      <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 10) }}>
                        Secret
                      </Form.Label>
                      <Col>
                        <Form.Control
                          size="md"
                          type="text"
                          placeholder=""
                          name="at_Secret"
                          value={portfolioSettings.at_Secret ?? ""}
                          onChange={__inputChange}
                          required
                        />
                      </Col>
                    </Form.Row>
                  </>
                ) : (
                  <>
                    <Form.Row className="mb-3">
                      <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 10) }}>
                        ApiIntegration Code
                      </Form.Label>
                      <Col style={{ ...mW(0, 18) }}>
                        <Form.Control
                          size="md"
                          type="text"
                          placeholder=""
                          name="at_ApiIntegrationCode"
                          readOnly
                          value={
                            portfolioSettings.at_ApiIntegrationCode ? `************${portfolioSettings.at_ApiIntegrationCode.substring(12)}` : ""
                          }
                          onChange={__inputChange}
                          required
                        />
                      </Col>
                      <Col style={{ ...mW(0, 3) }}>
                        <button
                          style={{ backgroundColor: "transparent", float: "left", marginTop: "5px" }}
                          onClick={() => setNewAtCredName("at_ApiIntegrationCode")}
                        >
                          📝
                        </button>
                      </Col>
                    </Form.Row>
                    <Form.Row className="mb-3">
                      <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 10) }}>
                        Username
                      </Form.Label>
                      <Col style={{ ...mW(0, 18) }}>
                        <Form.Control
                          size="md"
                          type="text"
                          placeholder=""
                          name="at_UserName"
                          readOnly
                          value={portfolioSettings.at_UserName ? `******${portfolioSettings.at_UserName.substring(6)}` : ""}
                          onChange={__inputChange}
                          required
                        />
                      </Col>
                      <Col style={{ ...mW(0, 3) }}>
                        <button
                          style={{ backgroundColor: "transparent", float: "left", marginTop: "5px" }}
                          onClick={() => setNewAtCredName("at_UserName")}
                        >
                          📝
                        </button>
                      </Col>
                    </Form.Row>
                    <Form.Row className="mb-3">
                      <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 10) }}>
                        Secret
                      </Form.Label>
                      <Col style={{ ...mW(0, 18) }}>
                        <Form.Control
                          size="md"
                          type="text"
                          placeholder=""
                          name="at_Secret"
                          readOnly
                          value={portfolioSettings.at_Secret ? `************${portfolioSettings.at_Secret.substring(12)}` : ""}
                          onChange={__inputChange}
                          required
                        />
                      </Col>
                      <Col style={{ ...mW(0, 3) }}>
                        <button
                          style={{ backgroundColor: "transparent", float: "left", marginTop: "5px" }}
                          onClick={() => setNewAtCredName("at_Secret")}
                        >
                          📝
                        </button>
                      </Col>
                    </Form.Row>
                  </>
                )}
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 10) }}>
                    Zone
                  </Form.Label>
                  <Col>
                    <Form.Control
                      size="md"
                      style={{ ...mW(8, 10) }}
                      type="text"
                      placeholder=""
                      name="at_Zone"
                      value={portfolioSettings.at_Zone ?? ""}
                      onChange={(e) => {
                        __inputChange(e);
                      }}
                    />
                  </Col>
                </Form.Row>

                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 10) }}>
                    Custom Status Codes
                  </Form.Label>
                  <Col>
                    <Form.Control
                      size="md"
                      style={{ ...mW(8, 10) }}
                      type="text"
                      placeholder=""
                      name="at_customStatusCodes"
                      value={portfolioSettings.at_customStatusCodes ?? ""}
                      onChange={(e) => {
                        __inputChange(e);
                      }}
                    />
                    <small style={{ color: "blue", textAlign: "center" }}>Comma separated integers</small>
                  </Col>
                </Form.Row>

                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 10) }}>
                    Api Version
                  </Form.Label>
                  <Col>
                    <Form.Control
                      size="md"
                      style={{ ...mW(8, 10) }}
                      type="text"
                      placeholder=""
                      name="at_Version"
                      readOnly
                      value="1.0"
                      onChange={__inputChange}
                    />
                  </Col>
                </Form.Row>
              </Col>
            </Row>
            {/* AutoTask END */}
            {/* Bottom LEFT Section END */}
          </Col>
          {/* LEFT Section End */}
          {/* Right Section */}
          <Col className="left-border-dashed" style={{ ...mW(0, 40) }}>
            {/* Top Right Section */}
            <Row className="bottom-border-dashed pl-4 pb-3">
              <Col className="pt-3">
                {/* Top Title-Right Area */}
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm">
                    XERO SECTION
                  </Form.Label>
                </Form.Row>
                {/* Top Title-Right Area End */}
                {!portfolioSettings.xero_initialized ? (
                  <>
                    <Form.Row>
                      <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 8) }}>
                        Redirect URI
                      </Form.Label>
                      <Col>
                        <Form.Control size="sm" type="text" placeholder="" readOnly name="xero_callbackUri" value={redirectUri} required />
                      </Col>
                    </Form.Row>
                    <Form.Row className="mb-3">
                      <small className="ml-5" style={{ color: "blue" }}>
                        Copy the redirect URI above and use it on your Xero App to connect
                      </small>
                    </Form.Row>
                    <Form.Row className="mb-3">
                      <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 8) }}>
                        Client ID
                      </Form.Label>
                      <Col style={{ ...mW(0, 18) }}>
                        <Form.Control
                          size="sm"
                          type="text"
                          placeholder=""
                          name="xero_clientId"
                          readOnly
                          value={portfolioSettings.xero_clientId ? `************${portfolioSettings.xero_clientId.substring(12)}` : ""}
                          onChange={__inputChange}
                          required
                        />
                      </Col>
                      <Col style={{ ...mW(0, 3) }}>
                        <button
                          style={{ backgroundColor: "transparent", float: "left", marginTop: "5px" }}
                          onClick={() => setNewXeroCredName("xero_clientId")}
                        >
                          📝
                        </button>
                      </Col>
                    </Form.Row>
                    <Form.Row className="mb-1">
                      <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 8) }}>
                        Client SECRET
                      </Form.Label>
                      <Col style={{ ...mW(0, 18) }}>
                        <Form.Control
                          size="sm"
                          type="text"
                          placeholder=""
                          name="xero_clientSecret"
                          readOnly
                          value={portfolioSettings.xero_clientSecret ? `************${portfolioSettings.xero_clientSecret.substring(12)}` : ""}
                          onChange={__inputChange}
                          required
                        />
                      </Col>
                      <Col style={{ ...mW(0, 3) }}>
                        <button
                          style={{ backgroundColor: "transparent", float: "left", marginTop: "5px" }}
                          onClick={() => setNewXeroCredName("xero_clientSecret")}
                        >
                          📝
                        </button>
                      </Col>
                    </Form.Row>
                    <Form.Row className="mb-1">
                      <small className="ml-5" style={{ color: "blue" }}>
                        Enter the Client ID and SECRET provided by Xero then click Authorize
                      </small>
                    </Form.Row>
                    <div style={{ flexDirection: "row", display: "flex", justifyContent: "center" }}>
                      <div style={{ width: 150, padding: 10, margin: 0, flexShrink: 1 }}>
                        <CrossDomainPopup
                          url={`https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${portfolioSettings.xero_clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${xeroState}`}
                          onCode={onCode}
                          onClose={onClose}
                        >
                          <button
                            disabled={!portfolioSettings.xero_clientId || !portfolioSettings.xero_clientSecret || xeroButtonTxt !== "Authorize"}
                            type="button"
                            className="btn btn-primary"
                            onClick={() => {
                              setXeroButtonTxt("Connecting...");
                            }}
                          >
                            {xeroButtonTxt}
                          </button>
                        </CrossDomainPopup>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div style={{ flexDirection: "col", display: "flex", justifyContent: "center" }}>
                      <small className="ml-5" style={{ color: "blue", alignSelf: "center" }}>
                        Your Portfolio is connected to XERO
                      </small>
                      <div style={{ width: 100, padding: 10, margin: 0, flexShrink: 1 }}>
                        <button type="button" className="btn btn-danger" onClick={__xeroDisconnect}>
                          DISCONNECT
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </Col>
            </Row>
            <Row className="bottom-border-dashed pl-4 pb-3">
              <Col className="pt-3">
                {/* Top Title-Right Area */}
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm">
                    PORTFOLIO PROFILE
                  </Form.Label>
                </Form.Row>
                {/* Top Title-Right Area End */}
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(7, 7) }}>
                    Upload Logo
                  </Form.Label>
                  <div className="mb-3">
                    <input type="file" onChange={__onSelectFile} />
                  </div>
                  {upImg && (
                    <ReactCrop
                      // src={upImg}
                      crop={crop}
                      onChange={(c) => setCrop(c)}
                      onComplete={(c) => setCompletedCrop(c)}
                    >
                      <img ref={imgRef} alt="Uploaded logo" src={upImg} onImageLoaded={__onLoad} />
                    </ReactCrop>
                  )}
                </Form.Row>
              </Col>
            </Row>
            {/* Top Right Section End */}
            {/* Bottom-Right */}
            <Row className="pl-4 pt-3">
              <Col>
                {/* Title-Right Area */}
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm">
                    Logo Preview
                  </Form.Label>
                </Form.Row>
                {/* Title-Right Area End */}
                <Form.Row className="mb-3">
                  <canvas
                    ref={previewCanvasRef}
                    // Rounding is important so the canvas width and height matches/is a multiple for sharpness.
                    style={{
                      //width: Math.round(completedCrop?.width ?? 0),
                      height: completedCrop?.height ? 150 : 0,
                    }}
                  />
                  {!imgRef.current && (
                    <div
                      style={{
                        backgroundColor: portfolioSettings?.theme_color,
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                      }}
                    >
                      <Image src={portfolioSettings?.photo} width="250" />
                    </div>
                  )}
                </Form.Row>
              </Col>
            </Row>
            {/* Bottom-Right End */}
          </Col>
          {/* Right Section End */}
        </Row>
        {/* Footer */}
        <Row className="mt-3">
          <div className="add-project-footer">
            <DefaultButton
              className="btn-default float-left"
              style={{ boxShadow: theme.effects.elevation4 }}
              onClick={() => {
                history.push("/members");
              }}
              text="Cancel"
              disabled={testingAT}
              allowDisabledFocus
            />
            <DefaultButton
              className="btn-default float-right"
              style={{ boxShadow: theme.effects.elevation4 }}
              disabled={testingAT}
              text={testingAT ? "Querying Autotask..." : !option ? "Save Portfolio" : "Add Portfolio"}
              onClick={() => __saveSettings(portfolioSettings)}
              allowDisabledFocus
            />
          </div>
        </Row>
        {/* Footer End */}
        {/* Alerts and notification */}
        <div>
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
        {/* Edit AT Creds Modal */}
        <Modal show={newAtCredName?.length > 0} backdrop="static" keyboard={false} size="lg" centered>
          <Modal.Header>
            <Modal.Title>AutoTask API Credentials</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Row className="mb-3">
              <Form.Label className="pt-2 txt-lg" column="sm">
                Enter New '{newAtCredName}'
              </Form.Label>
              <Form.Control
                className="ml-3"
                size="md"
                type="text"
                name="at_ApiIntegrationCode"
                value={newAtCredValue}
                onChange={(e) => setNewAtCredValue(e.target.value)}
                required
              />
            </Form.Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setNewAtCredName(null)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={__atCredChange}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Edit Xero Creds Modal */}
        <Modal show={newXeroCredName?.length > 0} backdrop="static" keyboard={false} size="lg" centered>
          <Modal.Header>
            <Modal.Title>Xero API Credentials</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Row className="mb-3">
              <Form.Label className="pt-2 txt-lg" column="sm">
                Enter New '{newXeroCredName}'
              </Form.Label>
              <Form.Control
                className="ml-3"
                size="md"
                type="text"
                name="xero_ApiIntegrationCode"
                value={newXeroCredValue}
                onChange={(e) => setNewXeroCredValue(e.target.value)}
                required
              />
            </Form.Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setNewXeroCredName(null)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={__xeroCredChange} disabled={newXeroCredValue?.length < 5}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default ContentSetting;
