import { toDate, toDigitDate, dateRange, baseApiURL, toShortDate } from "../../../../components/reusableFunctions";
import axios from "axios";

export const getProjectData = async (payload) => {
  const project = await __getLocalProject(payload);
  if (project.error) {
    return project;
  } else {
    const processedProject = processProjectData(project);
    return processedProject;
  }
};

const __getLocalProject = async (payload) => {
  // console.log(`${baseApiURL}/project/${payload}`);
  return axios
    .get(`${baseApiURL}/project/${payload}`)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
};

export const processProjectData = (project) => {
  // if (project.pid === "PR-b2PARLIeJ6") console.log("Project", project);
  project.total_budget = project.project_services + project.contingency;
  if (project.project_type === "fp") {
    if (project.forecast_burndowns) {
      project.invoiced = 0;
      for (const fb of project.forecast_burndowns) {
        if (new Date(toShortDate(new Date())) > new Date(fb.month) || project.status === 0) {
          project.invoiced += +fb.financial;
        }
      }
    }
    if (project.timesheets) {
      project.effort_spent = 0;
      for (const ts of project.timesheets) {
        // if (new Date(toDate(new Date(ts.date_worked))) <= new Date(toDate(new Date()))) {
        project.effort_spent += +ts.hours_worked;
        // }
      }
    }
  } else {
    if (project.timesheets) {
      project.effort_spent = 0;
      project.invoiced = 0;
      if (project.project_status === 0) project.total_budget = 0;
      for (const ts of project.timesheets) {
        if (project.project_status === 0) {
          project.total_budget += +ts.hours_worked * +project.hourly_rate;
        }
        if (new Date(toDate(new Date(new Date(ts.date_worked).getFullYear(), new Date(ts.date_worked).getMonth() + 1, 0))) < new Date(toDate(new Date()))) {
          project.invoiced += +ts.hours_worked * +project.hourly_rate;
          project.effort_spent += +ts.hours_worked;
          // if (project.pid === "PR-BrlSMm3z9p")
          //   console.log(
          //     new Date(toDate(new Date(new Date(ts.date_worked).getFullYear(), new Date(ts.date_worked).getMonth() + 1, 0))) <= new Date(toDate(new Date())),
          //     toDate(new Date(new Date(ts.date_worked).getFullYear(), new Date(ts.date_worked).getMonth() + 1, 0)),
          //     project.invoiced,
          //     project.effort_spent
          //   );
        } else {
          project.effort_spent += +ts.hours_worked;
          // if (project.project_status === 0) {
          //   // project.invoiced += ts.hours_worked * project.hourly_rate;
          //   project.effort_spent += ts.hours_worked;
          // }
        }
      }
    }
  }
  // if (project.pid === "PR-YSEbaPHACX") console.log(project.timesheets);
  // if (project.pid === "PR-YSEbaPHACX") console.log(project.forecast_hours, project.effort_spent);
  if (typeof project.invoiced !== "undefined") {
    project.budget_remaining = +project.total_budget - +project.invoiced;
  }
  if (typeof project.effort_spent !== "undefined") {
    project.effort_remaining = +project.forecast_hours - +project.effort_spent;
  }
  if (typeof project.tasks !== "undefined") {
    project.tasks = project?.tasks?.reverse();
  }
  if (typeof project.comments !== "undefined") {
    project.comments = project?.comments?.reverse();
  }

  const status = (+project.schedule_status + +project.scope_status + +project.budget_status) / 3;

  if (status < 1.5) project.overall_status = "low";
  if (status >= 1.5 && status <= 2.5) project.overall_status = "mid";
  if (status > 2.5) project.overall_status = "high";

  project.month_range = dateRange(toDigitDate(project.start_date), toDigitDate(project.end_date));
  return project;
};
