import React from "react";
import { Jumbotron } from "react-bootstrap";

function ContentReport() {
    return (
        <div className="main-content">
            <Jumbotron>
                <div>
                    <h4>Reports</h4>
                    <p>Reports section.</p>
                </div>
            </Jumbotron>
        </div>
    );
}

export default ContentReport;
