import axios from "axios";

const Proxy = "https://api.ppm.one/autotask";

export const _at = {};

_at.get = async (url, query, config) => {
  const e_url = Buffer.from(url).toString("base64");
  const e_query = Buffer.from(query).toString("base64");
  return axios
    .get(`${Proxy}/?method=GET&url=${e_url}&query=${e_query}`, config)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      console.log(error);
      return { error: error };
    });
};

_at.getPlain = async (url, config) => {
  const e_url = Buffer.from(url).toString("base64");
  return axios
    .get(`${Proxy}/?method=GETPLAIN&url=${e_url}`, config)
    .then((res) => {
      // console.log(`${Proxy}/?method=GETPLAIN&url=${e_url}`);
      return res.data;
    })
    .catch(function (error) {
      console.log(error);
      return { error: error };
    });
};

_at.post = async (url, payload, config) => {
  const e_url = Buffer.from(url).toString("base64");
  const e_payload = Buffer.from(JSON.stringify(payload)).toString("base64");
  return axios
    .get(`${Proxy}/?method=POST&url=${e_url}&payload=${e_payload}`, config)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      console.log(error);
      return { error: error };
    });
};

_at.patch = async (url, payload, config) => {
  const e_url = Buffer.from(url).toString("base64");
  const e_payload = Buffer.from(JSON.stringify(payload)).toString("base64");
  return axios
    .get(`${Proxy}/?method=PATCH&url=${e_url}&payload=${e_payload}`, config)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      console.log(error);
      return { error: error };
    });
};

_at.delete = async (url, config) => {
  const e_url = Buffer.from(url).toString("base64");
  return axios
    .get(`${Proxy}/?method=DELETE&url=${e_url}`, config)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      console.log(error);
      return { error: error };
    });
};
