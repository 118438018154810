const mainMenuLinks = [
  {
    name: "HOME",
    path: "",
    title: "PORTFOLIO DASHBOARD",
    private: false,
    exclusive: false,
  },
  {
    name: "PROJECTS",
    path: "/projects",
    title: "PROJECT MANAGEMENT",
    private: true,
    exclusive: false,
    permission: "View Project Management Dashboard",
    subMenu: [
      {
        name: "PROJECT DETAILS",
        path: "/details",
        title: "PROJECT DETAILS",
      },
      {
        name: "PROJECT FINANCE",
        path: "/finance",
        title: "PROJECT DETAILS",
      },
      {
        name: "PROJECT REPORTS",
        path: "/reports",
        title: "PROJECT REPORTS",
      },
      {
        name: "VIEW TIMESHEETS",
        path: "/timesheets",
        title: "PROJECT TIMESHEETS",
      },
    ],
  },
  {
    name: "FINANCE",
    path: "/finance",
    title: "PORTFOLIO FINANCE",
    private: true,
    exclusive: false,
    permission: "View Portfolio Finance",
  },

  // {
  //   name: "REPORTING",
  //   path: "/reports",
  //   title: "REPORTS",
  //   private: true,
  // },

  {
    name: "RESOURCES",
    path: "/resources",
    title: "RESOURCES",
    private: true,
    exclusive: false,
    permission: "Manage Portfolio Resources",
    useFirstSubpath: true,
    subMenu: [
      {
        name: "TEAM VIEW",
        path: "/teamview",
        title: "RESOURCE FORECAST UTILISATION",
      },
      // {
      //   name: "PROJECT VIEW",
      //   path: "/projectview",
      //   title: "PROJECT VIEW",
      // },
      {
        name: "MANAGE USERS",
        path: "/users",
        title: "MANAGE USERS",
      },
      {
        name: "ROLES & PERMS",
        path: "/rbac",
        title: "ROLES & PERMISSIONS",
      },
    ],
  },
  {
    name: "SETTINGS",
    path: "/settings",
    title: "SETTINGS",
    private: true,
    exclusive: false,
    permission: "Edit Portfolio Settings",
  },
  {
    name: "ADD NEW PROJECT",
    path: "/add/project",
    title: "ADD NEW PROJECT",
    private: true,
    exclusive: false,
    permission: "Add Projects",
  },
  {
    name: "SUPER ADMIN",
    path: "/superadmin",
    title: "SUPER ADMIN ONLY",
    private: true,
    exclusive: false,
    role: "Super Admin",
    useFirstSubpath: true,
    subMenu: [
      {
        name: "User Related",
        path: "/users",
        title: "SUPER ADMIN - USER MANAGEMENT",
      },
      {
        name: "Dev Page",
        path: "/dev",
        title: "SUPER ADMIN - DEV TEST PAGE",
      },
    ],
  },
];
export default mainMenuLinks;
