/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { mW, hasRole } from "../../../components/reusableFunctions";
import { _rbac } from "../../../components/rbacApi";
import { getTheme } from "@fluentui/react";
import { ToastContainer } from "react-toastify";
import Select from "react-select";
import Swal from "sweetalert2";
import { FluentIcon } from "../../../components/FluentIcon.js";
import { _user } from "../../../components/userApi";
import _log from "../../../components/logApi";
import _portfolio from "../../../actions/portfolioActions";

function ManageUsers() {
  const theme = getTheme();
  const isMounted = useRef();
  const action = useDispatch();
  const user = useSelector((state) => state.user);
  const portfolioSettings = useSelector((state) => state.portfolioSettings);
  const portfolioMembers = useSelector((state) => state.portfolioMembers);
  const [onboardedUsers, setOnboardedUsers] = useState([]);
  const [userRoles, setUserRoles] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);
  const [userExtraPerms, setUserExtraPerms] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [xPermissions, setXPermissions] = useState([]);
  const [xRoles, setXRoles] = useState([]);
  const [permForAdding, setPermForAdding] = useState(null);
  const [roleForAdding, setRoleForAdding] = useState(null);
  const [pfRoles, setPfRoles] = useState([]);
  const [isRoleModalOpen, setIsRoleModalOpen] = useState(false);
  const [isPermissionModalOpen, setIsPermissionModalOpen] = useState(false);
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current && portfolioSettings.id) {
      __getPortfolioRoles(portfolioSettings.id);
      __getPermissions();
    }
  }, [portfolioSettings.id]);

  useEffect(() => {
    if (isMounted.current && portfolioMembers?.length > 0) {
      setOnboardedUsers(portfolioMembers.filter((data) => data.user_id !== null));
    }
  }, [portfolioMembers]);

  useEffect(() => {
    if (isMounted.current && selectedUser?.user_id) {
      __getUserRolesAndPermissions(selectedUser.user_id);
    }
  }, [selectedUser]);

  const __enDisUser = async () => {
    try {
      const resEnDisUser = await _user.updateInfo({ ms_id: selectedUser.id, updates: { isEnabled: !selectedUser.isEnabled } });
      if (resEnDisUser) {
        const selectedUserUpdate = selectedUser;
        selectedUserUpdate.isEnabled = !selectedUser.isEnabled;
        action(_portfolio.updateMember(selectedUserUpdate));
        await _log.add({ pfid: portfolioSettings.pfid, userId: user.id, targetId: selectedUser.user_id, type: "Manage Users", log: selectedUser.isEnabled ? "User has been enabled" : "User has been disabled" });
      }
    } catch (err) {
      console.log(err);
      __popError();
    }
  };

  //End of Admin Only

  const __popError = () => {
    Swal.fire({
      icon: "error",
      title: "Something Went Wrong",
      text: "An error has occured. Please contact us!",
      allowOutsideClick: false,
    });
  };

  const __getPermissions = async () => {
    const res_permissions = await _rbac.getPermissions();
    setPermissions(res_permissions);
  };

  const __getPortfolioRoles = async (pf_id) => {
    const res_pfRoles = await _rbac.getPortfolioRoles(pf_id);
    if (hasRole("Admin")) {
      setPfRoles([{ id: 2, name: "Admin" }, ...res_pfRoles]);
    } else {
      setPfRoles(res_pfRoles);
    }
  };

  const __getUserRolesAndPermissions = async (user_id) => {
    const res_userRbacData = await _rbac.getUserRolesPermissions(user_id);
    setUserRoles(res_userRbacData?.raw?.roles);
    setUserPermissions(res_userRbacData?.raw?.permissions);
    setUserExtraPerms(res_userRbacData?.raw?.extra_permissions);
  };

  const __selectUser = async (data) => {
    // console.log(data);
    setSelectedUser(data);
  };

  const __deleteUserPermission = async (rp_id) => {
    setInProgress(true);
    const res_delUserPermission = await _rbac.deleteUserPermission(selectedUser?.user_id, +rp_id);
    if (res_delUserPermission.error || !res_delUserPermission) {
      console.log("Error removing user permission");
    } else {
      setUserExtraPerms(userExtraPerms.filter((data) => data.id !== +rp_id));
      setUserPermissions(userPermissions.filter((data) => data.id !== +rp_id));
      //__successAlert();
    }
    setInProgress(false);
  };

  const __deleteUserRole = async (rp_id) => {
    setInProgress(true);
    const res_delUserRole = await _rbac.deleteUserRole(selectedUser?.user_id, +rp_id);
    if (res_delUserRole.error || !res_delUserRole) {
      console.log("Error removing user role");
    } else {
      //setUserRoles(userRoles.filter((data) => data.id !== +rp_id));
      __getUserRolesAndPermissions(selectedUser?.user_id);
      //__successAlert();
    }
    setInProgress(false);
  };

  const __initAddUserRole = async () => {
    let userRole_ids = userRoles.map((data) => {
      return data.id;
    });
    let temp_roles = pfRoles.filter((data) => !userRole_ids.includes(data.id));
    setXRoles(temp_roles);
    setIsRoleModalOpen(true);
  };

  const __initAddUserPermission = async () => {
    let userPermission_ids = userPermissions.map((data) => {
      return data.id;
    });
    let temp_xperms = permissions.filter((data) => !userPermission_ids.includes(data.id));
    setXPermissions(temp_xperms);
    setIsPermissionModalOpen(true);
  };

  const __addUserRole = async () => {
    setInProgress(true);
    const payload = { user_id: selectedUser?.user_id, rp_id: roleForAdding?.id };
    const res_newUserRole = await _rbac.addUserRole(payload);
    if (res_newUserRole.error || !res_newUserRole) {
      console.log("Error adding user role");
    } else {
      //setUserRoles([...userRoles, roleForAdding]);
      __getUserRolesAndPermissions(selectedUser?.user_id);
    }
    setRoleForAdding(null);
    setIsRoleModalOpen(false);
    setInProgress(false);
  };

  const __addUserPermission = async () => {
    setInProgress(true);
    const payload = { user_id: selectedUser?.user_id, rp_id: permForAdding?.id };
    const res_newUserPermission = await _rbac.addUserPermission(payload);
    if (res_newUserPermission.error || !res_newUserPermission) {
      console.log("Error adding user permission");
    } else {
      setUserPermissions([...userPermissions, permForAdding]);
      setUserExtraPerms([...userExtraPerms, permForAdding]);
    }
    setPermForAdding(null);
    setIsPermissionModalOpen(false);
    setInProgress(false);
  };

  const __confirmDeleteUserPermission = async (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This will remove the extra permission for this user",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Confirm",
      confirmButtonColor: "#DF3D22",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        __deleteUserPermission(data.target.value);
      }
    });
  };

  const __confirmDeleteUserRole = async (data) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This will remove the role for this user",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Confirm",
      confirmButtonColor: "#DF3D22",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        __deleteUserRole(data.target.value);
      }
    });
  };

  // const __successAlert = () => {
  //   Swal.close();
  //   Swal.fire({
  //     position: "top-end",
  //     icon: "success",
  //     title: "Success!",
  //     showConfirmButton: false,
  //     timer: 1500,
  //     didClose: () => {
  //       console.log("Processing done...");
  //     },
  //   });
  // };

  return (
    <div className="main-content">
      <div className="pl-3" style={{ ...mW(50, 65) }}>
        <Row>
          {/* LEFT Section */}
          <Col style={{ ...mW(30, 35) }}>
            {/* Top LEFT Section */}
            <Row className="pb-3 pr-4">
              <Col className="pt-3">
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 7) }}>
                    Select User
                  </Form.Label>
                  <Col>
                    <Select value={selectedUser} options={onboardedUsers} isClearable getOptionLabel={(e) => `${e.displayName} (${e.mail})`} getOptionValue={(e) => e.user_id} onChange={__selectUser} />
                  </Col>
                </Form.Row>
              </Col>
            </Row>
            {/* Top LEFT Section END */}

            {/* Bottom LEFT Section */}
            {/* This section is for AutoTask */}
            <Row className="pb-3 pr-4 top-border-dashed">
              <Col className="pt-3">
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm">
                    MANAGE ROLES
                  </Form.Label>
                </Form.Row>
                {selectedUser?.user_id ? (
                  <>
                    {userRoles?.length > 0 ? (
                      <>
                        {userRoles?.map((data, index) => (
                          <Form.Row key={index} className="ml-3">
                            <Form.Label className="mt-1 task-name" column="sm" style={{ ...mW(0, 2.5) }}>
                              #{index + 1}{" "}
                            </Form.Label>
                            <Col>
                              <Form.Control size="md" type="text" placeholder="Add a name..." className="input-task-name" value={data.name} maxLength="120" readOnly />
                            </Col>

                            <Col style={{ ...mW(0, 6) }}>
                              {data.id === 2 ? (
                                <>
                                  {user.id !== selectedUser.user_id && hasRole("Admin") && (
                                    <Button className="i-btn" variant="danger" value={data.id} title="Delete role from this user" size="sm" onClick={__confirmDeleteUserRole}>
                                      <FluentIcon name="Delete" />
                                    </Button>
                                  )}
                                </>
                              ) : (
                                <Button className="i-btn" variant="danger" value={data.id} title="Delete role from this user" size="sm" onClick={__confirmDeleteUserRole}>
                                  <FluentIcon name="Delete" />
                                </Button>
                              )}
                            </Col>
                          </Form.Row>
                        ))}
                      </>
                    ) : (
                      <span className="ml-3 font-italic text-secondary">No Roles found...</span>
                    )}
                  </>
                ) : (
                  <span className="ml-3 font-italic text-secondary">No User selected...</span>
                )}
                {selectedUser?.user_id && (
                  <Form.Row>
                    <Col>
                      <DefaultButton className="ml-5 mt-3" style={{ boxShadow: theme.effects.elevation4, marginleft: "1em" }} text="Assign Role" title="Assign Role to this User" onClick={__initAddUserRole} allowDisabledFocus />
                    </Col>
                  </Form.Row>
                )}
              </Col>
            </Row>

            <Row className="pb-3 pr-4">
              <Col className="pt-3">
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm">
                    MANAGE EXTRA PERMISSIONS
                  </Form.Label>
                </Form.Row>
                {selectedUser?.user_id ? (
                  <>
                    {userExtraPerms?.length > 0 ? (
                      <>
                        {userExtraPerms?.map((data, index) => (
                          <Form.Row key={index} className="ml-3">
                            <Form.Label className="mt-1 task-name" column="sm" style={{ ...mW(0, 2.5) }}>
                              #{index + 1}{" "}
                            </Form.Label>
                            <Col>
                              <Form.Control size="md" type="text" placeholder="Add a name..." className="input-task-name" value={data.name} maxLength="120" readOnly />
                            </Col>
                            <Col style={{ ...mW(0, 6) }}>
                              <Button className="i-btn" variant="danger" value={data.id} title="Delete extra permission from this user" size="sm" onClick={__confirmDeleteUserPermission}>
                                <FluentIcon name="Delete" />
                              </Button>
                            </Col>
                          </Form.Row>
                        ))}
                      </>
                    ) : (
                      <span className="ml-3 font-italic text-secondary">No extra permissions found</span>
                    )}
                  </>
                ) : (
                  <span className="pl-3 font-italic text-secondary">No User selected...</span>
                )}
                {selectedUser?.user_id && (
                  <>
                    {userPermissions?.length !== permissions?.length ? (
                      <Form.Row>
                        <Col>
                          <DefaultButton className="ml-5 mt-3" style={{ boxShadow: theme.effects.elevation4, marginleft: "1em" }} text="Add Permission" title="Add extra permission to this user" onClick={__initAddUserPermission} allowDisabledFocus />
                        </Col>
                      </Form.Row>
                    ) : (
                      <Form.Row className="mt-3 ml-3">
                        <Col>
                          <small className="font-italic text-info">All permissions have been added (including the ones that are part of the roles assigned)</small>
                        </Col>
                      </Form.Row>
                    )}
                  </>
                )}
                {selectedUser && (
                  <Form.Row>
                    <Col>
                      <DefaultButton
                        className={`ml-5 mt-5 float-right btn-${selectedUser.isEnabled ? "danger" : "primary"}`}
                        style={{ boxShadow: theme.effects.elevation4, marginleft: "1em" }}
                        text={`${selectedUser.isEnabled ? "Disable" : "Enable"} User`}
                        title="Disable this user"
                        onClick={__enDisUser}
                        allowDisabledFocus
                      />
                    </Col>
                  </Form.Row>
                )}
              </Col>
            </Row>
            {/* AutoTask END */}
            {/* Bottom LEFT Section END */}
          </Col>
          {/* LEFT Section End */}
          {/* Right Section */}
          <Col className="left-border-dashed" style={{ ...mW(0, 30) }}>
            {/* Top Right Section */}
            <Row className="pl-4 pb-3">
              <Col className="pt-3">
                {/* Top Title-Right Area */}
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm">
                    HOW IT WORKS
                  </Form.Label>
                </Form.Row>
                {/* Top Title-Right Area End */}
                <Form.Row className="mb-3">
                  <span className="ml-3"> This is a text on how Roles and Permissions work</span>
                </Form.Row>
              </Col>
            </Row>
            {/* Top Right Section End */}
            {/* Bottom-Right */}
            {/* Bottom-Right End */}
          </Col>
          {/* Right Section End */}
        </Row>
        {/* Footer */}
        {/* Footer End */}
        {/* Alerts and notification */}
        <div>
          <ToastContainer position="top-center" autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
        {/* Modals */}
        {/* Add User Role Modal */}
        <Modal show={isRoleModalOpen} backdrop="static" keyboard={false} size="md" centered>
          <Modal.Header>
            <Modal.Title>Add Role to User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Row className="mb-3">
              <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(7, 7) }}>
                Role
              </Form.Label>
              <Col>
                <Select options={xRoles} isClearable getOptionLabel={(e) => e.name} getOptionValue={(e) => e.id} onChange={(e) => setRoleForAdding(e)} />
              </Col>
            </Form.Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              disabled={inProgress}
              onClick={() => {
                setIsRoleModalOpen(false);
                setXRoles(null);
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" disabled={inProgress || !roleForAdding} onClick={__addUserRole}>
              {inProgress ? "Saving..." : "Add Role"}
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Add User Permission Modal */}
        <Modal show={isPermissionModalOpen} backdrop="static" keyboard={false} size="md" centered>
          <Modal.Header>
            <Modal.Title>Add Extra Permission</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Row className="mb-3">
              <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(7, 7) }}>
                Permission
              </Form.Label>
              <Col>
                <Select options={xPermissions} isClearable getOptionLabel={(e) => e.name} getOptionValue={(e) => e.id} onChange={(e) => setPermForAdding(e)} />
              </Col>
            </Form.Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              disabled={inProgress}
              onClick={() => {
                setIsPermissionModalOpen(false);
                setXPermissions(null);
              }}
            >
              Cancel
            </Button>
            <Button variant="primary" disabled={inProgress || !permForAdding} onClick={__addUserPermission}>
              {inProgress ? "Saving..." : "Add Permission"}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default ManageUsers;
