const portfolioReducer = {};

portfolioReducer.details = (state = {}, action) => {
  switch (action.type) {
    case "LOAD_PORTFOLIO_DETAILS":
      return action.payload;
    default:
      return state;
  }
};

portfolioReducer.members = (state = [], action) => {
  switch (action.type) {
    case "LOAD_PORTFOLIO_MEMBERS":
      return action.payload;
    case "UPDATE_PORTFOLIO_MEMBER":
      return [...state.filter((data) => data.user_id !== action.payload.user_id), action.payload];
    default:
      return state;
  }
};

portfolioReducer.projects = (state = [], action) => {
  switch (action.type) {
    case "LOAD_PORTFOLIO_PROJECTS":
      return action.payload;
    default:
      return state;
  }
};

portfolioReducer.settings = (state = {}, action) => {
  switch (action.type) {
    case "LOAD_PORTFOLIO_SETTINGS":
      return action.payload;
    case "UPDATE_PORTFOLIO_SETTINGS":
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default portfolioReducer;
