/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Form, Row, Col, Image } from "react-bootstrap";
import "../../../assets/css/contentProjects/addProject.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  dateRange,
  mW,
  mH,
  toDigitDate,
  toDate,
  toShortDate,
  prNanoid,
  sDate,
  eDate,
  can,
  baseApiURL,
  debounce,
} from "../../../components/reusableFunctions";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { getTheme } from "@fluentui/react";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
//import Swal from "sweetalert2";
import {
  _updateNewDetails,
  _updateNewSchedule,
  _setNewMonthRange,
  _addNewBurndown,
  _updateNewBurndown,
  _resetNewBurndown,
  _resetNewProject,
  _addNewBucket,
  _addNewTask,
  _resetNewTask,
  _loadAutotask,
  _resetNewDetails,
  _loadTimesheets,
} from "../../../actions/newProjectActions";
import { nanoid } from "nanoid";
import { FluentIcon } from "../../../components/FluentIcon.js";
import portfolio from "../portfolio/getPortfolioData";
// import { baseApiURL } from "../../../components/reusableFunctions";
import axios from "axios";
import Select from "react-select";
import { Editor } from "@tinymce/tinymce-react";
import editorLoader from "../../../assets/images/loading_spinner.gif";

function AddProjects(props) {
  const isMounted = useRef();
  const theme = getTheme();
  const { url } = useRouteMatch();
  const action = useDispatch();
  const history = useHistory();
  const portfolioSettings = useSelector((state) => state.portfolioSettings);
  const newProject = useSelector((state) => state.newProject);
  const monthRange = useSelector((state) => state.newProject.month_range);
  const user = useSelector((state) => state.user);
  const [onboardedUsers, setOnboardedUsers] = useState([]);
  const [btn, setBtn] = useState(newProject.autotask_projects.length > 0 ? "Refresh List" : "Query Autotask");
  const [isMultiRate, setIsMultiRate] = useState(false);
  const [noRate, setNoRate] = useState(false);
  const editorRef = useRef(null);
  const [loadingEditor, setLoadingEditor] = useState(true);
  const [selectedAtProject, setSelectedAtProject] = useState([]);

  useEffect(() => {
    isMounted.current = true;
    // console.log(contractExpiryMonth);
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      if (newProject.details.pid === "") {
        const pid = `PR-${prNanoid()}`;
        action(_updateNewDetails({ pid: pid, created_by: user.id, pfid: portfolioSettings.pfid }));
        if (newProject.buckets.length === 0) {
          action(_addNewBucket({ pid: pid, bid: nanoid(6), name: "To do" }));
        }
        if (localStorage.getItem("atProjectsList")) {
          action(_loadAutotask(JSON.parse(localStorage.getItem("atProjectsList"))));
        }
      }
      if (url === "/members/projects/add") {
        props.setModuleTitle("ADD NEW PROJECT");
      }
    }
  }, [newProject.details.pid]);

  useEffect(() => {
    if (isMounted.current) {
      if (newProject.details.at_pid > 0) {
        setSelectedAtProject(newProject.autotask_projects.filter((data) => data.id === newProject.details.at_pid));
      } else {
        setSelectedAtProject([]);
      }
    }
  }, [newProject.details.at_pid]);

  useEffect(() => {
    if (isMounted.current && newProject.autotask_projects.length > 0) {
      setBtn(newProject.autotask_projects.length > 0 ? "Refresh List" : "Query Autotask");
    }
  }, [newProject.autotask_projects]);

  useEffect(() => {
    if (isMounted.current) {
      if (
        monthRange.length !== 0 &&
        (monthRange[0] !== toShortDate(newProject.project_schedule.start_date) ||
          monthRange[monthRange.length - 1] !== toShortDate(newProject.project_schedule.end_date))
      ) {
        action(
          _resetNewBurndown({
            pid: newProject.details.pid,
            fbid: nanoid(6),
            month: toShortDate(newProject.project_schedule.start_date),
            financial: "",
            effort: "",
          })
        );
        __setMonthRange(new Date(newProject.project_schedule.start_date), new Date(newProject.project_schedule.end_date));
        //console.log("Month Range changed...");
      } else {
        __setMonthRange(new Date(newProject.project_schedule.start_date), new Date(newProject.project_schedule.end_date));
      }
    }
  }, [newProject.project_schedule.start_date, newProject.project_schedule.end_date]);

  useEffect(() => {
    if (isMounted.current) {
      async function getPfUsers() {
        const result = await __getUsers(portfolioSettings.pfid);
        if (result && result.length > 0) {
          setOnboardedUsers(result);
        }
      }
      getPfUsers();
    }
  }, [portfolioSettings]);

  const __getUsers = async (pfid) => {
    return axios
      .get(`${baseApiURL}/user/${pfid}`)
      .then((res) => {
        return res.data;
      })
      .catch(function (error) {
        return { error: error };
      });
  };

  const __getLocalProjects = async () => {
    const resultLocalAtProjects = await portfolio.getLocalAtProjects(portfolioSettings?.pfid);
    let existingAtProjects = [];
    for (const localAtProject of resultLocalAtProjects) {
      existingAtProjects = [...existingAtProjects, localAtProject.at_pid];
    }
    return existingAtProjects;
  };

  const __queryAtApi = async () => {
    __inProgress("Getting Projects from Autotask");
    const timeoutStart = Math.floor(Date.now() / 1000);
    try {
      if (newProject.autotask_projects.length > 0) {
        action(_resetNewProject());
        action(_updateNewDetails({ in_autotask: 1, at_pid: 0 }));
      }
      const existingAtProjects = await __getLocalProjects();
      // console.log(existingAtProjects);
      //Filter: [{ field: "startDateTime", op: "gte", value: targetDateProjects.toISOString() }],
      // const targetDateProjects = moment().subtract(12, "month");
      const payloadProjects = {
        filter: [{ field: "status", op: "noteq", value: 5 }],
        includeFields: [
          "id",
          "contractID",
          "companyID",
          "projectName",
          "description",
          "startDateTime",
          "endDateTime",
          "estimatedTime",
          "originalEstimatedRevenue",
          "projectNumber",
        ],
      };
      const resQueryProjects = await axios.post(`${baseApiURL}/at/query/Projects/${portfolioSettings.pfid}`, payloadProjects);
      // console.log(resQueryProjects);
      const newAtProjects = resQueryProjects?.data?.items?.filter((data) => !existingAtProjects.includes(data.id));
      // console.log(newAtProjects);

      const payloadCompanies = { Filter: [{ field: "id", op: "gte", value: 0 }], includeFields: ["id", "companyName"] };
      const resQueryCompanies = await axios.post(`${baseApiURL}/at/query/Companies/${portfolioSettings.pfid}`, payloadCompanies);
      const atCompanies = resQueryCompanies?.data?.items;
      // console.log(atCompanies);

      let validProjects = [];
      for (const project of newAtProjects) {
        const company = atCompanies.filter((data) => data.id === project.companyID)[0] ?? { companyName: "NoCompanyName" };
        const data = { ...project, company: company.companyName };
        validProjects = [...validProjects, data];
      }
      // console.log(validProjects);
      validProjects.sort((a, b) => a.company.localeCompare(b.company));
      Swal.close();
      localStorage.setItem("atProjectsList", JSON.stringify(validProjects));
      action(_loadAutotask(validProjects));
    } catch (error) {
      console.log(error);
      if (Math.floor(Date.now() / 1000) - timeoutStart > 10) {
        Swal.fire(
          "Error (qAtApi)",
          "The API connection to Autotask is currently non-responsive, please try again later or contact your Administrator",
          "error"
        );
      } else {
        Swal.fire("Error (qAtApi)", "Something went wrong. Please contact us!", "error");
      }
    }
  };

  const __atSelect = async (data) => {
    __inProgress("Querying Project Details");
    const timeoutStart = Math.floor(Date.now() / 1000);
    try {
      action(_resetNewTask([]));
      localStorage.setItem("selectedAtProject", JSON.stringify(data));
      if (data.contractID) {
        const payloadContract = { Filter: [{ field: "id", op: "eq", value: data.contractID }] };
        const resQueryContract = await axios.post(`${baseApiURL}/at/query/Contracts/${portfolioSettings.pfid}`, payloadContract);
        data.contract = resQueryContract.data.items[0];

        const payloadRates = { Filter: [{ field: "contractID", op: "eq", value: data.contractID }] };
        const resQueryRates = await axios.post(`${baseApiURL}/at/query/ContractRates/${portfolioSettings.pfid}`, payloadRates);
        data.rates = resQueryRates.data.items;
      }
      delete data.userDefinedFields;
      __processSelectedAtProject(data);
    } catch (error) {
      console.log(error);
      if (Math.floor(Date.now() / 1000) - timeoutStart > 10) {
        Swal.fire(
          "Error (qAtSel)",
          "The API connection to Autotask is currently non-responsive, please try again later or contact your Administrator",
          "error"
        );
      } else {
        Swal.fire("Error (qAtSel)", "Something went wrong. Please contact us!", "error");
      }
    }
  };

  const __processSelectedAtProject = async (data) => {
    __inProgress("Analyzing Project Data");
    const timeoutStart = Math.floor(Date.now() / 1000);
    try {
      const newAtProject = {};
      newAtProject.in_autotask = 1;
      newAtProject.company = data.company;
      newAtProject.name = data.projectName;
      newAtProject.orig_name = data.projectName;
      newAtProject.description = data.description;
      newAtProject.at_pid = data.id;
      newAtProject.at_crid = data.contractID;
      if (data.contractID) {
        newAtProject.forecast_hours = data.contract.estimatedHours === 0 ? data.estimatedTime : data.contract.estimatedHours;
        newAtProject.project_services = data.contract.contractType === 4 ? data.originalEstimatedRevenue : data.contract.estimatedRevenue;
        newAtProject.project_type =
          data.contract.contractType === 1 || data.contract.contractType === 4 ? "tm" : data.contract.contractType === 3 ? "fp" : "";
        newAtProject.hourly_rate = data.rates.length > 0 ? data.rates[0].contractHourlyRate : 0;
      } else {
        newAtProject.forecast_hours = data.estimatedTime ?? 0;
        newAtProject.project_services = data.originalEstimatedRevenue ?? 0;
        newAtProject.project_type = "";
        newAtProject.hourly_rate = 0;
      }

      if (data.contract?.contractType === 7) {
        newAtProject.effort_only = 1;
        newAtProject.project_services = 0;
        newAtProject.hourly_rate = 0;
      } else {
        newAtProject.effort_only = 0;
      }

      // console.log(newAtProject);

      action(_updateNewDetails(newAtProject));
      __handleStartDateChange(new Date(data.startDateTime));
      __handleEndDateChange(new Date(data.endDateTime), 1);
      setIsMultiRate(data.rates?.length > 1 ? true : false);
      setNoRate(data.rates?.length < 1 ? true : false);

      const payloadTasks = { Filter: [{ field: "projectID", op: "eq", value: data.id }] };
      const resQueryTasks = await axios.post(`${baseApiURL}/at/query/Tasks/${portfolioSettings.pfid}`, payloadTasks);
      const newTasks = resQueryTasks.data;
      // console.log(newTasks);
      if (!newTasks.result) {
        console.log("ERROR:", newTasks.message);
      } else {
        if (newTasks?.items?.length > 0) {
          for (const task of newTasks.items) {
            __handleAddAtTask(task.id, task.title, task.startDateTime, task.endDateTime);
          }
          const projectTaskIds = newTasks.items.map((data) => data.id);
          // console.log(projectTaskIds);
          const resTimeEntries = await axios.post(`${baseApiURL}/at/query/TimeEntries/${portfolioSettings.pfid}`, {
            filter: [{ field: "taskID", op: "in", value: projectTaskIds }],
          });
          if (resTimeEntries.data.result) {
            const allTimeEntries = resTimeEntries.data?.items;
            action(_loadTimesheets(allTimeEntries));
            // console.log(allTimeEntries);
          }
        }
      }

      action(_updateNewSchedule({ orig_end_date: data.endDateTime }));
      Swal.close();
    } catch (error) {
      console.log(error);
      if (Math.floor(Date.now() / 1000) - timeoutStart > 10) {
        Swal.fire(
          "Error (anProj)",
          "The API connection to Autotask is currently non-responsive, please try again later or contact your Administrator",
          "error"
        );
      } else {
        Swal.fire("Error (anProj)", "Something went wrong. Please contact us!", "error");
      }
    }
  };

  const __toast = (msg, link, time = 2500, w = "30") =>
    toast.error(msg, {
      onClose: () => (link ? (window.location.href = link) : false),
      position: "top-center",
      autoClose: time,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: `toast-width-${w}`,
      progress: undefined,
    });

  const __handleStartDateChange = (date) => {
    const sdate = sDate(date);
    const edate = eDate(newProject.project_schedule.end_date);
    if (edate < sdate) {
      action(_updateNewSchedule({ end_date: date }));
    }
    action(_updateNewSchedule({ start_date: date }));
  };

  const __handleEndDateChange = (date, type = 0) => {
    const sdate = type === 0 ? sDate(newProject.project_schedule.start_date) : eDate(date);
    const edate = eDate(date);
    if (newProject.details.in_autotask === 1 && type === 0) {
      console.log(edate, newProject.project_schedule.orig_end_date);
      if (edate < eDate(newProject.project_schedule.orig_end_date)) {
        __toast("You can only change Autotask project end date to a later date. Not earlier than the original end date.", null, 3000, 35);
        return;
      }
    }
    if (sdate > edate) {
      __toast("End date should be greater than start date", null, 1500, 25);
      return;
    }
    action(_updateNewSchedule({ end_date: date }));
  };

  const __handleProjectLeadChange = (id) => {
    action(_updateNewDetails({ created_by: id }));
  };

  const __handleCompanyChange = (e) => {
    debounce(action(_updateNewDetails({ company: e.target.value })), 1000);
  };

  const __handleNameChange = (e) => {
    debounce(action(_updateNewDetails({ name: e.target.value })), 1000);
  };

  const __handleDescriptionChange = (value) => {
    action(_updateNewDetails({ description: value }));
  };

  const __handleForecastHoursChange = (e) => {
    if (isNaN(e.target.value)) {
      __toast("Forecast Hours is not a valid number", null, 2000, 25);
      return;
    }
    debounce(action(_updateNewDetails({ forecast_hours: e.target.value === "" ? "" : +e.target.value })), 1000);
  };

  const __handleProjectServicesChange = (e) => {
    if (isNaN(e.target.value)) {
      __toast("Project Services is not a valid number", null, 2000, 25);
      return;
    }
    debounce(action(_updateNewDetails({ project_services: e.target.value === "" ? "" : +e.target.value })), 1000);
  };

  const __handleContingencyChange = (e) => {
    if (isNaN(e.target.value)) {
      __toast("Contingency is not a valid number", null, 2000, 25);
      return;
    }
    debounce(action(_updateNewDetails({ contingency: e.target.value === "" ? "" : +e.target.value })), 1000);
  };

  const __handleHourlyRateChange = (e) => {
    if (isNaN(e.target.value)) {
      __toast("Hourly Rate is not a valid number", null, 2000, 25);
      return;
    }
    debounce(action(_updateNewDetails({ hourly_rate: e.target.value === "" ? "" : +e.target.value })), 1000);
  };

  const __handlePTChange = (e) => {
    // if (newProject.details.in_autotask === 1 && newProject.details.effort_only !== 1) return;
    debounce(action(_updateNewDetails({ project_type: e.target.value })), 1000);
  };

  const __setMonthRange = (sdate, edate) => {
    const sDate = toDigitDate(sdate);
    const eDate = toDigitDate(edate);
    action(_setNewMonthRange(dateRange(sDate, eDate)));
  };

  const __handleAddBurndown = () => {
    const selectedMonth = monthRange[newProject.forecast_burndowns.length];
    action(_addNewBurndown({ pid: newProject.details.pid, fbid: nanoid(6), month: selectedMonth, financial: "", effort: "" }));
  };

  const __handleBurnDownChange = (e) => {
    const fbid = e.target.getAttribute("data-fbid");
    const name = e.target.getAttribute("data-name");
    const value = e.target.value;
    debounce(action(_updateNewBurndown({ [name]: value, fbid: fbid })), 1000);
  };

  const __handleNext = () => {
    /*
    const values = Object.keys(newProject.details).map((key) => (newProject.details[key].length === 0 ? false : true));
    if (values.includes(false)) {
      __toast("Please fill-up all fields", null, 1500, 15);
      return;
    }
    */
    if (newProject.details.in_autotask && !newProject.details.at_pid) {
      __toast("You have not selected an Autotask Project. Uncheck if you don't need it", null, 5000, 20);
      return;
    }
    if (!newProject.details.company || newProject.details.company.length < 3) {
      __toast("Company name is empty or too short", null, 3000, 20);
      return;
    }
    if (!newProject.details.name || newProject.details.name.length < 4) {
      __toast("Project name is empty or too short", null, 3000, 20);
      return;
    }
    if (!newProject.details.description || newProject.details.description.length < 4) {
      __toast("Project description is empty or too short", null, 3000, 20);
      return;
    }
    if (!newProject.details.forecast_hours) {
      __toast("Forecast hours is required and cannot be zero", null, 3000, 20);
      return;
    }
    if (!newProject.details.project_services && !newProject.details.in_autotask) {
      __toast("Project services is required and cannot be zero", null, 3000, 20);
      return;
    }
    if (!newProject.details.project_type) {
      __toast("Please select a project type", null, 3000, 20);
      return;
    }
    if (!newProject.details.hourly_rate) {
      if (newProject.details.effort_only !== 1) {
        __toast("Hourly rate is required and cannot be zero", null, 3000, 20);
        return;
      }
    }
    if (toDate(newProject.project_schedule.start_date) === toDate(newProject.project_schedule.end_date)) {
      __toast("Project schedule is not properly set", null, 2000, 20);
      return;
    }

    let total_financial = 0;
    let total_effort = 0;
    for (const fb of newProject.forecast_burndowns) {
      total_financial += +fb.financial;
      total_effort += +fb.effort;
    }
    if (+total_financial > +newProject.details.project_services + newProject.details.contingency) {
      __toast("Budget is not enough for financial burndown", null, 2000, 25);
      return;
    }
    if (+total_effort > +newProject.details.forecast_hours) {
      __toast("Total Effort Burndown is already putting the project at risk", null, 2500, 30);
      return;
    }

    if (newProject.details.project_type === "tm") {
      if (+newProject.forecast_hours * +newProject.details.hourly_rate > +newProject.details.project_services) {
        __toast("Project budget is not enough", null, 2000, 20);
        return;
      }

      for (const fb of newProject.forecast_burndowns) {
        if (+fb.financial !== +fb.effort * +newProject.details.hourly_rate) {
          __toast("A Burndown does not equate to Effort x Hourly Rate", null, 2500, 30);
          return;
        }
      }
    }

    //history.push(`${url}/bucket`);
    history.push(`${url}/bucket/task`);
  };

  const __inProgress = (title = `Processing`, html = `Please wait while we do our thing...`) => {
    Swal.fire({
      title: title,
      html: html,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  };

  const __confirmCancel = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "All data entered will be lost!",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Confirm",
      confirmButtonColor: "#DF3D22",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        __handleCancel();
      }
    });
  };

  const __handleCancel = () => {
    action(_resetNewProject());
    history.push("/members");
  };

  const __syncSet = (e) => {
    if (e.target.id === "inPlanner") {
      action(_updateNewDetails({ in_planner: newProject.details.in_planner === 0 ? 1 : 0 }));
    }
    if (e.target.id === "inAutotask") {
      if (newProject.details.in_autotask === 1) {
        action(_resetNewDetails());
        action(_resetNewTask());
      }
      action(_updateNewDetails({ in_autotask: newProject.details.in_autotask === 0 ? 1 : 0 }));
    }
  };

  const __handleAddAtTask = (atTid, title = "", startDate, dueDate) => {
    // console.log(newProject.details.pid, newProject.buckets[0].bid);
    action(
      _addNewTask({
        pid: newProject.details.pid,
        bid: newProject.buckets[0].bid,
        tid: nanoid(6),
        at_tid: atTid,
        name: title,
        orig_name: title,
        status: 0,
        assignments: [],
        start_date: startDate ?? newProject.project_schedule.start_date,
        orig_start_date: startDate ?? newProject.project_schedule.start_date,
        due_date: dueDate ?? newProject.project_schedule.start_date,
        orig_due_date: dueDate ?? newProject.project_schedule.start_date,
      })
    );
  };

  return (
    <div className="main-content">
      <div className="pl-3" style={{ ...mW(50, 65) }}>
        <Row>
          <Col>
            <Form.Row className="pb-3 bottom-border-dashed">
              {can("Sync To Planner") && (
                <Form.Check
                  inline
                  label="Sync to Planner"
                  type="checkbox"
                  id="inPlanner"
                  checked={newProject.details.in_planner === 1}
                  onChange={__syncSet}
                />
              )}
              {can("Sync From Autotask") && (
                <Form.Check
                  inline
                  label="Sync from Autotask"
                  type="checkbox"
                  id="inAutotask"
                  checked={newProject.details.in_autotask === 1}
                  disabled={
                    !portfolioSettings?.at_ApiIntegrationCode ||
                    !portfolioSettings?.at_UserName ||
                    !portfolioSettings?.at_Secret ||
                    !portfolioSettings?.at_Zone
                  }
                  onChange={__syncSet}
                />
              )}
            </Form.Row>
            {newProject.details.in_autotask === 1 && (
              <Form.Row className="pb-2 pt-2 bottom-border-dashed">
                <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 12) }}>
                  <button type="button" className="btn btn-primary p-1 float-right" style={{ marginTop: "2px" }} onClick={__queryAtApi}>
                    {btn}
                  </button>
                </Form.Label>
                <Col>
                  <Select
                    className="p-1 mt-1"
                    value={selectedAtProject}
                    options={newProject.autotask_projects}
                    // getOptionLabel={(e) => `${e.company.companyName} ➜ ${e.project.projectName} (${e.contract.contactName ?? "No contact"}) #${e.contract.id}`}
                    getOptionLabel={(e) => `${e.company} ➜ ${e.projectName} (${e.projectNumber}) #:${e.id}`}
                    getOptionValue={(e) => e.id}
                    onChange={__atSelect}
                    classNamePrefix={"ppm-select"}
                  />
                </Col>
              </Form.Row>
            )}
          </Col>
        </Row>
        {/* <Row style={{ ...mH(40, 0) }}></Row> */}
        {/* <Row style={{ minHeight: "98vh" }}> */}
        <Row style={{ ...mH(60, 0) }}>
          {/* LEFT Section */}
          <Col style={{ ...mW(30, 40) }}>
            {/* Top LEFT Section */}
            <Row className="pb-3 pr-4">
              <Col className="pt-3">
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 10) }}>
                    PROJECT CODE
                  </Form.Label>
                  <Col style={{ ...mW(0, 15) }}>
                    <Form.Control size="md" type="text" className="code" value={newProject.details.pid} readOnly required />
                  </Col>
                </Form.Row>
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 10) }}>
                    PROJECT LEAD
                  </Form.Label>
                  <Col style={{ ...mW(0, 30) }}>
                    <Select
                      options={onboardedUsers}
                      value={onboardedUsers.filter((data) => data.id === newProject.details.created_by ?? user.id)}
                      placeholder={"Select Project Lead"}
                      isDisabled={false}
                      getOptionLabel={(e) => `${e.displayName ?? "NoName"} (${e.mail ?? "No Email"})`}
                      getOptionValue={(e) => e.id}
                      onChange={(e) => __handleProjectLeadChange(e.id)}
                    />
                  </Col>
                </Form.Row>
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 10) }}>
                    COMPANY NAME
                  </Form.Label>
                  <Col>
                    <Form.Control
                      size="md"
                      type="text"
                      placeholder=""
                      value={newProject.details.company}
                      onChange={__handleCompanyChange}
                      readOnly={newProject.details.in_autotask === 1 && newProject.details.company !== "NoCompanyName"}
                      required
                    />
                  </Col>
                </Form.Row>
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 10) }}>
                    PROJECT NAME
                  </Form.Label>
                  <Col>
                    <Form.Control size="md" type="text" placeholder="" value={newProject.details.name} onChange={__handleNameChange} required />
                  </Col>
                </Form.Row>
                <Form.Row>
                  <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 10) }}>
                    DESCRIPTION
                  </Form.Label>
                </Form.Row>
                {/* <Col>
                    <Form.Control size="md" as="textarea" rows={5} value={newProject.details.description} onChange={__handleDescriptionChange} required />
                  </Col> */}
                <Form.Row className="mb-3">
                  <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyItems: "center" }}>
                    {loadingEditor && (
                      <div style={{ alignSelf: "center" }}>
                        <Image src={editorLoader} fluid />
                      </div>
                    )}
                    <Editor
                      apiKey="28judzb7gglya2ls2if1n6n4k8jy05wtvijkywght7rf993a"
                      onInit={(evt, editor) => {
                        editorRef.current = editor;
                        setLoadingEditor(false);
                      }}
                      onBlur={() => __handleDescriptionChange(editorRef.current.getContent())}
                      initialValue={newProject.details.description}
                      init={{
                        height: 300,
                        menubar: false,
                        plugins: ["autolink", "lists", "link", "image", "searchreplace", "code", "table", "code", "wordcount"],
                        toolbar:
                          "pastetext undo bold italic forecolor alignleft aligncenter alignright | bullist numlist outdent indent | removeformat",
                        content_style: "body { font-family:Arial,sans-serif; font-size:14px }",
                      }}
                    />
                  </div>
                </Form.Row>

                <Form.Row className="mb-3">
                  {!newProject.details.at_crid && newProject.details.at_pid > 0 && (
                    <small className="text-danger mt-1 ml-3">
                      Project has no contract attached to it. You must fill-in the financial data as accurate as you can.
                    </small>
                  )}
                </Form.Row>
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 10) }}>
                    FORECAST HOURS
                  </Form.Label>
                  <Col style={{ ...mW(0, 15) }}>
                    <Form.Control
                      size="md"
                      type="number"
                      placeholder=""
                      value={newProject.details.forecast_hours}
                      onChange={__handleForecastHoursChange}
                      // readOnly={newProject.details.in_autotask === 1 && newProject.details.effort_only !== 1}
                      required
                    />
                  </Col>
                </Form.Row>
              </Col>
            </Row>
            {/* Top LEFT Section END */}
            {/* Bottom LEFT Section */}
            <Row className="top-border-dashed">
              <Col className="pt-4">
                <Row>
                  <Col>
                    {/* Title-iLeft Area */}
                    <Form.Row className="mb-3">
                      <Form.Label className="pt-2 txt-lg" column="sm">
                        PROJECT BUDGET
                      </Form.Label>
                    </Form.Row>
                    {/* Title-iLeft Area End */}
                    {/* Options-iLeft */}
                    <Form.Row className="mb-3">
                      <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(8, 10) }}>
                        Project Services
                      </Form.Label>
                      <Col style={{ ...mW(0, 8) }}>
                        <div className="currency">$</div>
                        <Form.Control
                          size="md"
                          type="number"
                          placeholder=""
                          value={newProject.details.project_services}
                          onChange={__handleProjectServicesChange}
                          // readOnly={newProject.details.in_autotask === 1 && newProject.details.forecast_hours !== 0}
                          required
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row className="mb-3">
                      <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(8, 10) }}>
                        Contingency
                      </Form.Label>
                      <Col style={{ ...mW(0, 8) }}>
                        <div className="currency">$</div>
                        <Form.Control
                          className="float-left"
                          size="md"
                          type="number"
                          placeholder=""
                          value={newProject.details.contingency}
                          onChange={__handleContingencyChange}
                          required
                        />
                      </Col>
                    </Form.Row>
                    {/* Options-iLeft End */}
                  </Col>
                  <Col>
                    {/* Title-iRight Area */}
                    <Form.Row className="mb-3">
                      <Form.Label className="pt-2 txt-lg" column="sm">
                        PROJECT TYPE
                      </Form.Label>
                    </Form.Row>
                    {/* Title-iRight Area End */}
                    {/* Options-iRight */}
                    <Form.Row className="mb-3">
                      <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(9, 10) }}>
                        Time & Materials
                      </Form.Label>
                      <Col className="pt-2" style={{ ...mW(0, 8) }}>
                        <input
                          type="radio"
                          id="tm"
                          name="ptype"
                          value="tm"
                          onChange={__handlePTChange}
                          checked={newProject.details.project_type === "tm" ? true : false}
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row className="mb-3">
                      <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(9, 10) }}>
                        Fixed Price
                      </Form.Label>
                      <Col className="pt-2" style={{ ...mW(0, 8) }}>
                        <input
                          type="radio"
                          id="fp"
                          name="ptype"
                          value="fp"
                          onChange={__handlePTChange}
                          checked={newProject.details.project_type === "fp" ? true : false}
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row className="mb-3">
                      <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(8, 10) }}>
                        Hourly Rate
                      </Form.Label>
                      <Col style={{ ...mW(0, 8) }}>
                        <div className="currency">$</div>
                        <Form.Control
                          size="md"
                          type="number"
                          placeholder=""
                          value={newProject.details.hourly_rate}
                          onChange={__handleHourlyRateChange}
                          required
                        />
                      </Col>
                      {isMultiRate && newProject.details.at_crid && (
                        <small className="text-danger mt-1">
                          Multiple hourly rates detected. Please verify or update the Hourly Rate for this project
                        </small>
                      )}
                      {noRate && newProject.details.at_crid && (
                        <small className="text-danger mt-1">No hourly rate was detected from Autotask. Please enter the hourly rate above.</small>
                      )}
                    </Form.Row>
                    {/* Options-iRight End */}
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* Bottom LEFT Section END */}
          </Col>
          {/* LEFT Section End */}
          {/* Right Section */}
          <Col className="left-border-dashed" style={{ ...mW(0, 22) }}>
            {/* Top Right Section */}
            <Row className="bottom-border-dashed pl-4 pb-3">
              <Col className="pt-3">
                {/* Top Title-Right Area */}
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm">
                    PROJECT SCHEDULE
                  </Form.Label>
                </Form.Row>
                {/* Top Title-Right Area End */}
                {newProject.details.in_autotask === 1 && (
                  <Form.Row>
                    <small className="font-italic text-primary" style={{ marginLeft: "8em" }}>
                      Start date is locked
                    </small>
                  </Form.Row>
                )}
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(5.5, 5.5) }}>
                    Start Date
                  </Form.Label>
                  <Col style={{ ...mW(0, 9) }}>
                    <DatePicker
                      selected={newProject.project_schedule.start_date}
                      readOnly={newProject.details.in_autotask === 1}
                      onChange={(date) => __handleStartDateChange(date)}
                      dateFormat={"dd-MMM-yyyy"}
                      dateClassName="txt-lg"
                    />
                    <div className="i-inside">
                      <FluentIcon name="Calendar" />
                    </div>
                  </Col>
                </Form.Row>
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(5.5, 5.5) }}>
                    End Date
                  </Form.Label>
                  <Col style={{ ...mW(0, 9) }}>
                    <DatePicker
                      selected={newProject.project_schedule.end_date}
                      onChange={(date) => __handleEndDateChange(date)}
                      dateFormat={"dd-MMM-yyyy"}
                      dateClassName="txt-lg"
                    />
                    <div className="i-inside">
                      <FluentIcon name="Calendar" />
                    </div>
                  </Col>
                </Form.Row>
              </Col>
            </Row>
            {/* Top Right Section End */}
            {/* Bottom-Right */}
            <Row className="pl-4 pt-3">
              <Col>
                {/* Title-Right Area */}
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm">
                    FORECAST BURNDOWN
                  </Form.Label>
                </Form.Row>
                {/* Title-Right Area End */}
                <Form.Row className="txt-sm mb-1">
                  <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(6, 6) }}></Form.Label>
                  <Col className="text-center" style={{ ...mW(8.7, 8.7) }}>
                    FINANCIAL
                  </Col>
                  <Col className="text-center" style={{ ...mW(6.4, 6.4) }}>
                    EFFORT
                  </Col>
                </Form.Row>
                {newProject.forecast_burndowns.map((data, index) => (
                  <React.Fragment key={index}>
                    <Form.Row className="mb-2">
                      <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(6, 6) }}>
                        {data.month}
                      </Form.Label>
                      <Col style={{ ...mW(0, 7) }}>
                        <div className="currency">$</div>
                        <Form.Control
                          size="md"
                          type="number"
                          value={data.financial}
                          data-name="financial"
                          data-fbid={data.fbid}
                          onChange={__handleBurnDownChange}
                          placeholder=""
                          required
                        />
                      </Col>
                      <Col style={{ ...mW(0, 5) }}>
                        <Form.Control
                          size="md"
                          type="number"
                          value={data.effort}
                          data-name="effort"
                          data-fbid={data.fbid}
                          onChange={__handleBurnDownChange}
                          placeholder=""
                          required
                        />
                      </Col>
                    </Form.Row>
                  </React.Fragment>
                ))}
                <Form.Row className="mb-3">
                  <Col style={{ ...mW(13, 13) }}></Col>
                  <DefaultButton
                    className="float-right mr-2"
                    style={{ boxShadow: theme.effects.elevation4 }}
                    text="Add"
                    disabled={monthRange.length > newProject.forecast_burndowns.length ? false : true}
                    onClick={__handleAddBurndown}
                    allowDisabledFocus
                  />
                </Form.Row>
              </Col>
            </Row>
            {/* Bottom-Right End */}
          </Col>
          {/* Right Section End */}
        </Row>
        {/* Footer */}
        <Row className="mt-3">
          <div className="add-project-footer">
            <DefaultButton
              className="btn-default float-left"
              style={{ boxShadow: theme.effects.elevation4 }}
              text="Cancel"
              onClick={__confirmCancel}
              allowDisabledFocus
            />
            <DefaultButton
              className="btn-default float-right"
              style={{ boxShadow: theme.effects.elevation4 }}
              text="Next"
              allowDisabledFocus
              onClick={__handleNext}
            />
          </div>
        </Row>
        {/* Footer End */}
        {/* Alerts and notification */}
        <div>
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </div>
    </div>
  );
}

export default AddProjects;
