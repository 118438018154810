const _prReport = {}

_prReport.updateStatus = (data) => {
  return {
      type: "UPDATE_REPORT_STATUS",
      payload: data,
  };
};

_prReport.updateData = (data) => {
  return {
      type: "UPDATE_REPORT_DATA",
      payload: data,
  };
};

_prReport.updateEditables = (data) => {
  return {
      type: "UPDATE_REPORT_EDITABLES",
      payload: data,
  };
};

export default _prReport;