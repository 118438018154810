export const _loadDetails = (data) => {
    localStorage.setItem("editProject", JSON.stringify(data));
    return {
        type: "LOAD_DETAILS",
        payload: data,
    };
};

export const _updateDetails = (data) => {
    const localData = JSON.parse(localStorage.getItem("editProject"));
    const newData = { ...localData, ...data };
    localStorage.setItem("editProject", JSON.stringify(newData));
    return {
        type: "UPDATE_DETAILS",
        payload: data,
    };
};

export const _setMonthRange = (data) => {
    return {
        type: "SET_MONTH_RANGE",
        payload: data,
    };
};

export const _resetBurndown = (data) => {
    return {
        type: "RESET_BURNDOWN",
        payload: data,
    };
};

export const _addBurndown = (data) => {
    return {
        type: "ADD_BURNDOWN",
        payload: data,
    };
};

export const _updateBurndown = (data) => {
    return {
        type: "UPDATE_BURNDOWN",
        payload: data,
    };
};

export const _delBurndown = (data) => {
    return {
        type: "DEL_BURNDOWN",
        payload: data,
    };
};

export const _addBucket = (data) => {
    return {
        type: "ADD_BUCKET",
        payload: data,
    };
};

export const _updateBucket = (data) => {
    return {
        type: "UPDATE_BUCKET",
        payload: data,
    };
};

export const _delBucket = (data) => {
    return {
        type: "DEL_BUCKET",
        payload: data,
    };
};

export const _addTask = (data) => {
    return {
        type: "ADD_TASK",
        payload: data,
    };
};

export const _updateTask = (data) => {
    return {
        type: "UPDATE_TASK",
        payload: data,
    };
};

export const _updateTaskIndex = (data) => {
    return {
        type: "UPDATE_TASK_INDEX",
        payload: data,
    };
};


export const _delTask = (data) => {
    return {
        type: "DEL_TASK",
        payload: data,
    };
};

export const _addChecklist = (data) => {
    return {
        type: "ADD_CHECKLIST",
        payload: data,
    };
};

export const _updateChecklist = (data) => {
    return {
        type: "UPDATE_CHECKLIST",
        payload: data,
    };
};

export const _delChecklist = (data) => {
    return {
        type: "DEL_CHECKLIST",
        payload: data,
    };
};

export const _reloadTimesheets = (data) => {
    return {
        type: "RELOAD_TIMESHEETS",
        payload: data,
    };
};

export const _updateEfforSpent = (data) => {
    return {
        type: "UPDATE_EFFORT_SPENT",
        payload: data,
    };
};

export const _loadTaskNewAssignee = (data) => {
    return {
        type: "LOAD_EP_TASK_NEWASSIGNEE",
        payload: data,
    };
};

export const _loadTaskOldAssignee = (data) => {
    return {
        type: "LOAD_EP_TASK_OLDASSIGNEE",
        payload: data,
    };
};

export const _loadTaskNewComments = (data) => {
    return {
        type: "LOAD_EP_TASK_NEWCOMMENTS",
        payload: data,
    };
};

export const _updateTaskNewComments = (data) => {
    return {
        type: "UPDATE_EP_TASK_NEWCOMMENTS",
        payload: data,
    };
};

export const _loadTaskOldComments = (data) => {
    return {
        type: "LOAD_EP_TASK_OLDCOMMENTS",
        payload: data,
    };
};

export const _updateTaskNewComment = (data) => {
    return {
        type: "EP_TASK_NEWCOMMENT",
        payload: data,
    };
};

export const _setIdOfTaskInModal = (data) => {
    return {
        type: "EP_TASK_INMODAL",
        payload: data,
    };
};

export const _setTimesheetModal = (data) => {
    return {
        type: "EP_TIMESHEET_MODAL_OPEN",
        payload: data,
    };
};

export const _setEditNameModal = (data) => {
    return {
        type: "EP_EDITNAME_MODAL_OPEN",
        payload: data,
    };
};

export const _setNewTaskModal = (data) => {
    return {
        type: "EP_NEWTASK_MODAL_OPEN",
        payload: data,
    };
};
