import axios from "axios";
import { baseApiURL } from "./reusableFunctions";

export const _user = {};

_user.getPortfolioUsers = async (pfid) => {
  return axios
    .get(`${baseApiURL}/user/${pfid}`)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      return { error: error };
    });
};

_user.getMSInfo = async (userId) => {
  try {
    const res = await axios.get(`${baseApiURL}/user/msal/${userId}`);
    return res.data;
  } catch (error) {
    return { error: error };
  }
};

_user.get = async (userId) => {
  try {
    const res = await axios.get(`${baseApiURL}/user/get/${userId}`);
    return res.data;
  } catch (error) {
    return { error: error };
  }
};

_user.updateInfo = async (payload) => {
  return axios
    .post(`${baseApiURL}/user/msal/update`, payload)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      return { error: error };
    });
};
