/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "../../../assets/css/contentProjects/detailsProject.css";
import { ToastContainer, toast } from "react-toastify";
import { mW, toShortDate } from "../../../components/reusableFunctions";
import { _loadDetails, _updateDetails } from "../../../actions/editProjectActions";
import { useSelector, useDispatch } from "react-redux";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { Row } from "react-bootstrap";
import { StackedBarGraph, DonutChart, FinancialBurndown } from "./components/ProjectGraph";
import { getTheme } from "@fluentui/react";
import ProjectDetails from "./components/ProjectDetails";
import { getProjectData } from "./components/getProjectData";
import "../../../assets/css/contentDashboard.css";
import { CSVLink } from "react-csv";

function GraphFinanceProject(props) {
  const action = useDispatch();
  const theme = getTheme();
  const isMounted = useRef(false);
  const editProject = useSelector((state) => state.editProject);
  const [CSVData, setCSVData] = useState([]); //added by Dennis create state of date for different report

  useEffect(() => {
    isMounted.current = true;
    if (props.data.moduleTitle !== "PROJECT FINANCE") {
      props.data.setModuleTitle("PROJECT FINANCE");
    }
    if (editProject === null && localStorage.getItem("editProject")) {
      action(_loadDetails(JSON.parse(localStorage.getItem("editProject"))));
    } else {
      if (typeof editProject?.budget_remaining === "undefined" || typeof editProject?.effort_remaining === "undefined") {
        __refreshData(`*/${editProject?.pid}/Burndown,Task,Timesheet`);
      }
    }
    return () => {
      isMounted.current = false;
    };
  }, []);

  //added by dennis for CSV Export
  useEffect(() => {
    if (editProject) {
      // console.log(editProject);
      //need to check if forecast or actual
      //create CSV Data
      //header : DATE, Financial Breakdown , Effort , Status (Actual / Forecast)
      const newData = editProject.forecast_burndowns?.map((data, index) => {
        let newObject = { Date: data.month, "Financial Breakdown": `$${data.financial}`, Effort: data.effort };
        let convertedDate = new Date(data.month);
        let currentDate = new Date(toShortDate(new Date()));
        // console.log(convertedDate, currentDate);

        if (convertedDate < currentDate) return { ...newObject, Status: "actual" };
        else return { ...newObject, Status: "forecast" };
      });

      setCSVData(newData);
    }
  }, [editProject]);

  const __setViewEdit = () => {
    props.setView("edit");
  };

  const __refreshData = async (payload) => {
    if (!editProject?.pid) return;
    const project = await getProjectData(payload);
    if (project.error) {
      __toast(project.error, null, null, 30);
    } else {
      if (isMounted.current) {
        action(_updateDetails(project));
      }
      return true;
    }
    return false;
  };

  const __toast = (msg, link, time = 2500, w = 30) =>
    toast.error(`⛔ ${msg}`, {
      onClose: () => (link ? (window.location.href = link) : false),
      position: "top-center",
      autoClose: time,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: `toast-width-${w}`,
      progress: undefined,
    });

  if (typeof editProject?.budget_remaining === "undefined" || typeof editProject?.effort_remaining === "undefined") {
    return <></>;
  }

  const graphData = () => {
    let data = {
      tasksOutstanding: 0,
      tasksCompleted: 0,
      spentBarHeight: 0,
      remainingBarHeight: 0,
      budgetRemaining: 0,
      totalBudget: 0,
      budgetInvoiced: 0,
      effortSpent: 0,
      effortRemaining: 0,
    };

    let projectBudget = 0;
    let effortRemaining = 0;

    if (editProject) {
      if (editProject.project_status === 0 && editProject.project_type === "tm") {
        projectBudget = editProject.budget_remaining + editProject.invoiced;
      } else {
        projectBudget = editProject.total_budget;
        effortRemaining = editProject.effort_remaining;
      }
      data.tasksOutstanding = editProject?.tasks?.length - editProject?.tasks?.filter((data) => data.status === 1).length || 0;
      data.tasksCompleted = editProject?.tasks?.filter((data) => data.status === 1).length || 0;
      data.spentBarHeight = (editProject.invoiced / projectBudget) * 100 || 0;
      data.remainingBarHeight = (editProject.budget_remaining / projectBudget) * 100 || 0;
      data.budgetRemaining = editProject.budget_remaining || 0;
      data.totalBudget = projectBudget;
      data.budgetInvoiced = editProject.invoiced || 0;
      data.effortSpent = editProject.effort_spent || 0;
      data.effortRemaining = effortRemaining;
    }
    // console.log(data);
    return data;
  };

  const burndownData = () => {
    // console.log(editProject);
    let labels = [];
    let values = [];
    let bcolors = [];
    if (editProject) {
      if (editProject.project_type === "fp") {
        for (const fb of editProject.forecast_burndowns) {
          labels = [...labels, fb.month];
          values = [...values, fb.financial];
          if (new Date(toShortDate(new Date())) > new Date(fb.month) || editProject.project_status === 0) {
            bcolors = [...bcolors, "#055bfb"];
          } else {
            bcolors = [...bcolors, "#5bf50b"];
          }
        }
      } else {
        for (const fb of editProject.forecast_burndowns) {
          labels = [...labels, fb.month];
          if (new Date(fb.month) >= new Date(toShortDate(new Date()))) {
            if (editProject.project_status === 0) {
              const financial = editProject.timesheets.filter((data, index) => toShortDate(data.date_worked) === fb.month).reduce((total, key) => +parseFloat((total = total + key.hours_worked * editProject.hourly_rate)).toFixed(2), 0);
              values = [...values, financial];
            } else {
              values = [...values, fb.financial];
            }
          } else {
            const financial = editProject.timesheets.filter((data, index) => toShortDate(data.date_worked) === fb.month).reduce((total, key) => +parseFloat((total = total + key.hours_worked * editProject.hourly_rate)).toFixed(2), 0);
            values = [...values, financial];
          }
          if (new Date(toShortDate(new Date())) > new Date(fb.month)) {
            bcolors = [...bcolors, "#055bfb"];
          } else {
            bcolors = [...bcolors, "#5bf50b"];
          }
        }
      }
    }
    const data = { labels: labels, values: values, bcolors: bcolors };
    // console.log(data);
    return data;
  };

  return (
    <div>
      <ProjectDetails editProject={editProject} />
      <div className="pl-5 mt-3" style={{ ...mW(50, 65) }}>
        <Row>
          <div className="main-content">
            <div className="stat-col">
              <div className="stat-row">
                <div className="chart-box">
                  <div className="counter-box-header">
                    <span className="counter-box-title">FINANCIAL SUMMARY</span>
                    <div className="counter-box-header-line"></div>
                  </div>
                  <div className="counter-box-body">
                    <StackedBarGraph
                      data={{
                        spent_bar_height: graphData().spentBarHeight,
                        remaining_bar_height: graphData().remainingBarHeight,
                        budget_remaining: graphData().budgetRemaining,
                        total_budget: graphData().totalBudget,
                        budget_invoiced: graphData().budgetInvoiced,
                      }}
                    />
                  </div>
                </div>
                <div className="chart-box ml-3 mr-3">
                  <div className="counter-box-header">
                    <span className="counter-box-title">EFFORT SUMMARY</span>
                    <div className="counter-box-header-line"></div>
                  </div>
                  <div className="counter-box-body">
                    <DonutChart
                      data={{
                        values: [+graphData().effortRemaining, +graphData().effortSpent],
                        labels: ["Remaining", "Spent"],
                        bcolors: [+graphData().effortRemaining < 0 ? "#F5055B" : "#5bf50b", "#055BFB"],
                      }}
                    />
                  </div>
                </div>
                <div className="chart-box">
                  <div className="counter-box-header">
                    <span className="counter-box-title">TASK SUMMARY</span>
                    <div className="counter-box-header-line"></div>
                  </div>
                  <div className="counter-box-body">
                    <DonutChart
                      data={{
                        values: [graphData().tasksOutstanding, graphData().tasksCompleted],
                        labels: ["Outstanding", "Completed"],
                        bcolors: ["#5bf50b", "#055BFB"],
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="stat-row">
                <div className="chart-box-full">
                  <div className="header__financial__burndown">
                    <div className="counter-box-header">
                      <span className="counter-box-title">FINANCIAL BURNDOWN</span>
                      <div className="counter-box-header-line"></div>
                    </div>
                    <div className="txt-md mb-3 header__fb_button">
                      <CSVLink data={CSVData} filename={`${editProject.company}_${editProject.name}_FB`} target="_blank">
                        <DefaultButton className="btn-default float-right" style={{ boxShadow: theme.effects.elevation4 }} text="EXPORT CSV" allowDisabledFocus />
                      </CSVLink>
                    </div>
                  </div>

                  <div className="counter-box-body">
                    <div className="bar-graph-legend row">
                      <div className="mr-3">
                        <div className="bar-graph-legend-blue"></div>
                        Actual
                      </div>
                      <div className="bar-graph-legend-green"></div>
                      <div>Forecast</div>
                    </div>
                    <FinancialBurndown labels={burndownData().labels} values={burndownData().values} bcolors={burndownData().bcolors} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Row>

        {/* Footer */}
        <Row>
          <div className="mb-3">
            <DefaultButton className="btn-default" style={{ boxShadow: theme.effects.elevation4 }} text="Edit Finance" allowDisabledFocus onClick={__setViewEdit} />
          </div>
        </Row>
        {/* Footer End */}
      </div>
      {/* Modals */}

      {/* Alerts and notification */}
      <div>
        <ToastContainer position="top-center" autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      </div>
    </div>
  );
}

export default GraphFinanceProject;
