/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table } from "react-bootstrap";
import { mW, toDate, toDigitDate, minusDate, isEqual, can } from "../../../components/reusableFunctions";
import ProjectDetails from "./components/ProjectDetails";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { getTheme } from "@fluentui/react";
import Swal from "sweetalert2";
import { _loadDetails } from "../../../actions/editProjectActions";
import _prReport from "../../../actions/projectReportActions";
import portfolio from "../portfolio/getPortfolioData";
import _portfolio from "../../../actions/portfolioActions";
import ProjectStatusReport from "./components/ProjectStatusReport";
import "../../../assets/css/contentProjects/reportProject.css";
import { CSVLink } from "react-csv";

function ReportsProject(props) {
  const _isMounted = useRef(false);
  const editProject = useSelector((state) => state.editProject);
  const reportStatus = useSelector((state) => state.reportStatus);
  const reportData = useSelector((state) => state.reportData);
  const reportEditables = useSelector((state) => state.reportEditables);
  const portfolioSettings = useSelector((state) => state.portfolioSettings);
  const [viewPDF, setViewPDF] = useState(false);
  const [toDatePick, setToDatePick] = useState(toDigitDate(new Date()));
  const [fromDatePick, setFromDatePick] = useState(minusDate(new Date()));
  const user = useSelector((state) => state.user);
  const action = useDispatch();
  const theme = getTheme();


  const [CSVData, setCSVData] = useState([]); //added by Dennis create state of date for different report
  const [CSVFilename, setCSVFilename] = useState(""); //added by Dennis create state of project report name

  useEffect(() => {
    _isMounted.current = true;
    if (props.moduleTitle !== "PROJECT REPORTS") {
      props.setModuleTitle("PROJECT REPORTS");
    }
    if (editProject === null && localStorage.getItem("editProject") && _isMounted) {
      action(_loadDetails(JSON.parse(localStorage.getItem("editProject"))));
    }
    __getPortfolioSettings();
    action(_prReport.updateStatus({ report_staged: 0, report_type: 0 }));
    return () => {
      _isMounted.current = false;
    };
  }, []);

  const __getPortfolioSettings = async () => {
    const data = await portfolio.getSettings(portfolioSettings.pfid);
    if (data?.error) {
      action(_portfolio.updateSettings({ theme_color: "#00A528" }));
    } else {
      if (!isEqual(data, portfolioSettings)) {
        action(_portfolio.loadSettings(data));
      }
    }
  };

  const __selectReportType = (e) => {
    action(_prReport.updateStatus({ report_type: +e.target.value, report_staged: 0 }));
  };

  const __getOverallStatus = (type) => {
    const status = (editProject?.schedule_status + editProject?.scope_status + editProject?.budget_status) / 3;
    if (status < 1.5) return type === "color" ? "low" : "On Track";
    if (status >= 1.5 && status <= 2.5) return type === "color" ? "mid" : "At Risk";
    if (status > 2.5) return type === "color" ? "high" : "Off Track";
  };

  const __getStatus = (type, value) => {
    if (value === 1) return type === "color" ? "low" : "On Track";
    if (value === 2) return type === "color" ? "mid" : "At Risk";
    if (value === 3) return type === "color" ? "high" : "Off Track";
  };

  const __stageReport = () => {
    if (reportStatus.report_type === 0) {
      Swal.fire("Report Type is required");
      return;
    }

    if (
      (reportStatus.report_type === 1 && !can("Generate Project Status Report")) ||
      (reportStatus.report_type === 2 && !can("Generate Project Finance Report")) ||
      (reportStatus.report_type === 3 && !can("Generate Project Timesheet Report"))
    ) {
      Swal.fire("Not Allowed", "You have no permission to Generate this Report", "error");
      return;
    }

    //added by dennis
    if (reportStatus.report_type === 1) setCSVFilename((prev) => (prev = "PROJECT_STATUS_REPORT"));
    if (reportStatus.report_type === 2) setCSVFilename((prev) => (prev = "PROJECT_FINANCE_REPORT"));
    if (reportStatus.report_type === 3) setCSVFilename((prev) => (prev = "PROJECT_TIMESHEET_REPORT"));

    setViewPDF(false);
    action(_prReport.updateStatus({ report_loading: 1 }));
    //Prepare Tasks - Project Overview
    let project_overview = [];
    for (const task of editProject.tasks) {
      let tmpTask = {};
      tmpTask.name = task.name;
      tmpTask.due_date = task.due_date;
      tmpTask.at_tid = task.at_tid;
      tmpTask.index = task.index;
      //get Task progress percentage
      const chkListsTotal = editProject.checklists.filter((data) => data.tid === task.tid).length;
      const chkListsCompleted = editProject.checklists.filter((data) => data.tid === task.tid && data.status === 1).length;
      tmpTask.percent_done = task.status === 0 ? (chkListsTotal === 0 ? 0 : +parseFloat((chkListsCompleted / chkListsTotal) * 100).toFixed(2)) : 100;
      tmpTask.comments = editProject.comments.filter((data) => data.tid === task.tid);
      project_overview = [...project_overview, tmpTask];
    }

    //Prepare Tasks - Upcoming Activity
    let upcoming_activity = [];
    for (const task of editProject.tasks) {
      if (toDigitDate(task.due_date) > toDigitDate(new Date()) && toDigitDate(task.due_date) < toDigitDate(new Date(Date.now() + 7 * 86400000))) {
        for (const chk of editProject.checklists.filter((data) => data.tid === task.tid && data.status === 0)) {
          let activity = {};
          activity.name = chk.name;
          activity.due_date = toDate(task.due_date);
          if (task.assignments && task.assignments.length > 0) {
            activity.assignees = JSON.parse(task.assignments)
              .map((data) => {
                return data.label;
              })
              .join(", ");
          } else {
            activity.assignees = [];
          }
          upcoming_activity = [...upcoming_activity, activity];
        }
      }
    }

    action(
      _prReport.updateData({
        report_date: toDate(new Date()),
        company_name: editProject.company,
        project_name: editProject.name,
        prepared_by: user.displayName,
        status_summary: [
          {
            category: "Overall",
            value: +parseFloat((editProject?.schedule_status + editProject?.scope_status + editProject?.budget_status) / 3).toFixed(2),
            status: __getOverallStatus("color"),
          },
          { category: "Schedule", value: editProject.schedule_status, status: __getStatus("color", editProject.schedule_status) },
          { category: "Scope", value: editProject.scope_status, status: __getStatus("color", editProject.scope_status) },
          { category: "Budget", value: editProject.budget_status, status: __getStatus("color", editProject.budget_status) },
        ],
        project_overview: project_overview,
        upcoming_activity: upcoming_activity,
      })
    );

    let report_summary = editProject.comments
      .filter((item) => {
        const time = new Date(item.createdAt).getTime();
        return new Date(fromDatePick).getTime() < time && time < new Date(toDatePick);
      })
      .map((data) => {
        return { id: data.id, comment: data.comment, date: data.createdAt };
      });

    if (report_summary.length === 0) {
      report_summary = [
        {
          id: 0,
          comment: "No report summary for this date range",
          date: toDigitDate(new Date()),
        },
      ];
    }

    action(
      _prReport.updateEditables({
        report_summary: report_summary,
        upcoming_activity: upcoming_activity,
      })
    );
    action(_prReport.updateStatus({ report_staged: 1, report_loading: 0 }));
  };

  const __generatePdfReport = async () => {
    setViewPDF(true);
  };

  const __reStageReport = async () => {
    setViewPDF(false);
  };

  useEffect(() => {
    if (reportStatus.report_staged === 1) {
      console.log("report has been staged");
      if (reportStatus.report_type === 1) {
        const data = reportData.project_overview;
    
        const newData = data.map((each) => {
          let newObject = { Task: each.name, "% Done": each.percent_done, "Due Date": toDate(each.due_date), Comment: each.comments  };
          return newObject;
        });

        setCSVData(newData);

        console.log("setCSVData");
      }
    }
  }, [reportStatus, reportData.project_overview]);

  //End

  return (
    <div className="main-content p-0 m-0">
      <div>
        <ProjectDetails editProject={editProject} />
        <div className="content-details-row">
          <div className="content-details-col" style={{ ...mW(22, 22) }}>
            <div className="txt-lg mb-3">SELECT REPORT TYPE</div>
            <div className="details-summary-box" style={{ ...mW(20, 20) }}>
              <div className="content-details-row mb-2 p-0">
                <div className="content-details-col p-0 txt-lg" style={{ ...mW(14, 14) }}>
                  Project Status Report
                </div>
                <div className="content-details-col p-0">
                  <input
                    type="radio"
                    name="project_type"
                    value={1}
                    onChange={__selectReportType}
                    checked={reportStatus?.report_type === 1 ? true : false}
                  />
                </div>
              </div>
              {/* <div className="content-details-row mb-2 p-0">
                <div className="content-details-col p-0 txt-lg" style={{ ...mW(14, 14) }}>
                  Project Finance Report
                </div>
                <div className="content-details-col p-0">
                  <input type="radio" name="project_type" value={2} onChange={__selectReportType} checked={reportStatus?.report_type === 2 ? true : false} />
                </div>
              </div>
              <div className="content-details-row p-0">
                <div className="content-details-col p-0 txt-lg" style={{ ...mW(14, 14) }}>
                  Project Timesheet Report
                </div>
                <div className="content-details-col p-0">
                  <input type="radio" name="project_type" value={3} onChange={__selectReportType} checked={reportStatus?.report_type === 3 ? true : false} />
                </div>
              </div> */}
            </div>
            <div className="txt-lg mb-3 mt-3">SELECT DATE RANGE</div>
            <div className="details-summary-box" style={{ ...mW(20, 20) }}>
              <div>
                <label htmlFor="fromDate" className="mr-3">
                  From:
                </label>
                <input
                  type="date"
                  id="fromDate"
                  style={{ padding: 5, borderRadius: 5, border: "1px solid rgba(0,0,0,0.2)" }}
                  value={fromDatePick}
                  onChange={(e) => setFromDatePick(toDigitDate(e.target.value))}
                  required
                />
              </div>
              <div className="mt-3">
                <label htmlFor="toDate" style={{ marginLeft: 25, marginRight: 10 }}>
                  To:
                </label>
                <input
                  type="date"
                  id="toDate"
                  style={{ padding: 5, borderRadius: 5, border: "1px solid rgba(0,0,0,0.2)" }}
                  value={toDatePick}
                  onChange={(e) => setToDatePick(toDigitDate(e.target.value))}
                  required
                />
              </div>
            </div>
            <div className="content-details-row p-0 mt-3">
              <div className="content-details-col p-0">
                <div className="txt-lg mb-3">
                  <DefaultButton
                    className="btn-default float-right"
                    style={{ boxShadow: theme.effects.elevation4 }}
                    text="Stage Report"
                    allowDisabledFocus
                    onClick={__stageReport}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="content-details-col">
            <div className="content-details-row p-0">
              <div className="content-details-col p-0 pr-3">
                <div className="txt-lg mb-3">
                  <span className="mr-3">REPORT STAGING</span>
                </div>
                {reportStatus.report_staged === 1 && reportStatus.report_type === 1 && (
                  <ProjectStatusReport
                    portfolioSettings={portfolioSettings}
                    reportData={reportData}
                    reportEditables={reportEditables}
                    fromDate={fromDatePick}
                    toDate={toDatePick}
                    viewPDF={viewPDF}
                  />
                )}
                {reportStatus.report_staged === 1 && reportStatus.report_type === 2 && (
                  <div className="report-content">
                    <div className="report-header">
                      <div className="report-header-company-name">{reportData.company_name}</div>
                      <div className="report-header-project-name">{reportData.project_name}</div>
                    </div>
                    <div className="report-title">PROJECT FINANCE REPORT</div>
                    <div className="report-details-container">
                      <div className="report-section">
                        <div className="report-section-header">PROJECT SUMMARY</div>
                        <div className="report-section-detail">
                          <Table bordered size="sm">
                            <thead>
                              <tr className="report-table-head">
                                <th>Report Date</th>
                                <th>Project Name</th>
                                <th>Prepared By</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="report-translucent">
                                <td>{reportData.report_date}</td>
                                <td>{reportData.project_name}</td>
                                <td>{reportData.prepared_by}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      <div className="report-section">Work in progress...</div>
                    </div>
                  </div>
                )}
                {reportStatus.report_staged === 1 && reportStatus.report_type === 3 && (
                  <div className="report-content">
                    <div className="report-header">
                      <div className="report-header-company-name">{reportData.company_name}</div>
                      <div className="report-header-project-name">{reportData.project_name}</div>
                    </div>
                    <div className="report-title">PROJECT TIMESHEET REPORT</div>
                    <div className="report-details-container">
                      <div className="report-section">
                        <div className="report-section-header">PROJECT SUMMARY</div>
                        <div className="report-section-detail">
                          <Table bordered size="sm">
                            <thead>
                              <tr className="report-table-head">
                                <th>Report Date</th>
                                <th>Project Name</th>
                                <th>Prepared By</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr className="report-translucent">
                                <td>{reportData.report_date}</td>
                                <td>{reportData.project_name}</td>
                                <td>{reportData.prepared_by}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      <div className="report-section">Work in progress...</div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {reportStatus.report_staged ? (
              <div className="content-details-row p-0 mt-3">
                <div className="content-details-col p-0">
                  <div className="txt-lg mb-3">
                    {!viewPDF ? (
                      <>
                        <CSVLink data={CSVData} filename={CSVFilename} target="_blank">
                          <DefaultButton
                            className="btn-default float-right"
                            style={{ boxShadow: theme.effects.elevation4 }}
                            text="Export CSV Format"
                            allowDisabledFocus
                          />
                        </CSVLink>

                        <DefaultButton
                          className="btn-default float-right"
                          style={{ boxShadow: theme.effects.elevation4 }}
                          text="Generate PDF Report"
                          allowDisabledFocus
                          onClick={__generatePdfReport}
                        />
                        <DefaultButton
                          className="btn-default float-right"
                          style={{ boxShadow: theme.effects.elevation4 }}
                          text="Reset"
                          allowDisabledFocus
                          onClick={__stageReport}
                        />
                      </>
                    ) : (
                      <DefaultButton
                        className="btn-default float-right"
                        style={{ boxShadow: theme.effects.elevation4 }}
                        text="Back"
                        allowDisabledFocus
                        onClick={__reStageReport}
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <div className="mb-3">Select report type and click stage report...</div>
                <small className="font-italic">Each report requires specific permissions</small>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportsProject;
