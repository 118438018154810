/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import "../../../assets/css/contentProjects/detailsProject.css";
import { ToastContainer, toast } from "react-toastify";
import { mW, toDate, toDigitDate, dateRange, baseApiURL, eDate, can } from "../../../components/reusableFunctions";
import { _loadDetails, _updateDetails } from "../../../actions/editProjectActions";
import { getProjectData } from "./components/getProjectData";
import { useSelector, useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { DefaultButton } from "@fluentui/react/lib/Button";
import { Form, Col, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { getTheme } from "@fluentui/react";
import { FluentIcon } from "../../../components/FluentIcon.js";
import { nanoid } from "nanoid";
import ProjectDetails from "./components/ProjectDetails";
// import { _at } from "../../../components/autotask";
import axios from "axios";

function EditFinanceProject(props) {
  const action = useDispatch();
  const theme = getTheme();
  const isMounted = useRef(false);
  const editProject = useSelector((state) => state.editProject);
  const portfolioSettings = useSelector((state) => state.portfolioSettings);
  // const [autotask, setAutotask] = useState({});
  const initNewFinance = {
    orig_end_date: new Date(editProject?.end_date) ?? new Date(),
    orig_forecast_hours: editProject?.forecast_hours ?? 0,
    orig_project_services: editProject?.project_services ?? 0,
    end_date: new Date(editProject?.end_date) ?? new Date(),
    forecast_hours: editProject?.forecast_hours ?? 0,
    project_services: editProject?.project_services ?? 0,
    contingency: editProject?.contingency ?? 0,
    forecast_burndowns: editProject?.forecast_burndowns ?? [],
    month_range: editProject?.month_range ?? [],
    changed: false,
  };
  const [newFinance, setNewFinance] = useState(initNewFinance);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      if (props.data.moduleTitle !== "EDIT PROJECT FINANCE") {
        props.data.setModuleTitle("EDIT PROJECT FINANCE");
      }
    }
  }, []);

  useEffect(() => {
    if (isMounted.current && props.data.moduleTitle === "EDIT PROJECT FINANCE") {
      if (editProject === null && localStorage.getItem("editProject")) {
        action(_loadDetails(JSON.parse(localStorage.getItem("editProject"))));
      } else {
        __initNewFinance();
      }
      if (!editProject.tasks) {
        props.setView("edit");
        __refreshData(`*/${editProject?.pid}/Bucket,Task,Checklist,Comment`);
      }
    }
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      if (newFinance.forecast_burndowns && newFinance.forecast_burndowns.length !== 0) {
        __processBurnDowns();
      }
    }
  }, [newFinance.month_range]);

  // useEffect(() => {
  //   if (isMounted.current) {
  //     setAutotask({
  //       config: {
  //         headers: {
  //           ApiIntegrationCode: portfolioSettings?.at_ApiIntegrationCode,
  //           UserName: portfolioSettings?.at_UserName,
  //           Secret: portfolioSettings?.at_Secret,
  //           "Access-Control-Allow-Origin": "*",
  //           "Content-Type": "application/json",
  //         },
  //       },
  //       url: `https://${portfolioSettings?.at_Zone}.autotask.net/atservicesrest/v${portfolioSettings?.at_Version}`,
  //       default_query: 'search={"Filter":[{"field":"id","op":"gte","value":0}]}',
  //       current_query: null,
  //     });
  //   }
  // }, [portfolioSettings]);

  const __initNewFinance = () => {
    setNewFinance(initNewFinance);
  };

  const __setViewEdit = () => {
    props.setView("graph");
  };

  const __setMonthRange = (sdate, edate) => {
    const sDate = toDigitDate(sdate);
    const eDate = toDigitDate(edate);
    setNewFinance({ ...newFinance, month_range: dateRange(sDate, eDate), end_date: new Date(edate), changed: true });
  };

  const __processBurnDowns = () => {
    let forecast_burndowns = [];
    for (const fb of newFinance.forecast_burndowns) {
      if (newFinance.month_range.includes(fb.month)) {
        forecast_burndowns = [...forecast_burndowns, fb];
      }
    }
    setNewFinance({
      ...newFinance,
      forecast_burndowns: forecast_burndowns,
    });
  };

  const __handleAddBurndown = () => {
    const selectedMonth = newFinance.month_range[newFinance.forecast_burndowns.length];
    //action(_addBurndown({ pid: editProject.pid, fbid: nanoid(6), month: selectedMonth, financial: "", effort: "" }));
    let newFb = { pid: editProject.pid, fbid: nanoid(6), month: selectedMonth, financial: "", effort: "" };
    for (const fb of editProject.forecast_burndowns) {
      if (fb.month === selectedMonth) {
        newFb = fb;
      }
    }
    setNewFinance({
      ...newFinance,
      forecast_burndowns: [...newFinance.forecast_burndowns, newFb],
      changed: true,
    });
  };

  const __handleBurnDownChange = (e) => {
    const fbid = e.target.getAttribute("data-fbid");
    const name = e.target.getAttribute("data-name");
    const value = e.target.value;
    setNewFinance({
      ...newFinance,
      forecast_burndowns: newFinance.forecast_burndowns.map((data) => {
        if (data.fbid !== fbid) {
          return data;
        }
        return {
          ...data,
          [name]: value,
        };
      }),
      changed: true,
    });
  };

  const __handleEndDateChange = (date) => {
    const sdate = new Date(editProject.start_date);
    const oedate = new Date(toDate(editProject.end_date));
    const edate = new Date(toDate(date));
    if (sdate > edate) {
      __toast("End date should be greater than start date", null, 2000, 25);
      return;
    }
    if (edate < oedate) {
      __toast("Selected date is lesser than the original End date", null, 2000, 30);
      return;
    }
    __setMonthRange(toDigitDate(sdate), toDigitDate(edate));
  };

  const __handleForecastHoursChange = (e) => {
    if (isNaN(e.target.value)) {
      __toast("Forecast Hours is not a valid number", null, 2000, 25);
      return;
    }
    setNewFinance({ ...newFinance, forecast_hours: e.target.value === "" ? "" : +e.target.value, changed: true });
  };

  const __handleProjectServicesChange = (e) => {
    if (isNaN(e.target.value)) {
      __toast("Project Services is not a valid number", null, 2000, 25);
      return;
    }
    setNewFinance({ ...newFinance, project_services: e.target.value === "" ? "" : +e.target.value, changed: true });
  };

  const __handleContingencyChange = (e) => {
    if (isNaN(e.target.value)) {
      __toast("Contingency is not a valid number", null, 2000, 25);
      return;
    }
    setNewFinance({ ...newFinance, contingency: e.target.value === "" ? "" : +e.target.value, changed: true });
  };

  const __validateChanges = () => {
    if (!can("Edit Project Finance")) {
      Swal.fire("Not Allowed", "You have no permission to Edit Project Finances", "error");
      return;
    }

    if (newFinance.forecast_hours === null || newFinance.project_services === null || newFinance.contingency === null) {
      __toast("Please fill-up all fields", null, 1500, 15);
      return;
    }
    if (toDate(newFinance.start_date) === toDate(newFinance.end_date)) {
      __toast("Project schedule is not set properly", null, 2000, 20);
      return;
    }

    let total_financial = 0;
    let total_effort = 0;
    for (const fb of newFinance.forecast_burndowns) {
      total_financial += +fb.financial;
      total_effort += +fb.effort;
    }
    if (+total_financial > +newFinance.project_services + +newFinance.contingency) {
      __toast("Budget is not enough for financial burndown", null, 2000, 25);
      return;
    }
    if (+total_effort > +newFinance.forecast_hours) {
      __toast("Total Effort Burndown is already putting the project at risk", null, 2500, 30);
      return;
    }

    if (editProject.project_type === "tm") {
      if (+newFinance.forecast_hours * +editProject.hourly_rate > +newFinance.project_services + +newFinance.contingency) {
        __toast("Project budget is not enough", null, 2000, 20);
        return;
      }

      for (const fb of newFinance.forecast_burndowns) {
        if (+fb.financial !== +fb.effort * +editProject.hourly_rate) {
          __toast("A Burndown does not equate to Effort x Hourly Rate", null, 2500, 30);
          return;
        }
      }
    }
    __confirmSaveChanges();
  };

  const __submitChanges = async () => {
    __saveProgress();
    const payload = {};
    payload.updates = { ...newFinance };
    delete payload.updates.month_range;
    delete payload.updates.forecast_burndowns;
    delete payload.updates.changed;
    payload.updates.end_date = toDigitDate(toDate(payload.updates.end_date));
    payload.pid = editProject.pid;
    if (editProject.in_autotask === 1) {
      const resultAt = await __updateAutotaskProject();
      if (!resultAt || resultAt?.error) {
        console.log(resultAt);
      }
    }
    const resultFinance = await __apiSaveFinance(payload);
    if (resultFinance) {
      for (const fb of newFinance.forecast_burndowns) {
        const resultFb = await __apiSaveFinanceBurndown(fb);
        if (!resultFb) {
          Swal.close();
          return;
        }
      }
      const resultRefresh = await __refreshData(`*/${editProject?.pid}/Burndown,Task,Timesheet`);
      if (resultRefresh) {
        __successAlert();
      }
    }
  };

  const __updateAutotaskProject = async () => {
    if (newFinance.orig_end_date !== newFinance.end_date || newFinance.orig_project_services !== newFinance.project_services) {
      const payload = {
        id: editProject.at_pid,
        endDateTime: eDate(newFinance.end_date),
        laborEstimatedRevenue: newFinance.project_services,
      };
      console.log(payload);
      const resPatchProject = await axios.patch(`${baseApiURL}/at/upsert/${portfolioSettings.pfid}`, { url: `/Projects/`, payload: payload });
      const patchedProject = resPatchProject.data;
      if (patchedProject.result) {
        return { error: `Something went wrong updating the project in Autotask` };
      } else {
        console.log("Autotask project updated...");
      }
    }
    if (
      editProject.at_crid &&
      (newFinance.orig_forecast_hours !== newFinance.forecast_hours || newFinance.orig_project_services !== newFinance.project_services)
    ) {
      const payload = {
        id: editProject.at_crid,
        estimatedHours: newFinance.forecast_hours,
        estimatedRevenue: newFinance.project_services,
      };

      const resPatchContract = await axios.patch(`${baseApiURL}/at/upsert/${portfolioSettings.pfid}`, { url: `/Contracts/`, payload: payload });
      const patchedContract = resPatchContract.data;
      if (patchedContract.result) {
        return { error: `Something went wrong updating the contract in Autotask` };
      } else {
        console.log("Autotask contract updated...");
      }
    }
  };

  const __apiSaveFinance = async (payload) => {
    console.log("Saving finance changes...");
    return axios
      .post(`${baseApiURL}/project/edit`, payload)
      .then((res) => {
        if (res.data.error) {
          __toast("DB Error: Saving finance changes failed", null, null, 30);
          console.log(res.data.error);
          return false;
        } else {
          return true;
        }
      })
      .catch(function (error) {
        __toast("API Error: Saving finance changes failed", null, null, 30);
        console.log(error);
        return false;
      });
  };

  const __apiSaveFinanceBurndown = async (payload) => {
    return axios
      .post(`${baseApiURL}/project/burndown/edit`, payload)
      .then((res) => {
        if (res.data.error) {
          __toast("DB Error: Saving finance burndown failed", null, null, 30);
          console.log(res.data.error);
          return false;
        } else {
          return true;
        }
      })
      .catch(function (error) {
        __toast("API Error: Saving finance burndown failed", null, null, 30);
        console.log(error);
        return false;
      });
  };

  const __refreshData = async (payload) => {
    if (!editProject?.pid) return;
    const project = await getProjectData(payload);
    if (project.error) {
      __toast(project.error, null, null, 30);
    } else {
      if (isMounted.current) {
        action(_updateDetails(project));
      }
      return true;
    }
    return false;
  };

  const __confirmSaveChanges = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Financial changes affects the entire project?",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Confirm",
      confirmButtonColor: "#DF3D22",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        __submitChanges();
      }
    });
  };

  const __successAlert = () => {
    Swal.close();
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Success saving Finance changes!",
      showConfirmButton: false,
      timer: 1500,
      didClose: () => {
        console.log("Finance changes saved...");
      },
    });
  };

  const __saveProgress = () => {
    Swal.fire({
      title: "Saving Project",
      html: `Please wait while we do our thing...`,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  };

  const __toast = (msg, link, time = 2500, w = 30) =>
    toast.error(`⛔ ${msg}`, {
      onClose: () => (link ? (window.location.href = link) : false),
      position: "top-center",
      autoClose: time,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: `toast-width-${w}`,
      progress: undefined,
    });

  if (!editProject) {
    return <div></div>;
  }

  return (
    <div>
      <ProjectDetails editProject={editProject} />

      <div className="pl-5 mt-5" style={{ ...mW(50, 65) }}>
        <Row>
          {/* LEFT Section */}
          <Col style={{ ...mW(30, 37.76) }}>
            {/* Top LEFT Section */}

            <Row className="pb-3 pr-4">
              <Col className="pt-3">
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm">
                    PROJECT SCHEDULE
                  </Form.Label>
                </Form.Row>
                {/* Top Title-Right Area End */}
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(5.5, 5.5) }}>
                    Start Date
                  </Form.Label>
                  <Col style={{ ...mW(0, 9) }}>
                    <Form.Control size="md" type="text" placeholder="" value={toDate(editProject?.start_date)} readOnly />
                    <div className="i-inside">
                      <FluentIcon name="Calendar" />
                    </div>
                  </Col>
                </Form.Row>
                <Form.Row className="mb-5">
                  <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(5.5, 5.5) }}>
                    End Date
                  </Form.Label>
                  <Col style={{ ...mW(0, 9) }}>
                    <DatePicker
                      selected={newFinance?.end_date}
                      onChange={(date) => __handleEndDateChange(date)}
                      dateFormat={"dd-MMM-yyyy"}
                      dateClassName="txt-lg"
                    />
                    <div className="i-inside">
                      <FluentIcon name="Calendar" />
                    </div>
                  </Col>
                </Form.Row>
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 10) }}>
                    FORECAST HOURS
                  </Form.Label>
                  <Col style={{ ...mW(0, 8) }}>
                    <Form.Control
                      key="forecast_hours"
                      size="md"
                      type="number"
                      placeholder=""
                      value={newFinance?.forecast_hours}
                      onChange={__handleForecastHoursChange}
                      required
                    />
                  </Col>
                </Form.Row>
              </Col>
            </Row>
            {/* Top LEFT Section END */}
            {/* Bottom LEFT Section */}
            <Row className="top-border-dashed">
              <Col className="pt-4">
                <Row>
                  <Col>
                    {/* Title-iLeft Area */}
                    <Form.Row className="mb-3">
                      <Form.Label className="pt-2 txt-lg" column="sm">
                        PROJECT BUDGET
                      </Form.Label>
                    </Form.Row>
                    {/* Title-iLeft Area End */}
                    {/* Options-iLeft */}
                    <Form.Row className="mb-3">
                      <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(8, 10) }}>
                        Project Services
                      </Form.Label>
                      <Col style={{ ...mW(0, 8) }}>
                        <div className="currency">$</div>
                        <Form.Control
                          size="md"
                          type="number"
                          placeholder=""
                          value={newFinance?.project_services}
                          onChange={__handleProjectServicesChange}
                          required
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row className="mb-3">
                      <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(8, 10) }}>
                        Contingency
                      </Form.Label>
                      <Col style={{ ...mW(0, 8) }}>
                        <div className="currency">$</div>
                        <Form.Control
                          className="float-left"
                          size="md"
                          type="number"
                          placeholder=""
                          value={newFinance?.contingency}
                          onChange={__handleContingencyChange}
                          required
                        />
                      </Col>
                    </Form.Row>
                    <Form.Row className="mb-3">
                      <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(8, 10) }}>
                        Hourly Rate
                      </Form.Label>
                      <Col style={{ ...mW(0, 8) }}>
                        <div className="currency">$</div>
                        <Form.Control size="md" type="number" placeholder="" value={editProject?.hourly_rate} readOnly required />
                      </Col>
                    </Form.Row>
                    {/* Options-iLeft End */}
                  </Col>
                  <Col>
                    {/* Title-iRight Area */}
                    <Form.Row className="mb-3">
                      <Form.Label className="pt-2 txt-lg" column="sm">
                        PROJECT TYPE
                      </Form.Label>
                    </Form.Row>
                    {/* Title-iRight Area End */}
                    {/* Options-iRight */}
                    {editProject?.project_type === "tm" ? (
                      <Form.Row className="mb-3">
                        <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(9, 10) }}>
                          Time & Materials
                        </Form.Label>
                        <Col className="pt-2" style={{ ...mW(0, 8) }}>
                          <input type="radio" id="tm" name="ptype" value="tm" readOnly checked={true} />
                        </Col>
                      </Form.Row>
                    ) : (
                      <Form.Row className="mb-3">
                        <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(9, 10) }}>
                          Fixed Price
                        </Form.Label>
                        <Col className="pt-2" style={{ ...mW(0, 8) }}>
                          <input type="radio" id="fp" name="ptype" value="fp" readOnly checked={true} />
                        </Col>
                      </Form.Row>
                    )}
                    {/* Options-iRight End */}
                  </Col>
                </Row>
              </Col>
            </Row>
            {/* Bottom LEFT Section END */}
          </Col>
          {/* LEFT Section End */}
          {/* Right Section */}
          <Col className="left-border-dashed" style={{ ...mW(0, 22) }}>
            {/* Top Right Section */}
            <Row className="pl-4 pt-3">
              <Col>
                {/* Title-Right Area */}
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm">
                    FORECAST BURNDOWN
                  </Form.Label>
                </Form.Row>
                {/* Title-Right Area End */}
                <Form.Row className="txt-sm mb-1">
                  <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(6, 6) }}></Form.Label>
                  <Col className="text-center" style={{ ...mW(8.7, 8.7) }}>
                    FINANCIAL
                  </Col>
                  <Col className="text-center" style={{ ...mW(6.4, 6.4) }}>
                    EFFORT
                  </Col>
                </Form.Row>
                {newFinance?.forecast_burndowns?.map((data, index) => (
                  <React.Fragment key={index}>
                    <Form.Row className="mb-2">
                      <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(6, 6) }}>
                        {data.month}
                      </Form.Label>
                      <Col style={{ ...mW(0, 7) }}>
                        <div className="currency">$</div>
                        <Form.Control
                          size="md"
                          type="number"
                          value={data.financial}
                          data-name="financial"
                          data-fbid={data.fbid}
                          onChange={__handleBurnDownChange}
                          placeholder=""
                          required
                        />
                      </Col>
                      <Col style={{ ...mW(0, 5) }}>
                        <Form.Control
                          size="md"
                          type="number"
                          value={data.effort}
                          data-name="effort"
                          data-fbid={data.fbid}
                          onChange={__handleBurnDownChange}
                          placeholder=""
                          required
                        />
                      </Col>
                    </Form.Row>
                  </React.Fragment>
                ))}
                <Form.Row className="mb-3">
                  <Col style={{ ...mW(13, 13) }}></Col>
                  <DefaultButton
                    className="float-right mr-2"
                    style={{ boxShadow: theme.effects.elevation4 }}
                    text="Add"
                    disabled={newFinance?.month_range?.length > newFinance?.forecast_burndowns?.length ? false : true}
                    onClick={__handleAddBurndown}
                    allowDisabledFocus
                  />
                </Form.Row>
              </Col>
            </Row>
            {/* Top Right Section End */}
          </Col>
          {/* Right Section End */}
        </Row>
        {/* Footer */}
        <Row>
          <div className="add-project-footer mt-5">
            <DefaultButton
              className="btn-default float-right"
              style={{ boxShadow: theme.effects.elevation4 }}
              text="Submit Changes"
              disabled={newFinance.changed ? false : true}
              allowDisabledFocus
              onClick={__validateChanges}
            />
            <DefaultButton
              className="btn-default float-right"
              style={{ boxShadow: theme.effects.elevation4 }}
              text="Reset"
              disabled={newFinance.changed ? false : true}
              allowDisabledFocus
              onClick={__initNewFinance}
            />
            <DefaultButton
              className="btn-default float-right"
              style={{ boxShadow: theme.effects.elevation4 }}
              text="View Finance Summary"
              allowDisabledFocus
              onClick={__setViewEdit}
            />
          </div>
        </Row>
        {/* Footer End */}
      </div>
      {/* Modals */}

      {/* Alerts and notification */}
      <div>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
  );
}

export default EditFinanceProject;
