import React, { useEffect, useState, useRef } from "react";
import "../../assets/css/contentDashboard.css";
import { Button } from "react-bootstrap";
import { toCurrency, can } from "../../components/reusableFunctions";
import { StackedBarGraph, DonutChart } from "./contentProjects/components/ProjectGraph";
import portfolio from "./portfolio/getPortfolioData";
import _portfolio from "../../actions/portfolioActions";
import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
//import { _setLoading } from "../../actions/commonActions";

function ContentDashboard(props) {
  const action = useDispatch();
  const portfolioDetails = useSelector((state) => state.portfolioDetails);
  const portfolioSettings = useSelector((state) => state.portfolioSettings);
  const user = useSelector((state) => state.user);
  const [isLoading, setIsLoading] = useState(true);
  const _isMounted = useRef();

  useEffect(() => {
    _isMounted.current = true;
    if (!localStorage.getItem("loaded")) {
      __getPortfolio();
      localStorage.setItem("loaded", true);
    }
    return () => {
      localStorage.removeItem("loaded");
      _isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const __getPortfolio = async () => {
    setIsLoading(true);
    if (user?.member === true && portfolioSettings?.ms_365_group && portfolioSettings?.pfid) {
      const data = await portfolio.getOpenProjectsData(portfolioSettings?.pfid);
      if (data?.error) {
        __toast(data.error, null, 2000, 25);
      } else {
        // if (isEqual(portfolioDetails, data.details) === false) {
        //   action(_portfolio.loadDetails(data.details));
        // }
        // if (isEqual(portfolioProjects, data.projects) === false) {
        //   action(_portfolio.loadProjects(data.projects));
        // }
        // console.log(data);
        action(_portfolio.loadDetails(data.details));
        action(_portfolio.loadProjects(data.projects));
      }
    }
    if (_isMounted.current) {
      setIsLoading(false);
    }
  };

  const __toast = (msg, link, time = 2500, w = 30) =>
    toast.error(`⛔ ${msg}`, {
      onClose: () => (link ? (window.location.href = link) : false),
      position: "top-center",
      autoClose: time,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: `toast-width-${w}`,
      progress: undefined,
    });

  if (!portfolioDetails?.active_projects) {
    return (
      <div className="p-5">
        {isLoading ? (
          "Loading dashboard..."
        ) : user?.member === false ? (
          !portfolioSettings?.ms_365_group ? (
            <div>
              Your organization is not onboard PPM.one yet.{" "}
              <Button variant="primary" onClick={() => props.askAddOrg()}>
                Ok, get us onboard
              </Button>
            </div>
          ) : (
            <div>
              You are not onboard PPM.one yet.{" "}
              <Button variant="success" onClick={() => props.askAddUser(user)}>
                Ok, get me onboard
              </Button>
            </div>
          )
        ) : (
          "No projects yet..."
        )}
        {/* Alerts and notification */}
        <div>
          <ToastContainer position="top-center" autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
      </div>
    );
  }
  if (!can("View Portfolio Dashboard")) {
    return <div className="main-content">You have no permission to View Portfolio Dashboard</div>;
  }
  return (
    <div className="main-content">
      <div className="stat-col">
        <div className="stat-row">
          <div className="counter-box">
            <div className="counter-box-header">
              <span className="counter-box-title">ACTIVE PROJECTS</span>
              <div className="counter-box-header-line"></div>
            </div>
            <div className="counter-box-body">
              <span className="counter-box-content">
                {portfolioDetails.active_projects}
                <span style={{ color: "lightgray" }}>/{portfolioDetails.total_projects}</span>
              </span>
            </div>
          </div>
          <div className="counter-box">
            <div className="counter-box-header">
              <span className="counter-box-title">TOTAL HOURS IN EFFORT</span>
              <div className="counter-box-header-line"></div>
            </div>
            <div className="counter-box-body">
              <span className="counter-box-content">{toCurrency(parseFloat(portfolioDetails?.efforts ?? 0).toFixed(2))}</span>
            </div>
          </div>
          <div className="counter-box">
            <div className="counter-box-header">
              <span className="counter-box-title">EFFORT COMPLETED</span>
              <div className="counter-box-header-line"></div>
            </div>
            <div className="counter-box-body">
              <span className="counter-box-content">{toCurrency(parseFloat(portfolioDetails?.efforts_done ?? 0).toFixed(2))}</span>
            </div>
          </div>
          <div className="counter-box">
            <div className="counter-box-header">
              <span className="counter-box-title">REMAINING EFFORT</span>
              <div className="counter-box-header-line"></div>
            </div>
            <div className="counter-box-body">
              <span className={portfolioDetails.efforts_left < 0 ? "counter-box-content text-danger" : "counter-box-content"}>{toCurrency(parseFloat(portfolioDetails?.efforts_left ?? 0).toFixed(2))}</span>
            </div>
          </div>
        </div>

        <div className="stat-row">
          <div className="chart-box">
            <div className="counter-box-header">
              <span className="counter-box-title">PROJECT STATUS</span>
              <div className="counter-box-header-line"></div>
            </div>
            <div className="counter-box-body">
              <DonutChart
                data={{
                  values: [portfolioDetails.new_projects, portfolioDetails.inprogress_projects, portfolioDetails.onhold_projects],
                  labels: ["New", "In Progress", "On Hold"],
                  bcolors: ["#055bfb", "#5bf50b", "#F5055B"],
                }}
              />
            </div>
          </div>
          <div className="chart-box">
            <div className="counter-box-header">
              <span className="counter-box-title">FINANCIAL SUMMARY</span>
              <div className="counter-box-header-line"></div>
            </div>
            <div className="counter-box-body">
              <StackedBarGraph
                data={{
                  spent_bar_height: (portfolioDetails.invoiced / portfolioDetails.budget) * 100,
                  remaining_bar_height: (portfolioDetails.budget_left / portfolioDetails.budget) * 100,
                  budget_remaining: portfolioDetails.budget_left,
                  total_budget: portfolioDetails.budget,
                  budget_invoiced: portfolioDetails.invoiced,
                }}
              />
            </div>
          </div>
          <div className="chart-box">
            <div className="counter-box-header">
              <span className="counter-box-title">PORTFOLIO HEALTH</span>
              <div className="counter-box-header-line"></div>
            </div>
            <div className="counter-box-body">
              <DonutChart
                data={{
                  values: [portfolioDetails.on_track, portfolioDetails.at_risk, portfolioDetails.off_track],
                  labels: ["On Track", "At Risk", "Off Track"],
                  bcolors: ["#5bf50b", "#F59F05", "#F5055B"],
                }}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Alerts and notification */}
      <div>
        <ToastContainer position="top-center" autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      </div>
    </div>
  );
}

export default ContentDashboard;
