const PrReport = {};

const initReportStatus = {
  report_loading: 0,
  report_type: 0,
  report_staged: 0
};

PrReport.status = (state = initReportStatus, action) => {
  switch (action.type) {
    case "UPDATE_REPORT_STATUS":
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

PrReport.data = (state = {}, action) => {
  switch (action.type) {
    case "LOAD_REPORT_DATA":
      return action.payload;
    case "UPDATE_REPORT_DATA":
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

PrReport.editables = (state = {}, action) => {
  switch (action.type) {
    case "UPDATE_REPORT_EDITABLES":
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export default PrReport;
