/* eslint-disable react-hooks/exhaustive-deps */
import { useMsal } from "@azure/msal-react";
import { getTheme } from "@fluentui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Image, Navbar } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import Swal from "sweetalert2";
import { _doLogout, _doSaveUser, _hasAuth } from "../../actions/authenticationActions";
import { _setLoading } from "../../actions/commonActions";
import _portfolio from "../../actions/portfolioActions";
import "../../assets/css/mainContainer.css";
import Logo from "../../assets/images/MicrosoftTeams-image.png";
import { graphConfig } from "../../authConfig";
import { doPostGraphApi, getMemberPhoto, getUserAvatar } from "../../components/graph";
import { _rbac } from "../../components/rbacApi";
import { baseApiURL, baseURL, can, hasRole, imgPlaceHolder, mW } from "../../components/reusableFunctions";
import { _user } from "../../components/userApi";
import { blobToBase64 } from "../../Utils/blobToBase64";
import LogoutForm from "../guest/LogoutForm";
import ContentDashboard from "./ContentDashboard";
import ContentDev from "./contentSuperAdmin/Dev";
import ContentFinance from "./ContentFinance";
import ContentProject from "./ContentProject";
import AddBucket from "./contentProjects/AddBucket";
import AddProject from "./contentProjects/AddProject";
import AddTask from "./contentProjects/AddTask";
import DetailsProject from "./contentProjects/DetailsProject";
import FinanceProject from "./contentProjects/FinanceProject";
import ReportsProject from "./contentProjects/ReportsProject";
import TimesheetProject from "./contentProjects/TimesheetProject";
import ContentReport from "./ContentReport";
import ManageRBAC from "./contentResources/ManageRBAC";
import ManageUsers from "./contentResources/ManageUsers";
import ContentSetting from "./ContentSetting";
import mainMenuLinks from "./mainContainer/components/mainMenuLinks";
import portfolio from "./portfolio/getPortfolioData";
import TeamView from "./contentResources/TeamView";
import SuperAdmin from "./contentSuperAdmin/SuperAdmin";

function MainContainer() {
  const theme = getTheme();
  const { instance } = useMsal();
  //routes and paths
  const { url } = useRouteMatch();
  const location = useLocation();
  const history = useHistory();

  const action = useDispatch();
  //const authMethod = useSelector((state) => state.authMethod);
  const hasAuth = useSelector((state) => state.hasAuth);
  const user = useSelector((state) => state.user);
  const editProject = useSelector((state) => state.editProject);
  const portfolioSettings = useSelector((state) => state.portfolioSettings);
  const portfolioMembers = useSelector((state) => state.portfolioMembers);
  const [moduleTitle, setModuleTitle] = useState(localStorage.getItem("title") || "PORTFOLIO DASHBOARD");
  const [mainMenu, setMainMenu] = useState([]);

  const __doLogout = () => {
    if (user.authMethod === "MS") {
      instance.logoutPopup();
    }
    window.isLoggingOut = true;
    action(_doLogout());
    action(_hasAuth(false));
    localStorage.clear();
    sessionStorage.clear();
  };

  useEffect(() => {
    Swal.close();
    if (user?.member === true) {
      document.title = portfolioSettings.company_name + " - PPM.one";
    }
  }, []);

  useEffect(() => {
    if (user) {
      if ((user?.authMethod === "MS" && hasAuth === false) || (user?.authMethod === "MS" && hasAuth === false && user?.member === false && portfolioSettings?.new === true)) {
        //__askAddUser(user);
        __checkIfOnboard();
      } else {
        __checkLogin();
      }
    }
  }, [user, hasAuth, portfolioSettings?.new, portfolioSettings?.ms_365_group]);

  useEffect(() => {
    if (user?.member === true && portfolioMembers?.length === 0 && portfolioSettings?.ms_365_group) {
      __getPortfolioMembers(portfolioSettings.ms_365_group);
    }
  }, [user, portfolioMembers, portfolioSettings?.ms_365_group]);

  useEffect(() => {
    if (user?.member === true) {
      setMainMenu(mainMenuLinks.filter((data) => data.exclusive === false));
    } else {
      setMainMenu(mainMenuLinks.filter((data) => data.private === false));
    }
    if (user && user.isEnabled === false) {
      console.log("USER:", user);
      __userDisabled();
    }
  }, [user]);

  const __userDisabled = async () => {
    action(_hasAuth(false));
    Swal.fire({
      icon: "error",
      title: "Access Blocked",
      text: `Your account has been disabled. Please contact your administrator! (code: NIAMTNOC)`,
      confirmButtonText: "Ok",
      confirmButtonColor: "#DF3D22",
      timer: 5000,
    }).then((result) => {
      __doLogout();
    });
  };

  const __checkLogin = async () => {
    await portfolio.getMyProfile();
  };

  const __checkIfOnboard = async () => {
    const tenancy = await portfolio.getTenant(user.tenant_id);
    if (tenancy?.error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: '<a href="">Contact Us</a>',
      });
    } else {
      if (tenancy && tenancy.tenant_id) {
        if (user?.member === false) {
          if (portfolioSettings?.new === true) {
            const payload = portfolioSettings;
            payload.new = false;
            action(_portfolio.loadSettings(payload));
            __addUser(user);
          } else {
            action(_portfolio.loadSettings(tenancy));
            if (portfolioSettings?.ms_365_group) {
              __askAddUser(user);
            }
          }
        } else {
          action(_portfolio.loadSettings(tenancy));
          document.title = portfolioSettings.company_name + " - PPM.one";
        }
      } else {
        if (!Swal.isVisible()) {
          __askAddOrg();
        }
      }
    }
  };

  const __getPortfolioMembers = async (group_id) => {
    console.log("Getting members...");
    // if (!isMsAuth) {
    //   localStorage.clear();
    //   sessionStorage.clear();
    //   window.location.replace(`${baseURL}/login`);
    // }
    action(_setLoading(true));
    const members = await portfolio.getUsers(group_id);
    if (members?.error) {
      console.log(members.error);
    } else {
      if (members.value.length > 0) {
        let tmp_members = [];
        for (const member of members.value) {
          const res_userinfo = await _user.getMSInfo(member.id);
          member.isEnabled = res_userinfo?.isEnabled;
          if (res_userinfo?.id) {
            member.user_id = res_userinfo?.id;
          } else {
            member.user_id = null;
          }
          const photo = await getMemberPhoto(member.id);
          if (photo && !photo?.error) {
            const avatarData = await blobToBase64(photo);
            member.photo = avatarData;
          } else {
            const name = member.displayName ? member.displayName : member.userPrincipalName;
            const avatar = await getUserAvatar(name);
            if (avatar?.error) {
              member.photo = imgPlaceHolder;
            } else {
              const avatarData = await blobToBase64(avatar);
              member.photo = avatarData;
            }
          }
          tmp_members = [...tmp_members, member];
        }
        localStorage.setItem("members", JSON.stringify(tmp_members));
        action(_portfolio.loadMembers(tmp_members));
      } else {
        console.error("No members found...");
      }
    }
    action(_setLoading(false));
  };

  const __urlMatch = (u) => {
    if (u.length > 0) {
      if (location.pathname.includes(url + u)) return true;
    } else {
      if (url + u === location.pathname) return true;
    }
    return false;
  };

  const __setModuleTitle = (title) => {
    setModuleTitle(title);
    localStorage.setItem("title", title);
  };

  const __addUser = async (payload) => {
    const addMemberPayload = {
      "@odata.id": `https://graph.microsoft.com/v1.0/directoryObjects/${payload.msId}`,
    };
    const resultAddMember = await doPostGraphApi(graphConfig.addMember.replace("[group-id]", portfolioSettings?.ms_365_group), addMemberPayload);
    //console.log(resultAddMember);
    if (resultAddMember.error) {
      if (resultAddMember?.error?.response?.data?.error?.message?.includes("added object references already exist")) {
      } else {
        Swal.fire({
          icon: "error",
          title: "Onboarding Error",
          text: "Something went wrong getting you onboard. Please contact us.",
          footer: '<a href="">Contact Us</a>',
        });
        return;
      }
    }
    delete payload.businessPhones;
    payload.pfid = portfolioSettings?.pfid;

    const res_portfolioUsers = await _user.getPortfolioUsers(portfolioSettings?.pfid);

    axios
      .post(`${baseApiURL}/user/msal`, payload)
      .then(async (res) => {
        if (!res.data || res.data.errors) {
          console.log(`ERROR: ${res.data.errors[0].message}`);
        }
        if (res.data.id) {
          res.data.member = true;
          res.data.tenant_id = user.tenant_id;
          if (res_portfolioUsers?.length === 0) {
            await _rbac.addUserRole({ user_id: res.data.id, rp_id: 2 });
            const res_rbac = await _rbac.getUserRolesPermissions(res.data.id);
            res.data.rbac = res_rbac;
          }
          action(_doSaveUser(res.data));
          __successAlert();
          console.log("Onboarded successfully!");
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const __askAddOrg = async () => {
    Swal.fire({
      title: "Welcome to PPM.ONE",
      text: `Your organization "${user.tenant_name}" is not onboard PPM.ONE yet. You need to set it up first. And with that, we will be needing additional information. Do you want to continue?`,
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Confirm",
      confirmButtonColor: "#DF3D22",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        //__addUser(payload);
        setModuleTitle("NEW PORTFOLIO");
        history.push(`${url}/settings/new`);
        //__successAlert();
      }
    });
  };

  const __askAddUser = async (payload) => {
    if (!portfolioSettings?.ms_365_group) return;
    Swal.fire({
      title: "Welcome to PPM.ONE",
      text: `As a new user, we need to get you onboard before you can start using PPM.ONE under your organization "${user.tenant_name}". Do you want to continue?`,
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Confirm",
      confirmButtonColor: "#DF3D22",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        __addUser(payload);
      }
    });
  };

  const __successAlert = () => {
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Welcome onboard mate!",
      showConfirmButton: true,
      timer: 2000,
    }).then((result) => {
      window.location.replace(`${baseURL}/members`);
    });
  };

  /*
  const __toast = (type, msg, link, time = 2500, w = "30") => {
    const payload = {
      onClose: () => (link ? (window.location.href = link) : false),
      position: "top-center",
      autoClose: time,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: `toast-width-${w}`,
      progress: undefined,
    };
    if (type === "error") {
      return toast.error(msg, payload);
    }
    if (type === "info") {
      return toast.info(msg, payload);
    }
    if (type === "warning") {
      return toast.warning(msg, payload);
    }
    if (type === "success") {
      return toast.success(msg, payload);
    }
  };
  */

  const MembersRoute = () => {
    return (
      <React.Fragment>
        <Route exact path={`${url}/projects`} component={() => <ContentProject setModuleTitle={__setModuleTitle} moduleTitle={moduleTitle} />} />
        <Route exact path={`${url}/projects/details/:pid`} component={() => <DetailsProject setModuleTitle={__setModuleTitle} moduleTitle={moduleTitle} />} />
        <Route exact path={`${url}/projects/finance`} component={() => <FinanceProject setModuleTitle={__setModuleTitle} moduleTitle={moduleTitle} />} />
        <Route exact path={`${url}/projects/reports`} component={() => <ReportsProject setModuleTitle={__setModuleTitle} moduleTitle={moduleTitle} />} />
        <Route path={`${url}/projects/timesheets`} component={() => <TimesheetProject setModuleTitle={__setModuleTitle} moduleTitle={moduleTitle} />} />
        <Route exact path={`${url}/add/project`} component={() => <AddProject setModuleTitle={__setModuleTitle} />} />
        <Route exact path={`${url}/add/project/bucket`} component={() => <AddBucket setModuleTitle={__setModuleTitle} />} />
        <Route path={`${url}/add/project/bucket/task`} component={() => <AddTask setModuleTitle={__setModuleTitle} />} />
        <Route path={`${url}/finance`} component={ContentFinance} />
        <Route path={`${url}/reports`} component={ContentReport} />
        <Route exact path={`${url}/resources/teamview`} component={() => <TeamView setModuleTitle={__setModuleTitle} moduleTitle={moduleTitle} />} />
        <Route exact path={`${url}/resources/rbac`} component={() => <ManageRBAC setModuleTitle={__setModuleTitle} moduleTitle={moduleTitle} />} />
        <Route exact path={`${url}/resources/users`} component={() => <ManageUsers setModuleTitle={__setModuleTitle} moduleTitle={moduleTitle} />} />

        <Route path={`${url}/logout`} component={LogoutForm} />
        {hasRole("Super Admin") && (
          <>
            <Route exact path={`${url}/superadmin/users`} component={() => <SuperAdmin setModuleTitle={__setModuleTitle} moduleTitle={moduleTitle} />} />
            <Route path={`${url}/superadmin/dev`} component={ContentDev} />
          </>
        )}
      </React.Fragment>
    );
  };

  return (
    <div className="base-container" style={{ ...mW(90, 110), marginLeft: "auto", marginRight: "auto" }}>
      <div className="top-bar">
        <Navbar variant="light">
          <Navbar.Brand>
            <div className="top-bar-right">
              <div className="top-bar-logo">
                <Image src={Logo} fluid></Image>
              </div>
              <div className="top-bar-text">{moduleTitle}</div>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              <Image className="top-user-photo" src={user.photo} roundedCircle />
              <a className="top-user-name" href="#login">
                {user.displayName}
              </a>
            </Navbar.Text>
          </Navbar.Collapse>
        </Navbar>
      </div>
      <div className="main-container">
        <div className="menu-container">
          <div className="menu-top">
            {mainMenu.map((data, index) => (
              <React.Fragment key={index}>
                {/* If a permission exists, display the menu if the user has access to it. If no permissions, show to everyone */}
                {!data.permission && !data.role && (
                  <NavLink
                    key={data.name}
                    className="menu-item"
                    activeClassName="menu-item-active"
                    isActive={() => __urlMatch(data.path)}
                    onClick={() => __setModuleTitle(data.useFirstSubpath ? data.subMenu[0].title : data.title)}
                    exact
                    to={`${url}${data.useFirstSubpath ? data.path + data.subMenu[0].path : data.path}`}
                  >
                    {data.name}
                  </NavLink>
                )}
                {data.permission && can(data.permission) && (
                  <>
                    {can(data.permission) && (
                      <NavLink
                        key={data.name}
                        className="menu-item"
                        activeClassName="menu-item-active"
                        isActive={() => __urlMatch(data.path)}
                        onClick={() => __setModuleTitle(data.useFirstSubpath ? data.subMenu[0].title : data.title)}
                        exact
                        to={`${url}${data.useFirstSubpath ? data.path + data.subMenu[0].path : data.path}`}
                      >
                        {data.name}
                      </NavLink>
                    )}
                  </>
                )}
                {/* Menu for roles */}
                {data.role && hasRole(data.role) && (
                  <>
                    {hasRole(data.role) && (
                      <NavLink
                        key={data.name}
                        className="menu-item"
                        activeClassName="menu-item-active"
                        isActive={() => __urlMatch(data.path)}
                        onClick={() => __setModuleTitle(data.useFirstSubpath ? data.subMenu[0].title : data.title)}
                        exact
                        to={`${url}${data.useFirstSubpath ? data.path + data.subMenu[0].path : data.path}`}
                      >
                        {data.name}
                      </NavLink>
                    )}
                  </>
                )}
                {data.path === "/projects" ? (
                  <React.Fragment>
                    {data.subMenu &&
                      __urlMatch(data.path) &&
                      location.pathname !== url + data.path &&
                      data.subMenu.map((sub, si) => (
                        <NavLink
                          key={sub.name}
                          className="submenu-item"
                          activeClassName="submenu-item-active"
                          isActive={() => __urlMatch(`${data.path}${sub.path}`)}
                          onClick={() => __setModuleTitle(sub.title)}
                          exact
                          to={`${url}${data.path}${sub.path}${editProject && sub.path === "/details" ? "/" + editProject.pid : ""}`}
                        >
                          {"•"} {sub.name}
                        </NavLink>
                      ))}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {data.subMenu &&
                      __urlMatch(data.path) &&
                      data.subMenu.map((sub, si) => (
                        <NavLink
                          key={sub.name}
                          className="submenu-item"
                          activeClassName="submenu-item-active"
                          isActive={() => __urlMatch(`${data.path}${sub.path}`)}
                          onClick={() => __setModuleTitle(sub.title)}
                          exact
                          to={`${url}${data.path}${sub.path}`}
                        >
                          {"•"} {sub.name}
                        </NavLink>
                      ))}
                  </React.Fragment>
                )}
              </React.Fragment>
            ))}
          </div>
          <div className="menu-bottom">
            <div className="menu-item" onClick={() => __doLogout()}>
              LOG OUT
            </div>
          </div>
        </div>
        <div className="content-container" style={{ backgroundColor: theme.palette.neutralLighter }}>
          <div className="content-header"></div>
          <Switch>
            <Route exact path={`${url}`} component={() => <ContentDashboard askAddUser={__askAddUser} askAddOrg={__askAddOrg} />} />
            <Route path={`${url}/settings/:option?`} component={ContentSetting} />
            {user.member === true ? <MembersRoute /> : <></>}
          </Switch>
        </div>
      </div>
      {/* Alerts and notification */}
    </div>
  );
}

export default MainContainer;
