/* eslint-disable react-hooks/exhaustive-deps */
import { getTheme } from "@fluentui/react";
import { DefaultButton } from "@fluentui/react/lib/Button";
import axios from "axios";
import { nanoid } from "nanoid";
import { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Image, Modal, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import Switch from "react-switch";
import { toast, ToastContainer } from "react-toastify";
import Swal from "sweetalert2";
import { _setLoading } from "../../../actions/commonActions";
import { _loadDetails, _setEditNameModal, _setNewTaskModal, _setTimesheetModal, _updateDetails, _updateTaskIndex } from "../../../actions/editProjectActions";
import "../../../assets/css/contentProjects/detailsProject.css";
import { graphConfig } from "../../../authConfig";
import { FluentIcon } from "../../../components/FluentIcon.js";
import { doGetGraphApi, doPatchGraphApi, doPostGraphApi } from "../../../components/graph";
import { baseApiURL, can, eDate, mH, mW, sDate, toDate, toDigitDate, toFullDateTime, toSqlDate } from "../../../components/reusableFunctions";
import portfolio from "../portfolio/getPortfolioData";
import { getProjectData } from "./components/getProjectData";
import ProjectDetails from "./components/ProjectDetails";
import ProjectTask from "./components/ProjectTask";
import TaskLoader from "./components/TaskLoader";
import { Editor } from "@tinymce/tinymce-react";
import editorLoader from "../../../assets/images/loading_spinner.gif";
import { useMemo } from "react";
import { sortTask } from "../../../Utils";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

function DetailsProject(props) {
  const history = useHistory();
  const theme = getTheme();
  const isMounted = useRef();
  const portfolioSettings = useSelector((state) => state.portfolioSettings);
  const [openStatusSummary, setOpenStatusSummary] = useState(false);
  const editNameModal = useSelector((state) => state.isEditNameModalOpen);
  const newTaskModal = useSelector((state) => state.isNewTaskModalOpen);
  const [newProjectName, setNewProjectName] = useState("");
  const [newCompanyName, setNewCompanyName] = useState("");
  const [newProjectLead, setNewProjectLead] = useState(false);
  const [newTaskData, setNewTaskData] = useState({
    name: "",
    start_date: new Date(),
    due_date: new Date(),
  });
  const [savingNewTask, setSavingNewTask] = useState(false);
  const [statusSummary, setStatusSummary] = useState({});
  const [timesheet, setTimesheet] = useState({
    date_worked: "",
    hours_worked: "",
    description: "",
    desc_remaining: 255,
  });
  const [comments, setComments] = useState([]);
  const [taskToUpdate, setTaskToUpdate] = useState(false);
  const [atTaskId, setAtTaskId] = useState(false);
  const [savingChanges, setSavingChanges] = useState(false);
  const [atRoles, setAtRoles] = useState([]);
  const [atResources, setAtResources] = useState([]);
  const [atSelectedRole, setAtSelectedRole] = useState(false);
  const [atSelectedResource, setAtSelectedResource] = useState(false);
  const [addAsUpdate, setAddAsUpdate] = useState(false);
  const [showOnInvoice, setShowOnInvoice] = useState(false);
  const isLoading = useSelector((state) => state.isLoading);
  const openTimesheet = useSelector((state) => state.isTimesheetModalOpen);
  const user = useSelector((state) => state.user);
  const editProject = useSelector((state) => state.editProject);
  const portfolioMembers = useSelector((state) => state.portfolioMembers);
  const [onboardedUsers, setOnboardedUsers] = useState([]);
  const action = useDispatch();
  const editorRef = useRef(null);
  const [loadingEditor, setLoadingEditor] = useState(true);

  const taskOrder = useMemo(() => {
    //this will check if all task has no indexes if no index will sort by at_id
    const haveIndexes = editProject?.tasks?.every(task => task.index >= 0 && task.index !== null);

    if(haveIndexes)  {
      //sort tasks by index;
      return sortTask(editProject?.tasks,'index', 'ASC');
    }
    return editProject?.tasks?.length ? sortTask(editProject?.tasks,'at_id', 'ASC') : [];
  }, [editProject.tasks]);


  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      if (props.moduleTitle !== "PROJECT DETAILS") {
        props.setModuleTitle("PROJECT DETAILS");
      }
      if (editProject === null && localStorage.getItem("editProject")) {
        if (isMounted.current) {
          action(_loadDetails(JSON.parse(localStorage.getItem("editProject"))));
        }
      }
      if (!editProject?.tasks || !editProject?.checklists || !editProject?.comments) {
        if (isMounted.current) {
          __refreshData(`*/${editProject?.pid}/Bucket,Task,Checklist,Comment,Timesheet`);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      if (editProject?.comments?.length > 0) {
        __rebuildComments(editProject.comments);
      }
    }
  }, [editProject.comments]);

  useEffect(() => {
    if (isMounted.current) {
      if (editProject?.pid && editProject?.in_planner === 1) {
        __checkInPlanner(editProject.ms_pid, editProject.pid);
      }
    }
  }, []);

  const __checkInPlanner = async (ms_pid, pid) => {
    const data = await doGetGraphApi(graphConfig.updatePlan.replace("[plan-id]", ms_pid));
    if (data?.error?.response?.status === 404) {
      __removedFromPlanner();
      await portfolio.syncProject(pid, 0, editProject.in_autotask);
      action(_updateDetails({ in_planner: 0 }));
    }
  };

  useEffect(() => {
    if (isMounted.current) {
      async function getPfUsers() {
        const result = await __getUsers(portfolioSettings.pfid);
        if (result && result.length > 0) {
          setOnboardedUsers(result);
        }
      }
      getPfUsers();

      const atData = JSON.parse(portfolioSettings?.at_Data);
      // console.log(atData);
      setAtRoles(atData?.roles);
      setAtResources(atData?.resources);
      const preSelectedAtResource = atData?.resources?.filter((data) => data.email === user.mail);
      if (preSelectedAtResource?.length > 0) {
        setAtSelectedResource(preSelectedAtResource[0].id);
      }
    }
  }, [portfolioSettings]);

  const __getUsers = async (pfid) => {
    return axios
      .get(`${baseApiURL}/user/${pfid}`)
      .then((res) => {
        return res.data;
      })
      .catch(function (error) {
        return { error: error };
      });
  };

  const __rebuildComments = (payload) => {
    const tmpComments = JSON.parse(JSON.stringify(payload));
    for (const tmpComment of tmpComments) {
      const userData = JSON.parse(tmpComment.user_data);
      const tmpUserData = portfolioMembers?.filter((data) => data.id === userData.msId)[0];
      if (tmpUserData) {
        userData.displayName = tmpUserData.displayName ?? tmpUserData.userPrincipalName;
        userData.photo = tmpUserData.photo;
        tmpComment.user_data = JSON.stringify(userData);
      }
    }
    setComments(tmpComments);
  };

  const __closeOpenProject = async () => {
    const status = editProject.project_status === 1 ? 0 : 1;
    if (status === 0) {
      __confirmClosingProject(0);
    } else {
      __doCloseOpenProject(1);
    }
  };

  const __doCloseOpenProject = async (status) => {
    action(_updateDetails({ project_status: status }));
    const payload = {
      pid: editProject.pid,
      updates: {
        project_status: status,
      },
    };
    await __apiUpdateProject(payload);
  };

  const __doDeleteProject = async () => {
    action(_updateDetails({ active: 0 }));
    const payload = {
      pid: editProject.pid,
      updates: {
        active: 0,
      },
    };
    await __apiUpdateProject(payload);
    history.push("/members");
  };

  const __changeProjectProgress = async (e) => {
    action(_updateDetails({ project_progress: +e.target.value }));
    const payload = {
      pid: editProject.pid,
      updates: {
        project_progress: +e.target.value,
      },
    };
    await __apiUpdateProject(payload);
  };

  const __getOverallStatus = (type) => {
    const status = (editProject?.schedule_status + editProject?.scope_status + editProject?.budget_status) / 3;
    if (status < 1.5) return type === "color" ? "low" : "On Track";
    if (status >= 1.5 && status <= 2.5) return type === "color" ? "mid" : "At Risk";
    if (status > 2.5) return type === "color" ? "high" : "Off Track";
  };

  const __getStatus = (type, value) => {
    if (value === 1) return type === "color" ? "low" : "On Track";
    if (value === 2) return type === "color" ? "mid" : "At Risk";
    if (value === 3) return type === "color" ? "high" : "Off Track";
  };

  const __addTimesheet = async () => {
    if (isMounted.current) {
      if (!editProject?.pid) return;
      const project = await getProjectData(`*/${editProject?.pid}/Burndown,Timesheet`);
      if (project.error) {
        __toast(project.error, null, null, 30);
      } else {
        action(
          _updateDetails({
            budget_remaining: project.budget_remaining,
            effort_remaining: project.effort_remaining,
          })
        );
      }

      setTimesheet({
        date_worked: "",
        hours_worked: "",
        description: "",
        desc_remaining: 255,
      });
      action(_setTimesheetModal(true));
    }
  };

  const __changeTimesheet = (name, val) => {
    let value = val;
    const payload = timesheet;
    if (name === "date_worked") {
      value = toDate(val);
    }
    if (name === "description") {
      payload.desc_remaining = 255 - value.length;
    }
    if (payload.desc_remaining < 0) {
      __toast("Description too long!", null, null, 20);
      return;
    }
    setTimesheet({ ...payload, [name]: value });
  };

  const __saveTimesheet = async () => {
    try {
      setSavingChanges(true);
      const payload = timesheet;
      payload.pid = editProject.pid;
      payload.user_id = user.id;
      payload.status = 0;
      //console.log(payload);
      if (payload.desc_remaining < 0) {
        __toast("Description too long!", null, null, 20);
        return;
      }
      if (!payload.date_worked) {
        __toast("Date worked is required!", null, null, 20);
        return;
      }
      if (!payload.hours_worked) {
        __toast("Hours worked is required!", null, null, 20);
        return;
      }
      if (!taskToUpdate) {
        __toast("Select the related task for this timesheet!", null, null, 30);
        return;
      }
      if (editProject.in_autotask === 1) {
        if (!atSelectedResource) {
          __toast("Select your Autotask user resource!", null, null, 30);
          return;
        }
        if (!atSelectedRole) {
          __toast("Select your Autotask user role!", null, null, 20);
          return;
        }
        const atPayload = {
          contractId: editProject.at_crid ?? null,
          hoursWorked: timesheet.hours_worked,
          dateWorked: toDigitDate(timesheet.date_worked),
          summaryNotes: timesheet.description,
          resourceID: atSelectedResource,
          taskID: atTaskId,
          roleID: atSelectedRole,
          showOnInvoice: showOnInvoice,
          timeEntryType: 6,
        };
        console.log(atPayload);

        const resCreateTe = await axios.put(`${baseApiURL}/at/create/TimeEntries/${portfolioSettings.pfid}`, atPayload);
        if (!resCreateTe.data.result) {
          setSavingChanges(false);
          __toast(resCreateTe.data.message ?? "Something went wrong creating TimeEntry in Autotask", null, 5000, 30);
          return;
        } else {
          console.log(resCreateTe.data);
        }
        setSavingChanges(false);
      }
      if (addAsUpdate) {
        const taskUpdateResult = await __addTaskUpdate(payload.description);
        if (taskUpdateResult?.error) {
          __toast(taskUpdateResult.error, null, null, 30);
        }
      }
      const result = await __apiSaveTimesheet(payload);
      if (result) {
        console.log("reloading project data for recalculation");
        const resultRefresh = await __refreshData(`*/${editProject?.pid}/Timesheet,Comment`);
        if (resultRefresh) {
          __successAlert();
        }
      }
      action(_setTimesheetModal(false));
    } catch (error) {
      console.log(error.response);
      action(_setTimesheetModal(false));
      // console.log(error?.response?.data?.message);
      // console.log(error?.response?.data?.message?.indexOf("Resource does not exist or is invalid"));
      if (error?.response?.data?.message?.indexOf("Resource does not exist or is invalid") !== -1) {
        Swal.fire("Error", "Selected User Resource does not belong to this project", "error");
      } else {
        Swal.fire("Error", error?.response?.data.message ?? "Something went wrong. Please contact us!", "error");
      }
    }
  };

  const __addTaskUpdate = async (taskNewComment) => {
    if (!taskToUpdate) return;
    const taskData = editProject.tasks.filter((data) => data.tid === taskToUpdate)[0];
    //console.log(taskData);
    const payload = {
      pid: taskData.pid,
      tid: taskData.tid,
      user_id: user.id,
      user_data: JSON.stringify({
        msId: user.msId,
        displayName: user.displayName,
        photo: user.photo,
      }),
      comment: taskNewComment,
      status: 1,
      createdAt: new Date(),
    };
    const result = await __postNewComment(payload);
    return result;
  };

  const __postNewComment = async (payload) => {
    console.log("posting comment...");
    return axios
      .post(`${baseApiURL}/comment/add`, payload)
      .then((res) => {
        return res.data;
      })
      .catch(function (error) {
        return { error: error };
      });
  };

  const __refreshData = async (payload) => {
    if (!editProject?.pid) return;
    if (isMounted.current) {
      action(_setLoading(true));
      __loadingProject();
      const project = await getProjectData(payload);
      if (project.error) {
        __toast(project.error, null, null, 30);
      } else {
        action(_updateDetails(project));
        action(_setLoading(false));
        Swal.close();
        return true;
      }
    }
    action(_setLoading(false));
    Swal.close();
    return false;
  };

  const __editStatusSummary = () => {
    if (!can("Edit Project Status Summary")) {
      Swal.fire("Not Allowed", "You have no permission to Edit Project Status Summary", "error");
      return;
    }
    if (openStatusSummary) {
      if (statusSummary.changed) __saveStatusSummary();
    } else {
      setStatusSummary({
        schedule_status: editProject.schedule_status,
        scope_status: editProject.scope_status,
        budget_status: editProject.budget_status,
        changed: false,
      });
    }
    setOpenStatusSummary(!openStatusSummary);
  };

  const __changeStatusSummary = (e) => {
    setStatusSummary({
      ...statusSummary,
      [e.target.name]: +e.target.value,
      changed: true,
    });
  };

  const __saveStatusSummary = async () => {
    action(_updateDetails({ schedule_status: statusSummary.schedule_status }));
    action(_updateDetails({ scope_status: statusSummary.scope_status }));
    action(_updateDetails({ budget_status: statusSummary.budget_status }));
    setStatusSummary([]);
    const payload = {
      pid: editProject.pid,
      updates: {
        schedule_status: statusSummary.schedule_status,
        scope_status: statusSummary.scope_status,
        budget_status: statusSummary.budget_status,
      },
    };
    await __apiUpdateProject(payload);
  };

  const __apiUpdateProject = async (payload) => {
    console.log("updating project...");
    return axios
      .post(`${baseApiURL}/project/edit`, payload)
      .then((res) => {
        if (res.data.error) {
          __toast("DB Error: Updating project failed", null, null, 30);
          console.log(res.data.error);
          return false;
        } else {
          return true;
        }
      })
      .catch(function (error) {
        __toast("API Error: Updating project failed", null, null, 30);
        console.log(error);
        return false;
      });
  };

  const __apiSaveTimesheet = async (payload) => {
    console.log("saving timesheet...");
    return axios
      .post(`${baseApiURL}/timesheet/add`, payload)
      .then((res) => {
        if (res.data.error) {
          __toast("DB Error: Adding timesheet failed", null, null, 30);
          console.log(res.data.error);
          return false;
        } else {
          // console.log(res.data);
          return true;
        }
      })
      .catch(function (error) {
        __toast("API Error: Adding timesheet failed", null, null, 30);
        console.log(error);
        return false;
      });
  };

  const __confirmClosingProject = async (status) => {
    Swal.fire({
      text: "Are you sure you wish to close the Project?",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Confirm",
      confirmButtonColor: "#DF3D22",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        __doCloseOpenProject(status);
      }
    });
  };

  const __confirmDeletingProject = async (status) => {
    Swal.fire({
      title: "Are you sure you wish to delete this Project?",
      text: editProject.at_pid
        ? editProject.project_status === 1
          ? "This project is still open so it can be added again from Autotask."
          : "This project is already closed. You will not be able to add this again from Autotask."
        : "This cannot be undone!",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Confirm",
      confirmButtonColor: "#DF3D22",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        __doDeleteProject(status);
      }
    });
  };

  const __successAlert = () => {
    Swal.close();
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Success!",
      showConfirmButton: false,
      timer: 1500,
      didClose: () => {
        console.log("Data saved...");
      },
    });
  };

  const __removedFromPlanner = () => {
    Swal.fire({
      icon: "info",
      title: "Project Not In Planner!",
      text: "We detected that this project doesn't exist in Planner anymore so we converted it as a Local Only project to avoid errors",
    });
  };

  // eslint-disable-next-line no-unused-vars
  const __loadingProject = () => {
    Swal.fire({
      title: "Loading Project Data",
      html: `Please wait while we do our thing...`,
      timer: 0,
      timerProgressBar: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  };

  const __toast = (msg, link, time = 2500, w = 30) =>
    toast.error(`⛔ ${msg}`, {
      onClose: () => (link ? (window.location.href = link) : false),
      position: "top-center",
      autoClose: time,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: `toast-width-${w}`,
      progress: undefined,
    });

  const __editProject = () => {
    action(_setEditNameModal(true));
    setNewProjectLead(editProject.created_by);
    setNewProjectName(editProject.name);
    setNewCompanyName(editProject.company);
  };

  const __changeProjectName = (e) => {
    setNewProjectName(e.target.value);
  };

  const __changeCompanyName = (e) => {
    setNewCompanyName(e.target.value);
  };

  const __changeProjectLead = (id) => {
    setNewProjectLead(id);
  };

  const __changeNewTaskName = (e) => {
    setNewTaskData({ ...newTaskData, name: e.target.value });
  };

  const __doAddNewTask = async () => {
    setSavingNewTask(true);
    const bucket = editProject.buckets[0];
    const newTaskResult = await __postTask(bucket, newTaskData);
    if (newTaskResult?.error) {
      // __toast("ERROR: Something went wrong while saving task.", null, null, 30);
      Swal.fire("Error", newTaskResult?.error ?? "Something went wrong while saving task!", "error");
      return;
    } else {
      action(_setNewTaskModal(false));
      setNewTaskData({
        name: "",
        start_date: new Date(),
        due_date: new Date(),
      });
      setSavingNewTask(false);
      await __refreshData(`*/${editProject?.pid}/Bucket,Task,Checklist,Comment`);
      __successAlert();
    }
  };

  const __postTask = async (bucket, task) => {
    const payload = {
      title: task.name,
      planId: bucket.ms_pid,
      bucketId: bucket.ms_bid,
      startDateTime: sDate(task.start_date),
      dueDateTime: eDate(task.due_date),
      orderHint: " !",
    };
    if (editProject.in_planner === 1) {
      const result = await doPostGraphApi(graphConfig.postTask, payload);
      if (!result || result?.error) {
        return { error: `Something went wrong saving task: ${task.name}` };
      }
      task.ms_pid = bucket.ms_pid;
      task.ms_bid = bucket.ms_bid;
      task.ms_tid = result.id;
    }
    if (editProject.in_autotask === 1) {
      const payload = {
        projectID: editProject.at_pid,
        status: 1,
        taskType: 1,
        title: task.name,
        startDateTime: sDate(task.start_date),
        endDateTime: eDate(task.due_date),
      };
      const resPostTask = await axios.post(`${baseApiURL}/at/upsert/${portfolioSettings.pfid}`, {
        url: `/Projects/${payload.projectID}/Tasks`,
        payload: payload,
      });
      if (!resPostTask.data.result) {
        return { error: `Something went wrong saving task: ${task.name}` };
      } else {
        task.at_tid = resPostTask.data.itemId;
      }
    }

    task.pid = bucket.pid;
    task.bid = bucket.bid;
    task.tid = nanoid(6);
    task.assignments = "[]";
    task.start_date = toSqlDate(sDate(task.start_date));
    task.due_date = toSqlDate(eDate(task.due_date));
    task.status = 0;
    const saveResult = await __saveTask(task);
    if (saveResult?.error) {
      return { error: saveResult.error };
    }
    return saveResult;
  };

  const __saveTask = async (payload) => {
    console.log("saving task...");
    return axios
      .post(`${baseApiURL}/task/add`, payload)
      .then((res) => {
        return res.data;
      })
      .catch(function (error) {
        console.error(error);
        __toast("ERROR: Something went wrong while saving task.", null, null, 30);
        return { error: error };
      });
  };

  const __doEditProject = async (e) => {
    setSavingChanges(true);
    const payload = {
      pid: editProject.pid,
      updates: {
        name: newProjectName,
        company: newCompanyName,
        created_by: newProjectLead,
      },
    };
    if (editProject.in_planner === 1) {
      const msPlan = await __updateMsPlan({
        title: `${newCompanyName} - ${newProjectName}`,
      });
      if (msPlan?.error) {
        console.log(msPlan.error);
        __toast("Failed updating plan in Planner", null, null, 30);
        return;
      }
    }
    if (editProject.in_autotask === 1 && editProject.name !== newProjectName) {
      const payload = {
        id: editProject.at_pid,
        projectName: newProjectName,
      };
      const resPatchProject = await axios.patch(`${baseApiURL}/at/upsert/${portfolioSettings.pfid}`, { url: `/Projects/`, payload: payload });
      const patchedProject = resPatchProject.data;
      if (!patchedProject.result) {
        setSavingChanges(false);
        return {
          error: `Something went wrong updating the project in Autotask`,
        };
      } else {
        console.log("Autotask project updated...");
      }
    }
    if (editorRef.current) {
      payload.updates.description = editorRef.current.getContent();
    }
    await __apiUpdateProject(payload);
    await __refreshData(`*/${editProject?.pid}/User`);
    action(_updateDetails({ company: newCompanyName, name: newProjectName }));
    action(_setEditNameModal(false));
    setSavingChanges(false);
  };

  const __updateMsPlan = async (payload) => {
    console.log(payload);
    const resultGet = await doGetGraphApi(graphConfig.updatePlan.replace("[plan-id]", editProject.ms_pid));
    if (resultGet?.error) {
      return {
        error: `Something went wrong querying plan: ${editProject.ms_pid}`,
      };
    } else {
      const etag = resultGet["@odata.etag"];
      console.log("etag found updating plan...", payload);
      const resultPatch = await doPatchGraphApi(graphConfig.updatePlan.replace("[plan-id]", editProject.ms_pid), payload, etag);
      if (resultPatch?.error) {
        return { error: `Something went updating plan: ${editProject.ms_pid}` };
      }
    }
    return true;
  };

  const __handleTaskStart = async (date) => {
    if (toDigitDate(date) < toDigitDate(editProject.start_date) || toDigitDate(date) > toDigitDate(editProject.end_date)) {
      __toast("Due date is beyond project timeline", null, null, 30);
      return;
    }
    if (toDigitDate(date) > toDigitDate(newTaskData.due_date)) {
      setNewTaskData({ ...newTaskData, start_date: date, due_date: date });
    } else {
      setNewTaskData({ ...newTaskData, start_date: date });
    }
  };

  const __handleTaskDue = async (date) => {
    if (new Date(toDate(date)) < new Date(toDate(newTaskData.start_date))) {
      __toast("Due date is lesser than start date", null, null, 30);
      return;
    }
    if (new Date(toDate(date)) < new Date(toDate(editProject.start_date)) || new Date(toDate(date)) > new Date(toDate(editProject.end_date))) {
      __toast("Due date is beyond project timeline", null, null, 30);
      return;
    }
    setNewTaskData({ ...newTaskData, due_date: date });
  };

  const __editFinance = () => {
    localStorage.setItem("viewEdit", "edit");
    setTimeout(() => {
      history.push("/members/projects/finance");
    }, 100);
  };



  function _handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(taskOrder);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem); 
    _updateTaskOrder(items);
    const newItemsIndex = items.map((task,index) => {
      return {...task, index}
    })
    action(_updateTaskIndex(newItemsIndex));
  }

  const _updateTaskOrder = async (items) => {
      try {
        await axios.post(`${baseApiURL}/task/updateTaskIndex`, {items})
      } catch (error) {
        __toast("ERROR: Something went wrong while updating task index.", null, null, 30);
      }
  }

  return (
    <div className="main-content p-0 m-0">
      <div>
        <ProjectDetails
          editProject={editProject}
          doEditProject={__editProject}
          canEditProject={can("Edit Project Details")}
        />
        <div className="content-details-row">
          <div
            className="content-details-col"
            style={{ ...mW(30, 35) }}
          >
            <Row className="mb-3">
              <Col>
                <div className="txt-lg mt-1">PROJECT TASKS</div>
              </Col>
              <Col>
                <DefaultButton
                  className="float-right p-0"
                  style={{ boxShadow: theme.effects.elevation4 }}
                  text="New Task"
                  title="Add a new task"
                  disabled={editProject?.project_status === 0 || !can("Add Project Tasks")}
                  onClick={() => action(_setNewTaskModal(true))}
                  allowDisabledFocus
                />
              </Col>
            </Row>
            {isLoading ? (
              <div className="task-box">
                <TaskLoader />
              </div>
            ) : (
              <DragDropContext onDragEnd={_handleOnDragEnd}>
                <Droppable droppableId="tasks">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                    >
                      {taskOrder?.map((data, index) => (
                        <Draggable
                          key={data.id}
                          draggableId={data.id.toString()}
                          index={index}
                        >
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                              <ProjectTask
                               
                                task={data}
                                members={portfolioMembers}
                                portfolioSettings={portfolioSettings}
                                project={editProject}
                                user={user}
                                changeProgress={__changeProjectProgress}
                                refreshData={__refreshData}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </div>
          <div className="content-details-col">
            <div className="content-details-row p-0">
              <div className="content-details-col p-0 pr-3">
                <div className="txt-lg mb-3">
                  <span className="mr-3">PROJECT STATE</span>
                  <Switch
                    onChange={__closeOpenProject}
                    checked={editProject?.project_status === 1 ? true : false}
                    disabled={!can("Edit Project State")}
                    uncheckedIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          fontSize: 15,
                          color: "#dfdfdf",
                          paddingRight: "1.5em",
                          paddingBottom: "0.2em",
                        }}
                      >
                        Closed
                      </div>
                    }
                    checkedIcon={
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          fontSize: 15,
                          color: "white",
                          paddingLeft: "1.5em",
                          paddingBottom: "0.2em",
                        }}
                      >
                        Open
                      </div>
                    }
                    offColor="#9e9e9e"
                    onColor="#2E80C6"
                    height={30}
                    width={90}
                  />
                </div>
                <div
                  className="details-summary-box"
                  style={{ ...mW(20, 20) }}
                >
                  <div className="content-details-row mb-2 p-0">
                    <div className="content-details-col p-0 txt-lg">New</div>
                    <div className="content-details-col p-0">
                      <input
                        type="radio"
                        name="project_progress"
                        disabled={!can("Edit Project Progress")}
                        value={1}
                        onChange={__changeProjectProgress}
                        checked={editProject?.project_progress === 1 ? true : false}
                      />
                    </div>
                  </div>
                  <div className="content-details-row mb-2 p-0">
                    <div className="content-details-col p-0 txt-lg">In Progress</div>
                    <div className="content-details-col p-0">
                      <input
                        type="radio"
                        name="project_progress"
                        disabled={!can("Edit Project Progress")}
                        value={2}
                        onChange={__changeProjectProgress}
                        checked={editProject?.project_progress === 2 ? true : false}
                      />
                    </div>
                  </div>
                  <div className="content-details-row p-0">
                    <div className="content-details-col p-0 txt-lg">On Hold</div>
                    <div className="content-details-col p-0">
                      <input
                        type="radio"
                        name="project_progress"
                        disabled={!can("Edit Project Progress")}
                        value={3}
                        onChange={__changeProjectProgress}
                        checked={editProject?.project_progress === 3 ? true : false}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="content-details-col p-0">
                <div className="txt-lg mb-3">STATUS SUMMARY</div>
                <div
                  className="details-summary-box-clickable"
                  style={{ ...mW(20, 20) }}
                  title="Click to edit status summary"
                  onClick={__editStatusSummary}
                >
                  <div className="content-details-row mb-2 p-0">
                    <div
                      className="content-details-col p-0 txt-lg"
                      style={{ ...mW(3, 3) }}
                    >
                      Overall
                    </div>
                    <div
                      className="content-details-col p-0"
                      style={{ ...mW(8, 8) }}
                    >
                      <div className="content-summary-row">
                        <div className={`mt-1 mr-3 status-circle-${__getOverallStatus("color")}`}></div>
                        {__getOverallStatus()}
                      </div>
                    </div>
                  </div>
                  <div className="content-details-row mb-2 p-0">
                    <div
                      className="content-details-col p-0 txt-lg"
                      style={{ ...mW(3, 3) }}
                    >
                      Schedule
                    </div>
                    <div
                      className="content-details-col p-0"
                      style={{ ...mW(8, 8) }}
                    >
                      <div className="content-summary-row">
                        <div className={`mt-1 mr-3 status-circle-${__getStatus("color", editProject?.schedule_status)}`}></div>
                        {__getStatus("txt", editProject?.schedule_status)}
                      </div>
                    </div>
                  </div>
                  <div className="content-details-row mb-2 p-0">
                    <div
                      className="content-details-col p-0 txt-lg"
                      style={{ ...mW(3, 3) }}
                    >
                      Scope
                    </div>
                    <div
                      className="content-details-col p-0"
                      style={{ ...mW(8, 8) }}
                    >
                      <div className="content-summary-row">
                        <div className={`mt-1 mr-3 float-left status-circle-${__getStatus("color", editProject?.scope_status)}`}></div>
                        {__getStatus("txt", editProject?.scope_status)}
                      </div>
                    </div>
                  </div>
                  <div className="content-details-row mb-2 p-0">
                    <div
                      className="content-details-col p-0 txt-lg"
                      style={{ ...mW(3, 3) }}
                    >
                      Budget
                    </div>
                    <div
                      className="content-details-col p-0"
                      style={{ ...mW(8, 8) }}
                    >
                      <div className="content-summary-row">
                        <div className={`mt-1 mr-3 status-circle-${__getStatus("color", editProject?.budget_status)}`}></div>
                        {__getStatus("txt", editProject?.budget_status)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-details-row p-0 mt-3">
              <div className="content-details-col p-0">
                <div className="txt-lg mb-3">
                  PROJECT UPDATES
                  {can("Delete Projects") && (
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      className="float-right"
                      title="Delete this project"
                      onClick={__confirmDeletingProject}
                    >
                      <FluentIcon name="Delete" />
                    </Button>
                  )}
                  <DefaultButton
                    className="float-right p-0 mr-2"
                    style={{ boxShadow: theme.effects.elevation4 }}
                    text="Goto Finance"
                    title="Edit Project Finance"
                    disabled={editProject?.project_status === 0}
                    onClick={__editFinance}
                    allowDisabledFocus
                  />
                </div>
                <div
                  className={editProject?.comments?.length > 0 ? "content-project-updates bg-white" : "content-project-updates"}
                  style={{ ...mH(20, 30) }}
                >
                  {editProject?.comments?.length > 0 ? (
                    <div className="task-box-comments">
                      {comments
                        ?.sort(function (a, b) {
                          return new Date(b.createdAt) - new Date(a.createdAt);
                        })
                        .map((data, index) => (
                          <div
                            className="task-comment-main mb-3"
                            key={index}
                          >
                            <div className="task-comment-photo">
                              <Image
                                src={JSON.parse(data.user_data).photo}
                                roundedCircle
                                fluid
                              />
                            </div>
                            <div className="task-comment-body">
                              <div className="task-comment-user">
                                <div>{JSON.parse(data.user_data).displayName}</div>
                                <div>{toFullDateTime(data.createdAt)}</div>
                              </div>
                              <div className="task-comment-text">{data.comment}</div>
                            </div>
                          </div>
                        ))}
                    </div>
                  ) : (
                    <div className="ml-3 font-italic">No updates yet...</div>
                  )}
                </div>
              </div>
            </div>
            <div className="content-details-row p-0 mt-3">
              <div className="content-details-col p-0">
                <div className="txt-lg mb-3">
                  <DefaultButton
                    className="btn-default float-right"
                    disabled={!can("Add Project Timesheet")}
                    style={{ boxShadow: theme.effects.elevation4 }}
                    text="Add Timesheet"
                    allowDisabledFocus
                    onClick={__addTimesheet}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modals */}
        {/* Status Summary Modal */}
        <Modal
          show={openStatusSummary}
          backdrop="static"
          keyboard={false}
          size="lg"
          centered
        >
          <Modal.Header>
            <Modal.Title>Edit Status Summary</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="content-details-row p-0">
              {/* Schedule section */}
              <div
                className="details-summary-box"
                style={{ ...mW(15, 15) }}
              >
                <div className="txt-lg mb-3">SCHEDULE STATUS</div>
                <div className="content-details-row mb-2 p-0 pl-3">
                  <div className="content-details-col p-0">On Track</div>
                  <div className="content-details-col p-0">
                    <input
                      type="radio"
                      name="schedule_status"
                      value={1}
                      onChange={__changeStatusSummary}
                      checked={statusSummary?.schedule_status === 1 ? true : false}
                    />
                  </div>
                </div>
                <div className="content-details-row mb-2 p-0 pl-3">
                  <div className="content-details-col p-0">At Risk</div>
                  <div className="content-details-col p-0">
                    <input
                      type="radio"
                      name="schedule_status"
                      value={2}
                      onChange={__changeStatusSummary}
                      checked={statusSummary?.schedule_status === 2 ? true : false}
                    />
                  </div>
                </div>
                <div className="content-details-row p-0 pl-3">
                  <div className="content-details-col p-0">Off Track</div>
                  <div className="content-details-col p-0">
                    <input
                      type="radio"
                      name="schedule_status"
                      value={3}
                      onChange={__changeStatusSummary}
                      checked={statusSummary?.schedule_status === 3 ? true : false}
                    />
                  </div>
                </div>
              </div>
              {/* Scope section */}
              <div
                className="details-summary-box"
                style={{ ...mW(15, 15) }}
              >
                <div className="txt-lg mb-3">SCOPE STATUS</div>
                <div className="content-details-row mb-2 p-0 pl-3">
                  <div className="content-details-col p-0">On Track</div>
                  <div className="content-details-col p-0">
                    <input
                      type="radio"
                      name="scope_status"
                      value={1}
                      onChange={__changeStatusSummary}
                      checked={statusSummary?.scope_status === 1 ? true : false}
                    />
                  </div>
                </div>
                <div className="content-details-row mb-2 p-0 pl-3">
                  <div className="content-details-col p-0">At Risk</div>
                  <div className="content-details-col p-0">
                    <input
                      type="radio"
                      name="scope_status"
                      value={2}
                      onChange={__changeStatusSummary}
                      checked={statusSummary?.scope_status === 2 ? true : false}
                    />
                  </div>
                </div>
                <div className="content-details-row p-0 pl-3">
                  <div className="content-details-col p-0">Off Track</div>
                  <div className="content-details-col p-0">
                    <input
                      type="radio"
                      name="scope_status"
                      value={3}
                      onChange={__changeStatusSummary}
                      checked={statusSummary?.scope_status === 3 ? true : false}
                    />
                  </div>
                </div>
              </div>
              {/* Budget section */}
              <div
                className="details-summary-box"
                style={{ ...mW(15, 15) }}
              >
                <div className="txt-lg mb-3">BUDGET STATUS</div>
                <div className="content-details-row mb-2 p-0 pl-3">
                  <div className="content-details-col p-0">On Track</div>
                  <div className="content-details-col p-0">
                    <input
                      type="radio"
                      name="budget_status"
                      value={1}
                      onChange={__changeStatusSummary}
                      checked={statusSummary?.budget_status === 1 ? true : false}
                    />
                  </div>
                </div>
                <div className="content-details-row mb-2 p-0 pl-3">
                  <div className="content-details-col p-0">At Risk</div>
                  <div className="content-details-col p-0">
                    <input
                      type="radio"
                      name="budget_status"
                      value={2}
                      onChange={__changeStatusSummary}
                      checked={statusSummary?.budget_status === 2 ? true : false}
                    />
                  </div>
                </div>
                <div className="content-details-row p-0 pl-3">
                  <div className="content-details-col p-0">Off Track</div>
                  <div className="content-details-col p-0">
                    <input
                      type="radio"
                      name="budget_status"
                      value={3}
                      onChange={__changeStatusSummary}
                      checked={statusSummary?.budget_status === 3 ? true : false}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={__editStatusSummary}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Timesheet Modal */}
        <Modal
          show={openTimesheet}
          backdrop="static"
          keyboard={false}
          size="lg"
          centered
        >
          <Modal.Header>
            <Modal.Title>Add Timesheet</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col className="mr-3">
                <Form.Row className="mb-3">
                  <Form.Label
                    className="pt-2 txt-lg"
                    column="sm"
                  >
                    PROJECT NAME
                  </Form.Label>
                  <Form.Control
                    className="ml-3"
                    size="md"
                    type="text"
                    placeholder=""
                    value={editProject?.name}
                    readOnly
                    required
                  />
                </Form.Row>
                <Form.Row>
                  <Form.Label
                    className="pt-2 txt-lg"
                    column="sm"
                  >
                    BUDGETED HOURS
                  </Form.Label>
                </Form.Row>
                <Form.Row className="mb-3">
                  <Form.Control
                    style={{ ...mW(6, 6) }}
                    className="ml-3"
                    size="md"
                    type="text"
                    placeholder=""
                    value={editProject?.forecast_hours}
                    readOnly
                    required
                  />
                </Form.Row>
                <Form.Row>
                  <Form.Label
                    className="pt-2 txt-lg"
                    column="sm"
                  >
                    REMAINING HOURS
                  </Form.Label>
                </Form.Row>
                <Form.Row className="mb-3">
                  <Form.Control
                    style={{ ...mW(6, 6) }}
                    className="ml-3"
                    size="md"
                    type="text"
                    placeholder=""
                    value={editProject?.effort_remaining}
                    readOnly
                    required
                  />
                </Form.Row>
                {editProject.in_autotask === 1 && (
                  <>
                    <Form.Row className="mb-0">
                      <Form.Label
                        className="pt-2 txt-lg"
                        column="sm"
                      >
                        USER RESOURCE (Autotask)
                      </Form.Label>
                    </Form.Row>
                    <Select
                      options={atResources}
                      value={atResources?.filter((data) => data.id === atSelectedResource)}
                      placeholder={"Select your Autotask account..."}
                      isDisabled={false}
                      getOptionLabel={(e) => `${e.userName} (${e.email ?? "No Email"})`}
                      getOptionValue={(e) => e.id}
                      onChange={(e) => setAtSelectedResource(e.id)}
                    />
                    <Form.Row className="mb-0">
                      <Form.Label
                        className="pt-2 txt-lg"
                        column="sm"
                      >
                        USER ROLE (Autotask)
                      </Form.Label>
                    </Form.Row>
                    <Select
                      options={atRoles}
                      value={atRoles?.filter((data) => data.id === atSelectedRole)}
                      placeholder={"Select your Autotask role/rate..."}
                      isDisabled={false}
                      getOptionLabel={(e) => `${e.name} (${e.hourlyRate})`}
                      getOptionValue={(e) => e.id}
                      onChange={(e) => setAtSelectedRole(e.id)}
                    />
                  </>
                )}
              </Col>
              <Col className="mr-3">
                <Form.Row className="mb-0">
                  <Form.Label
                    className="pt-2 txt-lg"
                    column="sm"
                  >
                    RELATED TASK
                  </Form.Label>
                </Form.Row>
                <Select
                  options={editProject?.tasks}
                  value={editProject?.tasks?.filter((data) => data.tid === taskToUpdate)}
                  placeholder={"Select the related task..."}
                  isDisabled={false}
                  getOptionLabel={(e) => e.name}
                  getOptionValue={(e) => e.tid}
                  onChange={(e) => {
                    setTaskToUpdate(e.tid);
                    setAtTaskId(e.at_tid);
                  }}
                />
                <Form.Row className="mb-3">
                  <Form.Label
                    className="pt-2 txt-lg"
                    column="sm"
                  >
                    DESCRIPTION
                  </Form.Label>
                  <Form.Control
                    size="md"
                    as="textarea"
                    rows={5}
                    value={timesheet?.description}
                    onChange={(e) => __changeTimesheet("description", e.target.value)}
                    required
                  />
                  <small>Characters remaining: {timesheet?.desc_remaining}</small>
                </Form.Row>
                <Form.Check
                  type="checkbox"
                  className="mt-1"
                  checked={addAsUpdate}
                  onChange={() => setAddAsUpdate(!addAsUpdate)}
                  label="Also update task by adding this as a comment"
                />
                <Row>
                  <Col>
                    <Form.Row>
                      <Form.Label
                        className="pt-2 txt-lg"
                        column="sm"
                      >
                        DATE WORKED
                      </Form.Label>
                    </Form.Row>
                    <Form.Row>
                      <Col style={{ ...mW(0, 10) }}>
                        <DatePicker
                          value={timesheet.date_worked ?? ""}
                          onChange={(date) => __changeTimesheet("date_worked", date)}
                          dateFormat={"dd-MMM-yyyy"}
                          dateClassName="txt-lg"
                        />
                        <div className="i-inside2">
                          <FluentIcon name="Calendar" />
                        </div>
                        {timesheet.date_worked && new Date(timesheet.date_worked) > new Date() && <small style={{ fontStyle: "italic", color: "red" }}>Date is from the FUTURE</small>}
                      </Col>
                    </Form.Row>
                  </Col>
                  <Col>
                    <Form.Row>
                      <Form.Label
                        className="pt-2 txt-lg"
                        column="sm"
                      >
                        HOURS WORKED
                      </Form.Label>
                    </Form.Row>
                    <Form.Row className="mb-3">
                      <Form.Control
                        style={{ ...mW(6, 6) }}
                        className="ml-3"
                        size="md"
                        type="number"
                        name="hours_worked"
                        placeholder=""
                        value={timesheet.hours_worked ?? ""}
                        onChange={(e) => __changeTimesheet("hours_worked", e.target.value)}
                        required
                      />
                    </Form.Row>
                  </Col>
                </Row>
                {editProject.in_autotask === 1 && (
                  <Form.Check
                    type="checkbox"
                    className="mt-1"
                    checked={showOnInvoice}
                    onChange={() => setShowOnInvoice(!showOnInvoice)}
                    label="Show on invoice (Autotask)"
                  />
                )}
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              disabled={savingChanges}
              onClick={() => {
                action(_setTimesheetModal(false));
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={savingChanges}
              onClick={__saveTimesheet}
            >
              {savingChanges ? "Saving..." : "Save"}
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Edit Project Name Modal */}
        <Modal
          show={editNameModal}
          backdrop="static"
          keyboard={false}
          size="lg"
          centered
        >
          <Modal.Header>
            <Modal.Title>Edit Project</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Row className="mb-3">
              <Form.Label
                className="pt-2 txt-lg"
                column="sm"
              >
                COMPANY NAME
              </Form.Label>
              <Form.Control
                className="ml-3"
                size="md"
                type="text"
                placeholder=""
                value={newCompanyName}
                onChange={__changeCompanyName}
                readOnly={editProject.in_autotask}
                required
              />
            </Form.Row>
            <Form.Row className="mb-3">
              <Form.Label
                className="pt-2 txt-lg"
                column="sm"
              >
                PROJECT NAME
              </Form.Label>
              <Form.Control
                className="ml-3"
                size="md"
                type="text"
                placeholder=""
                value={newProjectName}
                onChange={__changeProjectName}
                required
              />
            </Form.Row>
            <Form.Row>
              <Form.Label
                className="pt-2 txt-lg"
                column="sm"
              >
                PROJECT DESCRIPTION
              </Form.Label>
              {/* <Editor value={projectDesc} onChange={(e) => setProjectDesc(e)} /> */}
            </Form.Row>
            <Form.Row className="mb-3">
              <div style={{ display: "flex", flex: 1, flexDirection: "column", justifyItems: "center" }}>
                {loadingEditor && (
                  <div style={{ alignSelf: "center" }}>
                    <Image
                      src={editorLoader}
                      fluid
                    />
                  </div>
                )}
                <Editor
                  apiKey="28judzb7gglya2ls2if1n6n4k8jy05wtvijkywght7rf993a"
                  onInit={(evt, editor) => {
                    editorRef.current = editor;
                    setLoadingEditor(false);
                  }}
                  initialValue={editProject.description}
                  init={{
                    height: 300,
                    menubar: false,
                    plugins: ["autolink", "lists", "link", "image", "searchreplace", "code", "table", "code", "wordcount"],
                    toolbar: "pastetext undo bold italic forecolor alignleft aligncenter alignright | bullist numlist outdent indent | removeformat",
                    content_style: "body { font-family:Arial,sans-serif; font-size:14px }",
                  }}
                />
              </div>
            </Form.Row>
            <Form.Row className="mb-0">
              <Form.Label
                className="pt-2 txt-lg"
                column="sm"
              >
                PROJECT LEAD
              </Form.Label>
            </Form.Row>
            <div
              className="ml-2"
              style={{ ...mW(0, 30) }}
            >
              <Select
                options={onboardedUsers}
                value={onboardedUsers?.filter((data) => data.id === newProjectLead)}
                placeholder={"Select Project Lead"}
                isDisabled={false}
                getOptionLabel={(e) => `${e.displayName ?? "NoName"} (${e.mail ?? "No Email"})`}
                getOptionValue={(e) => e.id}
                onChange={(e) => __changeProjectLead(e.id)}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              disabled={savingChanges}
              onClick={() => {
                action(_setEditNameModal(false));
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={savingChanges}
              onClick={__doEditProject}
            >
              {savingChanges ? "Saving..." : "Save"}
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Add New Task Modal */}
        <Modal
          show={newTaskModal}
          backdrop="static"
          keyboard={false}
          size="md"
          centered
        >
          <Modal.Header>
            <Modal.Title>Add A New Task</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Row className="mb-3">
              <Form.Label
                className="pt-2 txt-lg"
                column="sm"
              >
                Task Name
              </Form.Label>
              <Form.Control
                className="ml-3"
                size="md"
                type="text"
                placeholder=""
                value={newTaskData.name}
                onChange={__changeNewTaskName}
                maxLength="120"
                required
              />
              <small className="ml-3">Remaining: {120 - newTaskData.name.length}</small>
            </Form.Row>
            <Form.Row>
              <Form.Label
                column="sm"
                style={{ ...mW(4, 0) }}
              >
                <div className="float-right txt-sm-l">Start</div>
              </Form.Label>
              <Col style={{ ...mW(0, 7.5) }}>
                <DatePicker
                  selected={new Date(newTaskData.start_date)}
                  onChange={(date) => __handleTaskStart(date)}
                  dateFormat={"dd-MMM-yyyy"}
                  className="due-date mt-1 ml-3"
                />
              </Col>
              <Form.Label
                className="pt-1"
                column="sm"
                style={{ ...mW(0, 3) }}
              >
                <div className="float-right txt-sm-l">Due</div>
              </Form.Label>
              <Col
                style={{ ...mW(0, 6.3) }}
                className="p-0"
              >
                <DatePicker
                  selected={new Date(newTaskData.due_date)}
                  onChange={(date) => __handleTaskDue(date)}
                  dateFormat={"dd-MMM-yyyy"}
                  className="due-date mt-1 ml-3"
                />
              </Col>
              <div style={{ marginTop: "0.4em", marginLeft: "1.4em" }}>
                <FluentIcon name="CalendarAgenda" />
              </div>
            </Form.Row>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              disabled={savingNewTask}
              onClick={() => {
                action(_setNewTaskModal(false));
                setNewTaskData({
                  name: "",
                  start_date: new Date(),
                  due_date: new Date(),
                });
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={__doAddNewTask}
              disabled={newTaskData.name.length > 3 ? (savingNewTask ? true : false) : true}
            >
              {savingNewTask ? "Processing..." : "Save"}
            </Button>
          </Modal.Footer>
        </Modal>
        {/* Alerts and notification */}
        <div>
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </div>
    </div>
  );
}

export default DetailsProject;
