/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import { Jumbotron } from "react-bootstrap";
import { useSelector } from "react-redux";
import ReactJson from "react-json-view";
import portfolio from "../portfolio/getPortfolioData";
import "../../../assets/css/autotask.css";
import { baseApiURL, baseURL } from "../../../components/reusableFunctions";
import axios from "axios";
import CrossDomainPopup from "../../../components/CrossDomainPopup";

function ContentDev() {
  const isMounted = useRef(false);
  const portfolioSettings = useSelector((state) => state.portfolioSettings);
  const [url, setUrl] = useState("");
  const [state, setState] = useState("");
  const [clientId, setClientId] = useState("09BDED5515F3412E930E4BB34A6F734E");
  const [clientSecret, setClientSecret] = useState("nBtVSaP_h1J_hWwC1zkRGt6Sx3vMTf2IsBJqVSTootKp8MCu");
  const [scope, setScope] = useState("email profile openid accounting.settings accounting.transactions accounting.journals.read accounting.contacts offline_access accounting.settings");
  const [tokens, setTokens] = useState({});
  const [redirectUri] = useState(`${baseURL}/oauth/callback`);
  const [cons, setCons] = useState([]);
  const [currentData, setCurrentData] = useState({});

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      setState(portfolioSettings.pfid);
    }
  }, [portfolioSettings]);

  useEffect(() => {
    if (isMounted.current && tokens.access_token) {
      __xeroGetConnections();
    }
  }, [tokens]);

  const onCode = async (code, params) => {
    console.log("wooooo a code", code);
    console.log("alright! the URLSearchParams interface from the popup url", params);
    await __exchangeCodeToTokens(code);
  };

  const onClose = (type) => {
    console.log("closed!", type);
  };

  // const __getCodeFromProxy = async () => {
  //   const res = await fetch(`${baseApiURL}/xero/list/${portfolioSettings.pfid}/${state}`);
  //   const codes = await res.json();
  //   console.log(codes);
  //   setCodeFromProxy(codes);
  // };

  const __exchangeCodeToTokens = async (code) => {
    const payload = {
      url: `https://identity.xero.com/connect/token`,
      headers: {
        Authorization: "Basic " + Buffer.from(`${clientId}:${clientSecret}`).toString("base64"),
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: {
        grant_type: "authorization_code",
        code: code,
        redirect_uri: redirectUri,
      },
    };

    try {
      const result = await axios.post(`${baseApiURL}/xero/post`, { data: payload });
      const tokens = await result.data;
      setTokens(tokens);
    } catch (err) {
      console.log(err);
    }
  };

  const __refreshTokens = async () => {
    const payload = {
      url: `https://identity.xero.com/connect/token`,
      headers: {
        Authorization: "Basic " + Buffer.from(`${clientId}:${clientSecret}`).toString("base64"),
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: {
        grant_type: "refresh_token",
        refresh_token: tokens.refresh_token,
      },
    };

    try {
      const result = await axios.post(`${baseApiURL}/xero/post`, { data: payload });
      const tokens = await result.data;
      setTokens(tokens);
    } catch (err) {
      console.log(err);
    }
  };

  const __xeroGetConnections = async () => {
    const payload = {
      url: "https://api.xero.com/connections",
      headers: {
        Authorization: "Bearer " + tokens.access_token,
        "Content-Type": "application/json",
      },
    };

    try {
      const result = await axios.post(`${baseApiURL}/xero/get`, { data: payload });
      const cons = await result.data;
      setCons(cons);
      await __xeroDisconnect();
    } catch (err) {
      console.log(err);
    }
  };

  const __xeroDisconnect = async () => {
    const payload = {};
    const updates = {};
    payload.pfid = portfolioSettings.pfid;
    updates.xero_initialized = false;
    payload.updates = updates;
    console.log(payload);
    const data = await portfolio.saveSettings(payload);
    if (!data || data?.error) {
      alert("Something went wrong!");
    } else {
      axios.get(`${baseApiURL}/xero/stop-cron/${portfolioSettings.pfid}`).then((data) => console.log(data));
    }
  };

  const __xeroApiQuery = async () => {
    if (!cons.length > 0) {
      console.log("No tenants found");
      return;
    }

    const payload = {
      url: url,
      headers: {
        Authorization: "Bearer " + tokens.access_token,
        Accept: "application/json",
        "Xero-tenant-id": cons[0].tenantId,
      },
    };

    try {
      const result = await axios.post(`${baseApiURL}/xero/get`, { data: payload });
      const data = await result.data;
      console.log(data);
      setCurrentData(data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="main-content">
      <Jumbotron>
        <div>
          <h4>Xero - Dev</h4>
          <div className="p-3" style={{ border: "1px solid #3d3d3d" }}>
            <div className="p-2">
              Client_Id: <input type="text" size="50" value={clientId} onChange={(e) => setClientId(e.target.value)} /> Client_Secret: <input type="text" size="50" value={clientSecret} onChange={(e) => setClientSecret(e.target.value)} />
            </div>
            <div className="p-2">
              Scope: <input type="text" size="80" value={scope} onChange={(e) => setScope(e.target.value)} />
            </div>
            <div className="p-2">
              redirect_uri: <input type="text" readOnly size="50" value={redirectUri} />
            </div>
            {/* <div className="p-2">
              State: <input type="text" size="50" value={state} onChange={(e) => setState(e.target.value)} />
            </div> */}
            <div style={{ flexDirection: "row", display: "flex" }}>
              <div style={{ width: 100, padding: 10, margin: 0, flexShrink: 1 }}>
                <CrossDomainPopup url={`https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&state=${state}`} onCode={onCode} onClose={onClose}>
                  <button type="button" className="btn btn-primary">
                    Authorize
                  </button>
                </CrossDomainPopup>
              </div>
            </div>
            {/*
            <hr />
            <div style={{ flexDirection: "row", display: "flex" }}>
              <div style={{ padding: 10, margin: 0, flexShrink: 1 }}>
                <button type="button" className="btn btn-info" onClick={__getCodeFromProxy}>
                  Get Code from Proxy
                </button>
              </div>
            </div>
            Code from Proxy:
            <div className="at-result-div at-result">
              <ReactJson src={codeFromProxy} theme="chalk" displayDataTypes={false} />
            </div>
            <hr />
            <div style={{ flexDirection: "row", display: "flex" }}>
              <div style={{ padding: 10, margin: 0, flexShrink: 1 }}>
                <button type="button" className="btn btn-success" onClick={__exchangeCodeToTokens}>
                  Exchange Code for Tokens in Xero
                </button>
              </div>
            </div>
            Tokens from Xero:
            <div className="at-result-div at-result">
              <ReactJson src={tokens} theme="chalk" displayDataTypes={false} />
            </div>
            <hr />
            <div style={{ flexDirection: "row", display: "flex" }}>
              <div style={{ padding: 10, margin: 0, flexShrink: 1 }}>
                <button type="button" className="btn btn-success" onClick={__xeroGetConnections}>
                  Get Xero Tenants (connections)
                </button>
              </div>
            </div>
            <div className="p-2">
              Xero Connections URL: <input type="text" size="80" value={consUrl} onChange={(e) => setConsUrl(e.target.value)} />
            </div>
            Tenants from Xero:
            <div className="at-result-div at-result">
              <ReactJson src={cons} theme="chalk" displayDataTypes={false} />
            </div> */}
            <hr />
            <h4>Test XERO API</h4>
            <div className="p-2">
              Api URL: <input type="text" size="100" value={url} onChange={(e) => setUrl(e.target.value)} />
            </div>
            <div style={{ flexDirection: "row", display: "flex" }}>
              <div style={{ padding: 10, margin: 0, flexShrink: 1 }}>
                <button type="button" className="btn btn-primary" disabled={!tokens.access_token} onClick={__xeroApiQuery}>
                  {tokens.access_token ? "Query XERO API" : "Authorize to Xero First"}
                </button>
              </div>
              <div style={{ padding: 10, margin: 0, flexShrink: 1 }}>
                <button type="button" className="btn btn-warning" onClick={__refreshTokens}>
                  Refresh Tokens
                </button>
              </div>
            </div>
            Data:
            <div className="at-result-div at-result">
              <ReactJson src={currentData} theme="chalk" displayDataTypes={false} />
            </div>
          </div>
        </div>
      </Jumbotron>
    </div>
  );
}

export default ContentDev;
