export const authReducer = (state = false, action) => {
  switch (action.type) {
    case "LOG_IN":
      return true;
    case "LOG_OUT":
      return false;
    default:
      return state;
  }
};

export const authMethodReducer = (state = false, action) => {
  switch (action.type) {
    case "AUTH_METHOD":
      return action.payload;
    default:
      return state;
  }
};

export const userInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case "SAVE_USER":
      return action.payload;
    default:
      return state;
  }
};

export const hasAuthReducer = (state = false, action) => {
  switch (action.type) {
    case "HAS_AUTH":
      return action.payload;
    default:
      return state;
  }
};
