import axios from "axios";
import { baseApiURL } from "./reusableFunctions";

export const _rbac = {};

_rbac.getPermissions = async () => {
  return axios
    .get(`${baseApiURL}/rbac/permissions`)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      return { error: error };
    });
};

_rbac.getUserRolesPermissions = async (userId) => {
  return axios
    .get(`${baseApiURL}/rbac/user/${userId}/permissions`)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      return { error: error };
    });
};

_rbac.getPortfolioRoles = async (pf_id) => {
  return axios
    .get(`${baseApiURL}/rbac/roles/${pf_id}`)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      return { error: error };
    });
};

_rbac.createPortfolioRole = async (payload) => {
  return axios
    .post(`${baseApiURL}/rbac/role`, payload)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      return { error: error };
    });
};

_rbac.deletePortfolioRole = async (role_id) => {
  return axios
    .delete(`${baseApiURL}/rbac/role/${role_id}`)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      return { error: error };
    });
};

_rbac.getPortfolioRolePermissions = async (role_id) => {
  return axios
    .get(`${baseApiURL}/rbac/role/${role_id}/permissions`)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      return { error: error };
    });
};

_rbac.addPortfolioRolePermission = async (payload) => {
  return axios
    .post(`${baseApiURL}/rbac/role/permission`, payload)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      return { error: error };
    });
};

_rbac.deletePortfolioRolePermission = async (role_id, perm_id) => {
  return axios
    .delete(`${baseApiURL}/rbac/role/${role_id}/permission/${perm_id}`)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      return { error: error };
    });
};

_rbac.addUserRole = async (payload) => {
  return axios
    .post(`${baseApiURL}/rbac/user/role`, payload)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      return { error: error };
    });
};

_rbac.addUserPermission = async (payload) => {
  return axios
    .post(`${baseApiURL}/rbac/user/permission`, payload)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      return { error: error };
    });
};

_rbac.deleteUserRole = async (user_id, rp_id) => {
  return axios
    .delete(`${baseApiURL}/rbac/user/${user_id}/role/${rp_id}`)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      return { error: error };
    });
};

_rbac.deleteUserPermission = async (user_id, rp_id) => {
  return axios
    .delete(`${baseApiURL}/rbac/user/${user_id}/permission/${rp_id}`)
    .then((res) => {
      return res.data;
    })
    .catch(function (error) {
      return { error: error };
    });
};
