export const editProjectReducer = (state = null, action) => {
    switch (action.type) {
      case "LOAD_DETAILS":
        return { ...action.payload };
      case "UPDATE_DETAILS":
        return {
          ...state,
          ...action.payload,
        };
      case "UPDATE_START_DATE":
        return {
          ...state,
          start_date: action.payload,
        };
      case "UPDATE_END_DATE":
        return {
          ...state,
          end_date: action.payload,
        };
      case "SET_MONTH_RANGE":
        return {
          ...state,
          month_range: action.payload,
        };
      case "RESET_BURNDOWN":
        return {
          ...state,
          forecast_burndowns: [action.payload],
        };
      case "ADD_BURNDOWN":
        return {
          ...state,
          forecast_burndowns: [...state.forecast_burndowns, action.payload],
        };
      case "UPDATE_BURNDOWN":
        return {
          ...state,
          forecast_burndowns: state.forecast_burndowns.map((data) => {
            if (data.fbid !== action.payload.fbid) {
              return data;
            }
            return {
              ...data,
              ...action.payload,
            };
          }),
        };
      case "ADD_BUCKET":
        return {
          ...state,
          buckets: [...state.buckets, action.payload],
        };
      case "UPDATE_BUCKET":
        return {
          ...state,
          buckets: state.buckets.map((data) => {
            if (data.bid !== action.payload.bid) {
              return data;
            }
            return {
              ...data,
              name: action.payload.name,
            };
          }),
        };
      case "DEL_BUCKET":
        return {
          ...state,
          buckets: [...state.buckets.filter((data) => data.bid !== action.payload.bid)],
          tasks: [
            ...state.tasks.filter((data) => {
              if (data.bid === action.payload.bid) {
                return "";
              }
              return data;
            }),
          ],
          checklists: [
            ...state.checklists.filter((data) => {
              if (data.bid === action.payload.bid) {
                return "";
              }
              return data;
            }),
          ],
        };
      case "ADD_TASK":
        return {
          ...state,
          tasks: [...state.tasks, action.payload],
        };
      case "UPDATE_TASK":
        return {
          ...state,
          tasks: state.tasks.map((data) => {
            if (data.tid === action.payload.tid && data.bid === action.payload.bid) {
              return {
                ...data,
                ...action.payload,
              };
            }
            return data;
          }),
        };
      case "UPDATE_TASK_INDEX":
        state = {
          ...state,
          tasks: action.payload,
        };
        return state;
      case "DEL_TASK":
        return {
          ...state,
          tasks: [
            ...state.tasks.filter((data) => {
              if (data.bid === action.payload.bid && data.tid === action.payload.tid) {
                return "";
              }
              return data;
            }),
          ],
          checklists: [
            ...state.checklists.filter((data) => {
              if (data.bid === action.payload.bid && data.tid === action.payload.tid) {
                return "";
              }
              return data;
            }),
          ],
        };
      case "ADD_CHECKLIST":
        return {
          ...state,
          checklists: [...state.checklists, action.payload],
        };

      case "DEL_CHECKLIST":
        return {
          ...state,
          checklists: [
            ...state.checklists.filter((data) => {
              if (data.pid === action.payload.pid && data.tid === action.payload.tid && data.cid === action.payload.cid) {
                return "";
              }
              return data;
            }),
          ],
        };
      //this section has been updated for project edit
      case "UPDATE_CHECKLIST":
        return {
          ...state,
          checklists: state.checklists.map((data) => {
            if (data.cid === action.payload.cid && data.tid === action.payload.tid && data.pid === action.payload.pid) {
              return {
                ...data,
                name: action.payload.name,
                status: action.payload.status,
              };
            }
            return data;
          }),
        };
      case "RELOAD_TIMESHEETS":
        return {
          ...state,
          timesheets: action.payload,
        };
      case "UPDATE_EFFORT_SPENT":
        return {
          ...state,
          effort_remaining: state.effort_remaining - action.payload,
          effort_spent: action.payload,
        };
      default:
        return state;
    }
};

const initTaskAssignmentEdit = {
    newAssignees: [],
    oldAssignees: [],
};

export const epTaskEdit = (state = initTaskAssignmentEdit, action) => {
    switch (action.type) {
        case "LOAD_EP_TASK_NEWASSIGNEE":
            return { ...state, newAssignees: action.payload };
        case "LOAD_EP_TASK_OLDASSIGNEE":
            return { ...state, oldAssignees: action.payload };
        case "UPDATE_EP_TASK_NEWCOMMENT":
            return { ...state, newComment: action.payload };
        default:
            return state;
    }
};

const initTaskComments = {
    newComments: [],
    oldComments: [],
};

export const epTaskComments = (state = initTaskComments, action) => {
    switch (action.type) {
        case "LOAD_EP_TASK_NEWCOMMENTS":
            return { ...state, newComments: action.payload };
        case "UPDATE_EP_TASK_NEWCOMMENTS":
            return { ...state, newComments: [action.payload, ...state.newComments] };
        case "LOAD_EP_TASK_OLDCOMMENTS":
            return { ...state, oldComments: action.payload };
        default:
            return state;
    }
};

export const taskNewComment = (state = "", action) => {
    switch (action.type) {
        case "EP_TASK_NEWCOMMENT":
            return action.payload;
        default:
            return state;
    }
};

export const taskModal = (state = 0, action) => {
    switch (action.type) {
        case "EP_TASK_INMODAL":
            return action.payload;
        default:
            return state;
    }
};

export const timesheetModal = (state = false, action) => {
    switch (action.type) {
        case "EP_TIMESHEET_MODAL_OPEN":
            return action.payload;
        default:
            return state;
    }
};

export const editNameModal = (state = false, action) => {
    switch (action.type) {
        case "EP_EDITNAME_MODAL_OPEN":
            return action.payload;
        default:
            return state;
    }
};

export const newTaskModal = (state = false, action) => {
    switch (action.type) {
        case "EP_NEWTASK_MODAL_OPEN":
            return action.payload;
        default:
            return state;
    }
};
