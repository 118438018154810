// import { createStore } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import allReducers from "./reducers/allReducers";

const store = configureStore({
  reducer: allReducers,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }),
  ],
  devTools: process.env.NODE_ENV !== "production",
});

export default store;

// export const store = createStore(allReducers, nodeEnv !== "production" && typeof window !== "undefined" && window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
