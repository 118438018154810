import React from "react";
import ContentLoader from "react-content-loader";

export const ProjectLoader = (props) => (
    <ContentLoader speed={2} width={400} height={200} viewBox="0 0 400 200" backgroundColor="#f3f3f3" foregroundColor="#dbdbdb" {...props}>
        <circle cx="362" cy="36" r="22" />
        <rect x="9" y="23" rx="5" ry="5" width="220" height="29" />
        <circle cx="162" cy="82" r="10" />
        <rect x="204" y="78" rx="5" ry="5" width="187" height="13" />
        <rect x="8" y="79" rx="5" ry="5" width="120" height="12" />
        <rect x="7" y="115" rx="5" ry="5" width="187" height="13" />
        <rect x="7" y="149" rx="5" ry="5" width="187" height="13" />
        <rect x="8" y="184" rx="5" ry="5" width="187" height="13" />
        <rect x="205" y="115" rx="5" ry="5" width="187" height="13" />
        <rect x="205" y="149" rx="5" ry="5" width="187" height="13" />
        <rect x="207" y="184" rx="5" ry="5" width="187" height="13" />
    </ContentLoader>
);
