/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import "../../assets/css/contentProjects.css";
import ProjectBox from "./contentProjects/components/ProjectBox";
import { NavLink, useRouteMatch } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { toDate, baseApiURL, datePast, toFullDateTime } from "../../components/reusableFunctions";
import { _loadDetails } from "../../actions/editProjectActions";
import { useSelector, useDispatch } from "react-redux";
import { ProjectLoader } from "../private/contentProjects/components/ProjectLoader";
import portfolio from "./portfolio/getPortfolioData";
import { _setLoading } from "../../actions/commonActions";
import _portfolio from "../../actions/portfolioActions";
import axios from "axios";
import Swal from "sweetalert2";

function ContentProject(props) {
  const isMounted = useRef(false);
  const hasChecked = useRef(false);
  const hasInit = useRef(false);
  const portfolioSettings = useSelector((state) => state.portfolioSettings);
  const portfolioProjects = useSelector((state) => state.portfolioProjects);
  const portfolioMembers = useSelector((state) => state.portfolioMembers);
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const [searchStatus, setSearchStatus] = useState("all");
  const [searchTrack, setSearchTrack] = useState("all");
  const action = useDispatch();
  const { url } = useRouteMatch();
  const [lastSync, setLastSync] = useState("None");

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current && !hasInit.current) {
      async function initPf() {
        await __initPf();
      }
      initPf();
    }
  }, []);

  /* Start Code for Autotask Sync */
  useEffect(() => {
    if (isMounted.current && !hasChecked.current && hasInit.current && portfolioProjects.length > 0) {
      __checkSyncSettings();
    }
  }, [portfolioProjects]);

  const __checkSyncSettings = async () => {
    const lastChecked = await __getLastAtUpdate();
    setLastSync(lastChecked ?? "None");
  };

  const __getLastAtUpdate = async () => {
    return axios
      .get(`${baseApiURL}/portfolio/atLastUpdate/${portfolioSettings.pfid}`)
      .then((res) => {
        return res?.data?.at_lastUpdate;
      })
      .catch(function (error) {
        return null;
      });
  };

  const __initPf = async () => {
    console.log("getting portfolio projects...");
    const tmpProjects = await __getProjects();
    hasInit.current = true;
    action(_portfolio.loadProjects(tmpProjects));
    if (url === "/members/projects") {
      props.setModuleTitle("PROJECT MANAGEMENT");
    }
  };

  const __syncAtPortfolio = async () => {
    try {
      __loadingAlert();
      const resAtSync = await axios.get(`${baseApiURL}/at/forcesync/${portfolioSettings.pfid}`);
      console.log(resAtSync.data);
      if (resAtSync?.data?.result === true) {
        await __checkSyncSettings();
        __successAlert(resAtSync.data.message);
      } else {
        __errorAlert();
      }
    } catch (e) {
      console.log(e);
      __errorAlert();
    }
  };

  const __loadingAlert = () => {
    Swal.fire({
      title: "Syncing Autotask Data",
      html: `Please wait while we do our thing...`,
      timer: 0,
      timerProgressBar: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  };

  const __successAlert = (msg) => {
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Success!",
      text: msg,
      showConfirmButton: false,
      timer: 1500,
      didClose: () => {
        console.log("AT sync successful...");
      },
    });
  };

  const __errorAlert = () => {
    Swal.fire("Error", "Something went wrong. Please contact us!", "error");
  };

  /* End Code for Autotask Sync */

  const __getProjects = async () => {
    if (portfolioProjects?.length === 0) setLoading(true);
    const data = await portfolio.getData(portfolioSettings.ms_365_group, portfolioSettings.pfid);
    let tmpProjects = [];
    if (data?.error) {
      __toast("error", data.error, null, 2000, 25);
    } else {
      //if (!isEqual(portfolioProjects, data.projects) && _isMounted.current) {
      if (data.projects?.length > 0) {
        tmpProjects = JSON.parse(JSON.stringify(data.projects));
        for (const tmpProject of tmpProjects) {
          const origTmpProject = JSON.parse(JSON.stringify(tmpProject));
          const tmpMember = portfolioMembers?.filter((data) => data.id === tmpProject?.user?.msId)[0];
          if (tmpMember) {
            tmpProject.user.photo = tmpMember.photo;
            tmpProject.user.displayName = tmpMember.displayName;
            if (JSON.stringify(tmpProject) !== JSON.stringify(origTmpProject)) {
              const payload = { ms_id: tmpProject.user.msId, updates: tmpProject.user };
              await __updateUser(payload);
            }
          }
        }
      }
    }
    setLoading(false);
    return tmpProjects;
  };

  const __updateUser = async (payload) => {
    //console.log(payload);
    return axios
      .post(`${baseApiURL}/user/msal/update`, payload)
      .then((res) => {
        return res.data;
      })
      .catch(function (error) {
        return { error: error };
      });
  };

  const __toast = (type, msg, link, time = 2500, w = "30") => {
    const payload = {
      onClose: () => (link ? (window.location.href = link) : false),
      position: "top-center",
      autoClose: time,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: `toast-width-${w}`,
      progress: undefined,
    };
    if (type === "error") {
      return toast.error(msg, payload);
    }
    if (type === "info") {
      return toast.info(msg, payload);
    }
    if (type === "warning") {
      return toast.warning(msg, payload);
    }
    if (type === "success") {
      return toast.success(msg, payload);
    }
  };

  const __filterProjects = async () => {
    const tmpProjects = await __getProjects();
    let filteredProjects = tmpProjects.length > 0 ? tmpProjects : [];
    if (searchKey !== "") {
      filteredProjects = tmpProjects.filter((data) => data.company.toLowerCase().includes(searchKey.toLowerCase()) || data.name.toLowerCase().includes(searchKey.toLowerCase()) || data.user.displayName.toLowerCase().includes(searchKey.toLowerCase()));
    }
    if (searchStatus !== "all") {
      const projectStatus = searchStatus === "open" ? 1 : 0;
      filteredProjects = filteredProjects.filter((data) => data.project_status === projectStatus);
    }
    if (searchTrack !== "all") {
      filteredProjects = filteredProjects.filter((data) => data.overall_status === searchTrack);
    }
    action(_portfolio.loadProjects(filteredProjects));
  };

  const __clearSearch = () => {
    setSearchKey("");
    setSearchStatus("all");
    setSearchTrack("all");
    __initPf();
  };

  return (
    <div className="main-content">
      <div className="project-col">
        <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "space-between", marginBottom: "10px", alignItems: "center", justifyItems: "center" }}>
          <div style={{ display: "flex", flexShrink: 1, flexDirection: "row", backgroundColor: "white", padding: "5px", borderRadius: 10, boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px" }}>
            <div style={{ marginTop: "3px" }}>Filter:</div>
            <div>
              <input className="search-box-input ml-2" type="text" value={searchKey} onChange={(e) => setSearchKey(e.target.value)} />
            </div>
            <div>
              <select name="project_status" id="project_status" style={{ backgroundColor: "#e6e6e6", padding: "5px", marginLeft: "10px" }} value={searchStatus} onChange={(e) => setSearchStatus(e.target.value)}>
                <option value="all">All</option>
                <option value="open">Open</option>
                <option value="closed">Closed</option>
              </select>
            </div>
            <div>
              <select name="project_track" id="project_track" style={{ backgroundColor: "#e6e6e6", padding: "5px", marginLeft: "10px" }} value={searchTrack} onChange={(e) => setSearchTrack(e.target.value)}>
                <option value="all">All</option>
                <option value="low">On Track</option>
                <option value="mid">At Risk</option>
                <option value="high">Off Track</option>
              </select>
            </div>
            <div>
              <button style={{ backgroundColor: "#f0f0f0", padding: "3px", marginLeft: "10px", width: 30, borderRadius: 25 }} onClick={__filterProjects}>
                Go
              </button>
              <button style={{ backgroundColor: "#f0f0f0", padding: "3px", marginLeft: "10px", borderRadius: 10 }} onClick={__clearSearch}>
                Clear
              </button>
            </div>
          </div>
          {portfolioProjects.filter((data) => data.in_autotask === 1).length > 1 && (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
              <div style={{ fontSize: 11, fontStyle: "italic", marginTop: 2 }}>Last sync: {lastSync === "None" ? "None" : toFullDateTime(lastSync)}</div>
              <button
                title="Force Autotask sync now..."
                style={{ backgroundColor: "#E6FFF6", padding: "3px", marginLeft: "10px", borderRadius: 10, paddingLeft: "5px", paddingRight: "5px", fontSize: 10, boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px" }}
                onClick={() => __syncAtPortfolio()}
              >
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                  <i className="ms-Icon ms-Icon--Refresh" style={{ pointerEvents: "none", marginRight: 2 }} />
                  <div>{"Sync Now"}</div>
                </div>
              </button>
            </div>
          )}
        </div>
        <div className="project-row">
          {/* */}
          {portfolioProjects?.length > 0 ? (
            <React.Fragment>
              {portfolioProjects
                ?.filter((data) => data.project_status === 1)
                .map((data) => (
                  <NavLink key={data.pid} title={`${data.company} - ${data.name} :${data.at_pid}`} onClick={() => action(_loadDetails(data))} exact to={`${url}/details/${data.pid}`}>
                    <ProjectBox
                      data={{
                        syncing: false,
                        isOpen: data.project_status,
                        title: `${data.company} - ${data.name}`,
                        photo: data.user.photo,
                        status: data.overall_status,
                        revenue: data.project_services + data.contingency,
                        invoiced: data.invoiced,
                        remaining: data.budget_remaining,
                        dueDate: toDate(data.end_date),
                        effortForecast: data.forecast_hours,
                        effortSpent: data.effort_spent,
                        effortRemaining: data.effort_remaining,
                        inPlanner: data.in_planner,
                        inAutotask: data.in_autotask,
                        projectType: data.project_type,
                      }}
                    />
                  </NavLink>
                ))}
            </React.Fragment>
          ) : (
            <>
              {loading ? (
                <>
                  <div className="project-box pb-3">
                    <ProjectLoader />
                  </div>
                </>
              ) : (
                "No projects found..."
              )}
            </>
          )}
          {/* */}
        </div>

        <div className="project-row">
          {/* */}
          {portfolioProjects?.length > 0 ? (
            <React.Fragment>
              {portfolioProjects
                ?.filter((data) => data.project_status === 0 && datePast(data.closed_date ?? data.updatedAt) <= 30)
                .map((data) => (
                  <NavLink key={data.pid} onClick={() => action(_loadDetails(data))} exact to={`${url}/details/${data.pid}`}>
                    <ProjectBox
                      data={{
                        syncing: false,
                        isOpen: data.project_status,
                        title: `${data.company} - ${data.name}`,
                        photo: data.user.photo,
                        status: data.overall_status,
                        revenue: data.project_services + data.contingency,
                        invoiced: data.invoiced,
                        remaining: data.budget_remaining,
                        dueDate: toDate(data.end_date),
                        effortForecast: data.forecast_hours,
                        effortSpent: data.effort_spent,
                        effortRemaining: data.effort_remaining,
                        inPlanner: data.in_planner,
                        inAutotask: data.in_autotask,
                        projectType: data.project_type,
                      }}
                    />
                  </NavLink>
                ))}
            </React.Fragment>
          ) : (
            ""
          )}
          {/* */}
        </div>
        {/* Alerts and notification */}
        <div>
          <ToastContainer position="top-center" autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
      </div>
    </div>
  );
}

export default ContentProject;
