/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col, Button, Image } from "react-bootstrap";
import "../../../assets/css/contentProjects/addProject.css";
//import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { mW, mH, toDate, baseApiURL, toSqlDate, toDigitDate, sDate, eDate, nextDay } from "../../../components/reusableFunctions";
//import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DefaultButton } from "@fluentui/react/lib/Button";
import DatePicker from "react-datepicker";
import { nanoid } from "nanoid";
import axios from "axios";
import Swal from "sweetalert2";
import Select, { components } from "react-select";
import { getTheme } from "@fluentui/react";
import { FluentIcon } from "../../../components/FluentIcon.js";
import { ToastContainer, toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";
import { _addNewTask, _updateNewTask, _delNewTask, _addNewChecklist, _updateNewChecklist, _delNewChecklist, _resetNewProject } from "../../../actions/newProjectActions";
import { graphConfig } from "../../../authConfig";
import { doPatchGraphApi, doPostGraphApi, doGetGraphApi } from "../../../components/graph";

function AddTask(props) {
  const isMounted = useRef(false);
  const theme = getTheme();
  const { url } = useRouteMatch();
  const action = useDispatch();
  const history = useHistory();
  const newProject = useSelector((state) => state.newProject);
  const portfolioSettings = useSelector((state) => state.portfolioSettings);
  const portfolioMembers = useSelector((state) => state.portfolioMembers);
  const [selectedBucket, setSelectedBucket] = useState(newProject.buckets[0]?.bid || "");
  const [memberPicker, setMemberPicker] = useState([]);

  //build memberPicker with icons
  const { Option } = components;
  const CustomSelectOption = (props) => (
    <Option {...props}>
      <Image src={props.data.icon} style={{ marginTop: "-3px", width: "22px", height: "22px" }} fluid roundedCircle />
      {props.data.label}
    </Option>
  );

  const CustomSelectValue = (props) => (
    <div>
      <Image src={props.data.icon} style={{ marginTop: "-3px", width: "22px", height: "22px" }} fluid roundedCircle />
      {props.data.label}
    </div>
  );

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      if (portfolioMembers?.length > 0) {
        let pickerOptions = [];
        for (const member of portfolioMembers) {
          const option = {};
          option.value = member.id;
          option.label = member.displayName;
          option.icon = member.photo;
          pickerOptions = [...pickerOptions, option];
        }
        setMemberPicker(pickerOptions);
      }
    }
  }, [portfolioMembers]);

  useEffect(() => {
    //no pr-code catcher
    if (isMounted.current) {
      if (newProject.details.pid === "") {
        history.push(url.replace("/bucket/task", ""));
      }
      if (url === "/members/add/project/bucket/task") {
        props.setModuleTitle("ADD NEW TASK");
      }
    }
  }, []);

  const __toast = (msg, link, time = 2500, w = 30) =>
    toast.error(msg, {
      onClose: () => (link ? (window.location.href = link) : false),
      position: "top-center",
      autoClose: time,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      className: `toast-width-${w}`,
      progress: undefined,
    });
  const __handlePrevious = () => {
    props.setModuleTitle("ADD NEW PROJECT");
    //props.setModuleTitle("ADD NEW BUCKET");
    //history.push(url.replace("/task", ""));
    history.push(url.replace("/bucket/task", ""));
  };

  const __handleAddTask = () => {
    action(
      _addNewTask({
        pid: newProject.details.pid,
        bid: selectedBucket,
        tid: nanoid(6),
        name: "",
        status: 0,
        assignments: [],
        start_date: newProject.project_schedule.start_date,
        due_date: new Date(nextDay(newProject.project_schedule.start_date)),
      })
    );
  };

  const __handleTaskAssignment = (value, tid) => {
    action(_updateNewTask({ tid: tid, bid: selectedBucket, assignments: value }));
  };

  const __handleTaskNameChange = (e) => {
    const tid = e.target.getAttribute("data-tid");
    const value = e.target.value;
    if (value.length > 120) {
      __toast("Task name too long", null, 1500, 15);
      return;
    }
    action(_updateNewTask({ tid: tid, bid: selectedBucket, name: value }));
  };

  const __handleDelTask = (e) => {
    const tid = e.target.getAttribute("data-tid");
    action(_delNewTask({ tid: tid, bid: selectedBucket }));
  };

  const __handleBucketChange = (e) => {
    const bid = e.target.value;
    setSelectedBucket(bid);
    //console.log(newProject.tasks.filter((data, index) => data.bid === +id));
  };

  const __handleTaskStart = (date, tid) => {
    if (toDigitDate(date) < toDigitDate(newProject.project_schedule.start_date) || toDigitDate(date) > toDigitDate(newProject.project_schedule.end_date)) {
      __toast(`Selected start date is beyond project schedule`, null, 2500, 25);
      return;
    }
    const task = newProject.tasks.filter((data) => data.tid === tid);
    if (toDigitDate(task[0].due_date) <= toDigitDate(date)) {
      action(_updateNewTask({ tid: tid, bid: selectedBucket, due_date: new Date(nextDay(date)) }));
    }
    action(_updateNewTask({ tid: tid, bid: selectedBucket, start_date: date }));
  };

  const __handleTaskDue = (date, tid) => {
    if (toDigitDate(date) > toDigitDate(newProject.project_schedule.end_date)) {
      __toast(`Selected due date is beyond project schedule`, null, 2500, 25);
      return;
    }
    const task = newProject.tasks.filter((data) => data.tid === tid);
    if (toDigitDate(task[0].start_date) > toDigitDate(date)) {
      __toast(`Selected due date can't be earlier than start date`, null, 2500, 25);
      return;
    }
    action(_updateNewTask({ tid: tid, bid: selectedBucket, due_date: date }));
  };

  const __handleAddChecklist = (e) => {
    const tid = e.target.getAttribute("data-tid");
    action(
      _addNewChecklist({
        pid: newProject.details.pid,
        bid: selectedBucket,
        tid: tid,
        cid: nanoid(6),
        name: "",
        status: 0,
      })
    );
  };

  const __handleUpdateChecklist = (e) => {
    const tid = e.target.getAttribute("data-tid");
    const cid = e.target.getAttribute("data-cid");
    const value = e.target.value;
    if (value.length > 120) {
      return;
    }
    action(_updateNewChecklist({ cid: cid, tid: tid, bid: selectedBucket, name: value }));
  };

  const __kdChecklist = (e) => {
    if (e.key === "Enter") {
      __handleAddChecklist(e);
      e.key = "Tab";
    }
  };

  const __handleDelChecklist = (e) => {
    const tid = e.target.getAttribute("data-tid");
    const cid = e.target.getAttribute("data-cid");
    action(_delNewChecklist({ cid: cid, tid: tid, bid: selectedBucket }));
  };

  const __handleSubmit = async () => {
    try {
      const taskValues = newProject.tasks.map((data) => (data.name.length === 0 ? false : true));
      const checklistValues = newProject.task_checklists.map((data) => (data.name.length === 0 ? false : true));
      if (taskValues.includes(false) || checklistValues.includes(false)) {
        __toast("Please fill-up all fields", null, 1500, 15);
        return;
      }
      __saveProgress();

      const projectResult = await __postProject();
      if (projectResult?.error) {
        Swal.close();
        console.error(projectResult.error);
        __toast(projectResult.error, null, 2000, 30);
      } else {
        if (projectResult.pid) {
          const postElementsResult = await __processProjectElements(projectResult);
          if (postElementsResult?.error) {
            console.error(postElementsResult.error);
            __toast(postElementsResult.error, null, 2000, 30);
          } else {
            if (newProject.details.in_autotask) {
              let resList = [];
              const atData = JSON.parse(portfolioSettings?.at_Data);
              if (atData?.resources?.length > 0) {
                resList = atData.resources.map((data) => {
                  return { id: data.id, name: !data.firstName && !data.lastName ? data.email : `${data.firstName} ${data.lastName}` };
                });
              }

              const timeEntries = newProject.time_sheets;
              if (timeEntries.length > 0) {
                for (const timeEntry of timeEntries) {
                  const resource = resList?.filter((data) => data.id === timeEntry.resourceID);
                  const payload = {
                    date_worked: toDate(timeEntry.dateWorked),
                    hours_worked: timeEntry.hoursToBill,
                    description: timeEntry.summaryNotes,
                    pid: newProject.details.pid,
                    user_id: newProject.details.created_by,
                    at_teid: timeEntry.id,
                    status: 0,
                    at_data: resource.length > 0 ? JSON.stringify(resource[0]) : null,
                  };
                  const result = await __apiSaveTimesheet(payload);
                  if (!result) {
                    console.log(`ERROR: TimeEntry #${timeEntry.id} not saved`);
                  } else {
                    console.log(`TimeEntry #${timeEntry.id} saved as timesheet`);
                  }
                }
              }
            }
            console.log("Saving project success!");
            Swal.close();
            __successAlert();
          }
        } else {
          Swal.close();
          __toast("Something went wrong while saving project.", null, 2000, 30);
          console.error("posting result empty...");
        }
      }
    } catch (error) {
      Swal.fire("Error", "Something went wrong. Please contact us!", "error");
    }
  };

  const __postProject = async () => {
    const project = newProject.details;
    //If project option is to sync to planner
    if (newProject.details.in_planner === 1) {
      const plannerPayload = {
        owner: portfolioSettings.ms_365_group,
        title: `${newProject.details.company} - ${newProject.details.name}`,
      };
      const projectResult = await doPostGraphApi(graphConfig.postPlan, plannerPayload);
      console.log(projectResult);
      if (projectResult?.error) {
        __toast(projectResult.error, null, 2000, 25);
        return projectResult;
      }
      project.ms_pid = projectResult?.id;
      console.log("posted to planner OK");
    } else {
      project.ms_pid = null;
    }
    //If project is pulled from autotask
    if (newProject.details.in_autotask === 1 && (project.name !== project.orig_name || newProject.project_schedule.end_date !== newProject.project_schedule.orig_end_date)) {
      const payloadProject = {
        id: newProject.details.at_pid,
        projectName: newProject.details.name,
        endDateTime: eDate(newProject.project_schedule.end_date).toISOString(),
      };
      const resPatchProject = await axios.patch(`${baseApiURL}/at/upsert/${portfolioSettings.pfid}`, { url: `/Projects/`, payload: payloadProject });
      const patchedProject = resPatchProject.data;
      if (patchedProject.result) {
        console.log("Autotask project update OK");
      } else {
        return { error: `ERROR: updating the project in Autotask` };
      }
    }
    project.start_date = toSqlDate(sDate(newProject.project_schedule.start_date)).toString();
    project.end_date = toSqlDate(eDate(newProject.project_schedule.end_date)).toString();
    project.forecast_burndowns = newProject.forecast_burndowns;

    console.log("saving project and forecast burndowns...");
    const result = await __saveProject(project);
    if (result?.error) {
      return { error: result.error };
    } else {
      for (const fb of newProject.forecast_burndowns) {
        fb.financial = fb.financial === "" ? 0 : +fb.financial;
        fb.effort = fb.effort === "" ? 0 : +fb.effort;
        const burndownsResult = await __saveBurndown(fb);
        if (burndownsResult?.error) {
          return { error: burndownsResult.error };
        }
      }
      console.log("saving project and forecast burndowns... done");
    }
    return project;
  };

  const __apiSaveTimesheet = async (payload) => {
    console.log("saving timesheet...");
    return axios
      .post(`${baseApiURL}/timesheet/add`, payload)
      .then((res) => {
        if (res.data.error) {
          __toast("DB Error: Adding timesheet failed", null, null, 30);
          console.log(res.data.error);
          return false;
        } else {
          console.log(res.data);
          return true;
        }
      })
      .catch(function (error) {
        __toast("API Error: Adding timesheet failed", null, null, 30);
        console.log(error);
        return false;
      });
  };

  const __processProjectElements = async (project) => {
    let buckets = [];
    let tasks = [];
    const bucketsResult = await __processBuckets(project, buckets);
    if (bucketsResult?.error) {
      return bucketsResult.error;
    } else {
      console.log("Buckets saved successfully!");
      const tasksResult = await __processTasks(bucketsResult, tasks);
      if (tasksResult?.error) {
        return { error: tasksResult.error };
      } else {
        console.log("Tasks saved successfully!");
        const checklistsResult = await __processChecklists(tasksResult);
        if (checklistsResult?.error) {
          return { error: checklistsResult.error };
        }
        return true;
      }
    }
  };

  const __processBuckets = async (project, buckets) => {
    console.log("Processing buckets...");
    if (newProject.buckets.length !== 0) {
      for (const bucket of newProject.buckets) {
        const result = await __postBucket(project, bucket);
        if (result?.error) {
          return { error: result.error };
        } else {
          buckets = [...buckets, { ...result }];
        }
      }
      return buckets;
    }
  };

  const __processTasks = async (buckets, tasks) => {
    console.log("Processing tasks...");
    for (const bucket of buckets) {
      let tmp_buckets = newProject.tasks.filter((data) => data.bid === bucket.bid);
      if (tmp_buckets.length > 0) {
        for (const task of tmp_buckets.reverse()) {
          const result = await __postTask(bucket, task);
          if (result?.error) {
            return { error: result.error };
          } else {
            tasks = [...tasks, { ...result }];
          }
        }
      }
    }
    return tasks;
  };

  const __processChecklists = async (tasks) => {
    console.log("Processing checklists...");
    for (const task of tasks) {
      let taskChecklists;
      const checklists = newProject.task_checklists
        .filter((data) => data.tid === task.tid)
        .map((item, index) => {
          return {
            [item.cid]: {
              "@odata.type": "microsoft.graph.plannerChecklistItem",
              orderHint: `${(index + 1).toString().padStart(5, "0")} !`,
              title: item.name,
              isChecked: false,
            },
          };
        });
      if (checklists.length > 0) {
        for (const cl of checklists) {
          taskChecklists = { ...taskChecklists, ...cl };
        }
        const payload = { checklist: taskChecklists, previewType: "checklist" };
        console.log("Checklist Payload:", payload);
        // "If-Match" header with the last known e-tag for the task required for adding checklist
        const result = await __postChecklist(payload, task);
        if (result?.error) {
          return { error: result.error };
        }
      }
    }
    return true;
  };

  const __postBucket = async (project, bucket) => {
    if (newProject.details.in_planner === 1) {
      const payload = {
        name: bucket.name,
        planId: project.ms_pid,
        orderHint: " !",
      };
      const result = await doPostGraphApi(graphConfig.postBucket, payload);
      if (result?.error) {
        return { error: `Something went wrong saving bucket: ${bucket.name}` };
      }
      bucket.ms_pid = project.ms_pid;
      bucket.ms_bid = result.id;
    }
    const saveResult = await __saveBucket(bucket);
    if (saveResult?.error) {
      return { error: saveResult.error };
    }
    return saveResult;
  };

  const __postTask = async (bucket, task) => {
    if (newProject.details.in_planner === 1) {
      let assignments = {};
      if (task?.assignments?.length > 0) {
        for (const ta of task.assignments) {
          assignments[ta.value] = {
            "@odata.type": "#microsoft.graph.plannerAssignment",
            orderHint: `${ta.label.replace(" ", "")} !`,
          };
        }
      }
      const payload = {
        title: task.name,
        planId: bucket.ms_pid,
        bucketId: bucket.ms_bid,
        startDateTime: sDate(task.start_date),
        dueDateTime: eDate(task.due_date),
        assignments: assignments,
        orderHint: " !",
      };
      if (task?.assignments?.length === 0) delete payload.assignments;
      const result = await doPostGraphApi(graphConfig.postTask, payload);
      if (result?.error) {
        return { error: `Something went wrong saving task: ${task.name}` };
      }
      task.ms_pid = bucket.ms_pid;
      task.ms_bid = bucket.ms_bid;
      task.ms_tid = result.id;
    }
    if (newProject.details.in_autotask === 1) {
      if (task.at_tid) {
        if (task.name !== task.orig_name || task.start_date !== task.orig_start_date || task.due_date !== task.orig_due_date) {
          const payload = {
            id: task.at_tid,
            title: task.name,
            projectID: newProject.details.at_pid,
            startDateTime: sDate(task.start_date),
            endDateTime: eDate(task.due_date),
          };
          const resPatchTask = await axios.patch(`${baseApiURL}/at/upsert/${portfolioSettings.pfid}`, { url: `/Projects/${payload.projectID}/Tasks`, payload: payload });
          if (!resPatchTask.data.result) {
            return { error: `Something went wrong updating the task in Autotask` };
          } else {
            console.log("Autotask project task updated...");
          }
        }
      } else {
        const payload = {
          projectID: newProject.details.at_pid,
          title: task.name,
          startDateTime: sDate(task.start_date),
          endDateTime: eDate(task.due_date),
          status: 1,
          taskType: 1,
        };
        const resPostTask = await axios.post(`${baseApiURL}/at/upsert/${portfolioSettings.pfid}`, { url: `/Projects/${payload.projectID}/Tasks`, payload: payload });
        if (!resPostTask.data.result) {
          return { error: `Something went wrong adding new task in Autotask` };
        } else {
          task.at_tid = resPostTask.data.itemId;
        }
      }
    }
    task.pid = bucket.pid;
    task.bid = bucket.bid;
    task.assignments = JSON.stringify(task.assignments);
    task.start_date = toSqlDate(sDate(task.start_date));
    task.due_date = toSqlDate(eDate(task.due_date));
    const saveResult = await __saveTask(task);
    if (saveResult?.error) {
      return { error: saveResult.error };
    }
    return saveResult;
  };

  const __postChecklist = async (payload, task) => {
    if (newProject.details.in_planner === 1) {
      console.log("getting task details...");
      const resultGet = await doGetGraphApi(graphConfig.postTaskDetails.replace("[task-id]", task.ms_tid));
      if (resultGet?.error) {
        return { error: `Something went wrong getting details for task: ${task.tid}` };
      } else {
        const etag = resultGet["@odata.etag"];
        console.log("etag found posting checklists...");
        const resultPatch = await doPatchGraphApi(graphConfig.postTaskDetails.replace("[task-id]", task.ms_tid), payload, etag);
        if (resultPatch?.error) {
          return { error: `Something went wrong saving checklist for task: ${task.tid}` };
        }
      }
    }
    for (const checklist of newProject.task_checklists.filter((data) => data.tid === task.tid)) {
      const saveResult = await __saveChecklist(checklist);
      if (saveResult?.error) {
        return { error: saveResult.error };
      }
    }
    return true;
  };

  const __saveProject = async (payload) => {
    payload.active = 1;
    console.log("saving project...");
    return axios
      .post(`${baseApiURL}/project/add`, payload)
      .then((res) => {
        return res.data;
      })
      .catch(function (error) {
        console.error(error);
        __toast("ERROR: Something went wrong while saving Project.", null, null, 30);
        return { error: error };
      });
  };

  const __saveBurndown = async (payload) => {
    console.log("saving burndown...");
    return axios
      .post(`${baseApiURL}/project/burndown/add`, payload)
      .then((res) => {
        return res.data;
      })
      .catch(function (error) {
        console.error(error);
        __toast(`ERROR: Something went wrong saving Forecast Burndown: ${payload.name}`, null, null, 30);
        return { error: error };
      });
  };

  const __saveBucket = async (payload) => {
    console.log("saving bucket...");
    return axios
      .post(`${baseApiURL}/bucket/add`, payload)
      .then((res) => {
        return res.data;
      })
      .catch(function (error) {
        console.error(error);
        __toast("ERROR: Something went wrong while saving Bucket.", null, null, 30);
        return { error: error };
      });
  };

  const __saveTask = async (payload) => {
    console.log("saving task...");
    return axios
      .post(`${baseApiURL}/task/add`, payload)
      .then((res) => {
        return res.data;
      })
      .catch(function (error) {
        console.error(error);
        __toast("ERROR: Something went wrong while saving task.", null, null, 30);
        return { error: error };
      });
  };

  const __saveChecklist = async (payload) => {
    console.log("saving checklist...");
    return axios
      .post(`${baseApiURL}/checklist/add`, payload)
      .then((res) => {
        return res.data;
      })
      .catch(function (error) {
        console.error(error);
        __toast("ERROR: Something went wrong while saving task.", null, null, 30);
        return { error: error };
      });
  };

  const __successAlert = () => {
    Swal.close();
    Swal.fire({
      position: "top-end",
      icon: "success",
      title: "Project successfully created!",
      showConfirmButton: false,
      timer: 1500,
      didClose: () => {
        console.log("Project saving done...");
        //window.location.replace(url.replace("/add/project/bucket/task", "/projects"));
        const at_pid = newProject.details.at_pid;
        const atProjectsList = newProject.autotask_projects.filter((data) => data.id !== at_pid);
        localStorage.setItem("atProjectsList", JSON.stringify(atProjectsList));
        window.location.replace(url.replace("/add/project/bucket/task", "/projects"));
      },
    });
  };

  const __saveProgress = () => {
    Swal.fire({
      title: "Saving Project",
      html: `Please wait while we do our thing...`,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
  };

  const __confirmCancel = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "All data entered will be lost!",
      showCancelButton: true,
      cancelButtonText: "Cancel",
      confirmButtonText: "Confirm",
      confirmButtonColor: "#DF3D22",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        __handleCancel();
      }
    });
  };

  const __handleCancel = () => {
    action(_resetNewProject());
    history.push("/members");
  };

  return (
    <div className="main-content">
      <div className="pl-3" style={{ ...mW(50, 65) }}>
        <Row style={{ ...mH(40, "auto") }}>
          {/* LEFT Section */}
          <Col style={{ ...mW(30, 40) }}>
            {/* Top LEFT Section */}
            <Row className="pb-3 pr-4" style={{ ...mH(35, "auto") }}>
              <Col className="p-0">
                {/* BUCKET Selection */}
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2">Add Tasks to your Buckets. You can also add Checklist Items to your Tasks.</Form.Label>
                  <small className="text-primary">Note: Tasks imported from Autotask can be renamed but are non-removable</small>
                </Form.Row>
                <Form.Row className="mb-1 pb-2 bottom-border-dashed">
                  <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(0, 10) }}>
                    SELECTED BUCKET
                  </Form.Label>
                  <Col style={{ ...mW(0, 18) }}>
                    <Form.Control as="select" onChange={__handleBucketChange}>
                      {newProject.buckets.map((data) => (
                        <React.Fragment key={data.name}>
                          <option value={data.bid}>{data.name}</option>
                        </React.Fragment>
                      ))}
                    </Form.Control>
                  </Col>
                  <Col style={{ ...mW(0, 6.8) }} className="pl-0">
                    <DefaultButton className="float-right" style={{ boxShadow: theme.effects.elevation4, marginTop: "0.3em" }} text="Add Task" title="Add task to this bucket" onClick={__handleAddTask} allowDisabledFocus />
                  </Col>
                </Form.Row>
                {/* BUCKECT Section End */}
                {/* TASKS Section */}
                {newProject.tasks
                  .filter((data) => data.bid === selectedBucket)
                  .map((data, index) => (
                    <React.Fragment key={index}>
                      {data.bid === selectedBucket ? (
                        <React.Fragment>
                          <Form.Row>
                            <Form.Label className="mt-1 task-name" column="sm" style={{ ...mW(0, 9.5) }}>
                              #{index + 1} TASK NAME{" "}
                              <span className="mt-3" style={{ fontSize: "8px" }}>
                                {120 - data.name.length}
                              </span>
                            </Form.Label>
                            <Col>
                              <Form.Control size="md" type="text" placeholder="Add a name..." className="input-task-name" value={data.name} data-tid={data.tid} data-bid={data.bid} maxLength="120" onChange={__handleTaskNameChange} required />
                            </Col>
                            <Col style={{ ...mW(0, 6) }}>
                              {!data.at_tid && (
                                <Button className="i-btn" variant="danger" data-tid={data.tid} data-bid={data.bid} title="Delete task and all its checklists" size="sm" onClick={__handleDelTask}>
                                  <FluentIcon name="Delete" />
                                </Button>
                              )}
                              <Button className="ml-1 i-btn" variant="success" data-tid={data.tid} title="Add a checklist to this task" size="sm" onClick={__handleAddChecklist}>
                                <FluentIcon name="ChoiceColumn" />
                              </Button>
                            </Col>
                          </Form.Row>
                          <Form.Row>
                            <Form.Label className="pt-1" column="sm" style={{ ...mW(9.17, 0) }}>
                              <div className="float-right txt-sm-l">Start</div>
                            </Form.Label>
                            <Col style={{ ...mW(0, 7.5) }}>
                              <DatePicker selected={new Date(data.start_date)} onChange={(date) => __handleTaskStart(date, data.tid)} dateFormat={"dd-MMM-yyyy"} className="due-date mt-1 ml-3" />
                            </Col>
                            <Form.Label className="pt-1" column="sm" style={{ ...mW(0, 3) }}>
                              <div className="float-right txt-sm-l">Due</div>
                            </Form.Label>
                            <Col style={{ ...mW(0, 6.3) }} className="p-0">
                              <DatePicker selected={new Date(data.due_date)} onChange={(date) => __handleTaskDue(date, data.tid)} dateFormat={"dd-MMM-yyyy"} className="due-date mt-1 ml-3" />
                            </Col>
                            <div style={{ marginTop: "0.18em", marginLeft: "0.5em" }}>
                              <FluentIcon name="CalendarAgenda" />
                            </div>
                          </Form.Row>
                          <Form.Row>
                            <Form.Label className="pt-1 mt-1" column="sm" style={{ ...mW(9.17, 0) }}>
                              <div className="float-right txt-sm-l">Assigned To</div>
                            </Form.Label>
                            <Col style={{ ...mW(0, 28) }}>
                              <Select
                                className="ml-3 member-picker"
                                defaultValue={data.assignments}
                                options={memberPicker}
                                isMulti
                                isClearable={false}
                                styles={{
                                  control: (styles) => ({
                                    ...styles,
                                    backgroundColor: "none",
                                    border: "none",
                                  }),
                                }}
                                components={{
                                  Option: CustomSelectOption,
                                  MultiValueLabel: CustomSelectValue,
                                  DropdownIndicator: () => null,
                                  CrossIndicator: () => null,
                                  IndicatorSeparator: () => null,
                                }}
                                onChange={(value) => __handleTaskAssignment(value, data.tid)}
                              />
                            </Col>
                          </Form.Row>
                          {newProject.task_checklists.filter((item) => item.tid === data.tid).length !== 0 ? (
                            <Form.Row key={index} className="mb-3">
                              <Form.Label className="pt-1" column="sm" style={{ ...mW(9.17, 0) }}>
                                <div className="float-right txt-sm-l">Checklist</div>
                              </Form.Label>
                              <Col style={{ ...mW(19, 19) }}>
                                {newProject.task_checklists
                                  .filter((item) => item.tid === data.tid)
                                  .map((item) => (
                                    <Col style={{ ...mW(18.5, 18.5) }} key={item.cid}>
                                      <div className="check-mark">
                                        <FluentIcon name="StatusCircleRing" />
                                      </div>
                                      <Form.Control
                                        type="text"
                                        style={{ ...mW(16, 16) }}
                                        className="input-checklist"
                                        placeholder="Add a name..."
                                        value={item.name}
                                        data-tid={item.tid}
                                        data-cid={item.cid}
                                        maxLength="120"
                                        onChange={__handleUpdateChecklist}
                                        onKeyDown={__kdChecklist}
                                        required
                                      />
                                      <span className="mt-3" style={{ fontSize: "8px" }}>
                                        {120 - item.name.length}
                                      </span>
                                      <div className="del-checklist" data-cid={item.cid} data-tid={item.tid} title="Delete checklist" onClick={__handleDelChecklist}>
                                        <FluentIcon name="BoxMultiplySolid" />
                                      </div>
                                    </Col>
                                  ))}
                              </Col>
                            </Form.Row>
                          ) : (
                            ""
                          )}
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                    </React.Fragment>
                  ))}
              </Col>
            </Row>
            {/* LEFT Section END */}

            {/* Bottom LEFT Section END */}
          </Col>
          {/* LEFT Section End */}
          {/* Right Section */}
          <Col className="left-border-dashed" style={{ ...mW(0, 22) }}>
            {/* Top Right Section */}
            <Row className="bottom-border-dashed pl-4 pb-3">
              <Col className="pt-3">
                {/* Top Title-Right Area */}
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm">
                    PROJECT SCHEDULE
                  </Form.Label>
                </Form.Row>
                {/* Top Title-Right Area End */}
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(5.5, 5.5) }}>
                    Start Date
                  </Form.Label>
                  <Col style={{ ...mW(0, 9) }}>
                    <Form.Control size="md" type="text" placeholder="" value={toDate(newProject.project_schedule.start_date)} readOnly />
                    <div className="i-inside">
                      <FluentIcon name="Calendar" />
                    </div>
                  </Col>
                </Form.Row>
                <Form.Row className="mb-3">
                  <Form.Label className="pt-2 txt-lg" column="sm" style={{ ...mW(5.5, 5.5) }}>
                    End Date
                  </Form.Label>
                  <Col style={{ ...mW(0, 9) }}>
                    <Form.Control size="md" type="text" placeholder="" value={toDate(newProject.project_schedule.end_date)} readOnly />
                    <div className="i-inside">
                      <FluentIcon name="Calendar" />
                    </div>
                  </Col>
                </Form.Row>
              </Col>
            </Row>
            <Row className="pl-4">
              <Col className="pt-3">
                <Row className="txt-lg">TASKS OVERVIEW</Row>
                <div className="tiny">Shows all project tasks with their schedule for easy referencing while you go through each bucket.</div>
                {newProject.buckets.map((data) => (
                  <React.Fragment key={data.bid}>
                    <Row className="ov-bucket-name">{data.name}:</Row>
                    {newProject.tasks
                      .filter((task) => task.bid === data.bid)
                      .map((item, index) => (
                        <React.Fragment key={item.tid}>
                          <Row className="ov-task-name">
                            #{index + 1} {item.name ? item.name : "No name..."}
                          </Row>
                          <Row className="ov-task-dates">
                            {toDate(item.start_date)} → {toDate(item.due_date)}
                          </Row>
                        </React.Fragment>
                      ))}
                  </React.Fragment>
                ))}
              </Col>
            </Row>
            {/* Top Right Section End */}
          </Col>
          {/* Right Section End */}
        </Row>
        {/* Footer */}
        <Row className="mt-3">
          <div className="add-project-footer">
            <DefaultButton className="btn-default float-left" style={{ boxShadow: theme.effects.elevation4 }} text="Cancel" onClick={__confirmCancel} allowDisabledFocus />
            <DefaultButton className="btn-default float-right" style={{ boxShadow: theme.effects.elevation4 }} text="Submit Project" allowDisabledFocus onClick={__handleSubmit} />
            <DefaultButton className="btn-default float-right" style={{ boxShadow: theme.effects.elevation4 }} text="Previous" allowDisabledFocus onClick={__handlePrevious} />
          </div>
        </Row>
        {/* Footer End */}
        {/* Alerts and notification */}
        <div>
          <ToastContainer position="top-center" autoClose={2000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        </div>
      </div>
    </div>
  );
}

export default AddTask;
